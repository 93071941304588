.agent {
    margin:0 10px;
    flex-grow: 1;
    font-size : 10px;
    color: #FFF;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.agent > p {
    margin: 2px 0 0;
    color: #FFF;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    max-width: 183px;
}
.agent > img {
    width : 8px;
    margin-right: 4px;
}
.menuImage > img {
    width: 24px;
    cursor: pointer;
}
.menuCollapse, .menuBox{
    text-align: left;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 8px;
    background: #49648C;
    width: 328px;
    min-height: 49px;
    flex-shrink: 0;
    color: #fff;
    margin-left: 5px;
    margin-top: 17px;
}
.changeAgent{
    font-size: 10px;
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 22px !important;
    margin-right: 5px !important;
    margin-left: -83px !important;
    width: 40%;
    text-align: right;
}
.changeAgent >  img {
    /* width : 10px;
    height: 10px; */
    margin-left: 3px;
    margin-bottom: -5px;
    border-radius: 8px;
    width: 16.182px;
    height: 16.182px;
    flex-shrink: 0;
}
/* .changeAgent > a {
    color : #506AC6;
} */
.agentMenuOptions {
    padding: 9px 0 6px;
}
.agentMenuOptions > button{
    margin: 3px 2px 0 2px;
    /* border: 1px solid #33478A; */
    /* color :#33478A; */
    border-radius: 64px;
    background-color: #4178ff;
    display: inline-block;
    text-align: center;
    font-size: 9px;
    padding: 3.7px 5px;
    /* font-weight: 600; */
    cursor: pointer;
    min-width: 74px;
    height: 22px;
    flex-shrink: 0;
    color: #FFF;
    font-family: Lato;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
}
.smallText{
    font-size: 10px;
    font-weight: 500;
    text-align: right;
}
.menuAgentBox {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.changeNameText{
    color: #FFF !important;
    font-family: Lato;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none !important;
}
.menu-table-cell{background-color: #4178ff;height: 24px;width: 24px;border-radius: 50%;margin-top: -21px;}
.menu-table-icon{    
    margin-top: 4.5px;
    margin-left: 4.7px;
    height: 15px;
    width: 15px;
    cursor: pointer;
;}
.ac-input:focus-visible{outline: none !important;}
.agentMenuBtn{
    border:  1px solid #fff ;
    border-radius: 50% ;
    height: 16.18px ;
    width: 16.18px ;
    margin-top: -0.5px;
}
.agentMenuText{
    display: flex;
    align-items: center;
}