.minimizable-web-chat> .maximize {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 1.5rem;
  z-index: 9999;
  transition: all 0.3s ease-in-out;
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  border-radius: 64px;
  background-color: transparent;
  align-items: center;
  justify-content: flex-end;
  width: 3.5rem;
  height: 3.5rem;
  box-shadow: none;
  border: none;
}
  .minimizable-web-chat> .maximize:hover {
    width: 10.75rem;
  }

  .WebChat_chatIconText__xchxr{
    position: absolute;
    right: 3.75rem;
    transition: all 0.2s linear;
    white-space: nowrap;
    opacity: 0;
    color: white;
    margin-left: 0.5rem;
    font-size: 16px;
    font-family: Lato ;
    line-height: normal;
    font-weight: 400;
  }

.maximize {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  cursor: pointer;
}

.minimizable-web-chat>button.minimize {
  border-radius: 50%;
  bottom: 10px;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, .2); */
  background-color: transparent;
  font-size: 30px;
  height: 40px;
  outline: 0;
  position: fixed;
  right: 10px;
  width: 40px;
  z-index: 1000;
  border: none;
}

.minimizable-web-chat> .maximize>.red-dot {
  color: #EF0000;
  font-size: 12px;
  position: absolute;
  right: 6px;
  top: 2px;
}

.minimizable-web-chat> .maximize:focus:not(:active),
.minimizable-web-chat> .maximize:hover:not(:active) {
  background-color: #052a63;
  /* border-color: #069; */
}

.minimizable-web-chat> .maximize:active {
  background-color: White;
  color: #39C;
}

.minimizable-web-chat>.chat-box {
  background-color: rgba(255, 255, 255, .8);
  backdrop-filter: blur(10px);
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, .2);
  border: 4px solid #39C;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  max-width: 378px;
  min-width: 378px;
  position: fixed;
  top: 20px;
  width: 30%;
}

.minimizable-web-chat>.chat-box.hide {
  display: none;
}

.minimizable-web-chat>.chat-box.left {
  left: 20px;
}

.minimizable-web-chat>.chat-box.right {
  right: 30px;
  z-index: 999;
  background-color: rgb(5, 42, 99);
  padding-bottom: 20px;
}

.minimizable-web-chat>.chat-box>header {
  background-color: #39C;
  height: 61px;
  display: flex;
}

.minimizable-web-chat>.chat-box>header>.filler {
  flex: 0 10000 100%;
}

.minimizable-web-chat>.chat-box>header>button {
  width: 40px;
  height: 40px;
  background-color: Transparent;
  border: 0;
  color: White;
  outline: 0;
}

.minimizable-web-chat>.chat-box>header>button:focus:not(:active),
.minimizable-web-chat>.chat-box>header>button:hover:not(:active) {
  color: rgba(255, 255, 255, .6);
}

.minimizable-web-chat>.chat-box>header>button:focus:active {
  color: rgba(255, 255, 255, .4);
}

.minimizable-web-chat>.chat-box>.react-web-chat {
  flex: 1;
}

/* FIX: DI Updates */
.webchat__bubble .webchat__bubble__content {
  flex-grow: 0 !important;
}

.webchat__stacked-layout .webchat__stacked-layout__attachment>.webchat__bubble__content {
  background: transparent !important;
}

.webchat__stacked-layout .webchat__stacked-layout__attachment {
  min-width: 110px !important;
  max-width: fit-content !important;
}

.webchat__bubble:not(.webchat__bubble--from-user) .webchat__bubble__content {
  min-height: 0px !important;
  color: black !important;
  margin-top: 8px;
  margin-left: 3px;
}

.ac-container.ac-adaptiveCard {
  padding: 0px !important;
  margin: 0px !important;
  background-color: transparent !important;
  border: 0px !important;
  /* padding-bottom: 5px !important; */
}

.webchat__bubble__content {
  border-style: none !important;
}

#tipCard.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>div>.ac-image {
  width: 14px !important;
  padding-top: 0px !important;
  background-color: #1D3E71;
  margin-left: 12px;
}

#tipCard.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock>p {
  font-size: 13px !important;
  margin-top: 0px !important;
  padding-left: 4px !important;
  color: #fff !important;
  width: 100% !important;
  white-space: pre-wrap !important;
  background-color: #1D3E71;
}

#tipCard.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container {
  flex: none !important;
  background-color: #1D3E71;
}

#buttons.ac-container.ac-adaptiveCard>div>div>.ac-actionSet,
#commissionButtons.ac-container.ac-adaptiveCard>div>div>.ac-actionSet {
  display: flex !important;
  flex-direction: row !important;
  align-items: start !important;
  flex-wrap: wrap !important;
  background-color: #1D3E71;
}

#buttons.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>.ac-pushButton,
#commissionButtons.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>.ac-pushButton {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
  margin-right: 7px !important;
  /* background-color: #c49e45 !important; */
  background-color: #4178ff !important;
  border-style: hidden !important;
  color: white !important;
  border-radius: 64px !important;
  font-weight: 500 !important;
  font-size: 11px !important;
  cursor: pointer;
  min-width: 80px;
  height: 22px;
  flex-shrink: 0;
  font-family: Lato;
  font-style: normal;
  line-height: normal;
  margin-left: 20px;
}
#buttons.ac-container.ac-adaptiveCard>div>div>.ac-actionSet.button:first-child,
#commissionButtons.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>.ac-pushButton>button:first{
  margin-left: 0px !important;
}

.react-film__flipper .react-film__main__slider--right, .react-film__flipper .react-film__main__overlay .react-film__main__slider--right:hover {
  background: url("./../images/icon-Circle-right.svg") no-repeat;
  background-color: transparent !important;
  color: transparent !important;
  outline: 0;
  width: 32px !important;
  height: 32px !important;
  margin-left: 27px;
  cursor: pointer; 
}
.react-film__flipper__body{
  display: none;
}
.react-film__flipper .react-film__main__slider--left, .react-film__flipper .react-film__main__slider--left:hover {
  background: url("./../images/icon-Circle-left.svg") no-repeat;
  background-color: transparent !important;
  color: transparent !important;
  outline: 0;
  width: 32px !important;
  height: 32px !important;
  margin-left: 7px;
  cursor: pointer;

}

.react-film__filmstrip {
  width: 328px !important;
}


.ac-horizontal-separator {
  height: 0px !important;
}

.ac-vertical-separator {
  width: 0px !important;
}

.react-film__flipper .react-film__flipper__slider {
  position: static !important;
}

.chatBottom {
  margin: 4px 0 0px 20px;
  margin-bottom: -7px;
}

.bottomIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #FFF;
  font-family: Lato;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 6px;
  margin-bottom: -13px;
}

#resetIcon {
  width: 14px;
  height: 14px;
}

#helpIcon {
  width: 11px;
  height: 11px;
  cursor: pointer;
  margin-top: -1px;
}

.bottomIconData {
  cursor: pointer;
  /* font-weight: 600 !important;
  font-size: 11px !important; */
  margin: 0 4px 0 0;
}

.bottomdivider {
  display: inline-block;
  width: 1px;
  height: 10px;
  background: #fff;
  margin: 0 6px 0 2px;
}

#agentInfoCard.ac-container.ac-adaptiveCard,
#statusInfoCard.ac-container.ac-adaptiveCard {
  width: 98px !important;
  background-color: #F6F1E3 !important;
  height: 118px;
  border-radius: 4px;
  background-color: #49648c !important;
}

#statusInfoCard.ac-container.ac-adaptiveCard>div.ac-container>div.ac-container>div.ac-columnSet>div.ac-container>div.ac-textBlock>p {
  color: #6FCF4A !important;
  margin-left: 6px !important;
  margin-top: -4px !important;
  margin-bottom: 4px !important;
  font-size: 21px !important;
  display: none !important;
}

#statusInfoCard.ac-container.ac-adaptiveCard>div.ac-container>div.ac-container>div.ac-columnSet>div.ac-container>div.ac-container>div.ac-textBlock>p {
  /* color: #6FCF4A !important;
  font-size: 12px !important; */
  color: #FFF;
  font-family: Lato !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin-left: 7px;
}

#statusInfoCard.ac-container.ac-adaptiveCard>div.ac-container>div.ac-container>div.ac-columnSet>div.ac-container {
  flex-direction: row !important;
  margin-top: 7px !important;
}

#statusInfoCard.ac-container.ac-adaptiveCard>div.ac-container>div.ac-container>div.ac-columnSet>div.ac-container>div.ac-container>div.ac-container>div.ac-container>div.ac-container>div.ac-textBlock>p {
  /* color: #FF0000 !important;
  font-size: 12px !important; */
  color: #FFF;
  font-family: Lato !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin-left: 12px;
}

#statusInfoCard.ac-container.ac-adaptiveCard>div.ac-container>div.ac-container>div.ac-columnSet>div.ac-container>div.ac-container>div.ac-container>div.ac-container>div.ac-container>div.ac-container>div.ac-container>div.ac-textBlock>p {
  /* color: #FF0000 !important; */
  margin-left: 6px !important;
  margin-top: -4px !important;
  margin-bottom: 4px !important;
  font-size: 21px !important;
  display: none !important;
}

#statusInfoCard.ac-container.ac-adaptiveCard>div.ac-container>div.ac-container>div.ac-columnSet>div.ac-container>div.ac-container>div.ac-container>div.ac-container>div.ac-container>div.ac-container>div.ac-textBlock>p {
  /* color: #F89500 !important; */
  margin-left: 6px !important;
  margin-top: -4px !important;
  margin-bottom: 4px !important;
  font-size: 21px !important;
  display: none !important;
}

#statusInfoCard.ac-container.ac-adaptiveCard>div.ac-container>div.ac-container>div.ac-columnSet>div.ac-container>div.ac-container>div.ac-container>div.ac-container>div.ac-textBlock>p {
  /* color: #F89500 !important;
  font-size: 12px !important; */
  color: #FFF;
  font-family: Lato !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin-left: 6px;
}

#statusInfoCard.ac-container.ac-adaptiveCard>div.ac-container>div.ac-container>div.ac-columnSet>div.ac-container>div.ac-container>div.ac-container>div.ac-container>div.ac-textBlock,
#statusInfoCard.ac-container.ac-adaptiveCard>div.ac-container>div.ac-container>div.ac-columnSet>div.ac-container>div.ac-container>div.ac-textBlock,
#statusInfoCard.ac-container.ac-adaptiveCard>div.ac-container>div.ac-container>div.ac-columnSet>div.ac-container>div.ac-container>div.ac-container>div.ac-container>div.ac-container>div.ac-textBlock {
  margin-top: -3px !important;
  margin-left: 1px !important;
}

#statusInfoCard.ac-container.ac-adaptiveCard>div>div>.ac-actionSet {
  margin-bottom: 10px !important;
  padding-top: 22px !important;
}

#statusInfoCard.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>button.ac-pushButton.style-default {
  background-color: #4178ff  !important;
  color: white !important;
  margin-right: 7px !important;
  margin-left: 5px !important;
  font-size: 10px !important;
  border: none !important;
  padding: 4px !important;
  border-radius: 64px !important;
  cursor: pointer;
  width: 89px;
  height: 22px;
  flex-shrink: 0;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

#statusInfoCard.ac-container.ac-adaptiveCard>.ac-container>div.ac-columnSet>div.ac-container>.ac-textBlock>p {
  /* color: grey !important; */
  font-size: 10px !important;
  margin-left: 8px !important;
  width: 90% !important;
  line-height: 10px !important;
  overflow: hidden !important;
  height: 20px !important;
  text-overflow: ellipsis !important;
  margin-bottom: 8px !important;
  white-space: pre-wrap !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

#statusInfoCard.ac-container.ac-adaptiveCard>div.ac-container>div.ac-columnSet>div.ac-container>div.ac-container>div.ac-textBlock>p {
  font-size: 12px !important;
  margin-left: 7px !important;
  margin-bottom: 5px !important;
}

#statusInfoCard.ac-container.ac-adaptiveCard>div.ac-container>div.ac-columnSet>div.ac-container>div.ac-container>div.ac-textBlock {
  margin-top: -2px !important;
}

#agentInfoCard.ac-container.ac-adaptiveCard>.ac-textBlock {
  min-height: 20px !important;
}

#agentInfoCard.ac-container.ac-adaptiveCard>.ac-textBlock>p {
  margin-top: 5px !important;
  margin-left: 9px !important;
  margin-bottom: -2px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  width: fit-content !important;
}

#agentInfoCard.ac-container.ac-adaptiveCard>.ac-container>.ac-textBlock {
  min-height: 17px !important;
}

#agentInfoCard.ac-container.ac-adaptiveCard>.ac-container>.ac-textBlock>p {
  margin-top: -2px !important;
  margin-left: 9px !important;
  margin-right: 7px !important;
  font-size: 13px !important;
  width: fit-content !important;
}

#agentInfoCard.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock {
  min-height: 17px !important;
}

#agentInfoCard.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock>p {
  margin-top: 2px !important;
  margin-left: 9px !important;
  font-size: 10px !important;
  /* color: grey !important; */
  width: fit-content !important;
  color: #FFF;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#agentInfoCard.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  min-height: 17px !important;
}

#agentInfoCard.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock>hr {
  display: none !important;
}

#agentInfoCard.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock>p {
  margin-top: 0px !important;
  margin-left: 9px !important;
  font-size: 11px !important;
  font-weight: bold !important;
  margin-bottom: 3px !important;
  margin-right: 33px !important;
  width: fit-content !important;
}

#agentInfoCard.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>button.ac-pushButton.style-default {
  /* background-color: #c49e45 !important; */
  background-color: #4178FF !important;
  color: white !important;
  margin-right: 7px !important;
  margin-left: 5px !important;
  font-size: 10px !important;
  border: none !important;
  padding: 4px !important;
  border-radius: 64px !important;
  cursor: pointer;
  width: 89px;
  height: 22px;
  flex-shrink: 0;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

#agentInfoCard.ac-container.ac-adaptiveCard>div>div>.ac-actionSet {
  margin-bottom: 5px !important;
  margin-top: 16px;
  position: absolute;
  right: -2px;
  bottom: 2px;
}

#agentInfoCard.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>.ac-pushButton {
    /* font-weight: 500 !important; */
    color: #FFF;
    font-family: Lato;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#carrierCard.ac-container.ac-adaptiveCard>div>div>.ac-actionSet {
  display: flex !important;
  flex-direction: row !important;
  align-items: start !important;
  flex-wrap: wrap !important;
}

#carrierCard.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>.ac-pushButton {
  margin: 3px 3px !important;
  background-color: #c49e45 !important;
  border-style: hidden !important;
  color: white !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
  padding: 8px !important;
  font-size: 11px !important;
  cursor: pointer;
}

#carrierCard.ac-container.ac-adaptiveCard>div>div>div.ac-container.ac-adaptiveCard {
  background-color: white !important;
  border: none !important;
}

#carrierCard.ac-container.ac-adaptiveCard>div>div>div.ac-container.ac-adaptiveCard>div>div>div.ac-actionSet>.ac-pushButton {
  margin-left: 19px;
  margin-top: 8px;
  margin-bottom: 20px;
  background-color: #c49e45 !important;
  border-style: hidden !important;
  color: white !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
  padding: 8px !important;
  font-size: 11px !important;
  cursor: pointer;
}

#carrierCard.ac-container.ac-adaptiveCard>div>div>div.ac-container.ac-adaptiveCard>div>div>div.ac-actionSet {
  display: flex !important;
  flex-direction: row !important;
  align-items: start !important;
  flex-wrap: wrap !important;
}

#commissionCard.ac-container.ac-adaptiveCard {
  width: 153px !important;
  background: #49648c !important;
}

#commissionCard.ac-container.ac-adaptiveCard>.ac-textBlock {
  width: fit-content !important;
}

#commissionCard.ac-container.ac-adaptiveCard>.ac-textBlock>p {
  margin-top: 6px !important;
  margin-left: 9px !important;
  font-size: 13px !important;
}

#commissionCard.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet {
  margin-top: 5px !important;
  min-height: 18px !important;
}

#commissionCard.ac-container.ac-adaptiveCard>.ac-container>.ac-container {
  max-width: 95px !important;
  flex: none !important;
}

#commissionCard.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-textBlock {
  width: fit-content !important;
  max-width: 95px !important;
}

#commissionCard.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-textBlock>p {
  width: 91% !important;
  margin-top: 2px !important;
  margin-left: 9px !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

#commissionCard.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container {
  margin-left: -44px !important;
}

#commissionCard.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container {
  width: fit-content !important;
}

/* #commissionCard.ac-container.ac-adaptiveCard> .ac-container> .ac-columnSet> .ac-container> .ac-textBlock {
margin-left: 7px !important;
font-size: 12px !important;
font-weight: bold !important;
width: fit-content !important;
} */

#commissionCard.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>div>img.ac-image {
  height: 14px !important;
  width: 14px !important;
  margin-left: 9px !important;
  margin-top: -1px !important;
}

#commissionCard.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock>p {
  font-size: 11px !important;
}

#commissionCard.ac-container.ac-adaptiveCard>.ac-container>.ac-textBlock {
  color: grey !important;
  font-size: 11px !important;
  margin-left: 9px !important;
  margin-top: -4px !important;
  margin-bottom: 4px !important;
}

#commissionDetails.ac-container.ac-adaptiveCard {
  background-color: #F6F1E3 !important;
  width: 88px !important;
}

#commissionDetails.ac-container.ac-adaptiveCard>.ac-textBlock>p {
  margin-top: 8px !important;
  margin-left: 10px !important;
  font-size: 13px !important;
  font-weight: bold !important;
  width: 85% !important;
}

#commissionDetails.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-textBlock>p,
#productLevel.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-textBlock>p {
  margin-top: -4px !important;
  margin-left: 10px !important;
  font-size: 12px !important;
  width: 85% !important;
}

#commissionDetails.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-textBlock,
#productLevel.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-textBlock {
  min-height: 17px !important;
}

#commissionDetails.ac-container.ac-adaptiveCard>.ac-container>.ac-textBlock>p {
  margin-top: 6px !important;
  margin-left: 9px !important;
  font-size: 15px !important;
  font-weight: bold !important;
  width: 85% !important;
}

#commissionDetails.ac-container.ac-adaptiveCard>.ac-container>.ac-textBlock {
  margin-bottom: 7px !important;
  margin-right: 45px !important;
  width: 100%;
}

#productLevel.ac-container.ac-adaptiveCard {
  background-color: #F6F1E3 !important;
  width: 88px !important;
}

#productLevel.ac-container.ac-adaptiveCard>.ac-textBlock>p {
  margin-top: 8px !important;
  margin-left: 10px !important;
  font-size: 13px !important;
  font-weight: bold !important;
  margin-bottom: 2px !important;
  margin-right: 10px !important;
  width: 85% !important;
}

#productLevel.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-textBlock {
  margin-bottom: 7px !important;
}

.ac-multichoiceInput {
  padding: 6px !important;
}

#dropDownCard.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>button.ac-pushButton.style-default>img,
#dropDownNoThanksCard.ac-container.ac-adaptiveCard>div.ac-container>div>div>.ac-actionSet>button.ac-pushButton.style-default>img {
  width: 35px !important;
  height: 35px !important;
  border-radius: 15px !important;
  margin-left: 5px !important;
  margin-bottom: 1px !important;
  margin-top: 24px !important;
  cursor: pointer !important;
}

#dropDownCard.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>button.ac-pushButton.style-default,
#dropDownNoThanksCard.ac-container.ac-adaptiveCard>div.ac-container>div>div>.ac-actionSet>button.ac-pushButton.style-default {
  color: none !important;
  border: none !important;
  background: none !important;
}

#dropDownCard.ac-container.ac-adaptiveCard>div#dropDownInfo>div.ac-input-container,
#dropDownNoThanksCard.ac-container.ac-adaptiveCard>div.ac-container>div#dropDownInfo>div.ac-input-container {
  width: 263px !important;
}

.ac-input.ac-multichoiceInput.ac-choiceSetInput-compact {
    /* border-radius: 7px !important; */
    width: 300px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #375582;
    border: none;
}

.webchat__adaptive-card-renderer .ac-pushButton {
  padding: 0px !important;
}

#dropDownNoThanksCard.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>button.ac-pushButton.style-default {
   /* background-color: #c49e45 !important; */
   border-style: hidden !important;
   color: white !important;
   border-radius: 64px !important;
   /* font-weight: 500 !important; */
   padding: 8px !important;
   font-size: 11px !important;
   cursor: pointer;
   font-family: Lato;
   font-size: 16px;
   font-style: normal;
   font-weight: 400 !important;
   line-height: normal;
   background-color: #4178ff;
}

#dropDownNoThanksCard.ac-container.ac-adaptiveCard>div.ac-textBlock {
  min-width: 300px !important;
  margin-top: -6px !important;
}

#dropDownNoThanksCard.ac-container.ac-adaptiveCard>div.ac-textBlock>hr {
  margin-top: 2px !important;
  width: 75% !important;
  border: transparent !important;
}

#dropDownCard.ac-container.ac-adaptiveCard,
#dropDownNoThanksCard.ac-container.ac-adaptiveCard>div.ac-container {
  display: flex !important;
  flex-direction: row !important;
}

#dropDownNoThanksCard.ac-container.ac-adaptiveCard>div>div>.ac-actionSet {
  align-items: center !important;
  /* width: 85% !important; */
}

#dropDownNoThanksCard.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>button.ac-pushButton.style-default>div {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

#dropDownNoThanksCard.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>button.ac-pushButton.style-default {
  margin-top: 5px !important;
}

#dropDownCard.ac-container.ac-adaptiveCard>div#dropDownInfo>label>span:nth-child(2),
#dropDownNoThanksCard.ac-container.ac-adaptiveCard>div.ac-container>div#dropDownInfo>label>span:nth-child(2) {
  display: none !important;
}

.iconDiff {
  font-size: 14px;
  font-weight: 200;
  padding-left: 2px;
  padding-right: 7px;
}

.webchat__send-box__main {
  width: 100%;
  /* padding-bottom: 20px; */
}

.webchat__send-box-text-box {
  padding: 23px 9px 2px 9px !important;
  /* max-width: 342px !important;*/
}

/* .webchat__send-box .webchat__send-box__button {
background: #33478A !important;
width: 28px !important;
height: 28px !important;
border-radius: 50% !important;
margin: 11px 17px 0 0 !important;
cursor: pointer;
position: relative;
} */

.webchat__send-box-text-box__input {
  /* min-height: 30px !important;
  background-color: #EDF0F5 !important;
  border-radius: 2px;
  padding-left: 5px !important;
  font-size: 14px !important; */
  border-radius: 5rem !important;
  background-color: #375582 !important;
  height: 39.618px !important;
  margin: 0 1rem !important;
  font-size: 16px !important;
  padding: 0 37px 0 1rem !important;
  font-family: Lato !important;
  color:#fff !important;
  padding-top: 5px !important;
}

.webchat__send-box-text-box__input::placeholder{
  color: rgba(255, 255, 255, 0.80) !important;
font-family: Lato !important;
font-size: 16px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: normal !important;
}

/* .webchat__send-box .webchat__send-box__button::after {
content: '';
background-image: url(../images/send-arrow.svg);
background-repeat: no-repeat;
background-size: 100% 100%;
background-size: contain;
height: 14px;
width: 16px;
position: absolute;
left: 0;
top: 0;
transform: translate(50%, 50%)
} */

/* .webchat__send-box .webchat__send-box__button svg {
display: none;
} */

.webchat__basic-transcript__focus-indicator {
  border: none !important;
}

#bot {
    /* height: 62px;
  width: 62px;
  margin: -20px 0px 0px -20px; */
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  cursor: pointer;
  font-family: Lato;
}

#close {
  height: 48px;
  width: 48px;
  margin: -3px 0px 0px -14px;
  cursor: pointer;
}

.webchat__stacked-layout__avatar-gutter {
  width: 30px !important;
}

.webchat__imageAvatar {
  width: 40px !important;
}

.webchat__defaultAvatar--fromUser>.webchat__imageAvatar>.webchat__imageAvatar__image>img {
  /* width: 25px !important;
  height: 18px !important; */
  width: 28px !important;
  height: 28px !important;
  margin-left: 2px;
  margin-top: -10px;
}

.webchat__stacked-layout__attachment-row {
  margin-left: 0px !important;
}

.react-film__flipper {
  width: 70px !important;
}

.webchat__stacked-layout__status>span {
  padding: 0px !important;
  padding-left: 0px !important;
  color: #FFF;
  font-family: Lato;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
  margin-left: 3px;
  margin-top: 3px;
}

.webchat__carousel-filmstrip__status>span {
  padding-top: 1px !important;
  margin-left: -13px !important;
  color: rgb(255, 255, 255, 0.5);
}

.webchat__carousel-filmstrip--no-message>.webchat__carousel-filmstrip__status>.webchat__carousel-filmstrip__avatar-gutter {
  margin-left: 0px !important;
  width: 0px !important;
}

.webchat__carousel-filmstrip .webchat__carousel-filmstrip__status {
  margin-left: 5px !important;
}

.webchat__send-icon {
  margin-left: -25px;
}

.webchat__basic-transcript__activity-indicator--focus {
  display: none !important;
}

.webchat__stacked-layout__message.webchat__bubble .webchat__bubble__content {
  width: 300px !important;
}

.webchat__stacked-layout__message.webchat__bubble:not(.webchat__bubble--from-user) .webchat__bubble__content {
  /* background-color: #EDF0F5 !important; */
  background-color: #375582 !important;
  color: #fff !important;
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* .webchat__carousel-filmstrip .webchat__carousel-filmstrip__main {
  /* max-width: 250px !important; 
  max-width: 100px !important;
} */

.webchat__stacked-layout__message.webchat__bubble:not(.webchat__bubble--from-user) .webchat__bubble__content,
.webchat__stacked-layout__message.webchat__bubble.webchat__bubble--from-user .webchat__bubble__content {
   /* border-radius: 0 5px 5px 5px !important; */
   border-radius: 0px 5px 5px 0px !important;
   margin-left: 12px !important;
   position: relative;
   overflow: initial !important;
   margin-bottom: 3px !important;
}

/* .webchat__stacked-layout__message.webchat__bubble:not(.webchat__bubble--from-user) .webchat__bubble__content::after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-top: 8px solid #EDF0F5;
  border-left: 10px solid transparent;
  top: 0;
  left: -8px;
} */

.webchat__stacked-layout__message.webchat__bubble.webchat__bubble--from-user .webchat__bubble__content::after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  /* border-top: 8px solid rgb(5, 42, 99); */
  border-right: 10px solid transparent;
  top: 0;
  right: -8px;
}

.webchat__stacked-layout__message.webchat__bubble.webchat__bubble--from-user .webchat__bubble__content {
  /* border-radius: 5px 0 0 5px !important; */
  margin-right: 0 !important;
  flex-shrink: 0;
  background-color: #375582 !important;
  /* text-align: right; */
  max-width: 300px !important;
  border-radius: 8px 0 0 8px !important;
  width: auto !important;
}

.webchat__send-box .webchat__send-box__main {
  border-top: none !important;
  -webkit-box-align: stretch;
  align-items: stretch;
  background-color: rgb(5, 42, 99) !important ;
  border-top: 0px;
  position:  relative !important;
}

/* .webchat__carousel-layout {
max-width: 300px !important;
} */

/* .webchat__basic-transcript {
  border-bottom: 1px solid #E7E8EA;
} */

button:focus,
button:focus-visible {
  outline: none !important;
}

.webchat__typingIndicator {
  margin-left: 40px !important;
}

.webchat__carousel-filmstrip--no-message {
  margin-left: 35px !important;
  margin-right: 54px !important;
}

.webchat__carousel-layout .react-film__flipper--left {
  left: 5px !important;
}

.webchat__carousel-layout .react-film__flipper--right {
  right: -4px !important;
}

.webchat__carousel-layout .webchat__carousel-filmstrip-attachment,
.webchat__carousel-layout .webchat__carousel-filmstrip__attachment {
  min-width: 88px !important;
  padding-left: 6.1px !important;
  background: #375582;
  padding-bottom: 7px;
  border-radius: 6px;
  max-width: fit-content !important;
}

.webchat__carousel-layout .react-film__main__slider--hide {
  display: none !important;
}

.initIcon {
  width: 100px;
  height: auto;
}

.initP {
  margin-top: 0px !important;
  color: #fff;
}

button:disabled,
button[disabled],
button[aria-label="Reminders"],
button[aria-label="Service Tickets"],
button[aria-label="Appointments"],
button[aria-label="Application Status"] {
  opacity: 0.5;
  cursor: auto !important;
  pointer-events: none;
}

button[aria-label="Appointments"],
button[aria-label="Application Status"] {
  display: none !important;
}

.webchat__carousel-filmstrip .webchat__carousel-filmstrip__alignment-pad {
  width: 0px !important;
}

.webchat__carousel-layout .react-film__flipper--left,
.webchat__carousel-layout .react-film__flipper--right {
   /* height: calc(100% - 18px) !important; */
   height: 34px !important;
   margin-top: 45px;
 }
 
 @font-face {
   font-family: "Lato";
   src: url("./../../../../fonts/Lato-Regular.woff") format("woff");
   font-weight: 100;
 }

#agentInfoCard.ac-container.ac-adaptiveCard>.ac-textBlock>p,
#agentInfoCard.ac-container.ac-adaptiveCard>.ac-container>.ac-textBlock>p,
#agentInfoCard.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock>p {
  width: 85% !important;
  color: #FFF;
  font-family: Lato;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  }
  
  #agentInfoCard.ac-container.ac-adaptiveCard>.ac-container>.ac-textBlock>p{
    font-weight: 400 !important;
    margin-top: 2px !important;
  }  

#payoutTable.ac-container.ac-adaptiveCard,
#awnTable.ac-container.ac-adaptiveCard,
#commissionTable.ac-container.ac-adaptiveCard,
#awn.ac-container.ac-adaptiveCard {
  width: 300px !important;
   /* background-color: #F6F1E3 !important;
  border-radius: 5px !important; */
  flex-shrink: 0;
  border-radius: 0px 8px 8px 0px !important;
  background: #375582 !important;
  margin-left: 12px !important;
}

#payoutTable.ac-container.ac-adaptiveCard>.ac-columnSet,
#commissionTable.ac-container.ac-adaptiveCard>.ac-columnSet,
#awn.ac-container.ac-adaptiveCard>.ac-columnSet {
  padding: 3px !important;
   margin-top: 12px !important;
   border: 0.5px solid #375582;
   background: rgb(255, 255, 255, 0.2);
   width: 276px !important;
   min-height: 18px;
   flex-shrink: 0;
}

#commissionTable.ac-container.ac-adaptiveCard>.ac-columnSet{
  margin-left: 12px !important;
}

#payoutTable.ac-container.ac-adaptiveCard>.ac-columnSet{margin-left: 12px !important;}

#awnTable.ac-container.ac-adaptiveCard>.ac-columnSet {
   /* background-color: #c49e45 !important; */
   padding: 3px !important;
   margin-left: 12px !important;
   margin-right: 7px !important;
   width: 276px;
   min-height: 18px;
   flex-shrink: 0;
   border: 0.5px solid #375582;
   background-color: rgba(255,255, 255, 0.2);
   font-size: 12px !important;
   font-weight: 500 !important;
   font-family: Lato !important;
}

#awnTable.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock {
  margin-left: 9px !important;
}

#awnTable.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-textBlock {
  color: white !important;
  margin-left: 5px !important;
  font-size: 12px !important;
  margin-top: 2px !important;
}

#payoutTable.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock,
#payoutTable.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock,
#awnTable.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock,
#awnTable.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock,
#commissionTable.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock,
#commissionTable.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock,
#awn.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock {
  color: white !important;
  font-size: 12px !important;
}

#awn.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container {
  flex-direction: row !important;
}

#awn.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container {
  margin-left: 120px !important;
}

#awn.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock>p {
  color: white !important;
  margin-left: 40px;
}

#awn.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock>p {
  margin-left: 5px !important;
}

#awn.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container {
  flex-direction: row !important;
  width: 130px !important;
}

#awn.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container {
  margin-left: 63px !important;
}

#awn.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container {
  margin-left: -22px !important;
}

#awn.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock {
  margin-left: 7px !important;
  font-size: 12px !important;
  width: 130px !important;
  margin-top: 2px !important;
}

#awn.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  font-size: 12px !important;
  /* border-color: #6FCF4A !important; */
  background-color: #4178FF !important;
  color: white !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  border-radius: 10px !important;
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

#awn.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-textBlock {
  font-size: 11px !important;
  background-color: #4178FF !important;
  /* border-color: #FF5F5F !important; */
  color: white !important;
  padding-left: 12px !important;
  padding-right: 11px !important;
  border-radius: 8px !important;
  margin: 2px 27px 0 -41px !important;
}

#awn.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-textBlock>p,
#awn.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock>p {
  margin-bottom: -2px !important;
  margin-top: -2px !important;
}

#payoutTable.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock {
  margin-left: 8px !important;
  margin-top: 1px !important;
}

#payoutTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet,
#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet,
#commissionTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet,
#awn.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet {
  /* border-color: #c49e45 !important;
  border-top: white !important;
  background: white !important; */
  /* border-style: solid !important; */
  margin-left: 12px !important;
  margin-right: 7px !important;
  border-width: 1px !important;
  width: 276px;
  min-height: 18px;
  flex-shrink: 0;
  border: 0.5px solid #375582;
  /* opacity: 0.2; */
  background: rgb(255, 255, 255, 0.2);
}

#payoutTable.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  text-align: right !important;
  margin-right: 8px !important;
}

#payoutTable #_payoutTablePayoutHeader {
  margin-right: 26px !important;
  margin-top: 2px !important;
}

#commissionTable.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  text-align: right !important;
  margin-right: 13px !important;
}

#payoutTable.ac-container.ac-adaptiveCard>.ac-container,
#awnTable.ac-container.ac-adaptiveCard>.ac-container,
#commissionTable.ac-container.ac-adaptiveCard>.ac-container,
#awn.ac-container.ac-adaptiveCard>.ac-container {
  margin-bottom: 7px !important;
}

#payoutTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container {
  flex: none !important;
}

#payoutTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock,
#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock,
#commissionTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock {
  margin-left: 3px !important;
}

#payoutTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock>p {
  width: 160px !important;
  padding-top: 2px;
}

#payoutTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock,
#payoutTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock,
#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock,
#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock,
#commissionTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock,
#commissionTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  font-size: 12px !important;
  margin-left: 12px !important;
  display: inline-block !important;
  text-align: left !important;
}

#commissionTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock {
  width: 142px !important;
}

#payoutTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  margin-left: 19px !important;
  margin-top: 2px !important;
}

#commissionTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  margin-right: 15px !important;
  text-align: right !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  /* max-width: 10px !important; */
  margin-left: 15px !important;
}

#commissionTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock>p {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

#payoutTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock>p {
  width: 91px !important;
  margin-left: 0px !important;
  text-align: left !important;
}

#awnTable.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  margin-left: 20px !important;
}

#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock {
  width: 85px !important;
}

#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  margin-left: 20px !important;
  margin-top: 2px !important;
}

#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock>p {
  width: 50px !important;
}

#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-textBlock {
  margin-left: 7px !important;
  margin-right: 47px !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  font-family: Lato !important;
  /* border-color: #6FCF4A !important;
  background-color: #7BDC52 !important;
  color: white !important; */
  padding-left: 10px !important;
  border-radius: 10px !important;
  margin-top: 6px !important;
  margin-bottom: 3px !important;
  width: 41px;
  height: 14px;
  flex-shrink: 0;
  background-color: #4178FF !important;
  position: absolute !important;
}

#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-container{
  flex-direction: initial !important;
  justify-content: initial !important;
}
#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container>div:nth-child(3){
  align-items: initial !important;
  justify-content: initial !important;
  margin-top: -2px !important;
}
#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container .ac-image{
  min-width: 0px;
  max-width: 100%;
  margin-left: 34px !important;
  max-height: 100%;
  z-index: 9999;
  position: relative !important;
  margin-top: 4px;
  color: #ffffff;
  margin-top: 5px;
}

#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-container>.ac-textBlock {
  /* font-size: 11px !important;
  background-color: #FF7F7F !important;
  border-color: #FF5F5F !important;
  color: white !important; */
  padding-left: 12px !important;
  border-radius: 8px !important;
  margin-top: 3px !important;
  margin-bottom: 3px !important;
  margin-left: 7px !important;
  margin-right: 46px !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  font-family: Lato !important;
  padding-left: 10px !important;
  border-radius: 10px !important;
  margin-top: 3px !important;
  margin-bottom: 3px !important;
  width: 41px;
  height: 14px;
  flex-shrink: 0;
  background-color: #4178FF !important;
  position: absolute !important;
}

#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-textBlock>p,
#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-container>.ac-textBlock>p {
  margin-top: 1px !important;
  margin-bottom: -2px !important;
  margin-left: -6px;
}

#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-columnSet>.ac-container>.ac-columnSet>.ac-container,
#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-columnSet>.ac-container>.ac-columnSet>.ac-container>.ac-columnSet>.ac-container {
  flex-direction: row !important;
}

#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-columnSet>.ac-container>.ac-columnSet>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock {
  text-align: left !important;
  margin-left: 12px !important;
  width: 161px !important;
  margin-bottom: 2px !important;
  font-size: 13px !important;
}

#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-columnSet>.ac-container>.ac-columnSet>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock>p {
 /* font-size: 11px !important; */
 width: 111px !important;
 text-align: right !important;
 color: #FFF;
 text-align: right;
 font-family: Lato !important;
 font-size: 12px !important;
 font-style: normal !important;
 font-weight: 400 !important;
 line-height: normal;
}

/*for dot*/
#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-columnSet>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock>p,
#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-columnSet>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-container>.ac-container>.ac-container>.ac-textBlock>p,
#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-columnSet>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-container>.ac-container>.ac-container>.ac-container>.ac-textBlock>p {
  margin-top: 10px !important;
  font-size: 25px !important;
  margin-left: 6px !important;
  margin-right: 2px !important;
}

#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-columnSet>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock>p {
  /* color: #6FCF4A !important; */
  display: none;
}

#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-columnSet>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-container>.ac-container>.ac-container>.ac-textBlock>p {
  /* color: #F89500 !important; */
  display: none;
}

#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-columnSet>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-container>.ac-container>.ac-container>.ac-container>.ac-textBlock>p {
  /* color: #FF0000 !important; */
  display: none;
}

/*for Active*/
#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-columnSet>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock>p,
#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-columnSet>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-container>.ac-textBlock>p,
#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-columnSet>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-container>.ac-container>.ac-textBlock>p {
  margin-top: 10px !important;
  min-width: 211px !important;
}

#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-columnSet>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock>p {
   /* color: #6FCF4A !important; */
  /* font-size: 12px !important; */
  color: #FFF;
  font-family: Lato !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin-left: 12px;
}

#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-columnSet>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-container>.ac-textBlock>p {
  /* color: #F89500 !important;
  font-size: 12px !important; */
  color: #FFF;
  font-family: Lato !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin-left: 12px;
}

#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-columnSet>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-container>.ac-container>.ac-textBlock>p {
  /* color: #FF0000 !important;
  font-size: 12px !important; */
  color: #FFF;
  font-family: Lato !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin-left: 12px;
}

/*for effective*/
#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-container>.ac-columnSet>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-textBlock>p {
  margin-top: 10px !important;
  /* font-size: 12px !important; */
  /* margin-left: 5px !important; */
  color: #FFF;
  text-align: right;
  font-family: Lato !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal;
}

/*for cursor pointer for dropdown*/
#dropDownCard.ac-container.ac-adaptiveCard>div#dropDownInfo>div.ac-input-container>select,
#dropDownNoThanksCard.ac-container.ac-adaptiveCard>div.ac-container>div#dropDownInfo>div.ac-input-container>select {
  cursor: pointer !important;
  text-overflow: ellipsis !important;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #fff;
  margin-bottom: 3px;
}

/* Yes No Buttons in Contract flow restart*/
#anotherCarrierConfirmButton.ac-container.ac-adaptiveCard>div>div>.ac-actionSet {
  flex-direction: row !important;
  margin-left: 2px !important;
  margin-bottom: 5px;
}

#anotherCarrierConfirmButton.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>button.ac-pushButton.style-default {
  /* color: rgb(51, 71, 138) !important; */
  margin-right: 7px !important;
  margin-left: 7px !important;
  /* font-size: 13px !important; */
  border-color: rgb(51, 71, 138) !important;
  padding: 4px !important;
  /* border-radius: 6px !important; */
  cursor: pointer;
  /* background-color: #FFFFFF; */
  cursor: pointer;
  font-family: Lato;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  background-color: #4178ff;
  border-style: hidden !important;
  color: white !important;
  border-radius: 64px !important;
  margin-top: 1px;
  min-width: 40px;
}

#anotherCarrierConfirmButton.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>button.ac-pushButton.style-default>div {
  padding-bottom: 3px !important;
  padding-top: 4px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
}

/* Show more buttons*/
#showMoreButtons.ac-container.ac-adaptiveCard>div.ac-container>div>div>div.ac-actionSet {
  display: flex !important;
  flex-direction: row !important;
  align-items: start !important;
  flex-wrap: wrap !important;
}

#showMoreButtons.ac-container.ac-adaptiveCard>div.ac-container>div.ac-container>div.ac-container>div>div>div.ac-actionSet>button.ac-pushButton.style-default.expandable {
  background-color: none !important;
  color: rgba(51, 71, 138) !important;
  border: none !important;
  text-decoration-line: underline !important;
  font-size: 10px !important;
  margin-top: 6px !important;
}

#showMoreButtons.ac-container.ac-adaptiveCard>div.ac-container>div.ac-container>div.ac-container>div>div>div.ac-actionSet>button.ac-pushButton.style-default.expandable.expanded>div {
  display: none !important;
}

#showMoreButtons.ac-container.ac-adaptiveCard>div.ac-container>div>div>div.ac-actionSet>button.ac-pushButton.style-default {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
  margin-right: 0 !important;
  /* background-color: #c49e45 !important;
  border-style: hidden !important;
  color: white !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
  padding: 8px !important;
  font-size: 11px !important;
  cursor: pointer; */
  background-color: #4178ff !important;
  border-style: hidden !important;
  color: white !important;
  border-radius: 64px !important;
  font-weight: 500 !important;
  padding: 8px !important;
  cursor: pointer;
  margin-left: 0.52rem;
  min-width: 74px;
  height: 22px;
  flex-shrink: 0;
  font-family: Lato;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}

#showMoreButtons.ac-container.ac-adaptiveCard>div.ac-container>div.ac-container>div.ac-container>div>div>#button.ac-container.ac-adaptiveCard>div>div>div.ac-actionSet {
  display: flex !important;
  flex-direction: row !important;
  align-items: start !important;
  flex-wrap: wrap !important;
}

#showMoreButtons.ac-container.ac-adaptiveCard>div.ac-container>div.ac-container>div.ac-container>div>div>#button.ac-container.ac-adaptiveCard>div>div>div.ac-actionSet>button.ac-pushButton.style-default {
  margin-bottom: 3px !important;
  margin-right: 6px !important;
  background-color: #c49e45 !important;
  border-style: hidden !important;
  color: white !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
  padding: 8px !important;
  font-size: 11px !important;
  cursor: pointer;
}

/*Hierarchy css*/
#hierarchyTable.ac-container.ac-adaptiveCard,
#awnTable.ac-container.ac-adaptiveCard,
#commissionTable.ac-container.ac-adaptiveCard,
#awn.ac-container.ac-adaptiveCard,
#geoLocationSearch.ac-container.ac-adaptiveCard {
  width: 300px !important;
  /* background-color: #F6F1E3 !important; */
  /* border-radius: 5px !important; */
  padding: 6px 0px 6px 0px !important;
  border-radius: 0px 8px 8px 0px !important;
  background: #375582 !important;
  margin-left: 12px !important;
}

#awn.ac-container.ac-adaptiveCard{
  padding: 9.5px 0px 4px 0px !important;
}

#hierarchyTable.ac-container.ac-adaptiveCard{
  padding: 11.5px 6px 10.5px 7px !important;
}

#hierarchyTable.ac-container.ac-adaptiveCard>.ac-columnSet,
#commissionTable.ac-container.ac-adaptiveCard>.ac-columnSet,
#awn.ac-container.ac-adaptiveCard>.ac-columnSet ,
#geoLocationSearch.ac-container.ac-adaptiveCard>.ac-columnSet{
  /* background-color: #c49e45 !important; */
  margin-left: 6px !important;
  margin-right: 7px !important;
  margin-top: 2px !important;
  background: rgb(255, 255, 255, 0.2);
}

#commissionTable.ac-container.ac-adaptiveCard>.ac-columnSet{
  margin-left: 12px !important;
}


#awn.ac-container.ac-adaptiveCard>.ac-columnSet {
  margin-left: 12px !important;
  margin-right: 7px !important;
}

#hierarchyTable.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock,
#hierarchyTable.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock,
#awnTable.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock,
#awnTable.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock,
#commissionTable.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock,
#commissionTable.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock,
#awn.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock {
  color: transparent !important;
  font-size: 12px !important;
  font-family: Lato !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  padding-top: 2px;
}

#awn.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock{
  padding-top: 0px !important;
}

#hierarchyTable.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock {
  margin-left: 5px !important;
  margin-top: 1px !important;
  min-width: 100px;
  margin-bottom: 1px !important;
}

#hierarchyTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet,
#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet,
#commissionTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet,
#awn.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet,
#geoLocationSearch.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet {
  /* border-color: #c49e45 !important;
  border-top: white !important;
  background: white !important;
  border-style: solid !important; */
  margin-left: 12px !important;
  margin-right: 7px !important;
  border-width: 1px !important;
  width: 276px;
  min-height: 18px;
  flex-shrink:0;
  border: 0.5px solid #375582;
  background: rgb(255, 255, 255, 0.2);
}

#hierarchyTable.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  text-align: center !important;
  margin-left: 23px !important;
}

#hierarchyTable.ac-container.ac-adaptiveCard>.ac-container,
#awnTable.ac-container.ac-adaptiveCard>.ac-container,
#commissionTable.ac-container.ac-adaptiveCard>.ac-container,
#awn.ac-container.ac-adaptiveCard>.ac-container {
  margin-bottom: 7px !important;
}

#hierarchyTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container {
  flex: none !important;
}

#hierarchyTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock,
#awnTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock,
#commissionTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock {
  margin-left: 11px !important;
  margin-top: 4px;
}

#hierarchyTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock>p {
  width: 160px !important;
}

#hierarchyTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock,
#payoutTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock,
#commissionTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock,
#commissionTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  font-size: 12px !important;
  margin-left: 7px !important;
  display: inline-block !important;
  text-align: right;
}

#hierarchyTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  margin-left: 19px !important;
}

#hierarchyTable.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock>p {
  width: 80px !important;
}

#hierarchy-text-toh,
#hierarchy-text-ip {
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 4px;
  color: #fff;
}

#hierarchy-column-dc,
#hierarchy-column-iu,
#hierarchy-column-toh {
  flex: 1 1 130px !important;
}

#hierarchy-text-dc {
  color: #000 !important;
  background-color:transparent;
  padding-left: 4px;
}

#hierarchyTable.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>#hierarchy-text-dc {
  color: #000 !important;
}

.webchat__bubble__content a {
  text-decoration: underline !important;
  color: #fff !important;
}

button[aria-label="Reminders"],
button[aria-label="Service Tickets"] {
  display: none !important;
  transform: translateZ(0) scale(1, 1) !important;
}

button[aria-label="Agent Info"] {
  transform: translateZ(0) scale(1, 1) !important;
}

#commissionTable.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock p {
  margin-left: 4px;
}


/*----------------------Product Certification css start--------------------------*/

#productCertification.ac-container.ac-adaptiveCard {
  width: 300px !important;
  background-color: #375582 !important;
  border-radius: 0 8px 8px 0 !important;
  margin-left: 12px !important;
  padding: 2px 5px 5px 5px !important;
}

#productCertification.ac-container.ac-adaptiveCard>.ac-columnSet {
  background: rgb(255, 255, 255, 0.2) !important;
  padding: 3px !important;
  margin-left: 7px !important;
  margin-right: 7px !important;
  margin-top: 10px !important;
  border: 0.5px solid #375582;
}

#productCertification.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock,
#productCertification.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  color: white !important;
  font-size: 12px !important;
}

#productCertification.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock {
  margin-left: 3px !important;
}

#productCertification.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet {
  /* border-color: #c49e45 !important;
  border-top: white !important;
  background: white !important; */
  border-style: solid !important;
  margin-left: 7px !important;
  margin-right: 7px !important;
  border-width: 1px !important;
  background: rgb(255, 255, 255, 0.2) !important;
  border: 0.5px solid #375582;
  padding-top: 5px !important;
}

#productCertification.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  text-align: right !important;
  margin-right: 28px !important;
}

#productCertification #_productCertificationPayoutHeader {
  margin-right: 33px !important;
}

#productCertification.ac-container.ac-adaptiveCard>.ac-container {
  margin-bottom: 7px !important;
}

#productCertification.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container {
  /* flex: none !important; */
}

#productCertification.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock {
  margin-left: 3px !important;
}

#productCertification.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock>p {
  width: 131px !important;
}

#productCertification.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock,
#productCertification.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  font-size: 12px !important;
  margin-left: 6px !important;
  display: inline-block !important;
  text-align: left !important;
}

#productCertification.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  margin-left: 19px !important;
}

#productCertification.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock>p {
  width: 91px !important;
  margin-left: 4px !important;
  text-align: left !important;
}

#productCertification.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  font-size: 12px !important;
  margin-left: 12px !important;
  display: inline-block !important;
  text-align: right !important;
}

#productCertData>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-textBlock {
  font-size: 10px !important;
  color: grey !important;
  margin-left: 16px !important;
}

/*----------------------------------Product Certification css end----------------------------*/

/*----------------------------------Carrier Certification css start--------------------*/
#carrierCertification.ac-container.ac-adaptiveCard {
  width: 301px !important;
  background-color: #375582 !important;
  border-radius: 0 8px 8px 0 !important;
  margin-left: 12px !important;
  padding: 2px 5.5px 5.5px 5px!important;
}

#carrierCertification.ac-container.ac-adaptiveCard>.ac-columnSet {
  background-color: rgb(255, 255, 255, 0.2) !important;
  padding: 3px !important;
  margin-left: 7px !important;
  margin-right: 7px !important;
  margin-top: 10px !important;
}

#carrierCertification.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock,
#carrierCertification.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  color: white !important;
  font-size: 12px !important;
}

#carrierCertification.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock {
  margin-left: 5px !important;
}

#carrierCertification.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet {
  background-color: rgb(255, 255, 255, 0.2) !important;
  margin-left: 7px !important;
  margin-right: 7px !important;
  border-width: 1px !important;
  margin-top: 2px !important;
}

#carrierCertification.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  text-align: right !important;
  margin-right: 32px !important;
}

#carrierCertification #_carrierCertificationPayoutHeader {
  margin-right: 33px !important;
}

#carrierCertification.ac-container.ac-adaptiveCard>.ac-container {
  margin-bottom: 7px !important;
}

#carrierCertification.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container {
  flex: none !important;
  margin-top: 2px !important;
}

#carrierCertification.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock {
  margin-left: 3px !important;
}

#carrierCertification.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock>p {
  width: 86px !important;
}

#carrierCertification.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock,
#carrierCertification.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  font-size: 12px !important;
  margin-left: 7px !important;
  display: inline-block !important;
  text-align: left !important;
}

#carrierCertification.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  margin-left: 19px !important;
}

#carrierCertification.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock>p {
  width: 91px !important;
  margin-left: 4px !important;
  text-align: left !important;
}

#carrierCertification.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  font-size: 12px !important;
  margin-left: 7px !important;
  display: inline-block !important;
  text-align: right !important;
}

#carrierCertData>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-container>.ac-textBlock {
  font-size: 10px !important;
  /* color: grey !important; */
  margin-left: -3px !important;
}

#carrierCertData>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-textBlock {
  font-size: 12px !important;
  margin-left: -3px !important;
}

#_carrierCertProductHeader {
  margin-right: 40px !important;
}

#_carrierCertDateHeader {
  color: white !important;
  font-size: 12px !important;
  margin-left: -17px !important;
}

/*----------------------------------Carrier Certification css end---------------------------*/

/*----------------------------------Contact Information css start---------------------------*/
#contactInfo.ac-container.ac-adaptiveCard {
  width: 292px !important;
  background-color: #F6F1E3 !important;
  border-radius: 5px !important;
}

#contactInfo.ac-container.ac-adaptiveCard>.ac-columnSet {
  background-color: #c49e45;
  padding: 3px !important;
  margin-left: 7px !important;
  margin-right: 7px !important;
  margin-top: 10px !important;
}

#contactInfo.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock,
#contactInfo.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  color: white !important;
  font-size: 12px !important;
}

#contactInfo.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock {
  margin-left: 15px;
}

#contactInfo.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet {
  border-color: #c49e45 !important;
  border-top: white !important;
  background: white !important;
  border-style: solid !important;
  margin-left: 7px !important;
  margin-right: 7px !important;
  border-width: 1px !important;
}

#contactInfo.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  text-align: left !important;
  margin: 0 0 0 -34px !important;
}

#contactInfo.ac-container.ac-adaptiveCard>.ac-container {
  margin-bottom: 7px !important;
}

#contactInfo.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container {
  flex: none !important;
  width: 20px !important;
}

#contactInfo.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock {
  margin-left: 3px !important;
}

#contactInfo.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock,
#contactInfo.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  font-size: 12px !important;
  margin-left: 7px !important;
  display: inline-block !important;
  text-align: left !important;
}

#contactInfo.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  margin-left: 19px !important;
}

#contactInfo.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock>p {
  margin-left: 2px !important;
  text-align: left !important;
  margin-right: 2px !important;
  width: 99% !important;
  font-weight: bold;
}

#contactInfo.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  font-size: 12px !important;
  margin-left: 15px !important;
  display: inline-block !important;
  text-align: right !important;
}

#contactInfoData.ac-container>.ac-columnSet>.ac-container>.ac-container {
  width: 275px !important;
}

#contactInfoData.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-textBlock {
  margin-left: 15px;
}

#contactInfoData.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-columnSet {
  margin-left: 15px !important;
}

#contactInfoData.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock>p {
  font-size: 12px;
  font-weight: bold;
  padding-right: 5px;
}

#tableLegend {
  background-color: #F6F1E3 !important;
}

#tableLegend>.ac-container>.ac-textBlock {
  margin-left: 0px !important;
  margin-right: 6px;
}

#tableLegend>.ac-container>.ac-textBlock>p {
  color: black !important;
}

/*----------------------------------Contact Information css end---------------------------*/

/*----------------------------------Address css start---------------------------*/
#addressInfo.ac-container.ac-adaptiveCard {
  width: 292px !important;
  background-color: #F6F1E3 !important;
  border-radius: 5px !important;
}

#addressInfo.ac-container.ac-adaptiveCard>.ac-columnSet {
  background-color: #c49e45;
  padding: 3px !important;
  margin-left: 7px !important;
  margin-right: 7px !important;
  margin-top: 10px !important;
}

#addressInfo.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock,
#addressInfo.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  color: white !important;
  font-size: 12px !important;
}

#addressInfo.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock {
  margin-left: 15px;
}

#addressInfo.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet {
  border-color: #c49e45 !important;
  border-top: white !important;
  background: white !important;
  border-style: solid !important;
  margin-left: 7px !important;
  margin-right: 7px !important;
  border-width: 1px !important;
}

#addressInfo.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  text-align: left !important;
  margin: 0 0 0 -34px !important;
}

#addressInfo.ac-container.ac-adaptiveCard>.ac-container {
  margin-bottom: 7px !important;
}

#addressInfo.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container {
  flex: none !important;
  width: 20px !important;
}

#addressInfo.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock {
  margin-left: 3px !important;
}

#addressInfo.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock,
#addressInfo.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  font-size: 12px !important;
  margin-left: 7px !important;
  display: inline-block !important;
  text-align: left !important;
}

#addressInfo.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  margin-left: 19px !important;
}

#addressInfo.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock>p {
  margin-left: 2px !important;
  text-align: left !important;
  margin-right: 2px !important;
  width: 99% !important;
  font-weight: bold;
}

#addressInfo.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  font-size: 12px !important;
  margin-left: 15px !important;
  display: inline-block !important;
  text-align: right !important;
}

#addressInfoData.ac-container>.ac-columnSet>.ac-container>.ac-container {
  width: 275px !important;
}

#addressInfoData.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-textBlock {
  margin-left: 15px;
}

#addressInfoData.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-columnSet {
  margin-left: 15px !important;
}

#addressInfoData.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock>p {
  font-size: 12px;
  font-weight: bold;
  padding-right: 5px;
}

#tableLegend {
  background-color: white !important;
}

#tableLegend>.ac-container>.ac-textBlock {
  margin-left: 0px !important;
  margin-right: 6px;
}

#tableLegend>.ac-container>.ac-textBlock>p {
  color: black !important;
}

/*----------------------------------Address css end---------------------------*/

/*--------------------------------------Search by geolocation css start----------------------*/
#geoLocationSearch.ac-container.ac-adaptiveCard {
  /* width: 292px !important;
  background-color: #F6F1E3 !important;
  border-radius: 5px !important; */
  padding: 11px 0px 13px 0px !important;
}

#geoLocationSearch.ac-container.ac-adaptiveCard>.ac-columnSet {
  /* background-color: #c49e45;
  padding: 3px !important; */
  margin-left: 12.5px !important;
  margin-right: 13px !important;
  /*margin-top: 10px !important; */
}

#geoLocationSearch.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock,
#geoLocationSearch.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  color: white !important;
  font-size: 12px !important;
  margin-top: 2px !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

#geoLocationSearch.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock {
  margin-left: 9px;
}

/* #geoLocationSearch.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet {
  border-color: #c49e45 !important;
  border-top: white !important;
  background: white !important;
  border-style: solid !important;
  margin-left: 7px !important;
  margin-right: 7px !important;
  border-width: 1px !important;
} */

#geoLocationSearch.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  text-align: right !important;
  margin-right: 37px !important;
  padding-right: 21px !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

#geoLocationSearch #_geoLocationSearchPayoutHeader {
  margin-right: 33px !important;
}

#geoLocationSearch.ac-container.ac-adaptiveCard>.ac-container {
  margin-bottom: 7px !important;
}

#geoLocationSearch.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container {
  flex: none !important;
}

#geoLocationSearch.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock {
  margin-left: 3px !important;
}

#geoLocationSearch.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock>p {
  width: 58px !important;
}

#geoLocationSearch.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock,
#geoLocationSearch.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  font-size: 12px !important;
  margin-left: 7px !important;
  display: inline-block !important;
  text-align: left !important;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

#geoLocationSearch.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  margin-left: 19px !important;
}

#geoLocationSearch.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock>p {
  width: 91px !important;
  margin-left: 3px !important;
  text-align: left !important;
}

#geoLocationSearch.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock>p>a{
  color:#fff !important;
}

#geoLocationSearch.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  font-size: 12px !important;
  margin-left: 7px !important;
  display: inline-block !important;
  text-align: right !important;
}

#geoLocaSearchData>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-container>.ac-textBlock {
  font-size: 10px !important;
  color: grey !important;
  margin-left: 11px !important;
}

#geoLocaSearchData>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-textBlock {
  font-size: 12px !important;
  margin-left: 16px !important;
  inline-size: 100px;
}

#_geoLocSearchNameHeader {
  margin-right: 40px !important;
}

#_geoLocSearchCityStateHeader {
  color: white !important;
  font-size: 12px !important;
  margin-left: 13px;
  margin-top: 2px !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

#_geoLocactionDownload {
  /* background-color: #f6f1e3 !important; */
  padding-top: 0px !important;
  margin-top: 1px !important;
  color: #fff !important;
}

body #_geoLocactionDownload > p>a{
  color:#fff !important;
}

#_geoLocactionDownload>.ac-container>.ac-textBlock {
  margin-left: 85px !important;
}
#_geoLocactionDownload>.ac-container>.ac-textBlock>p>a{
  color: #fff !important;
}

/*--------------------------------------Search by geolocation css end----------------------*/

.webchat__bubble__content ul {
  list-style: disc !important;
  /* font-weight: bold !important; */
  padding: 0px !important;
  /* margin-left: 16px !important; */
  margin-top: 0;
  margin-bottom: 0;
}

.webchat__bubble__content ul>li {
  list-style: none !important;
}

.webchat__bubble__content p {
  margin-bottom: 0 !important;
  color: #FFF;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

/*--------------------------------------Policy lookup css start----------------------------*/
#policyLookup {
  width: 300px;
  background-color: #375582  !important;
  margin-left: 12px !important;
  border-radius: 0px 8px 8px 0px !important;
}

#_carrierHeaderPolicyStatus,
#_productHeaderPolicyStatus,
#_insuredHeaderPolicyStatus {
  background: rgb(255, 255, 255, 0.2);
  border: 1px solid #375582;
}

#_carrierHeaderPolicyStatus>.ac-textBlock,
#_insuredHeaderPolicyStatus>.ac-textBlock,
#_productHeaderPolicyStatus>.ac-textBlock {
  color: white !important;
}

#_carrierValuePolicyStatus,
#_insuredValuePolicyStatus,
#_productValuePolicyStatus {
  background: rgb(255, 255, 255, 0.2);
  border: 1px solid #375582;
}

#_productValuePolicyStatus {
  border-top: none;
}

#_productHeaderPolicyStatus {
  border-top: none;
}

#_carrierHeaderPolicyStatus>.ac-textBlock,
#_productHeaderPolicyStatus>.ac-textBlock,
#_carrierValuePolicyStatus>.ac-textBlock,
#_productValuePolicyStatus>.ac-textBlock,
#_insuredHeaderPolicyStatus>.ac-textBlock,
#_insuredValuePolicyStatus>.ac-textBlock {
  margin-left: 10px;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

#_statusHeaderPolicyStatus .ac-textBlock> p,
#_issueDateHeaderPolicyStatus .ac-textBlock >p{
  font-family: Lato !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

#_valueColumnSet .ac-textBlock>p{
  font-family: Lato !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

#_carrierColumnSet,
#_productColumnSet,
#_insuredColumnSet,
#_headerColumnSet,
#_valueColumnSet {
  padding: 0px 11px 0px 11px !important;
}

#_insuredHeaderPolicyStatus,
#_insuredValuePolicyStatus {
  border-bottom: none !important;
}

#_productColumnSet {
  padding-bottom: 11px !important;
}

#_headerColumnSet {
  padding-top: 10px !important;
}

#_insuredColumnSet {
  padding-top: 6px !important;
}

/*--------------------------------------Policy lookup css end------------------------------*/
/*--------------------------------------Policy Commission css start------------------------*/
#policyCommissionDetails.ac-container.ac-adaptiveCard {
  width: 292px !important;
  background-color: #375582 !important;
  border-radius: 0 8px 8px 0 !important;
  margin-left: 12px !important;
  width: 300px !important;
}

#policyCommissionDetails.ac-container.ac-adaptiveCard>.ac-columnSet {
  /* background-color: #c49e45 !important; */
  padding: 3px !important;
  margin-left: 12px !important;
  margin-right: 12px !important;
  margin-top: 10px !important;
  background-color: rgb(255, 255, 255, 0.2) !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

#policyCommissionDetails.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock,
#policyCommissionDetails.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  color: white !important;
  font-size: 12px !important;
}

#policyCommissionDetails.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-textBlock {
  margin-left: 5px !important;
}

#policyCommissionDetails.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet {
  /* 
  background: white !important;*/
  border-top: 375582 !important;
  border-color: #375582 !important;
  border-style: solid !important; 
  background-color: rgb(255, 255, 255, 0.2);
  margin-left: 11.5px !important;
  margin-right: 11.5px !important;
  border-width: 1px !important;
  font-family: Lato !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

#policyCommissionDetails.ac-container.ac-adaptiveCard>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  text-align: right !important;
  margin-right: 10px !important;
}

#policyCommissionDetails #_productCertificationPayoutHeader {
  margin-right: 33px !important;
}

#policyCommissionDetails.ac-container.ac-adaptiveCard>.ac-container {
  margin-bottom: 12px !important;
}

#policyCommissionDetails.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container {
  flex: none !important;
}

#policyCommissionDetails.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock {
  margin-left: 3px !important;
}

#policyCommissionDetails.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock>p {
  width: 160px !important;
}

#policyCommissionDetails.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-textBlock,
#policyCommissionDetails.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  font-size: 12px !important;
  margin-left: 7px !important;
  display: inline-block !important;
  text-align: left !important;
}

#policyCommissionDetails.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  margin-left: 19px !important;
}

#policyCommissionDetails.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock>p {
  width: 91px !important;
  margin-left: 4px !important;
}

#policyCommissionDetails.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock {
  font-size: 12px !important;
  margin-left: 2px !important;
  display: inline-block !important;
  text-align: right !important;
}

#policyCommissionData>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-textBlock {
  font-size: 10px !important;
  /* color: grey !important; */
  margin-left: 11px !important;
}

#totalPolicyCommission>.ac-textBlock {
  /* color: green !important; */
  font-size: 21px !important;
  margin-left: 5px !important;
}

#policyCommissionDesc>.ac-textBlock {
  font-weight: bold !important;
  margin-left: 5px !important;
}

#policyCommissionTable>.ac-textBlock {
  position: absolute !important;
  font-weight: bold !important;
  margin-left: 5px !important;
}

#totalPolicyCommission,
#policyCommissionDesc,
#policyCommissionTable {
  margin-left: 7px !important;
}

#policyCommissionDesc {
  margin-top: 7px !important;
}

#policyCommissionTable {
  margin-top: 10px !important;
}

.webchat__microphone-button__icon {
  margin-top: 8px;
  cursor: pointer;
}

.webchat__send-box__main>p {
  max-width: 342px;
}

.webchat__icon-button__shade {
  display: none;
}

/*--------------------------------------Policy Commission css end--------------------------*/

#root .minimizable-web-chat .chat-box header img {
  height: 48px !important;
  width: 48px !important;
}

.initbotIcon {
  border-radius: 50% !important;
}

.webchat--css-kkyqm-17j40uk>img {
  border-radius: 50% !important;
  height: 24px !important;
  left: 50%;
  position: absolute;
  width: 85% !important;
  z-index: 9999 !important;
}

.webchat__basic-transcript__activity-body .webchat__stacked-layout__main .webchat__stacked-layout__avatar-gutter .webchat--css-ghang-1ms7ti7.webchat__defaultAvatar {
  border-radius: 0% !important;
}

.webchat--css-dlhto-17j40uk.webchat__imageAvatar__image {
  height: 100% !important;
  width: 70% !important;
  left: 10% !important;
  top: -2% !important;
}

.webchat__basic-transcript__scrollable, .webchat__imageAvatar__image{
  background-color: #1D3E71 !important;
}
.minimizable-web-chat .webchat__bubble__nub-pad{width: 0 !important;}
.webchat--css-wcjqr-1ig1f67.webchat__send-box-text-box .webchat__send-box-text-box__input:not(:disabled):not([aria-disabled="true"]){color: #fff !important;}


.minimizable-web-chat>.chat-box>header>h4 {
  color: #FFF;
font-family: Lato;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.webchat__carousel-layout{
  margin-left: -10px;
}
.web-chat .webchat__basic-transcript .webchat__basic-transcript__scrollable::-webkit-scrollbar {
  width:10px;
}
.web-chat .webchat__basic-transcript .webchat__basic-transcript__scrollable::-webkit-scrollbar-thumb {
  background: #052a63;
  border:2px solid #375582;
  border-radius: 10px;
}
.web-chat .webchat__basic- .webchat__basic-transcript__scrollable::-webkit-scrollbar-track {
  background: transparent;
}
.maximize:hover {
  width: 10.35rem !important;
 }
 .maximize:hover .WebChat_chatIconText__xchxr{
  opacity: 1;
 }
 .webchat--css-veucw-bsfxty{
  color: #FFF !important;
  font-family: Lato !important;
  font-size: 10px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5 !important;
 }
.bot-arrow-icon .webchat__send-box .webchat__send-box__main .webchat__send-box__button .webchat__send-icon {
  background-image: url("./../images/Send.svg");
  background-size: contain;
  fill: transparent !important;
  cursor: pointer;
}
.bot-search-icon .webchat__send-box .webchat__send-box__main .webchat__send-box__button .webchat__send-icon {
  background-image: url("./../images/Search.svg");
  background-size: contain;
  fill: transparent !important;
  cursor: pointer;
}
.webchat--css-hrdsv-1r0ige7.webchat__icon-button{fill: transparent !important}
.webchat__send-box__main .webchat__send-box__button {
  justify-content: center !important;
  outline: 0px !important;
  padding: 0px !important;
  width: 63px !important;
  position: absolute !important;
  right: 0;
  bottom:6px;
 }
.webchat--css-flfxm-1r0ige7.webchat__icon-button{fill: transparent !important;display: none;}
.ac-textRun{
  color: #FFF !important;
  font-family: Lato !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
#dropDownInfo{margin-left: 10px;}
#commissionCard .ac-textBlock > p{
  color: #FFF !important;
  font-family: Lato !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}

/* .webchat__basic-transcript__activity-body .webchat__carousel-layout .webchat__carousel-filmstrip{
  width: 330px !important;
} */
.webchat__imageAvatar__image > img{border-radius: 50%;}
.webchat__imageAvatar__image {margin-top: 3px;    margin-left: -3px !important;}
#awnTable .ac-columnSet .ac-container .ac-textBlock > p,
#payoutTable .ac-columnSet .ac-container .ac-textBlock > p,
#hierarchyTable .ac-columnSet .ac-container .ac-textBlock > p,
#productCertification .ac-columnSet .ac-container .ac-textBlock > p{
  color:#fff;
  font-family: Lato !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal;
} 
#awnTable.ac-container.ac-adaptiveCard>.ac-columnSet> .ac-container> .ac-textBlock > p,
#payoutTable.ac-container.ac-adaptiveCard>.ac-columnSet> .ac-container> .ac-textBlock > p,
#hierarchyTable.ac-container.ac-adaptiveCard>.ac-columnSet> .ac-container> .ac-textBlock > p,
#productCertification.ac-container.ac-adaptiveCard>.ac-columnSet> .ac-container> .ac-textBlock > p{
  font-family: Lato !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal;
}
#hierarchyTable .ac-columnSet .ac-container .ac-textBlock > p> a{color: #fff !important;}
.ac-input-container>select option:checked{
  background-color: rgb(255, 255, 255, 0.2) !important;
  padding: 20px !important;
}
.ac-input-container>select option:hover{
  background-color: rgb(255, 255, 255, 0.2) !important;
  padding: 20px !important;
}
.ac-input-container>select option{
  background-color: #375582 !important;
}
.webchat__defaultAvatar{
  margin-top: 7px !important;
}
#showMoreButtons.ac-container.ac-adaptiveCard>div.ac-container>div>div>div.ac-actionSet>button:only-child.ac-pushButton.style-default > img{
  margin-top: 0 !important;
}
#showMoreButtons.ac-container.ac-adaptiveCard>div.ac-container>div>div>div.ac-actionSet>button:only-child.ac-pushButton.style-default > div{
  margin-top: 2px !important;
}

#buttons.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>.ac-pushButton > img,
#agentInfoCard.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>button.ac-pushButton.style-default > img,
#showMoreButtons.ac-container.ac-adaptiveCard>div.ac-container>div>div>div.ac-actionSet>button.ac-pushButton.style-default > img,
#statusInfoCard.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>button.ac-pushButton.style-default > img {

  float: right !important;
  height: 16px !important;
  width: 16px !important;
  margin-left: 3px !important;
}
#statusInfoCard.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>button.ac-pushButton.style-default > img{
  margin-right:  0 !important;
}
#buttons.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>.ac-pushButton > img{
  margin-right: 5px !important;
}

#agentInfoCard.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>button.ac-pushButton.style-default > img{
  margin-right: 0px !important;
}
#showMoreButtons.ac-container.ac-adaptiveCard>div.ac-container>div>div>div.ac-actionSet>button.ac-pushButton.style-default > img {
  max-height: 22px !important;
  margin-right: -3px !important;
  margin-top: -5px;
}
#buttons.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>.ac-pushButton,
#agentInfoCard.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>button.ac-pushButton.style-default,
#showMoreButtons.ac-container.ac-adaptiveCard>div.ac-container>div>div>div.ac-actionSet>button.ac-pushButton.style-default,
  #statusInfoCard.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>button.ac-pushButton.style-default{
  display: inline !important;
}
#buttons.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>.ac-pushButton button:only-child.div,
#showMoreButtons.ac-container.ac-adaptiveCard>div.ac-container>div>div>div.ac-actionSet>button:only-child.ac-pushButton.style-default.div
{
  margin-top: 3px !important;
}
#buttons.ac-container.ac-adaptiveCard>div>div>.ac-actionSet> button:only-child,
#showMoreButtons.ac-container.ac-adaptiveCard>div.ac-container>div>div>div.ac-actionSet>button:only-child.ac-pushButton.style-default
{ 
  width: 132px !important;
  height: 40px !important;
  font-size: 16px !important;
  padding: 8px !important;
  margin-left: 0px !important;
}
#buttons.ac-container.ac-adaptiveCard>div>div>.ac-actionSet > button:only-child > img{
  margin-right: 0 !important;
}
#showMoreButtons.ac-container.ac-adaptiveCard>div.ac-container>div>div>div.ac-actionSet>button:only-child.ac-pushButton.style-default{
  margin-left: 12px !important;
}
#buttons.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>.ac-pushButton > div{
  margin-top: 3px !important;
  margin-left: 6px;
}
#showMoreButtons.ac-container.ac-adaptiveCard>div.ac-container>div>div>div.ac-actionSet>button.ac-pushButton.style-default > div{
  margin-top: -2px !important;
  margin-left: 1px; 
}
#agentInfoCard.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>button.ac-pushButton.style-default > div,
#statusInfoCard.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>button.ac-pushButton.style-default > div{
  margin-top: 2px !important;
  margin-left: 4px;
}
#buttons.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>.ac-pushButton button:only-child > img,
#showMoreButtons.ac-container.ac-adaptiveCard>div.ac-container>div>div>div.ac-actionSet>button:only-child.ac-pushButton.style-default > img
{
  float: right !important;
  height: 32px !important;
  width: 32px !important;
  margin-right: -3px !important;
}
#buttons.ac-container.ac-adaptiveCard>div>div>.ac-actionSet > button:only-child > img,
#showMoreButtons.ac-container.ac-adaptiveCard>div.ac-container>div>div>div.ac-actionSet>button:only-child.ac-pushButton.style-default > img
{
  height: 24px !important;
  width: 24px !important;
}
#buttons.ac-container.ac-adaptiveCard>div>div>.ac-actionSet{margin-left: 10px !important;}
ul.webchat__carousel-filmstrip__attachments li:last-child{
  /* right: 6px !important; */
  margin-right: 6px;
}
ul.webchat__carousel-filmstrip__attachments.react-film__filmstrip__list{
  width: fit-content !important;
  border-radius: 6px !important;
  background-color: #375582 !important;
}

#buttons.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>.ac-pushButton{
  margin-left: 0!important;
}

.webchat--css-sygif-q2aqen.webchat__carousel-filmstrip .webchat__carousel-filmstrip__main{
  margin-right: 1px;
}
#dropDownNoThanksCard.ac-container.ac-adaptiveCard>div.ac-container>div>div>.ac-actionSet>button.ac-pushButton.style-default>img{
  margin-left: 4px !important;
}

#awn.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container{
  position: relative;
}
#awn.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container .ac-image{
  top: 3px;
  right: 30px;
  position: absolute;
}

#awn.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-textBlock>p,
#awn.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-textBlock>p
{
  font-family: Lato !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  padding-top:2px;
  padding-left: 0px !important;
  padding-right: 3px;
  width: 28px !important;
}
#awn.ac-container.ac-adaptiveCard>.ac-container>.ac-columnSet>.ac-container>.ac-container>.ac-container>.ac-textBlock{
  margin-left: -41px !important;
  padding-right: 8px!important;
}
.webchat__stacked-layout--from-user .webchat__stacked-layout__status{
  margin-top: -5px;
  margin-right: -10px;
}
.webchat__bubble__content strong{
  letter-spacing: 1px;
}
.react-web-chat .webchat__connectivityStatus{
  color: #fff !important;
  font-size: 10px !important;
  font-family: Lato !important;
  margin-left: 27px;
  margin-bottom: 0px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
}
.react-web-chat .webchat__connectivityStatus svg{
  fill: #fff;
  padding-right: 5px !important;
  padding-bottom: 2px !important;
}

#commissionButtons.ac-container.ac-adaptiveCard>div>div>.ac-actionSet>.ac-pushButton{
  padding: 7px !important;
}

.webchat__basic-transcript__terminator, .webchat__basic-transcript__terminator-text{
  display: none !important;
}