/* media query */

/* 90% to 25% */
@media screen and (min-width: 1422px) and (max-width: 8120px) {
  /* cm notes toold bar css */
  #cm-txtMessage .ql-snow.ql-toolbar button svg {
    height: 14px;
  }

  #cm-txtMessage button.ql-list svg {
    width: 15px;
    height: 20px !important;
    margin-right: 8px;
  }

  #cm-txtMessage .ql-toolbar.ql-snow .ql-formats > span {
    font-size: 14px;
    color: #aaaaaa !important;
  }

  /*  */
}

/* 100% */
@media screen and (min-width: 1270px) and (max-width: 1422px) {
  /* cm notes toold bar css */
  #cm-txtMessage .ql-snow.ql-toolbar button svg {
    height: 10px;
  }

  #cm-txtMessage button.ql-list svg {
    width: 8px;
    height: 12px !important;
    margin-right: 8px;
  }

  #cm-txtMessage .ql-toolbar.ql-snow .ql-formats > span {
    font-size: 10px;
    color: #aaaaaa !important;
  }

  .CmInfoTooltip .CMtooltiptextSave {
    visibility: hidden;
    width: 300px;
    background-color: #434a51;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -11% !important;
    right: 9.5em !important;
    margin-left: -11.9em;
    text-align: start;
    padding-left: 10px;
    font-size: 12px;
    padding: 12px;
    font-weight: 400;
  }

  .cm-note-text-error .CMNotesCounter {
    color: #ff4444 !important;
    font-weight: 400;
    margin-top: -10px !important;
    margin-top: -14px !important;
  }

  .cm-adr-city {
    min-width: auto !important;
  }

  .cm-adr-county {
    min-width: auto !important;
  }

  .cm-adr-state {
    min-width: auto !important;
  }

  .cm-adr-zip {
    min-width: auto !important;
  }

  .cmNotesDiscard {
    margin-left: 2em;
  }
}

/* 90% */
@media screen and (min-width: 1422px) and (max-width: 1600px) {
  .CmPreviewEditbtn {
    margin-right: 1.1% !important;
  }

  .cm-note-text-error .CMNotesCounter {
    color: #ff4444 !important;
    font-weight: 400;
    margin-top: -10px !important;
    margin-top: -22px !important;
  }

  .cmNotesDiscard {
    margin-left: 5.3em !important;
  }

  .CmInfoTooltip .CMtooltiptextSave {
    visibility: hidden;
    width: 300px;
    background-color: #434a51;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -11% !important;
    right: 9.5em !important;
    margin-left: -11.9em;
    text-align: start;
    padding-left: 10px;
    font-size: 12px;
    padding: 12px;
    font-weight: 400;
  }
}

/* 80% */
@media screen and (min-width: 1600px) and (max-width: 1706px) {
  .cm-note-text-error #cm-txtMessage .p-editor-toolbar.ql-toolbar.ql-snow {
    border: 1px solid #ff4444 !important;
    border-bottom: 0px solid red !important;
    padding-bottom: 23px !important;
  }

  .cm-note-text-error .CMNotesCounter {
    color: #ff4444 !important;
    font-weight: 400;
    margin-top: -10px !important;
    margin-top: -22px !important;
  }

  .CmPreviewEditbtn {
    margin-right: 1.7% !important;
  }

  .cmNotesDiscard {
    margin-left: 9.3em !important;
  }

  .CmInfoTooltip .CMtooltiptextSave {
    visibility: hidden;
    width: 300px;
    background-color: #434a51;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -11% !important;
    right: 9.5em !important;
    margin-left: -11.9em;
    text-align: start;
    padding-left: 10px;
    font-size: 12px;
    padding: 12px;
    font-weight: 400;
  }
}

/* 75% */
@media screen and (min-width: 1706px) and (max-width: 1920px) {
  .cm-note-text-error #cm-txtMessage .p-editor-toolbar.ql-toolbar.ql-snow {
    border: 1px solid #ff4444 !important;
    border-bottom: 0px solid red !important;
    padding-bottom: 30px !important;
  }

  .cm-note-text-error .CMNotesCounter {
    color: #ff4444 !important;
    font-weight: 400;
    margin-top: -10px !important;
    margin-top: -22px !important;
  }

  .CmPreviewEditbtn {
    margin-right: 2%;
  }

  .cmNotesDiscard {
    margin-left: 9.9em !important;
  }

  .CmInfoTooltip .CMtooltiptextSave {
    visibility: hidden;
    width: 300px;
    background-color: #434a51;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -11% !important;
    right: 9.5em !important;
    margin-left: -11.9em;
    text-align: start;
    padding-left: 10px;
    font-size: 12px;
    padding: 12px;
    font-weight: 400;
  }

  .layout-main .CMLayoutAddEditWrapper .CMaddCarrierBtnEnable {
    margin-left: 9px !important;
  }
}

/* 67% */
@media screen and (min-width: 1920px) and (max-width: 2560px) {

  .cm-note-text-error #cm-txtMessage .p-editor-toolbar.ql-toolbar.ql-snow {
    border: 1px solid #ff4444 !important;
    border-bottom: 0px solid red !important;
    padding-bottom: 43px !important;
  }

  .cm-note-text-error .CMNotesCounter {
    color: #ff4444 !important;
    font-weight: 400;
    margin-top: -10px !important;
    margin-top: -62px !important;
  }

  .CmPreviewEditbtn {
    margin-right: 2.5% !important;
  }

  .layout-main .CMLayoutAddEditWrapper .CMaddCarrierBtnEnable {
    margin-left: -18px !important;
  }

  .cmNotesDiscard {
    margin-left: 16.9em !important;
  }

  .CmInfoTooltip .CMtooltiptextSave {
    visibility: hidden;
    width: 300px;
    background-color: #434a51;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -11% !important;
    right: 9.5em !important;
    margin-left: -11.9em;
    text-align: start;
    padding-left: 10px;
    font-size: 12px;
    padding: 12px;
    font-weight: 400;
  }

  .cm-note-text-error .CMNotesCounter {
    color: #ff4444 !important;
    font-weight: 400;
    margin-top: -10px !important;
    margin-top: -25px !important;
  }
}

/* 50% */
@media screen and (min-width: 2560px) {
  .cm-note-text-error #cm-txtMessage .p-editor-toolbar.ql-toolbar.ql-snow {
    border: 1px solid #ff4444 !important;
    border-bottom: 0px solid red !important;
    padding-bottom: 87px !important;
  }

  .CmPreviewEditbtn {
    margin-right: 4% !important;
  }

  .cmNotesDiscard {
    margin-left: 31.9em !important;
  }

  .CmInfoTooltip .CMtooltiptextSave {
    visibility: hidden;
    width: 300px;
    background-color: #434a51;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -11% !important;
    right: 9.5em !important;
    margin-left: -11.9em;
    text-align: start;
    padding-left: 10px;
    font-size: 12px;
    padding: 12px;
    font-weight: 400;
  }

  .cm-note-text-error .CMNotesCounter {
    color: #ff4444 !important;
    font-weight: 400;
    margin-top: -10px !important;
    margin-top: -24px !important;
  }
}

/* 33% */
@media screen and (min-width: 3840px) {
  .dialog-width-address .form-group .p-dropdown label.p-dropdown-label {
    padding-top: 8.3px !important;
  }
}

/* 110% */
@media only screen and (min-width: 1163px) and (max-width: 1200px) {
  .CmInfoTooltip .CMtooltiptextSave {
    visibility: hidden;
    width: 300px;
    background-color: #434a51;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -11% !important;
    right: 9.5em !important;
    margin-left: -11.9em;
    text-align: start;
    padding-left: 10px;
    font-size: 12px;
    padding: 12px;
    font-weight: 400;
  }

  .CmPreviewEditbtn {
    margin-right: 0.1%;
  }

  .cmNotesDiscard {
    margin-left: 0.5em !important;
  }

  #cm-txtMessage .ql-toolbar.ql-snow .ql-formats {
    margin-right: 8px !important;
  }

  #cm-dialog-AddEditCarrierAddress .p-dialog-content {
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }

  .cm-adr-city {
    min-width: 50% !important;
  }

  .cm-adr-county {
    min-width: 50% !important;
  }

  .cm-adr-state {
    min-width: 50% !important;
  }

  .cm-adr-zip {
    min-width: 50% !important;
  }

  .cm-note-text-error #cm-txtMessage .p-editor-toolbar.ql-toolbar.ql-snow {
    border: 1px solid #ff4444 !important;
    border-bottom: 0px solid red !important;
    padding-bottom: 0px !important;
  }

  .cm-note-text-error .CMNotesCounter {
    color: #ff4444 !important;
    font-weight: 400;
    margin-top: -10px !important;
    margin-top: -13px !important;
  }
}

/* 125% */
@media only screen and (min-width: 1024px) and (max-width: 1163px) {
  .cm-note-text-error #cm-txtMessage .p-editor-content.ql-container.ql-snow {
    border: 1px solid #ff4444 !important;
    border-top: 0px solid red !important;
    max-height: 77% !important;
    background: white;
    padding-bottom: 20px;
  }

  .cm-note-text-error .CMNotesCounter {
    color: #ff4444 !important;
    font-weight: 400;
    margin-top: -10px !important;
    margin-top: -12px !important;
  }

  .CmPreviewEditbtn {
    margin-right: 0.1%;
  }

  .CmInfoTooltip .CMtooltiptextSave {
    visibility: hidden;
    width: 300px;
    background-color: #434a51;
    color: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -11% !important;
    right: 9.5em !important;
    margin-left: -11.9em;
    text-align: start;
    padding-left: 10px;
    font-size: 12px;
    padding: 12px;
    font-weight: 400;
  }
}

@media screen and (min-width: 768px) {
  .CmPreviewEditbtn {
    margin-right: 0.1%;
  }
}

/* media query end */
/* Add Edit CM***  */
.CMLayoutAddEditWrapper {
  background: #f4f8fb !important;
}

.layout-main .CMLayoutAddEditWrapper {
  background: transparent !important;
  box-shadow: none !important;
  overflow-y: hidden;
  font-family: Lato !important;
  padding-top: 3px;
}

.layout-main .CMLayoutAddEditWrapper #cm-tab-parent-custom .p-tabview-nav {
  max-height: 40px !important;
  margin-top: -15px;
}

.layout-main
  .CMLayoutAddEditWrapper
  #cm-tab-parent-custom
  .p-tabview-nav
  li.p-highlight
  a {
  font-size: 16px !important;
  line-height: 14px !important;
  margin-top: 1px;
  padding-bottom: 7px !important;
}

.layout-main
  .CMLayoutAddEditWrapper
  #cm-tab-parent-custom
  .p-tabview
  .p-tabview-nav:after {
  display: none !important;
}

.layout-main .CMLayoutAddEditWrapper #cm-tab-parent-custom .p-tabview-panels {
  display: none !important;
}

.layout-main .CMLayoutAddEditWrapper .CMdetailsTab .p-card.p-component {
  max-height: 45px;
}

#cm-tab-parent-custom .tab-left-border:before {
  content: "";
  position: absolute;
  left: 0%;
  top: 10%;
  height: 28px;
  width: 50%;
  border-left: 1.5px solid #d8dbe0;
}

.layout-main
  .CMLayoutAddEditWrapper
  #cm-tab-parent-custom
  .p-tabview-nav
  li.p-highlight:before {
  border-bottom: 0px solid #0052ce;
}

.CMcarrierInfoTab {
  color: #002d72;
  font-weight: 500;
  font-size: 24px;
  line-height: 19px;
  border-bottom: 0px solid #0052ce;
  width: 235px;
  padding-bottom: 8px;
  margin-left: 18px;
  padding-left: 0px;
  margin-top: 20px;
}

.CMLayoutAddEditWrapper #carrierName::placeholder {
  color: #aaaaaa !important;
}

.CMLayoutAddEditWrapper #carrierName {
  width: 100%;
  max-height: 40px;
  min-height: 40px;
  padding-top: 16px;
  font-size: 24px;
  font-weight: 400;
  padding-bottom: 14px;
  border: 1px solid #dfdedd !important;
}

body .CMLayoutAddEditWrapper .form-group .p-dropdown {
  border: 1px solid #dfdedd !important;
}

body .CMLayoutAddEditWrapper .form-group .p-inputtext {
  border: 1px solid #dfdedd !important;
}

body .CMLayoutAddEditWrapper .form-group .p-inputtext::placeholder {
  color: #aaaaaa !important;
}

.CMLayoutAddEditWrapper #shortName::placeholder {
  color: #aaaaaa !important;
}

.CMLayoutAddEditWrapper #shortName {
  min-height: 40px;
  max-height: 40px;
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 5px !important;
  border: 1px solid #dfdedd !important;
}

.CMNameIcon {
  margin-top: -8px;
  width: 52px;
  margin-right: 10px;
}

.layout-main .CMLayoutAddEditWrapper .CMaddCarrierBtnDisabled {
  min-width: 107px !important;
  height: 40px;
  background: #a0c4f8 !important;
  border-radius: 3px 0px 0px 3px !important;
  border: none !important;
  margin-left: 1px !important;
  pointer-events: none;
  padding-top: 4px;
}

.layout-main .CMLayoutAddEditWrapper .CMaddCarrierSplitBtn {
  background: #0052ce;
  border-radius: 0px 3px 3px 0px;
  min-width: 47px !important;
  height: 40px !important;
  border: none !important;
  padding-left: 10px;
}

.layout-main .CMLayoutAddEditWrapper .CMEditCarrierSplitBtn {
  background: #0052ce;
  border-radius: 0px 3px 3px 0px;
  min-width: 47px !important;
  height: 40px !important;
  border: none !important;
  padding-left: 10px;
}

.contract-wrapper-save-botton
  .CMaddCarrierSplitBtn
  .p-splitbutton-menubutton
  .p-button-icon-left:before,
.layout-main
  .CMLayoutAddEditWrapper
  .CMaddCarrierSplitBtn
  .p-splitbutton-menubutton
  .p-button-icon-left:before {
  content: url("../../../../fonts/savbtnSplitCarrat.svg") !important;
  color: white;
  font-size: 14px;
  font-weight: 300;
}

.layout-main
  .CMLayoutAddEditWrapper
  .CMEditCarrierSplitBtn
  .p-splitbutton.p-buttonset
  .p-button.p-splitbutton-menubutton {
  border-left: 1.5px solid #0052ce !important;
}

.layout-main
  .CMLayoutAddEditWrapper
  .CMaddCarrierSplitBtn
  .p-splitbutton.p-buttonset
  .p-button.p-splitbutton-menubutton {
  border-left: 1.5px solid #0052ce !important;
}

.layout-main
  .CMLayoutAddEditWrapper
  button.p-button.p-component.p-splitbutton-menubutton.p-button-icon-only {
  margin-top: 4px !important;
  border: none !important;
}

body
  .layout-main
  .CMLayoutAddEditWrapper
  .p-splitbutton.p-buttonset
  .p-button:first-child {
  display: none !important;
}

.displayFlex {
  display: flex;
}

.CMpaddingLeft0 {
  padding-left: 0px;
}

body .layout-main .CMLayoutAddEditWrapper .CMtoggleBtn .container {
  text-align: center;
  display: flex;
  margin-top: -3px;
}

body .layout-main .CMLayoutAddEditWrapper .CMtoggleBtn .toggle-switch {
  position: relative;
  width: 37px;
  display: inline-block;
  text-align: left;
}

body .layout-main .CMLayoutAddEditWrapper .CMtoggleBtn .checkbox {
  display: none;
}

body .layout-main .CMLayoutAddEditWrapper .CMtoggleBtn .label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #dfdedd;
  border-radius: 20px;
  height: 20px;
  width: 37px;
  padding-left: 0;
}

body .layout-main .CMLayoutAddEditWrapper .CMtoggleBtn .inner {
  display: block;
  width: 333%;
  margin-left: -100%;
  transition: margin 0.2s ease-in 0s;
}

body .layout-main .CMLayoutAddEditWrapper .CMtoggleBtn .inner:before,
body .layout-main .CMLayoutAddEditWrapper .CMtoggleBtn .inner:after {
  float: left;
  width: 30%;
  height: 20px;
  padding: 0;
  line-height: 26px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}

body .layout-main .CMLayoutAddEditWrapper .CMtoggleBtn .inner:before {
  content: "";
  padding-left: 10px;
  background-color: #0052ce;
  color: #fff;
}

body .layout-main .CMLayoutAddEditWrapper .CMtoggleBtn .inner:after {
  content: "";
  padding-right: 10px;
  background-color: #dfdedd;
  color: #fff;
  text-align: right;
}

body .layout-main .CMLayoutAddEditWrapper .CMtoggleBtn .switch {
  display: block;
  width: 15px;
  height: 15px;
  margin: 2.3px 2.1px 2.1px 2.1px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  border: 0 solid #dfdedd;
  border-radius: 20px;
  transition: all 0.2s ease-in 0s;
}

body
  .layout-main
  .CMLayoutAddEditWrapper
  .CMtoggleBtn
  .checkbox:checked
  + .label
  .inner {
  margin-left: 0;
}

body
  .layout-main
  .CMLayoutAddEditWrapper
  .CMtoggleBtn
  .checkbox:checked
  + .label
  .switch {
  right: 0px;
}

.checkBoxLabelCM {
  height: 17px;
  font-weight: 300;
  font-family: Lato !important;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  color: #434a51;
  margin: 5px 5px 0px 10px;
}

.CMLayoutAddEditWrapper #CMGroup {
  min-width: 280px;
  max-width: 280px;
  border: 0.5px solid #dfdedd !important;
}

.CMLayoutAddEditWrapper #CMGroup .p-dropdown-panel ul li {
  word-break: break-word;
}

.CMLayoutAddEditWrapper #CMGroup .pi-chevron-down:before {
  color: #0052ce;
}

.CMLayoutAddEditWrapper #CMPayment::placeholder {
  color: #aaaaaa !important;
}

.CMLayoutAddEditWrapper #CMPayment {
  min-width: 150px;
  border: 1px solid #dfdedd !important;
}

.paymentCMLabel {
  height: 16px;
  left: 94px;
  top: 366px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  color: #002d72 !important;
  margin-bottom: 10px;
  padding-bottom: 2px;
}

.cm-writing-num-label {
  color: #002d72;
  font-weight: 400;
}

.cmInfoIcon {
  margin-left: 7px;
}

.paymentCMCheckBox1 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.CMCheckboxes {
  margin-right: 0px !important;
}

.CMLayoutAddEditWrapper #divCMGroup > label {
  height: 17px;
  font-weight: 300;
  font-size: 16px;
  line-height: 17px;
  align-items: center;
  color: #434a51 !important;
  margin-left: 6px;
  margin-right: 10px;
}

.CMtoggleBtn {
  padding: 9px 0px 0px 0px;
}

.radioLabelCM {
  height: 17px;
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  align-items: center;
  color: #434a51;
  margin: 3px 0px 0px 10px;
}

.isOverlapDiv {
  margin-top: 0px;
  margin-left: 22px;
  margin-bottom: 18px;
}

.layout-main .CMLayoutAddEditWrapper .p-checkbox .p-checkbox-box.p-highlight {
  border-color: #0052ce !important;
  background-color: #0052ce !important;
}

body
  .layout-main
  .CMLayoutAddEditWrapper
  .p-checkbox
  .p-checkbox-box
  .p-checkbox-icon {
  top: 2px;
  font-weight: 900;
  font-size: 9px;
}

body
  .layout-main
  .CMLayoutAddEditWrapper
  #carrierGroups
  .p-checkbox
  .p-checkbox-box
  .p-checkbox-icon {
  top: 2px;
  font-weight: 900;
  font-size: 14px;
}

body .CMLayoutAddEditWrapper #carrierGroups .p-checkbox {
  width: 24px;
  height: 24px;
}

.CmInfoTooltip {
  position: relative;
  display: inline-block;
}

.CmInfoTooltip .CMtooltiptext {
  visibility: hidden;
  width: 280px;
  background-color: #434a51;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 0%;
  margin-left: -11em;
  text-align: start;
  padding-left: 10px;
  font-size: 12px;
  font-weight: 400;
  padding: 12px;
}

.CmInfoTooltip .CMtooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #434a51 transparent;
}

.CmInfoTooltip:hover .CMtooltiptext {
  visibility: visible;
}

.CMStatusToggle {
  margin-top: -8px !important;
  margin-left: 5px;
}

.CMparent {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.CMchild {
  background-color: white;
  margin: 0px 10px 0px 0px;
  flex-basis: 30%;
  display: flex;
  flex-wrap: wrap;
  height: 32px;
}

.CMchkBoxLabel {
  margin-top: -1px;
  font-weight: 300;
  font-size: 16px;
  line-height: 17px;
  display: flex;
  color: #434a51;
  width: auto;
}

.layout-main .CMLayoutAddEditWrapper .CMaddCarrierBtnEnable {
  min-width: 111px !important;
  background: #0052ce !important;
  border-radius: 3px !important;
  border: none !important;
  opacity: 1 !important;
  margin-left: -26px;
}

.layout-main
  .CMLayoutAddEditWrapper
  .carrierCMLandingTab
  li.p-unselectable-text.p-tabview-selected.p-highlight:first-child
  > a {
  border-bottom: 2px solid #0052ce !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 83%;
}

.layout-main
  .CMLayoutAddEditWrapper
  .carrierCMLandingTab
  li.p-unselectable-text.p-tabview-selected.p-highlight:last-child
  > a {
  border-bottom: 2px solid #0052ce !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 89%;
}

.layout-main
  .CMLayoutAddEditWrapper
  #tab-parent-custom
  .p-tabview-nav
  li.p-highlight
  a {
  color: #0052ce !important;
}

.CMaddCarrierBtn > span:not(.p-button-text) {
  top: 25%;
  height: 24px;
  padding-top: 2px !important;
  width: 100% !important;
  white-space: nowrap;
  padding-right: 12px !important;
  font-weight: 500;
}

.CarrierNamePreview {
  color: #434a51;
  font-size: 24px;
  margin-top: 0px;
}

.CarrierShortNamePreview {
  color: #434a51;
  font-size: 18px;
  margin-top: 5px;
  margin-left: 8px;
}

.CMLayoutAddEditWrapper .CMgroupLabel {
  color: #434a51;
  font-size: 16px;
  margin-top: 3px;
  font-weight: 300;
}

.CMLayoutAddEditWrapper .CMgroupLabel > span {
  color: #434a51;
  font-size: 16px;
  margin-left: 2px;
  margin-right: 10px;
  font-weight: 500;
}

.CMtoggleBtnPreview {
  margin-top: -3px;
  background: #d8f5d9;
  border: 1px solid #434a51;
  border-radius: 10px;
  height: 20px;
  color: #434a51;
  font-weight: 600;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
}

.CMparentPreview {
  display: flex;
  flex-wrap: wrap;
}

.CMchildPreview {
  margin: 10px 0px;
  white-space: nowrap;
  margin-right: 16px;
}

.CMchildPreview .CMchkBoxLabelPreview {
  flex-wrap: wrap;
  white-space: nowrap;
  background: #f1faff;
  border-radius: 5px;
  padding: 5px 15px;
  max-height: 30px;
  width: fit-content;
}

.CMCircleW {
  padding-left: 8px;
  margin-top: -1px;
}

.cmcheckIcon {
  margin-right: 10px;
  margin-top: 2px;
}

body .carrierInformationCard {
  border-radius: 3px;
  background-color: #fff;
  padding-top: 1px;
  margin-top: -22px;
  margin-left: 2px;
  margin-right: 2px;
}

.carrierInformationCard .CarrierCMContactView .p-card.p-component {
  border: none !important;
  box-shadow: none !important;
}

div#CMaddCarrierSplitBtn_overlay {
  top: 50px !important;
  z-index: 1;
  left: -107.7223px !important;
  font-size: 16px;
}

.CmCancelChanges {
  content: url("../../../../fonts/CM-times-cancel.svg") !important;
  color: #0052ce;
  text-decoration: none;
}

.CMLayoutAddEditWrapper
  .p-splitbutton.secondary-button
  .p-menu
  .p-menuitem-link {
  text-decoration: none !important;
  max-height: 30px !important;
  padding-top: 5px !important;
  padding-left: 8px !important;
}

.CMLayoutAddEditWrapper
  .p-dropdown-panel.p-hidden.p-input-overlay.p-input-overlay-visible {
  border-radius: 3px !important;
}

#SSRcustomReportTable .p-paginator .p-dropdown {
  min-width: 3.5rem;
}

#SSRcustomReportTable .p-dropdown .p-dropdown-panel {
  left: 0px;
  right: 0px;
}

.CMLayoutAddEditWrapper
  .p-dropdown-panel.p-hidden.p-input-overlay.p-input-overlay-visible
  li.p-dropdown-item {
  color: #434a51;
  font-size: 16px !important;
  font-weight: 300 !important;
  margin: 4px;
  padding: 0.5em 0.25em;
  text-align: center;
  border-radius: 3px;
}
.CMLayoutAddEditWrapper
.cm-add-edit-carrier
  .p-dropdown-panel.p-hidden.p-input-overlay.p-input-overlay-visible
  li.p-dropdown-item{
    text-align: start;
  }


.CMLayoutAddEditWrapper
  #CMPayment
  .p-dropdown-panel.p-hidden.p-input-overlay.p-input-overlay-visible
  li.p-dropdown-item,
.CMLayoutAddEditWrapper
  #CMGroup
  .p-dropdown-panel.p-hidden.p-input-overlay.p-input-overlay-visible
  li.p-dropdown-item,
.CMLayoutAddEditWrapper
  #CMselectedStatus
  .p-dropdown-panel.p-hidden.p-input-overlay.p-input-overlay-visible
  li.p-dropdown-item,
.CMLayoutAddEditWrapper
  #CMGroupStatus
  .p-dropdown-panel.p-hidden.p-input-overlay.p-input-overlay-visible
  li.p-dropdown-item {
  font-size: 16px !important;
}

.CMLayoutAddEditWrapper
  .p-dropdown-panel.p-hidden.p-input-overlay.p-input-overlay-visible
  li.p-dropdown-item:hover {
  background-color: #ededee !important;
}

.CMLayoutAddEditWrapper
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight {
  color: #0052ce !important;
  background-color: #ededee !important;
  margin: 4px;
  border-radius: 3px;
}

#CMPayment .pi-chevron-down:before {
  color: #0052ce !important;
}

.contract-wrapper-save-botton
  .CMEditCarrierSplitBtn
  .p-splitbutton-menubutton
  .p-button-icon-left:before,
.layout-main
  .CMLayoutAddEditWrapper
  .CMEditCarrierSplitBtn
  .p-splitbutton-menubutton
  .p-button-icon-left:before {
  content: url("../../../../fonts/cmEditIcon.svg") !important;
  vertical-align: middle !important;
}

.CarrierCMContactView .p-card-content {
  border-bottom: 2px solid #f5f6fa;
  border-top: 2px solid #f5f6fa;
  padding-top: 30px;
  padding-bottom: 30px;
}

.contactRadioBtns .p-radiobutton .p-radiobutton-box.p-highlight {
  border-width: 1.5px;
  border-color: #0052ce !important;
  background-color: white !important;
  height: 14px;
  width: 14px;
}

.contactRadioBtns
  .p-radiobutton
  .p-radiobutton-box.p-highlight
  .p-radiobutton-icon {
  background-color: #0052ce !important;
}

.contactRadioBtns .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  margin-left: -3px;
  margin-top: -3px;
  width: 5.8px !important;
  height: 5.8px !important;
}

.contactRadioBtns .p-radiobutton .p-radiobutton-box {
  height: 14px !important;
  width: 14px !important;
  margin-top: 2px;
  margin-left: 5px;
}

.contactRadioBtns > label {
  margin-right: 24px;
  font-weight: 400;
  font-size: 16px;
  vertical-align: bottom;
}

#saveContactButtonEnable {
  background: #a0c4f8;
  border: 1px solid #a0c4f8;
  margin-left: 13px;
  border-radius: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  opacity: 1;
}

.CmInfoTooltip .CMtooltiptextContactSave {
  visibility: hidden;
  width: 300px;
  background-color: #434a51;
  z-index: 99899 !important;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  left: 0 !important;
  top: 119% !important;
  margin-left: -11.1em !important;
  text-align: start;
  padding-left: 10px;
  font-size: 12px;
  padding: 12px;
  font-weight: 400;
}

.CmInfoTooltip .CMtooltiptextContactSave::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 55% !important;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #434a51 transparent;
}

.CmInfoTooltip .CMtooltiptextSave::after {
  content: "";
  position: absolute;
  bottom: 44%;
  left: 101.4%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #434a51;
}

.CmInfoTooltip:hover .CMtooltiptextSave {
  visibility: visible;
}

body .CMLayoutAddEditWrapper .dialog-width-address .form-group label {
  color: #434a51 !important;
  font-weight: 400 !important;
  font-size: 16px;
  color: #434a51;
  line-height: 19px;
}

.dialog-width-address .form-group .p-dropdown label.p-dropdown-label {
  font-weight: 300;
  padding-top: 10px;
}

.optionalTextCm {
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #002d72;
}

.cmStatusLabelPreview {
  color: #434a51;
  font-size: 16px;
  margin-left: 15px;
  margin-top: 3px;
  margin-right: 10px;
  font-weight: 300;
}

body
  .CMLayoutAddEditWrapper
  .dialog-width-address.p-dialog
  .p-dialog-titlebar
  .p-dialog-title {
  font-weight: 500;
  font-size: 24px;
  color: #002d72;
}

.cm-dropdown-custom.p-dropdown .p-dropdown-trigger,
.dialog-width-address.p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon,
.dialog-width-address.p-dialog
  .p-dialog-titlebar
  .p-dialog-titlebar-icon:hover {
  color: #0052ce;
}

.cm-dropdown-custom
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight,
.cm-dropdown-custom
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item-group.p-highlight {
  color: #434a51;
}

.cm-dropdown-custom.p-dropdown:not(.p-disabled).p-focus,
.cm-dropdown-custom.p-dropdown:enabled:focus {
  color: #0052ce;
  box-shadow: 0 0 0 0.1em #0052ce;
}

.cm-dropdown-custom.form-group .p-dropdown,
.cm-inputtext-custom.form-group .p-inputtext {
  background: #ffffff;
  border: 1px solid #dfdedd;
  border-radius: 3px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  text-align: center;
}

.cm-inputtext-custom.p-inputtext:enabled:focus,
.cm-inputtext-custom.form-group .p-inputtext:hover {
  color: #0052ce;
  box-shadow: 0 0 0 0.1em #0052ce;
}

#zip.p-inputtext:enabled:focus,
#zip.form-group .p-inputtext:hover {
  color: #0052ce;
  box-shadow: 0 0 0 0.1em #0052ce;
}

.cm-label-font-13 {
  font-size: 13px;
}

#createaddressBtn {
  background-color: transparent !important;
  border-color: transparent;
  height: 40px;
  min-width: 149px;
}

#CMCarriersContactTable tr.p-datatable-row td:nth-child(4) {
  border-right: none !important;
}

.CmPreviewEditbtn {
  margin-right: 0.1%;
}

.layout-main
  .CMLayoutAddEditWrapper
  .carrierSaveBtnWrapper:hover
  .CMaddCarrierBtnDisabled {
  background-color: #a0c4f8 !important;
}

.layout-main
  .CMLayoutAddEditWrapper
  .carrierSaveBtnWrapper:hover
  .CMaddCarrierBtnEnable {
  background-color: #2175f4 !important;
}

.layout-main
  .CMLayoutAddEditWrapper
  .carrierSaveBtnWrapper:hover
  #CMaddCarrierSplitBtn {
  background-color: #2175f4 !important;
}

.CMLayoutAddEditWrapper
  .p-splitbutton.secondary-button
  .p-menu
  .p-menuitem-link
  .p-menuitem-text {
  font-weight: 400px !important;
  color: #0052ce;
}

.CMLayoutAddEditWrapper
  .p-splitbutton.secondary-button
  .p-menu
  .p-menuitem-link:hover
  .p-menuitem-text {
  font-weight: 400px !important;
  color: #2175f4;
}

#createaddressBtn:hover {
  background-color: transparent !important;
}

#cmCancelButton {
  background: #ffffff !important;
  border: 1px solid #0052ce !important;
  border-radius: 3px !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  color: #0052ce;
  margin-right: 12px;
  margin-bottom: 20px;
}

#cmCancelButton:hover {
  background: #ffffff !important;
  border: 1px solid #2175f4 !important;
  border-radius: 3px !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  color: #2175f4;
  margin-right: 12px;
}

.cmAddressAddModalBtn {
  float: right;
}

.cmAddressAddModalBtn #saveButton:hover {
  background-color: #2175f4 !important;
}

#saveContactButtonEnable:hover:not(.p-disabled) {
  background-color: #2175f4 !important;
}

#saveContactButtonEnable:not(.p-disabled) {
  background-color: #0052ce !important;
}

.cm-delete-button {
  font-weight: 700 !important;
  font-size: 16px !important;
}

.CMLayoutAddEditWrapper ul.p-menu-list.p-reset {
  padding: 4px 4px 4px 4px !important;
}

body
  .CMLayoutAddEditWrapper
  .dialog-width-address
  .form-group
  label.validation-error,
body
  .CMLayoutAddEditWrapper
  .dialog-width-address
  .form-group
  div.invalid-feedback {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin: 2px 5px 0;
}

.CMLayoutAddEditWrapper input#carrierName::placeholder {
  font-size: 24px;
  color: #aaaaaa !important;
}

.CMLayoutAddEditWrapper #shortName::placeholder {
  font-size: 18px;
  padding-bottom: 10px !important;
  color: #aaaaaa !important;
}

#CMaddCarrierSplitBtn span.pi.pi-caret-down.p-c.p-button-icon-left {
  margin-top: -10px !important;
}

#CMCarriersTable .cm-pencil-icon-parent {
  height: 24px;
  width: 24px;
}

#CMCarriersTable .cm-pencil-icon-parent:hover {
  background-color: #f4f6fa !important;
}

#CMCarrierGroupsTable .cm-pencil-icon-parent {
  height: 24px;
  width: 24px;
}

#CMCarrierGroupsTable .cm-pencil-icon-parent:hover {
  background-color: #f4f6fa !important;
}

#CMCarriersContactTable .cm-pencil-icon-parent {
  height: 24px;
  width: 24px;
}

#CMCarriersContactTable .cm-pencil-icon-parent:hover {
  background-color: #f4f6fa !important;
}

body .ConfirmDelete .p-dialog,
body .CMdetailsTab.cm-delete-confirmAlert-Custom .p-dialog {
  width: 644px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  color: #434a51 !important;
}

body .ConfirmDelete .p-dialog .p-dialog-titlebar .p-dialog-title,
body
  .CMdetailsTab.cm-delete-confirmAlert-Custom
  .p-dialog
  .p-dialog-titlebar
  .p-dialog-title {
  font-weight: 500;
  font-size: 24px;
  color: #002d72 !important;
}

.ConfirmDelete .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon,
.CMdetailsTab.cm-delete-confirmAlert-Custom
  .p-dialog
  .p-dialog-titlebar
  .p-dialog-titlebar-icon {
  color: #0052ce !important;
}

.ConfirmDelete .cm-confirm-alert-label,
.CMdetailsTab.cm-delete-confirmAlert-Custom .cm-confirm-alert-label {
  font-size: 24px;
  line-height: 29px;
  color: #434a51 !important;
  font-weight: 400;
  left: 0% !important;
  right: 0% !important;
  align-items: center !important;
  text-align: center !important;
  margin: 55px 0px 45px 0px;
}

.ConfirmDelete .cm-confirm-alert-label {
  left: 5% !important;
}

.ConfirmDelete .p-button.cm-delete-button.p-button-text-only .p-button-text,
.CMdetailsTab.cm-delete-confirmAlert-Custom
  .p-button.cm-delete-button.p-button-text-only
  .p-button-text,
.CMdetailsTab.cm-delete-confirmAlert-Custom
  .p-button.cm-cancel-button.p-button-text-only
  .p-button-text {
  padding: 0em 1em;
  font-weight: 500 !important;
}

.CMdetailsTab.cm-delete-confirmAlert-Custom .p-button.cm-cancel-button span,
.ConfirmDelete .p-button.cm-cancel-button span {
  padding: 0em 2em !important;
  font-weight: 500 !important;
}

.writingNumberLabel {
  text-transform: uppercase;
  color: #434a51 !important;
}

.activeLinkCM:hover {
  color: #2175f4;
}

.cmLabelDiv label {
  font-size: 16px !important;
  font-weight: 400;
  color: #434a51 !important;
}

.cmLabelDivDD label {
  font-weight: 400;
}

.contactRadioBtns .p-radiobutton:has(.p-highlight) + label {
  font-weight: 700;
}

#contactTypeDropdown .pi-chevron-down:before {
  font-weight: 300;
  color: #0052ce;
}

.contactErrMsg {
  font-size: 12px !important;
  color: red !important;
  font-weight: 400 !important;
}

.cmLabelDiv:has(#contactValue-invalid) > .contactErrMsg {
  display: none !important;
}

.cm-contact-add-modal .p-dialog-titlebar > span {
  font-size: 24px;
  font-weight: 500 !important;
}

@font-face {
  font-family: "Lato";
  src: url("../../../../fonts/Lato-Regular.woff") format("woff");
}

.LatoFont {
  font-family: Lato !important;
}

#divcontactTypeDropdown > label {
  display: none !important;
}

.contactTypeDropdownLabel {
  margin-bottom: 6.2px;
  color: #434a51;
  display: flex;
  font-weight: 400;
  font-size: 16px;
}

.contactTypeDropdownLabel > span {
  margin-left: 1px;
  margin-top: 3px;
}

#contactValue-invalid .validation-error {
  width: 100%;
  margin: 2px 5px 0;
  font-size: 13px !important;
  color: #dc3545 !important;
  font-weight: 400 !important;
}

.layout-main .CMLayoutAddEditWrapper .cmEditCarrierBtn:hover .CMaddCarrierBtn {
  background-color: #2175f4 !important;
}

.layout-main
  .CMLayoutAddEditWrapper
  .cmEditCarrierBtn:hover
  #CMaddCarrierSplitBtn {
  background-color: #2175f4 !important;
}

body #isEligibilityChecked .p-checkbox-box {
  width: 12.5px !important;
  height: 13px !important;
}

body #isEligibilityChecked .p-checkbox-box .p-checkbox-icon {
  font-size: 9px !important;
}

body #isOverlapChecked .p-checkbox-box {
  width: 12.5px !important;
  height: 13px !important;
}

body #isOverlapChecked .p-checkbox-box .p-checkbox-icon {
  font-size: 9px !important;
}

body .dynWritingNumbers .p-checkbox .p-checkbox-box {
  width: 12.5px !important;
  height: 13px !important;
  margin-right: 5px;
}

#createaddressBtn .p-button-text {
  font-size: 16px !important;
  font-weight: 500;
  padding-top: 10px;
  color: #0052ce;
}

.CMaddCarrierBtn {
  font-size: 16px !important;
  font-weight: 700 !important;
}

#createCarrierBtn {
  font-size: 16px !important;
  font-weight: 500 !important;
}

#CMPayment .p-inputtext {
  font-size: 16px;
  font-weight: 400;
}

.CMLayoutAddEditWrapper #CMGroup label.p-dropdown-label.p-inputtext {
  font-size: 18px;
  font-weight: 400;
}

.CMLayoutAddEditWrapper
  #CMGroup
  label.p-dropdown-label.p-inputtext.p-placeholder {
  color: #aaaaaa !important;
}

.CMLayoutAddEditWrapper
  #CMGroup
  .p-dropdown-items-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
}

.CMLayoutAddEditWrapper #CMGroup .p-dropdown-items-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: white;
}

.CMLayoutAddEditWrapper
  #CMGroup
  .p-dropdown-items-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: lightgray;
}

.CMLayoutAddEditWrapper #CMPayment .p-dropdown label.p-dropdown-label {
  font-size: 16px;
}

.CMLayoutAddEditWrapper #divstate #state::placeholder {
  color: #aaaaaa !important;
}

.CMLayoutAddEditWrapper #divstate #state {
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  color: #434a51 !important;
  border: 1px solid #dfdedd !important;
}

.CMLayoutAddEditWrapper #addressName::placeholder {
  color: #aaaaaa !important;
}

.CMLayoutAddEditWrapper #addressName {
  border: 1px solid #dfdedd !important;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  color: #434a51 !important;
}

.CMLayoutAddEditWrapper #addressLine::placeholder {
  color: #aaaaaa !important;
}

.CMLayoutAddEditWrapper #addressLine label.p-dropdown-label.p-inputtext {
  border: 1px solid #dfdedd !important;
  color: #434a51 !important;
}

.CMLayoutAddEditWrapper #addressLine {
  border: 1px solid #dfdedd !important;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  color: #434a51 !important;
  padding-top: 12px !important;
}

#cm-dialog-AddEditCarrierAddress #city::placeholder {
  color: #aaaaaa !important;
}

#cm-dialog-AddEditCarrierAddress #city {
  border: 1px solid #dfdedd !important;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  color: #434a51 !important;
}

#cm-dialog-AddEditCarrierAddress #County::placeholder {
  color: #aaaaaa !important;
}

#cm-dialog-AddEditCarrierAddress #County {
  border: 1px solid #dfdedd !important;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  color: #434a51 !important;
}

#cm-dialog-AddEditCarrierAddress #zip::placeholder {
  color: #aaaaaa !important;
}

#cm-dialog-AddEditCarrierAddress #zip {
  border: 1px solid #dfdedd !important;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  color: #434a51 !important;
  padding-top: 12px !important;
}

.CMcancelChanges {
  padding-left: 30px;
  padding-right: 30px;
}

.CMcancelChanges .cm-delete-button .p-button-text {
  padding-top: 2px;
}

#cmnoteName {
  background: transparent;
  border-bottom: 1px solid #dfdedd !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0px;
  font-size: 18px;

  padding-top: 20px;
  padding-bottom: 16px;
}

#cm-txtMessage
  .ql-snow
  .ql-picker:not(.ql-color-picker):not(.ql-icon-picker)
  svg {
  right: auto !important;
}

#cm-txtMessage .ql-toolbar.ql-snow .ql-formats > span:before {
  font-size: 10px;
  color: #aaaaaa !important;
}

#cm-txtMessage .ql-toolbar.ql-snow .ql-picker.ql-font {
  width: fit-content;
  margin-left: 8px;
}

#cm-txtMessage span.ql-picker-label.ql-active {
  padding-right: 15px;
}

#cm-txtMessage .ql-toolbar.ql-snow .ql-picker.ql-header {
  width: fit-content;
}

#cm-txtMessage .ql-toolbar.ql-snow .ql-picker-label {
  padding-left: 8px;
  color: #aaaaaa;
}

body #cm-txtMessage .p-editor-toolbar.ql-toolbar.ql-snow {
  background-color: #ffffff;
  border-bottom: none;
  color: #aaaaaa;
  border: none;
}

.cmAddEditTabParent .cmAddEditTab .p-tabview-nav .p-unselectable-text a {
  background-color: transparent !important;
}

.cmAddEditTab li.p-unselectable-text:not(.p-tabview-selected) {
  padding-top: 14.5px;
}

.cmAddEditTab ul.p-tabview-nav.p-reset li:first-child {
  width: 80px;
}

#cm-txtMessage .ql-align {
  height: 12px;
  width: 8px !important;
  margin-right: 8px;
}

#cm-txtMessage .p-editor-content.ql-container.ql-snow {
  border: none;
  max-height: 85.5%;
  background: white;
}

.cmNotesGrid .p-datatable {
  position: inherit;
}

#cm-txtMessage span.ql-formats button {
  width: 8px;
}

.cmNotesCard {
  background-color: #fff;

  .CMNotesToggleBtn {
    padding-right: 20px;
  }
}

body .cmNotesCard #cmNotesSearchWrapper {
  border: 0.5px solid #dfdedd !important;
  padding-right: 20px;

  .cm-note-search-icon {
    margin: 0;
  }
}

.cmNotesSearchIcon {
  font-size: 14px !important;
  vertical-align: bottom;
  color: #0052ce !important;
  font-weight: 300 !important;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#cmNotesSearch::placeholder {
  font-size: 16px !important;
  color: #aaaaaa !important;
  font-family: Lato !important;
}

#cmNotesSearch {
  padding-right: 18px;
}

#cmnoteName::placeholder {
  color: #aaaaaa !important;
  font-size: 18px;
}

#cmNotesSearch {
  width: 100%;
  height: 40px;
  border: none !important;
  background-color: transparent;
  padding-left: 16px;
  font-size: 16px;
  color: #434a51 !important;
  font-weight: 300;
  font-family: Lato !important;
}

.cmNotesGridLabel {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #051d43;
  padding-left: 21px;
}

.cmNotesGrid {
  padding-left: 20px !important;
  padding-right: 0px;
}

.cmNotesAddBtn {
  min-width: 133px !important;
  margin-top: 3px;
}

#cm-txtMessage {
  height: 100vh;
  background-color: #ffffff;
  margin-top: -14px;
}

body #cmNotesSearch:enabled:focus {
  box-shadow: none !important;
  border: none !important;
}

.ui-datatable .ui-state-highlight {
  background-color: lightblue !important;
}

.notesTitlePreview {
  font-family: Lato !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 18px;
  padding-right: 10px;
  font-weight: 400;
  color: #434a51;
  border-bottom: 1px solid #dfdedd;
  padding-bottom: 9px;
}

.notesPreviewWrapper {
  font-family: Lato !important;
  background-color: #f5f6fa;
  padding: 10px 13px 13px 13px;
  position: inherit;
  margin-top: 8px;
  margin-bottom: 52px;
  border-top: 0.5px solid #dfdedd;
  border-right: 0.5px solid #dfdedd;
}

.notesPreviewInnerDiv {
  font-family: Lato !important;
  background-color: #ffffff;
  height: 100%;
  padding: 16px 12px 12px 12px;
}

.notesPreviewCreatedBy {
  font-family: Lato !important;
  font-size: 12px;
  font-weight: 400;
  color: #434a51;
  letter-spacing: 0.06em;
  line-height: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.notesPreviewNoteBody {
  font-family: Lato !important;
  font-size: 16px;
  font-weight: 300;
  color: #434a51;
  letter-spacing: 0.06em;
  /*line-height: 17px;*/
  min-height: 55px;
  overflow-y: auto;
  padding-top: 5px;
}

.cmNotesGrid td.LatoFont:nth-child(3) {
  border-right: 0px solid lightgray !important;
}

.cmNotescontainer {
  position: relative !important;
}

.cm-Notes-main-content {
  width: 34% !important;
  margin-left: 20% !important;
  height: fit-content !important;
}

.cm-notes-secondary-content {
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 66% !important;
  overflow-y: scroll !important;
}

.notesPreviewRowSelected {
  background-color: #f1faff !important;
}

.cmNotesSaveBtnDisabled {
  background-color: #a0c4f8 !important;
  border-color: #a0c4f8 !important;
  pointer-events: none;
}

body #cmnoteName:enabled:focus {
  box-shadow: none !important;
  border-bottom: 1px solid #dfdedd !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  font-size: 18px;
}

.cmNotesEnable {
  display: none !important;
}

.default-cm-sort .pi-sort-alt:before {
  content: url("../../../../fonts/inactiveCmSort.svg") !important;
}

.cm-asc-sort .pi-sort-amount-up-alt:before {
  content: url("../../../../fonts/cm-asc-sort.svg") !important;
}

.cm-desc-sort .pi-sort-amount-up-alt:before {
  content: url("../../../../fonts/cm-desc-sort.svg") !important;
}

.cm-desc-sort .pi-sort-amount-down:before {
  content: url("../../../../fonts/cm-desc-sort.svg") !important;
}

#cm-txtMessage .ql-editor.ql-blank {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.06em;
  color: #434a51;
  height: 86.5%;
}

.cmSaveNote {
  content: url("../../../../fonts/cm-notes-save.svg") !important;
  margin-left: 10px;
}

.cmDeleteNoteContent {
  font-size: 24px !important;
  color: #434a51 !important;
  font-weight: 400;
  margin-top: 70px;
  margin-bottom: 50px;
  text-align: center;
}

.cmDeleteNoteBtns {
  text-align: center;
  margin-bottom: 57px;
  font-family: Lato !important;
}

.cmNotesCancelButton:hover .p-button-text {
  color: #2175f4 !important;
}

.cmNotesCancelButton:hover {
  border-color: #2175f4 !important;
  color: #2175f4 !important;
}

.cm-delete-button-Notes {
  left: 0%;
  right: 0%;
  top: -0.5px;
  bottom: 0%;
  border-color: #ff4444 !important;
  background: #ff4444 !important;
  border-radius: 3px !important;
  border: 1px solid #ff4444;
  width: 137px;
  height: 32px;
  border-radius: 3px;
}

#cmNotesCancelButton {
  width: 137px;
  border-radius: 3px !important;
  margin-right: 25px !important;
  text-align: center !important;
}

#cmNotesDeleteButton {
  width: 137px; 
}

body .p-button.cm-delete-button-Notes:enabled:hover {
  background-color: #ff4444;
  color: #ffffff;
  border-color: #ff4444;
  border: 1px solid #ff4444;
  width: 137px;
  border-radius: 3px !important;
}

body .p-button.cm-delete-button-Notes span {
  left: 0%;
  right: 0%;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

#cmNotesCancelButton .p-button-text {
  padding: 5px 20px 5px 42px !important;
  font-family: Lato !important;
}

#cm-dialog-notes-delete .pi-times:before {
  color: #0052ce;
  font-weight: 300;
  font-size: inherit;
}

.CMLayoutAddEditWrapper {
  overflow-x: hidden;
}

.notesAddPaneWrapper {
  border-top: 0.5px solid #dfdedd;
  border-right: 0.5px solid #dfdedd;
  background-color: #f5f6fa;
  margin-top: 8px;
  margin-bottom: 6px;
  padding-left: 13px;
  padding-right: 12px;
  position: inherit;
}

.cmNotesGrid .p-datatable-emptymessage td {
  border-right: 0.5px solid #f5f6fa !important;
}

.cmNotesGrid th.p-sortable-column.p-resizable-column.default-cm-sort.LatoFont {
  background-color: #f5f6fa !important;
  height: 40px;
}

#cmNotesDiscardButton {
  background-color: #0052ce !important;
  border-color: #0052ce !important;
  border-radius: 3px !important;
}

#cmNotesDiscardButton:hover {
  background-color: #2175f4 !important;
  border-color: #2175f4 !important;
  border-radius: 3px !important;
}

#cmNotesDiscardButton .p-button-text {
  padding: 7px 15px 5px 19px !important;
}

.CmNotesInfoTooltip {
  position: relative;
  display: inline-block;
}

.CmNotesInfoTooltip:hover .CMNotestooltiptextSave {
  visibility: visible;
}

.CmNotesInfoTooltip .CMNotestooltiptextSave {
  visibility: hidden;
  width: 300px;
  background-color: #434a51;
  z-index: 99899 !important;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  left: 0 !important;
  top: 119% !important;
  margin-left: -14.9em !important;
  text-align: start;
  padding-left: 10px;
  font-size: 12px;
  padding: 12px;
  font-weight: 400;
}

.CmNotesInfoTooltip .CMNotestooltiptextSave::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 81% !important;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #434a51 transparent;
}

#cm-txtMessage .ql-toolbar.ql-snow .ql-formats:last-child {
  margin-right: 0px !important;
}

.cm-notes-TabView a.custom-tab-link.report-tabs {
  border: none !important;
}

#tab-parent-custom.cm-notes-TabView .p-tabview-nav li.p-highlight:before {
  content: "";
  position: absolute;
  left: 19.1%;
  bottom: -2px;
  height: 15px;
  width: 60%;
  border-bottom: 2px solid #0052ce;
}

.CMdetailsTab {
  --tab-height: 51px;
}

.cmDetailsActive {
  height: var(--tab-height);
  overflow: hidden;
}

.cmDetailsInActive {
  height: var(--tab-height);
}

.disableCMNotesTab #tab-parent-custom ul li:last-child {
  display: none;
}

.notesCmSelectedRow {
  background-color: #f1faff !important;
}

body .cmNotesGrid .p-datatable .p-datatable-thead > tr > th:last-child {
  border-right: 0.5px solid #dfdedd !important;
}

body .cmNotesGridWithNotes .p-datatable .notesCmSelectedRow > td:last-child {
  border-right: 0.5px solid #f1faff !important;
}

body .cmNotesGridWithoutNotes .p-datatable .notesCmSelectedRow > td:last-child {
  border-right: 0.5px solid #dfdedd !important;
}

.cmNotesSaveBtn {
  width: 134px !important;
}

#cmCarrierDiscardButton {
  background-color: #0052ce !important;
  border-color: #0052ce !important;
  border-radius: 3px !important;
}

#cmCarrierDiscardButton:hover {
  background-color: #2175f4 !important;
  border-color: #2175f4 !important;
  border-radius: 3px !important;
}

#cmCarrierDiscardButton .p-button-text {
  padding: 5px 5px 5px 9px !important;
}

#cmCarrierCancelButton {
  width: 137px;
  border-radius: 3px !important;
  margin-right: 25px !important;
  text-align: center !important;
}

#cmCarrierCancelButton .p-button-text {
  padding: 5px 20px 5px 51.5px !important;
}

body .CMLayoutAddEditWrapper .carrierSaveBtnWrapper #CMaddCarrierBtnEnable {
  border-radius: 3px 0px 0px 3px !important;
  min-width: 107px !important;
}

body
  .CMLayoutAddEditWrapper
  .carrierSaveBtnWrapper
  #CMaddCarrierBtnEnable
  .p-button-text {
  border-right: 1px solid #fff !important;
}

.CMLayoutAddEditWrapper > .p-card-body {
  padding-right: 0px !important;
  padding-bottom: 125px !important;
}

.CMLayoutAddEditWrapper .p-dialog .p-dialog-titlebar-icon span {
  color: #0052ce !important;
}

#addressName span.p-dropdown-trigger-icon.pi.pi-chevron-down.p-clickable,
.cm-adr-state
  #state
  span.p-dropdown-trigger-icon.pi.pi-chevron-down.p-clickable {
  font-size: 18px !important;
}

.CMLayoutAddEditWrapper #tab-parent-custom .p-tabview-nav li a {
  border: none !important;
  font-size: 12px;
  text-decoration: none !important;
  color: #0052ce !important;
}

.CMLayoutAddEditWrapper #tab-parent-custom .p-tabview-nav {
  margin-top: 0px;
  border: none !important;
  /* box-shadow: 0px 10px 10px -15px rgb(51 71 138 / 35%); */
  padding-bottom: 2px;
}

.CMLayoutAddEditWrapper #tab-parent-custom .tab-left-border:before {
  top: 23%;
  height: 24px !important;
}

.cm-product-icon::after {
  content: url("../../../../fonts/cm-products.svg") !important;
  position: absolute;
  left: 20%;
  top: 34%;
}

.cm-product-hallow-icon::after {
  content: url("../../../../fonts/cm-products-hallmark.svg") !important;
  position: absolute;
  left: 20%;
  top: 34%;
}

.cm-manage-product-icon {
  color: #0052ce !important;
}

.cm-manage-product-icon::before {
  content: url("../../../../fonts/cm-manage-product.svg") !important;
  font-size: 16px;
  position: absolute;
  margin-left: -20px;
  height: 14px;
  width: 14px;
}

.cm-subcategory-icon {
  padding-left: 30px;
}

.cm-subcategory-icon::before {
  content: url("../../../../fonts/cm-subcategory.svg") !important;
  font-size: 16px;
  position: absolute;
  margin-left: -20px;
  height: 14px !important;
  width: 14px !important;
}

.cm-document-edit-icon::after {
  content: url("../../../../fonts/cm-document-edit-pen.svg") !important;
  margin-left: -12px;
}

.cm-product-search {
  margin-top: 5px;
  margin-left: -5px;
}

.cm-product-details {
  background: #f1faff;
  padding: 20px 40px;
  margin: -10px -15px;
  padding-bottom: 0px;
}

.cm-product-details .p-grid {
  padding-bottom: 15px;
}

.cm-product-search #CMselectedStatus {
  width: 110px;
}

.carrierCmNoteTable64 {
  width: 64%;
}

.layout-main
  .CMLayoutAddEditWrapper
  .cm-preview-checkbox
  .p-checkbox-box.p-highlight,
.CMLayoutAddEditWrapper .cm-preview-checkbox .p-checkbox-box {
  width: 15px;
  height: 15px;
  margin-bottom: 3px;
  background: #aaa !important;
  border: #aaa !important;
  padding-top: 1px;
}

.cm-grid-section-label {
  color: #434a51;
  font-weight: 300;
}

.cm-grid-section-value {
  color: #434a51;
  font-weight: 400;
}

body .CMLayoutAddEditWrapper .p-dialog .p-dialog-titlebar {
  color: #002d72 !important;
  border-color: transparent !important;
  border-top-left-radius: 8px !important;
  font-weight: 500;
  max-height: 56px;
  padding-top: 11px;
  border-top-right-radius: 8px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

body .CMLayoutAddEditWrapper .p-dialog .p-dialog-content {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  padding: 20px;
}

body .CMLayoutAddEditWrapper .p-dialog .p-dialog-titlebar .p-dialog-title {
  font-size: 24px !important;
  font-weight: 500;
}

body .CMLayoutAddEditWrapper .p-dialog .pi-chevron-down:before {
  content: url("../../../../fonts/cm-chevron-down.svg") !important;
}

body
  .CMLayoutAddEditWrapper
  .cm-custom-table
  .p-paginator
  .p-dropdown
  .p-dropdown-trigger
  .p-dropdown-trigger-icon {
  top: 40% !important;
}

body
  .CMLayoutAddEditWrapper
  .cm-custom-table
  .p-paginator
  .pi-chevron-down:before {
  content: url("../../../../fonts/cm-page-down.svg") !important;
}

body .CMLayoutAddEditWrapper #carrierGroups .pi-chevron-down:before {
  content: url("../../../../fonts/cm-chevron-down.svg") !important;
}

body .CMLayoutAddEditWrapper .p-dialog .pi-chevron-down {
  top: 17px !important;
}

.cm-product-selected-row,
.cm-product-selected-row:hover,
.cm-product-selected-row td:hover,
.app-table.p-datatable .p-datatable-tbody > tr.cm-product-selected-row:hover {
  background: #f1faff !important;
  border: 1px solid #dfdedd;
}

.tooltip .tooltip-cm-product {
  font-size: 13px;
  margin-left: 0;
  left: -29px !important;
  top: 25px !important;
  background-color: #434a51;
  border-radius: 5px !important;
  width: 91px !important;
}

.tooltip .tooltip-cm-subcategory {
  width: 120px !important;
  font-size: 13px !important;
  margin-left: 0;
  left: -56px !important;
  top: 24px !important;
  background-color: #434a51 !important;
  border-radius: 5px !important;
}

.tooltip .tooltip-cm-doc-download {
  width: 80px !important;
  font-size: 13px;
  margin-left: 0;
  left: -32px !important;
  top: 24px !important;
  background-color: #434a51 !important;
  border-radius: 5px !important;
}

.tooltip .tooltip-cm-product:after,
.tooltip .tooltip-cm-subcategory:after,
.tooltip .tooltip-cm-levels:after,
.tooltip .tooltip-cm-document:after,
.tooltip .tooltip-cm-doc-download:after {
  content: "";
  position: absolute;
  top: -9px;
  left: 52.5%;
  margin-left: -6px;
  border: 5px solid transparent;
  border-bottom-color: #434a51;
}

.p-datatable .p-datatable-tbody > tr > td.cm-product-expand-icon .p-row-toggler,
.p-datatable
  .p-datatable-tbody
  > tr
  > td.cm-product-expand-icon
  .p-row-toggler:hover {
  color: #0052ce;
}

.cm-product-expand-icon .p-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #0052ce;
}

.carrierLandingView tr > th {
  min-height: 40px !important;
  height: 40px !important;
}

#CMCarrierGroupsTable tr > th {
  min-height: 40px !important;
  height: 40px !important;
}

body
  .CMLayoutAddEditWrapper
  .cm-custom-table
  button.p-paginator-page.p-paginator-element.p-link:first-child {
  border-radius: 0px !important;
}

body .CMLayoutAddEditWrapper .app-table .p-paginator .p-paginator-prev {
  border-radius: 5px 0px 0px 5px;
  margin-right: -1px;
  margin-left: 10px;
  height: 43px !important;
}

body .CMLayoutAddEditWrapper .app-table .p-paginator .p-paginator-first {
  border-radius: 5px;
  margin-right: -1px;
  margin-left: 11px;
  height: 43px !important;
}

body .CMLayoutAddEditWrapper span.p-paginator-pages > button {
  width: 33px !important;
  height: 43px !important;
}

body
  .CMLayoutAddEditWrapper
  .cm-custom-table
  button.p-paginator-next.p-paginator-element.p-link {
  height: 43px !important;
}

body
  .CMLayoutAddEditWrapper
  .cm-custom-table
  button.p-paginator-last.p-paginator-element.p-link {
  height: 43px !important;
}

body .CMLayoutAddEditWrapper .cm-custom-table .p-paginator .p-dropdown {
  height: 43px !important;
}

body
  .CMLayoutAddEditWrapper
  .cm-custom-table
  .p-paginator
  .p-dropdown
  .p-dropdown-label {
  margin-top: 7px !important;
}

body
  .CMLayoutAddEditWrapper
  .cm-custom-table
  .p-paginator
  .p-paginator-right-content {
  margin-top: 7px !important;
}

body
  .CMLayoutAddEditWrapper
  .cm-custom-table
  .p-paginator
  .p-paginator-current {
  margin-top: 7px !important;
}

.cm-tab-count {
  width: 20px;
  background: #d8f5d9;
  border-radius: 45%;
  position: absolute;
  left: 67%;
  bottom: 61%;
  padding: 2px 4px 1px 4px;
  font-size: 10px;
  text-align: center;
  color: #434a51;
}

.cm-product-icon,
.cm-product-hallow-icon {
  padding-left: 29px !important;
  color: #0052ce !important;
}

.cm-subcategory-icon {
  color: #0052ce !important;
}

.cm-custom-table.cm-product-table .p-datatable .p-datatable-thead > tr > th {
  border-right: none !important;
  padding: 13px 15px;
}

.cm-custom-table.cm-product-table .p-datatable .p-datatable-thead > tr > td {
  padding: 10px 15px;
}

.cm-custom-table.cm-product-table
  .p-datatable
  .p-datatable-thead
  > tr
  > th:last-child {
  border-left: 0.5px solid #d0d2d9 !important;
}

.CarrierCMContactView .secondry-title {
  color: #434a51;
}

.CarrierCMAddressView .secondry-title {
  color: #434a51;
}

body .CMLayoutAddEditWrapper .p-inputtext {
  color: #434a51 !important;
}

.disableCMNotesTab .p-tabview-panels {
  display: none !important;
}

.cmDetailsInActive .p-tabview-panels {
  display: none !important;
}

#cm-txtMessage .ql-editor {
  padding-top: 44px !important;
  padding-bottom: 0px !important;
  max-height: 92%;
}

.cmNotesContainer {
  display: table;
  width: 100%;
}

.cmNotesChild {
  display: table-cell;
  padding: 16px;
}

.CMNotesCounter {
  color: #000000 !important;
  font-size: 12px;
  position: absolute;
  font-weight: 300;
  font-family: Lato !important;
  right: 3.5%;
  margin-top: -22px;
  margin-right: 8px;
}

.cm-contact-add-modal #contactTypeDropdown {
  width: 292px;
  height: 40px;
}

.cm-contact-add-modal #contactValue {
  width: 292px;
  height: 40px;
  font-size: 16px;
}

.cm-contact-add-modal #contactValue .p-inputtext {
  padding-top: 5px !important;
}

.cm-contact-add-modal .cmLabelDiv .p-inputtext {
  padding-top: 10px !important;
}

.cm-contact-add-modal #contactTypeDropdown .p-dropdown-label {
  margin-top: 4px !important;
  font-size: 16px;
}

.cm-contact-add-modal .p-dialog-content {
  padding-left: 19px !important;
  padding-right: 15px !important;
}

.cmContactFooter .custom-center .form-group {
  margin-bottom: -4px !important;
  padding-top: 39px !important;
}

.cm-custom-table.cm-product-table
  .p-datatable
  .p-datatable-tbody
  > tr.p-datatable-emptymessage
  td {
  padding: 13px 15px;
}

.cmCarrierCancelButton span {
  font-family: Lato !important;
}

.cmCarrierDiscardButton span {
  font-family: Lato !important;
}

.openSansFam {
  font-family: "Open Sans" !important;
}

.cm-custom-table.cmWebsitesGrid
  .p-datatable
  .p-datatable-thead
  > tr
  > th:last-child,
body
  .cm-custom-table.cmWebsitesGrid
  .p-datatable
  .p-datatable-tbody
  > tr
  > td.cm-product-expand-icon {
  border-left: none !important;
}

body
  .cm-custom-table.cmWebsitesGrid
  .p-datatable
  .p-datatable-tbody
  > tr
  > td:nth-last-child(2) {
  border-right: none !important;
}

body .cm-custom-table.cmProductsGrid .p-datatable .p-datatable-tbody > tr > td {
  padding: 10px 15px;
}

.cm-product-details .cm-preview-checkbox .p-checkbox-box {
  background-color: #f1faff !important;
  border: 1px solid #434a51 !important;
}

.cm-product-details .p-checkbox {
  margin-top: 3px;
}

body .cm-product-table .p-component {
  font-family: Lato !important;
}

body
  .cmNotesCard
  .cm-custom-table.cm-product-table
  #CMCarrierProductsTable
  .p-datatable-thead
  > tr
  > th:last-child {
  border-left: 0.5px solid #d0d2d9 !important;
}

.cmProductsGrid {
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-top: -3px;
}

.cm-btn-primary {
  background: #0052ce !important;
  border: 1px solid #0052ce !important;
  border-radius: 3px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  height: 40px;
}

.cm-level-add-icon {
  content: url("../../../../fonts/arrows-turn-right.svg") !important;
  height: 14px;
  width: 14px;
  fill: #fff;
  font-size: 16px;
  font-weight: 300;
}

.cm-filter {
  border-radius: 3px;
  display: flex;
  margin-top: 5px;
  margin-left: -5px;
  border: 1px solid #dededd;
  border-radius: 3px;
}

.cm-filter-icon {
  margin-right: 13px;
  vertical-align: top;
  padding: 6px 0px 4px 7px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px !important;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0052ce;
}

.cm-filter-width {
  width: 416px;
}

.cm-filter-refresh {
  margin-top: 18px;
  padding-left: 16px;
  color: #0052ce;
}

.cm-manage-level {
  padding-top: 10px;
}

.cm-manage-level .p-button-text {
  font-size: 16px;
  padding: 0.629em 1em 0.429em 2.25em !important;
}

.cm-note-text-error #cm-txtMessage .p-editor-toolbar.ql-toolbar.ql-snow {
  border: 1px solid #ff4444 !important;
  border-bottom: 0px solid red !important;
}

.cm-note-text-error #cm-txtMessage .p-editor-content.ql-container.ql-snow {
  border: 1px solid #ff4444 !important;
  border-top: 0px solid red !important;
  max-height: 85% !important;
  background: white;
  padding-bottom: 20px;
}

.disableCMNotesSavebtnLenght #createCarrierBtn {
  background-color: #a0c4f8 !important;
  border-color: #a0c4f8 !important;
  pointer-events: none;
}

.tooltip .tooltiptext.tooltip-cm-document {
  --tooltip-background: #000;
  width: auto;
  font-size: 13px;
  margin-left: 0;
  left: 0;
  top: calc(100% + 5px);
  background-color: var(--tooltip-background);
  border-radius: 5px;
  padding: 5px;
  right: unset;

  &::after {
    border-bottom-color: var(--tooltip-background);
  }
}

#cm-txtMessage .ql-editor::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
}

#cm-txtMessage .ql-editor::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: white;
}

#cm-txtMessage .ql-editor::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: lightgray;
}

.cm-notes-TabView .custom-tab-link {
  color: #0052ce !important;
}

.cm-product-expand-icon .p-row-toggler .pi {
  font-size: 16px;
  font-weight: 300;
}

.layout-main .CMLayoutAddEditWrapper .cm-ellipsis-td {
  white-space: nowrap;
  min-width: 100px;
  max-width: 220px;
  overflow: hidden !important;
  text-overflow: ellipsis;
  border-right: 0.5px solid #dfdedd;
}

#cm-txtMessage .ql-toolbar.ql-snow .ql-formats:last-child {
  margin-right: 0px !important;
}

.cmNotesAddBtn .p-button-text {
  padding-left: 40px !important;
}

.cmNotesSaveBtn .p-button-text {
  padding-left: 30px !important;
}

.cmNotesAddBtn .cmCarrierAddIcon {
  margin-left: 10px;
}

.cm-notes-TabView li.p-unselectable-text {
  margin: 0px -10px 1px -1px !important;
}

.cm-notes-trash {
  margin-right: 4px;
  float: right;
  margin-top: 5px;
}

.cm-notes-align {
  margin-right: 0px;
  margin-top: -4px;
}

.cm-notes-points {
  /* margin-top: 1px; */
}

#cm-txtMessage
  .ql-snow
  .ql-picker:not(.ql-color-picker):not(.ql-icon-picker)
  svg {
  top: 43% !important;
}

.allow-overlap {
  font-size: 16px;
  margin-top: 2px;
}

.tooltip .tooltip-cm-levels {
  font-size: 13px;
  margin-left: 20px;
  left: -39px;
  top: 25px;
  background-color: #434a51;
  border-radius: 5px;
  width: max-content;
  padding-left: 5px;
  padding-right: 5px;
  max-width: 500px;
}

body
  .CMLayoutAddEditWrapper
  #tab-parent-custom.cm-notes-TabView
  .p-tabview-nav
  li:nth-child(1)
  .p-highlight:before {
  width: 50% !important;
  left: 24% !important;
}

.disable-cm-pagination .p-paginator {
  display: none;
}

.CMLayoutAddEditWrapper .pi {
  font-weight: 300;
}

.CMLayoutAddEditWrapper #CMGroup .pi {
  font-size: 14px !important;
}

.ml52 {
  margin-left: -52px;
}

.ml30 {
  margin-left: 30px;
}

.ml70 {
  margin-left: 70px;
}

.mr4 {
  margin-right: 4px;
}

#createCarrierLevelBtn .p-button-text {
  font-weight: 500;
}

.layout-main
  .CMLayoutAddEditWrapper
  #CMaddCarrierSplitBtn
  .p-menuitem
  .p-menuitem-text {
  margin-left: -3px;
  font-size: 16px;
  font-weight: 400;
  vertical-align: text-top;
}

.layout-main
  .CMLayoutAddEditWrapper
  #CMaddCarrierSplitBtn
  .p-menuitem
  .p-menuitem-icon {
  margin-top: 0.5px;
  margin-left: -2px;
}

body .cmNotesGridWithoutNotes .p-paginator {
  bottom: 106px;
}

.mw80 {
  max-width: 80px;
}

#CMCarrierLevelsTable {
  margin-top: -5.3px;
}

.cm-level-grid-label {
  margin-top: -5px;
}

.cm-contact-notes {
  padding-left: 10px;
  margin-top: 12px;
}

.cm-contact-notes-optional-text {
  width: 602px !important;
  margin-top: 5px;
}

.cm-contact-notes-character-limit {
  font-size: 12px;
  padding-left: 200px;
  font-style: italic;
  font-weight: 400;
  color: #aaaaaa !important;
  float: right;
  margin-right: 5px;
}

.cm-contact-footer {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 63px;
  margin-top: -15px;
  margin-left: 9px;
}

.cm-contact-footer-left {
  float: right;
}

.cm-contact-save {
  width: 75px !important;
  height: 32px !important;
  min-height: 32px !important;
}

.cm-contact-cancel {
  width: 90px !important;
  height: 32px !important;
  min-height: 32px !important;
}

.cm-add-edit-carrier {
  padding-top: 5px;
  margin-left: -5px;
}

.CMpaddingLeft5 {
  padding-left: 5px;
}

.CMpaddingBottom5 {
  padding-bottom: 5px;
}

.CMpaddingTop13 {
  padding-top: 13px;
}

.CMpaddingTop14 {
  padding-top: 14px;
}

.cm-add-edit-shortname {
  padding-top: 15px;
  padding-left: 35px;
}

.cm-add-edit-shortname > span {
  font-size: 14px;
  color: #434a51;
  padding-right: 5px;
}

.cm-add-edit-fontweight-300 {
  font-weight: 300;
}

.cm-add-edit-fontweight-400 {
  font-weight: 400;
}

.CMpaddingBottom30 {
  padding-bottom: 25px;
}

.cm-add-edit-carrier-aliasname {
  height: 26px;
  min-width: 238px;
  max-width: 238px;
  border: 0.5px solid #dfdedd !important;
}

.cm-add-edit-carrier-aliasname::placeholder {
  color: #aaaaaa !important;
}

.CMPaddingLeft10 {
  padding-left: 10px;
}

.CMMarginTop20 {
  margin-top: 20px;
}

.CMPaddingRight10 {
  padding-right: 10px;
}

.CMPaddingRight5 {
  padding-right: 5px;
}

.cm-preview-mastergroup {
  font-size: 24px;
  padding-top: 20px;
  padding-left: 10px;
  color: #434a51;
}

.cm-preview-mastergroup-shortname {
  font-size: 18px;
  padding-left: 5px;
  padding-top: 25px;
  color: #434a51;
}

.cm-preview-mastergroup-shortname .cm-preview-alias {
  font-size: 14px;
  font-weight: 300;
  color: #434a51;
  margin-left: 5px;
}

.cm-preview-alias-group-name {
  padding-left: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #434a51;
}

.cm-preview-group {
  padding-left: 15px;
  font-size: 14px;
  font-weight: 300;
  color: #434a51;
}

body .p-dropdown .p-dropdown-label.p-placeholder.cm-add-edit-master {
  color: #aaaaaa !important;
  font-size: 18px;
}

.checkBoxLabelCM-Group {
  min-width: 200px;
  max-width: 200px;
  height: 30px;
  padding-top: 5px;
  padding-left: 5px;
}

body #CMGroup .p-dropdown-panel .p-dropdown-filter-container {
  width: 278px;
}

.cm-preview-groupname {
  padding-left: 5px;
}

.cm-product-expand-icon .p-row-toggler .pi-chevron-right:before {
  content: url("../../../../fonts/cm-acc-down.svg") !important;
}

.cm-product-expand-icon .p-row-toggler .pi-chevron-down:before {
  content: url("../../../../fonts/cm-acc-up.svg") !important;
}

body #SSRcustomReportTable .p-datatable-thead > tr > th.p-highlight {
  color: #0052ce;
}

#carrierGroups .pi-times {
  margin-top: -34%;
  margin-right: 2px;
}

#carrierGroups .pi-times:before {
  content: url("../../../../fonts/CM-times-cancel.svg") !important;
}

#cm-txtMessage .ql-editor.ql-blank::before {
  font-style: normal;
}

/**CMList View**/
/**Active inActive Termniate**/
.carrier-groups-active,
.carrier-groups-inactive {
  background-color: #f5f6fa;
  border-radius: 3px;
  padding: 5px 8px 5px 7px;
  color: #434a51;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  letter-spacing: 0.06em;
  padding-left: 7px;
  width: 114px;
  display: inline-flex;
}

.cg-list-status-badge {
  margin-top: 2px;
  margin-right: 8px;
}

.cg-status-green,
.cg-status-gray {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
}

.cg-status-green {
  background-color: #7fd7b2;
}

.cg-status-gray {
  background-color: #aaaaaa;
}

.cg-wrap .CMtoggleBtn .label {
  height: 16px !important;
  width: 24px !important;
}

/**toogle switch**/
.cg-wrap .CMtoggleBtn .switch {
  width: 8px !important;
  height: 8px !important;
  margin: 4px 17.1px 2.1px 4.1px !important;
  background-color: #aaaaaa !important;
}

.cg-wrap .CMtoggleBtn .inner:after {
  background-color: #ffff !important;
  border: 2.5px solid #aaaaaa;
  height: 16px !important;
  border-radius: 8px;
}

.cg-wrap .CMtoggleBtn .checkbox:checked + .label .switch {
  background-color: #fff !important;
}

/* .cg-wrap .CMtoggleBtn .inner:after{
  border: 3px solid #aaa;
  background-color: transparent;
} */

/**Document Sub Menu**/
.document-tab-submenu #tab-parent-custom {
  margin-left: -17px;
}

.document-tab-submenu #tab-parent-custom .p-tabview-nav li a {
  font-size: 16px !important;
  font-weight: 400;
  color: #0052ce !important;
  padding-bottom: 4px;
  padding-top: 0;
  padding-left: 6px;
  padding-right: 9px;
}

.document-tab-submenu
  #tab-parent-custom.cm-notes-TabView
  .p-tabview-nav
  li.p-highlight:before {
  content: "";
  position: absolute;
  left: 6%;
  bottom: -2px;
  height: 15px;
  width: 80%;
  border-bottom: 2px solid #0052ce;
}

.document-tab-submenu #tab-parent-custom .tab-left-border:before {
  top: -1px;
}

body .cmNotesCard .p-grid .p-component {
  font-family: Lato !important;
}

.cm-ellipse-action .custom-report-menu-active {
  background: transparent !important;
  padding: 0 !important;
  margin-left: 6px !important;
}

.cm-ellipse-action .custom-report-menu-list {
  z-index: 999;
  margin-left: -132px;
  margin-top: 6.5px;
  right: 13px;
}

body
  .CMLayoutAddEditWrapper
  .cm-landing-cm-grid
  #SSRcustomReportTable
  .p-datatable-thead
  > tr
  > th:nth-child(6) {
  border-right: none !important;
}

body #isRigidContractingChecked .p-checkbox-box {
  width: 12.5px !important;
  height: 13px !important;
}

body #isRigidContractingChecked .p-checkbox-box .p-checkbox-icon {
  font-size: 9px !important;
}
