.layout-wrapper .layout-main {
  min-height: calc(100vh - 210px);
  height: 100%;
}

/**Integrity Footer Start**/
.ic-footer {
  font-family: "Lato" !important;
  text-align: center;
  font-size: 16px;
  color: #434A51;
  font-weight: 400;
  padding-bottom: 5px;
  margin-left: 55px;
}

.ic-footer img.footer-logo {
  content: url("../../../src/images/IntegrityConnect-logo.svg");
  margin-top: 20px;
  margin-bottom: 10px;
}

.ic-footer .footer-copyright {
  margin-top: 38px;
  margin-bottom: 5px;
}

.ic-footer .footer-link {
  margin-right: 40px;
}

.ic-footer .footer-link ul li {
  display: inline-block;
}

.ic-footer .footer-link ul li::after {
  content: "|";
  color: #434A51;
  height: 50px;
}

.ic-footer .footer-link ul li:last-child::after {
  display: none;
}

.ic-footer .footer-link ul li a {
  font-weight: 700;
  color: #717171;
  padding: 5px 22px;
  cursor: pointer;
  text-decoration: none;
}

.CMLayoutAddEditWrapper+.ic-footer {
  background-color: #f4f8fb;
}

.CMLayoutAddEditWrapper>.p-card-body {
  padding-bottom: 0 !important;
}

/** Need Help CSS**/
.need-help-wrap {
  position: relative;
  font-family: "Lato";
  margin-top: -29px;
  margin-left: -16px;
  width: 102.4%;
  background: #edf0f5;
}

.need-help-wrap .banner img {
  width: 100%;
}

.need-help-wrap .page-heading {
  background-color: #fff;
  height: 60px;
  line-height: 65px;
  font-size: 32px;
  font-weight: 400;
  padding-left: 25px;
  color: #002D72;
  margin-top: -5px;
  margin-bottom: -18px;
  border-bottom: 1px solid #DFDEDD;
}

.need-help-wrap .contact-support-wrap {
  margin-bottom: -40px;
  margin-top: 110px;
  display: flex;
  justify-content: center;
}

.need-help-wrap .contact-support-wrap .contact {
  width: 378px;
  text-align: center;
}

.need-help-wrap .contact-support-wrap h2 {
  font-size: 32px;
  font-weight: 400;
  color: #002D72;
}

.need-help-wrap .contact-support-wrap p {
  font-size: 16px;
  font-weight: 400;
  color: #434A51;
  margin-top: 8px;
  width: 375px;
  margin-bottom: 20px;
}

.need-help-wrap .email-wrap {
  background-color: #fff;
  border-radius: 10px;
  border-top: 1.5px solid #dfdedd;
  height: 100px;
  text-align: left;
  margin-bottom: 160px;
}

.need-help-wrap .email-wrap ul {
  padding-left: 15px;
  padding-top: 3px;
}

.need-help-wrap .email-wrap ul li a {
  font-size: 16px;
  font-weight: 700;
  color: #0052CE;
  cursor: pointer;
}

.need-help-wrap .email-wrap ul li:first-child {
  padding-bottom: 30px;
}

.need-help-wrap .email-wrap ul li:first-child a::before {
  content: url("../../fonts/browser.svg");
  padding: 10px 8px 6px;
}

.need-help-wrap .email-wrap ul li a::before {
  content: url("../../fonts/email-new.svg");
  background-color: #e9f1fe;
  padding: 8px;
  margin-right: 8px;
  border-radius: 50%;
}

.terms-of-use-banner-wrap {
  position: relative;
  margin-top: -29px;
  margin-left: -16px;
  width: 102.4%;
}

.terms-of-use-banner-wrap img {
  width: 100%;
}

@media screen and (min-width: 1536px) {

  .need-help-wrap,
  .terms-of-use-banner-wrap {
    width: 102.1%;
  }
}

@media screen and (min-width: 1800px) {

  .need-help-wrap,
  .terms-of-use-banner-wrap {
    width: 101.7%;
  }
}

.home-bottom-badge {
  margin-bottom: 0 !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.d-block {
  display: block;
}

.terms-of-use {
  background-color: #edf0f5;
  font-family: "Lato";
  margin: 56px 44px 80px;
}

.terms-of-use .content-wrap {
  padding: 52px 90px 52px;
  font-size: 16px;
  color: #434A51;
  background: #fff;
  border-radius: 4px;
}

.terms-of-use .page-heading {
  font-size: 32px;
  font-weight: 400;
  color: #002D72;
  margin-bottom: -10px;
}

.terms-of-use .tos_agreement_text p:first-child {
  font-size: 12px;
  font-weight: 400;
  color: #434A51;
}

.terms-of-use .tos_agreement_text p {
  line-height: 24px;
}

.terms-of-use .tos_agreement_text ol li {
  margin-bottom: 6px;
  line-height: 24px;
}

.terms-of-use a,
.terms-of-use a:hover {
  color: #0000EE !important;
}

.tos-footer {
  margin-bottom: -45px;
  margin-left: 0;
}