.tos_title .p-dialog-title {
   font-size: 24px;
   font-weight: 400 !important;
   color: #002D72;
   line-height: 29px;
}

.tos_agree {
    padding: 0px 20px 0px 20px;
}
.tos_content {
    height: 300px;
    overflow-y: scroll;
    padding-bottom: 40px;
}
.tos_footer {
    display: flex;
    flex-direction: row-reverse;
    padding-top: 30px;
    padding-bottom: 16px;
    padding-right: 18px;
}
.buttons_div {
    width: 250px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tos_agreement_text li {
    list-style: lower-roman;
}
