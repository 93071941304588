.banner_container {
  border-radius: 3px;
  border: 1px solid #dfdfe0;
  height: 52px;
  width: 100%;
  display: flex;
  align-items: center;
}
.banner_icon_container {
  height: 100%;
  width: 52px;
}
.banner_banner_icon {
  top: 24%;
  position: relative;
  left: 22%;
}
.banner_primary {
  background-color: #a0c4f8;
}
.banner_warning {
  background-color: #ffd390;
}
.banner_success {
  background-color: #d8f5d9;
}
.banner_danger {
  background-color: #ff7676;
}
.banner_body {
  margin: 0 5px;
  font-size: 0.8em;
}
