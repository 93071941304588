:root {
  --header-z-index: 9999;
  --header-height: 70px;
  --sidebar-width: 55px;
}

Doc Workflowbody {
  color: #142933;
}
body {
  overflow-anchor: none !important;
}
body .layout-main a {
  color: #005ae0;
}

body .layout-main a:hover {
  color: #33478a;
  text-decoration: underline;
}

body .layout-main a.fc-daygrid-event:hover {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
}

body .layout-main a.fc-daygrid-event p i {
  white-space: normal;
}

.dialog-content-scroll-y-enabled {
  max-height: 100%;
  overflow-y: auto;
}

.custom-drop-saveto {
  width: 180px;
  margin-top: 7px;
}

.custom-span-email {
  font-weight: bold;
  position: relative;
}

.custom-span-email:after {
  content: "; ";
  right: 0;
  top: 0;
}

.custom-span-email:last-child:after {
  content: " ";
}

.p-datepicker-buttonbar button:last-child {
  display: none;
}

/* TODO: need to be check for tablet width   */
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .dialog-content-scroll-y-enabled {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 1155px) {
  .agent-top-search {
    display: none;
  }
}

.layout-wrapper.layout-static .layout-topbar {
  left: 0 !important;
  height: var(--header-height);
  background: #fff !important;
  color: #142933;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border-bottom: 1px solid #d9e0e6;
  font-family: "Lato" !important;
  z-index: var(--header-z-index);
}

.layout-wrapper .layout-topbar .layout-menu-button {
  background: #f5f6fa;
  height: var(--header-height);
  width: 55px;
  position: fixed;
  left: 0;
  top: 0;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  color: #051d43 !important;
  border-bottom: 1px solid #d9e0e6;
}

.layout-wrapper .layout-topbar .layout-menu-button span {
  font-size: 1.6em !important;
}

.layout-wrapper .layout-main {
  padding-top: 85px !important;
}

.header-form-group {
  font-size: 13px;
}

.top-header-form-group {
  font-size: 13px;
  padding-top: 6px;
}

.header-form-group span {
  font-weight: bold;
}

.header-form-group.custom-notification {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  min-height: 50px;
  background-color: rgba(237, 240, 245, 0.55);
  border-bottom: 1px solid #c8c8c8;
}

.dropdown-topbar {
  background-color: rgba(237, 240, 245, 0.55) !important;
}

.header-form-group.custom-notification:hover {
  background-color: rgba(237, 240, 245, 0.55);
}

.header-form-group .doc-warning {
  margin: 0px;
  font-size: 14px;
}

.header-form-group .doc-warning:first-child {
  margin-bottom: 3px;
}

.header-form-group .doc-warning .doc-icon {
  font-size: 16px;
  color: goldenrod;
}

.layout-wrapper .layout-sidebar {
  margin-top: 0 !important;
  box-shadow: none;
  top: var(--header-height);
  width: var(--sidebar-width);
}

@media (max-width: 1024px) {
  .layout-wrapper .layout-sidebar {
    left: 0;
  }

  .search-form-group {
    margin-bottom: 22px !important;
  }
}

.layout-wrapper .layout-sidebar ul li.active-menuitem > a {
  color: #33478a !important;
  background-color: #fff !important;
  max-width: 55px;
}

.layout-wrapper.layout-static-sidebar-inactive
  .layout-sidebar
  ul
  li.active-menuitem
  > a {
  max-width: 220px;
}

.layout-wrapper .layout-sidebar ul li.normal-menuitem a:hover {
  color: #fff !important;
  opacity: 0.7;
}

.layout-wrapper.layout-static-sidebar-inactive
  .layout-sidebar
  ul
  li.active-menuitem
  a:hover,
.layout-wrapper.layout-static-sidebar-inactive
  .layout-sidebar
  ul
  li.active-menuitem
  a:hover
  span {
  color: #051d43;
}

.layout-wrapper .layout-sidebar ul li i {
  font-size: 18px !important;
  font-weight: normal !important;
  min-width: 28px;
  text-align: left;
}

.layout-wrapper .layout-sidebar ul li span {
  font-size: 16px !important;
  font-weight: normal !important;
}

.layout-wrapper .layout-sidebar ul {
  padding-top: 0px !important;
}

.layout-wrapper .layout-sidebar ul li.normal-menuitem > a {
  color: #fff;
  border: 0px !important;
  font-weight: 500;
}

.layout-wrapper .layout-sidebar ul li.sub-menuitem > a {
  color: #33478a;
  border: 0px !important;
}

.layout-wrapper .layout-sidebar ul li a {
  /* border-top: 1px solid #33478a; */
  /* padding: 0.9em 15px 0.9em 1em !important;*/
  padding: 11px 15px 11px 15px !important;
}

.layout-static-sidebar-inactive.layout-wrapper .layout-sidebar ul li a {
  border-top: 1px solid rgba(207, 211, 224, 0.6);
}

.layout-wrapper .layout-sidebar ul li a i {
  text-align: center;
}

.layout-wrapper .layout-sidebar ul li a span {
  display: none;
}

.layout-wrapper .layout-sidebar ul li a:hover .spanText {
  position: fixed;
  top: auto;
  background: black;
  color: white;
  padding: 4px 8px;
  border-radius: 10px;
  left: 42px;
  white-space: nowrap;
  z-index: 9999999 !important;
  overflow: visible;
  margin-top: -20px;
}

.layout-wrapper .layout-sidebar ul li a:hover .spanText2 {
  display: block;
  position: fixed;
  top: auto;
  background: black;
  color: white;
  padding: 4px 15px;
  border-radius: 10px;
  left: 42px;
  white-space: nowrap;
  z-index: 9999999 !important;
  overflow: visible;
  margin-top: -20px;
}

.layout-wrapper.layout-static-sidebar-inactive
  .layout-sidebar
  ul
  li
  a:hover
  span {
  margin-top: 0px;
}

.layout-wrapper.layout-static-sidebar-inactive
  .layout-sidebar
  ul
  li
  a:hover
  span {
  position: inherit;
  top: 0;
  background: none;
  padding: 0;
  border-radius: 0px;
  left: 0;
}

.layout-wrapper.layout-static-sidebar-inactive .layout-sidebar ul li a span {
  display: inline-block;
}

.layout-wrapper.layout-static .layout-main,
.layout-wrapper.layout-static .layout-footer {
  margin-left: var(--sidebar-width);
}

.layout-wrapper.layout-static.layout-static-sidebar-inactive .layout-sidebar {
  --sidebar-width: 200px;
  left: 0;
}

.layout-wrapper.layout-static.layout-static-sidebar-inactive .layout-topbar {
  left: 0;
}

.layout-wrapper.layout-static.layout-static-sidebar-inactive .layout-main,
.layout-wrapper.layout-static.layout-static-sidebar-inactive .layout-footer {
  margin-left: 200px;
}

.layout-wrapper .layout-sidebar-scroll-content {
  background-color: #051d43!important;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  padding-bottom: 1rem;
}

.layout-sidebar-scroll-content-recent {
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  font-family: "Lato";
  padding-left: 20px;
  padding-bottom: 10px;
}

.layout-sidebar-scroll-content-recent-main {
  padding-top: 14px !important;
  border-top: 0.5px solid #ffffff;
}

.layout-sidebar-scroll-content-recent-line {
  padding-bottom: 10px !important;
}

.layout-sidebar-scroll-content-recent-line-name {
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
  font-family: "Lato";
  padding-left: 5px;
}

.layout-sidebar-scroll-recent-agent-icon {
  content: url("../src/fonts/recent-profiles-agent.svg");
  padding-left: 18px;
}

.layout-sidebar-scroll-recent-agency-icon {
  content: url("../src/fonts/recent-profiles-agency.svg");
  padding-left: 18px;
}

.layout-sidebar-scroll-recent-insured-icon {
  content: url("../src/fonts/recent-profiles-insured.svg");
  padding-left: 18px;
}

.layout-sidebar-scroll-content-recent-hover:hover {
  text-decoration: underline !important;
  cursor: pointer;
}

.layout-sidebar-scroll-content-recent-items {
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 7px;
}

.recent-profile-tooltip-parent.show {
  opacity: 1 !important;
}

.recent-profile-tooltip-parent.place-top::before,
.recent-profile-tooltip-parent.place-right {
  visibility: hidden !important;
}

.recent-profile-tooltip-parent {
  width: 83px;
  height: 30px;
  white-space: initial;
}

.custom-split-btn {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* class for responsice dialog width */
.dialog-width {
  width: 50vw;
}

.dialog-width-address {
  width: 62vw;
}

.dialog-width-renewal {
  width: 70vw;
}

/*custom alignment styles  for data table columns */

.column-center {
  text-align: center !important;
}

.column-right {
  text-align: right !important;
}

@media (max-width: 979px) and (min-width: 641px) {
  .custom-split-btn {
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .layout-wrapper .layout-sidebar ul li a:hover span {
    display: none;
  }

  .layout-wrapper .layout-sidebar ul {
    padding-bottom: 15px;
  }

  body .form-group.search-form-group {
    margin-bottom: 21px !important;
  }
}

@media (max-width: 640px) {
  .custom-split-btn {
    width: 45%;
    display: flex;
    justify-content: left;
    align-items: left;
  }

  .custom-split-btn.width-60 {
    width: 60%;
  }

  .layout-wrapper .layout-sidebar ul li a:hover span {
    display: none;
  }
}

@media (max-width: 767px) {
  .custom-top-parent {
    flex-direction: row-reverse !important;
  }

  .layout-wrapper.layout-mobile-sidebar-active .layout-sidebar {
    left: -0;
    width: 200px;
    box-shadow: 7px 0px 11px -10px rgba(0, 0, 0, 0.92);
  }

  .layout-wrapper.layout-mobile-sidebar-active
    .layout-sidebar
    ul
    li.active-menuitem
    > a {
    max-width: 100%;
  }

  .layout-wrapper.layout-mobile-sidebar-active .layout-sidebar ul li a span {
    display: inline-block;
  }

  .layout-wrapper.layout-mobile-sidebar-active
    .layout-sidebar
    ul
    li
    a:hover
    span {
    position: inherit;
    top: 0;
    background: none;
    padding: 0;
    border-radius: 0px;
    left: 0;
  }

  .layout-wrapper.layout-static .layout-main {
    margin-left: 0 !important;
  }

  .layout-wrapper .layout-sidebar {
    left: -220px !important;
  }

  .layout-wrapper.layout-mobile-sidebar-active .layout-sidebar {
    left: 0px !important;
  }

  /* class for responsive dialog width */
  .dialog-width {
    width: 80vw;
  }

  .dialog-width-renewal {
    width: 80vw;
  }

  .column-center {
    text-align: left !important;
  }

  #queueselected,
  #clear.run-com {
    margin-left: 0 !important;
  }

  .column-right {
    text-align: left !important;
  }

  .mobile-100-width {
    width: 100%;
    flex: 0 0 auto;
  }
}

/* menu changes ends */
/* header Impersonating dropdown and user sec start */
.Logged-User {
  font-size: 13px;
  /* margin-right: 20px; */
}

.Logged-User span {
  max-width: 138px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
  text-align: center;
  color: #434a51;
}

.Logged-User .p-buttonset.p-button-secondary > .p-button {
  background-color: #fff !important;
  border: 0px !important;
  outline: 0;
}

.Logged-User .p-buttonset.p-button-secondary > .p-button:hover {
  background-color: #fff !important;
  border: 0px !important;
}

.Logged-User .p-buttonset.p-button-secondary > .p-button:enabled:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.Logged-User .p-button.p-button-text-only .p-button-text {
  padding: 0px !important;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 400;
}

.Logged-User .p-button-icon-only .p-button-icon-left {
  margin-top: -13.1px !important;
}

body .Logged-User .p-menu .p-menuitem-link:hover span {
  color: #0052ce !important;
}

.Logged-User .p-menu-list span {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 400;
  color: #434a51 !important;
  vertical-align: baseline;
}

body .Logged-User .p-menu .p-menuitem {
  max-height: 42px;
}

body .Logged-User .p-menu .p-menuitem-link {
  margin: 4px 6px 4px 6px;
  height: 38px;
  padding-top: 3.5px;
}

body .Logged-User .p-menu .p-menuitem-link:hover {
  font-weight: 400;
  color: #0052ce !important;
  vertical-align: baseline;
  margin: 4px 6px 4px 6px;
  height: 38px;
  padding-top: 3.5px;
  background: #ededee;
}

@media (max-width: 767px) {
  .Logged-User {
    width: 40px;
    height: 40px;
    background-color: #f5f6fa;
    border-radius: 50%;
    padding: 2px 6px;
    display: none;
    text-align: center;
  }

  .Logged-User span {
    text-overflow: clip;
    color: #33478a;
    width: 25px;
    font-size: 13px;
    margin-top: 11px;
    text-transform: uppercase;
  }

  .bell-icon {
    padding-top: 0;
  }

  .iconnect {
    display: none;
  }
}

.header-form-group .p-dropdown {
  width: 195px;
  margin-right: 10px;
}

.header-form-group .p-dropdown .p-dropdown-label {
  color: #434a51;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
}

.grid-split-btn .p-buttonset .p-button:focus,
.p-splitbutton.secondary-button .p-button:focus {
  box-shadow: none !important;
}

.p-datatable .p-datatable-tbody > tr > td.grid-split-btn {
  overflow: visible;
}

.grid-split-btn .p-buttonset .p-button {
  background-color: #f4f4f4;
  color: #333333;
}

.grid-split-btn .p-buttonset .p-button:focus {
  box-shadow: none !important;
}

.grid-split-btn .p-buttonset .p-button:enabled:active,
.grid-split-btn .p-buttonset .p-button:enabled:hover {
  background-color: #c8c8c8;
  color: #333333;
}

.form-group .p-splitbutton.secondary-button .p-menu,
.grid-split-btn .p-buttonset .p-menu,
.p-splitbutton.secondary-button .p-menu,
.app-table .secondary-button .p-splitbutton .p-menu {
  right: 0;
  left: inherit !important;
  width: auto;
  white-space: nowrap;
}

body .p-splitbutton.secondary-button .p-menu .p-menuitem-link {
  padding: 0.714em 1.2em;
}

body .p-splitbutton.secondary-button .p-menu .p-menuitem-link span {
  color: #33478a;
}

body .p-splitbutton.p-buttonset .p-button.p-splitbutton-menubutton {
  border-left: 0 !important;
}

body
  .app-table.p-datatable
  .p-splitbutton.p-buttonset
  .p-button.p-splitbutton-menubutton:focus {
  box-shadow: none !important;
}

body
  .app-table.p-datatable
  .p-splitbutton.p-buttonset.custom-center
  .p-button
  .p-button-text {
  padding: 0.3em 0.4em 0.3em 0.4em;
}

body
  .app-table.p-datatable
  .p-splitbutton.p-buttonset
  .p-button
  .p-button-text {
  padding: 0.3em 0.4em 0.3em 1.8em;
}

body .app-table.p-datatable .p-datatable-tbody > tr.p-highlight {
  background-color: #bdc1c3 !important;
}

/* header Impersonating dropdown and user sec ends */

.logo {
  height: 32px;
  margin-left: 40px;
  margin-top: 6px;
}

.iconnect {
  /*height: 55px;
  margin-left: 40px;*/
  width: 149px;
  margin-left: 36px;
  margin-top: -5px;
}

@media (max-width: 510px) {
  body .layout-logo {
    max-width: 84px;
    overflow: hidden;
  }
}

@media (min-width: 510px) and (max-width: 767px) {
  .logo {
    display: none !important;
  }

  .iconnect {
    display: block;
  }
}

body .p-button.logout-button {
  background-color: #f5f6fa;
  border: none;
  color: #33478a;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

body .p-button.logout-button:hover,
body .p-button.logout-button:enabled:hover {
  background-color: #eaeaea !important;
  color: #33478a !important;
}

body .p-button.logout-button:enabled:active,
body .p-button.logout-button:enabled:focus {
  background-color: #f5f6fa !important;
  color: #33478a !important;
  box-shadow: none !important;
}

.p-button.p-button-text-only .p-button-text {
  padding: 0.429em;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #33478a !important;
  background-color: #33478a !important;
  color: #ffffff;
}

body .p-radiobutton .p-radiobutton-box.p-highlight .p-radiobutton-icon {
  transform: translate(-50%, -50%);
  margin: unset;
}

.p-splitbutton-button {
  color: #ffffff !important;
  background-color: #33478a !important;
  border: 1px solid #33478a !important;
}

.secondary-button .p-button {
  color: #33478a !important;
  background-color: transparent !important;
  border: 1px solid #33478a !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #33478a !important;
  background-color: #33478a !important;
  color: #ffffff;
}

.clear-both {
  clear: both;
}

.margin-left-0 {
  margin-left: 0px !important;
}

.margin-top-0 {
  margin-top: 0px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-left-5 {
  margin-left: 5px !important;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-left-30 {
  margin-left: 30px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-3px {
  margin-bottom: 3px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.margin-right-20 {
  margin-right: 20px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.small-custom-font {
  font-size: 13px !important;
}

.small-custom-font.submisison-type-input {
  height: 37px;
}

@keyframes ui-progress-spinner-color {
  100%,
  0% {
    stroke: #d62d20;
  }

  40% {
    stroke: #0057e7;
  }

  66% {
    stroke: #008744;
  }

  80%,
  90% {
    stroke: #ffa700;
  }
}

body .app-table.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  background-color: #506ac3;
  font-weight: bold;
  color: #fff;
  font-size: 13px;
  padding: 0.7em 0.857em;
  border: 0.5px solid #d0d2d9;
}

body .app-table.p-datatable .p-datatable-tbody > tr > td { 
  padding: 0.4em 0.857em 0.4em;
  font-size: 13px;
  color: #142933;
  border: 0.5px solid #d0d2d9;
}

body .app-table.p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background-color: #f8f8f8;
}

body .app-table.p-datatable .p-datatable-tbody > tr:hover,
body .app-table.p-datatable .p-datatable-tbody > tr td:hover {
  background-color: transparent !important;
}

body .app-table.p-datatable .p-datatable-tbody > tr.light-green:hover,
body .app-table.p-datatable .p-datatable-tbody > tr.light-green td:hover {
  background-color: #7fffc5 !important;
}

body .app-table.p-datatable .p-datatable-tbody > tr.light-orange:hover,
body .app-table.p-datatable .p-datatable-tbody > tr.light-orange td:hover {
  background-color: #ffe4c4 !important;
}

body .app-table.p-datatable .p-datatable-tbody > tr.light-yellow:hover,
body .app-table.p-datatable .p-datatable-tbody > tr.light-yellow td:hover {
  background-color: #ff0 !important;
}

body .app-table.p-datatable .p-datatable-tbody > tr.blanchedalmond:hover,
body .app-table.p-datatable .p-datatable-tbody > tr.blanchedalmond td:hover {
  background-color: blanchedalmond !important;
}

body .app-table.p-datatable .p-datatable-thead > tr th:hover {
  background-color: #506ac3 !important;
  color: #fff;
}

body .app-table.email-table.p-datatable .p-datatable-tbody > tr:focus,
body .app-table.email-table.p-datatable .p-datatable-tbody > tr td:focus {
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  border: 1px solid transparent !important;
}

body .app-table.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #fff;
  font-size: 11px !important;
  margin-left: 3px;
  vertical-align: middle;
  margin-top: -1px;
}

body
  .app-table.p-datatable
  .p-sortable-column:not(.p-highlight):hover
  .p-sortable-column-icon {
  color: #fff;
}

body .app-table.p-datatable-resizable > .p-datatable-wrapper {
  overflow-x: visible;
}

body .app-table.p-datatable .p-datatable-tbody > tr td .fa.icon-fill-color {
  font-size: 18px;
}

body .app-table .p-paginator {
  border: none;
  text-align: right;
  margin-top: 15px;
  font-size: 13px;
  color: #142933;
  background-color: transparent;
}

body .app-table .p-paginator .p-dropdown {
  float: right;
  border: 1px solid #e0e0e0;
  height: auto;
  color: #142933;
}

.p-dropdown .app-table .p-dropdown-trigger .p-dropdown-trigger-icon {
  color: #575757;
}

body .app-table .p-paginator .p-dropdown .p-dropdown-label {
  color: #142933;
}

body .app-table .p-paginator .p-paginator-current {
  float: left;
  margin-left: 0;
}

body .app-table .p-paginator .p-paginator-pages .p-paginator-page {
  color: #33478a;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin: 0 2px;
  width: auto;
  padding: 0 5px !important;
}

body .app-table .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: #33478a;
  color: #ffffff;
}

body .app-table .p-paginator .p-paginator-first,
body .app-table .p-paginator .p-paginator-prev,
body .app-table .p-paginator .p-paginator-next,
body .app-table .p-paginator .p-paginator-last {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin: 0 2px;
  color: #33478a;
  font-weight: normal;
}

body .app-table .p-datatable-loading {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 999;
}

.app-table .p-datatable-loading-content {
  z-index: 99999;
}

body .app-table .p-component-overlay {
  background-color: transparent;
}

body .app-table .p-datatable-loading .pi-spin {
  font-size: 2.5em !important;
  color: #c49e45;
}

body .app-table .icons i {
  color: #33478a !important;
  margin-right: 5px;
}

.search-form-group {
  position: relative;
}

.search-form-group .p-button {
  position: absolute;
  right: 0;
  min-width: 40px;
}

body .p-button.primary-button {
  background-color: #33478a;
  border: 1px solid #33478a;
  width: auto;
  min-height: 36.6px;
}

body .p-button.primary-button .pi {
  font-size: 12px !important;
}

body .p-button.primary-button:enabled:hover,
body .p-button.primary-button:enabled:focus {
  background-color: #415392;
  border: 1px solid #415392;
  color: #fff;
}

body .p-button.secondary-button {
  background-color: #fff;
  border: 1px solid #33478a;
  color: #33478a;
  width: auto;
  min-height: 36.6px;
  min-width: 30px;
}

body .p-button.secondary-button.users-download {
  min-height: 36px;
  width: 107px !important;
  margin-bottom: 0;
  margin-top: 20px;
  margin-left: 52px !important;
}

body .p-button.p-button-text-icon-left.users-download .p-button-text {
  padding-left: 15px;
  padding-bottom: 7px;
  padding-right: 0px;
}

body .p-button.secondary-button .pi {
  font-size: 12px !important;
}

body .p-button.secondary-button:enabled:hover,
body .p-button.secondary-button:enabled:focus {
  background-color: #fff;
  border: 1px solid #082074;
  color: #082074;
}

/* start custom changes */
.padding-5 .p-card-body {
  padding: 5px !important;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center !important;
}

.fixing-width {
  width: 100px;
}

.margin-19 {
  padding-top: 19px;
}

.margin-top-26 {
  padding-top: 26px;
}

.margin-top-33 {
  padding-top: 33px;
}

.custom-wrap {
  padding-top: 19px;
}

.submisison-list-view {
  padding: 10px;
  border: 1px solid #afb0b2;
  max-height: 400px;
  overflow-y: auto;
  border-radius: 4px;
}

.submisison-list-view li {
  line-height: 30px;
}

.connector-translated-name {
  display: inline-block;
  background: #60b160;
  padding: 5px 10px;
  border-radius: 4px;
  color: #fff;
}

.connector-translated-name .fa-close {
  margin-left: 6px;
  cursor: pointer;
}

.connector-file-format p,
.submission-list-view-title {
  font-size: 1rem;
  font-weight: bold;
  padding-left: 35px;
  margin: 28px 0 0;
}

.submission-list-view-title {
  padding-left: 0;
}

.connector-file-format ul li {
  line-height: 40px;
  width: 50%;
  float: left;
}

.connector-file-format ul li span {
  min-width: 160px;
  display: inline-block;
}

.connector-icons {
  cursor: pointer;
}

@media (max-width: 767px) {
  .connector-file-format ul li {
    width: 100%;
    line-height: 30px;
  }
}

.head-role {
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.custom-listed-users {
  border: 1px solid #808080;
  border-radius: 4px;
  margin: 0;
  padding: 15px;
  max-height: 150px;
  overflow-y: auto;
}

.custom-listed-users li {
  line-height: 30px;
}

.custom-listed-users li span {
  cursor: pointer;
  margin-left: 8px;
}

@media (max-width: 767px) {
  .margin-top-33 {
    padding-top: 0px;
  }

  .custom-wrap {
    padding-top: 0;
  }
}

.p-button-secondary {
  width: auto !important;
  margin-right: 10px !important;
}

.overflow-visible {
  overflow: visible !important;
  z-index: 999999 !important;
}

.app-spinner {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.4);
  opacity: 1;
}

.css-zb82sl {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
}

.css-5tez44 {
  position: fixed !important;
  top: 50% !important;
  left: 52% !important;
}

.css-fiol1k {
  position: fixed !important;
  top: 50% !important;
  left: 54% !important;
}

.custom-attach-replace .p-dialog-titlebar {
  color: maroon !important;
}

/* Custom Changes end */
/***Template theme customization ***/
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group.p-highlight {
  background-color: #33478a !important;
}

/***Template theme customization ***/
body .form-group {
  margin-bottom: 10px;
}

body .form-group .p-inputtext {
  color: #142933 !important;
  border: 1px solid #afb0b2 !important;
  width: 100%;
  padding: 0.5em;
  font-size: 14px;
  border-radius: 4px;
}

body .form-group .p-autocomplete {
  width: 100%;
  zoom: 1;
  cursor: pointer;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  display: inline-block;
}

body .form-group .p-inputtext.p-component:disabled,
body .form-group input.p-inputtext.p-component:read-only,
body .form-group textarea.p-inputtext.p-component:read-only {
  opacity: 1;
  background-color: #f5f5f5;
}

body .form-group .p-dropdown {
  color: #142933 !important;
  border: 1px solid #afb0b2 !important;
  width: 100%;
  border-radius: 4px;
}

body .form-group .p-dropdown.p-disabled,
body .form-group .p-dropdown.p-disabled .p-dropdown-label.p-inputtext,
body .form-group .p-dropdown.p-disabled .p-dropdown-trigger {
  opacity: 1;
  background-color: #f5f5f5;
}

body .form-group .p-dropdown .p-dropdown-label.p-inputtext {
  margin-bottom: 0px;
  border: none !important;
  background-color: transparent;
  /* width: 160px; */
}

body .form-group .p-dropdown .p-dropdown-filter-container {
  width: 100%;
}

body
  .form-group
  .p-dropdown
  .p-dropdown-panel
  .p-dropdown-filter-container
  .p-dropdown-filter-icon {
  color: #33478a;
}

body .form-group label {
  font-size: 13px;
  color: #142933;
  margin-bottom: 6px;
  display: inline-block;
}

body .form-group textarea {
  resize: none;
}

body .form-group .p-inputtext.p-error,
body .form-group .p-dropdown.p-error,
body .form-group .p-error .p-inputtext,
body .form-group .p-error .p-button {
  border-color: #dc3545 !important;
}

/* span.p-calendar.p-error
{
    border: 1px solid #dc3545 !important;
} */

body .form-group .p-calendar {
  width: 100%;
  display: block;
}

body .form-group .p-calendar .p-calendar-button {
  position: absolute;
  right: 0;
  background: #fff;
  border-color: #afb0b2;
  background-color: white;
  border-radius: 0 4px 4px 0;
  opacity: 1;
  color: #33478a;
}

body .form-group .p-calendar .p-inputtext {
  width: 99%;
}

.invalid-feedback {
  width: 100%;
  margin: 2px 5px 0;
  font-size: 13px;
  color: #dc3545;
}

.required {
  display: inline-block;
  font-size: 13px;
  color: #dc3545;
}

body .form-group.search-form-group {
  position: relative;
  margin-bottom: 10px;
}

body .form-group.search-form-group .p-button {
  position: absolute;
  right: 0;
  min-width: 40px;
  min-height: 36.6px;
}

body .form-group.search-form-group .p-button.p-button-icon-only .p-button-text {
  padding: 0.5em 0.5em 0.6em;
}

/*===================== MULTISELECT CUSTOM STYLE========================= */

body .p-multiselect .p-multiselect-label {
  margin-bottom: 0;
  padding: 0.55em 0.5em;
  font-size: 14px;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item label {
  margin-bottom: 0;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #ffffff !important;
  background-color: #007ad9;
}

body
  .p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item.p-highlight
  label {
  color: #fff;
}

.p-multiselect {
  display: block !important;
}

.reportagentmarketer
  .p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item
  label {
  width: 280px;
}

.insured .p-autocomplete-list-item {
  width: 420px;
}

/*===================== MULTISELECT CUSTOM STYLE========================= */

body .p-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background: #fff !important;
  background-clip: border-box;
  border-radius: 8px;
  margin-bottom: 1.3rem;
  /*box-shadow: 0 5px 5px 5px rgba(80, 102, 224, 0.02);*/
}

.layout-wrapper.layout-static .layout-main .p-card.custom-home-card {
  background-color: transparent !important;
  box-shadow: none;
}

.layout-wrapper.layout-static
  .layout-main
  .p-card.custom-home-card
  > .p-card-body {
  padding: 0;
}

.home-top-badge {
  margin-bottom: 0.875rem !important;
}

.home-mid-badge-wrapper .home-mid-badge:nth-child(-n + 2) {
  min-height: 400px;
  margin-bottom: 0.575rem !important;
}

.home-mid-badge {
  min-height: 400px;
  margin-bottom: 0.875rem !important;
}

.badge-title {
  margin: 0;
  font-size: 1.15rem;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.badge-title span {
  font-size: 0.875rem;
  color: #506ac3;
  cursor: pointer;
}

.home-top-badge-content {
  font-size: 2rem;
  font-weight: 600;
  color: #506ac3 !important;
  margin: 5px 0 0;
}

.content-wrapper {
  padding: 15px 0 0;
}

.content-box {
  margin-bottom: 5px;
  padding: 10px;
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.content-box:nth-child(odd) {
  background-color: #edf0f5;
  border-radius: 4px;
}

.home-bottom-badge {
  min-height: 322px;
}

.home-bottom-badge .sub-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #edf0f5;
  padding: 10px;
  border-radius: 4px;
}

.home-bottom-badge ul {
  margin: 0;
  padding: 0;
}

.home-bottom-badge ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid lightgray;
}

.home-bottom-badge ul li:last-child {
  border-bottom-color: transparent;
}

.task-content-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid lightgray;
}

.task-content-box p {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  margin: 10px 0;
  align-items: center;
}

.task-content-box p .task-title {
  width: 60%;
  display: block;
}

.task-content-box p .task-priority {
  width: 10%;
  display: block;
  text-align: center;
}

.task-content-box:last-child {
  border-bottom-color: transparent;
}

/* .column-center-mobile{
  text-align: center;
} */

body .p-button.secondary-button.wosplit {
  min-height: 29px !important;
  width: 91px !important;
  text-align: left;
}

.p-splitbutton.wosplit > button.p-button-text-icon-left {
  width: 100px !important;
}

@media only screen and (max-width: 767px) {
  .task-content-box p {
    padding: 8px 5px;
  }

  .task-content-box p .task-title {
    width: 40%;
    font-size: 14px;
  }

  .task-content-box p .task-priority {
    width: 25%;
    font-size: 10px;
  }

  body .p-button.secondary-button.download-mktg {
    margin-bottom: 10px;
    margin-right: 0 !important;
  }

  .form-group.custom-check-mobile {
    text-align: left !important;
  }

  body .p-button.primary-button.edit-mktg {
    margin-right: 10px;
  }

  .mktg-event-detail-table {
    width: 100%;
    border-top: 1px solid #ddd;
    padding-top: 10px;
  }

  .mktg-event-detail-table > tr {
    display: flex;
  }

  .mktg-event-detail-table .hdr-label {
    max-width: 100px;
    width: 100px;
  }

  .column-center-mobile {
    text-align: left !important;
  }

  .p-datatable-responsive
    .p-datatable-tbody
    > tr.p-datatable-row
    > td.doc-column
    .p-column-title {
    display: none;
  }
}

tr.p-datatable-row.selectedAgent {
  background-color: #fafad2 !important;
}

@media only screen and (max-width: 500px) {
  .mktg-event-detail-table .hdr-label {
    max-width: 75px;
    width: 75px;
  }

  .into-custom {
    margin-top: 0px !important;
  }

  .customer-date {
    text-align: left !important;
  }

  .custom-submissiondropdown {
    margin-left: -6px !important;
    float: right !important;
  }

  .custom-doc-drpdwn,
  #drpSubmission {
    min-width: 127px !important;
  }

  .wid-sub {
    width: 147px !important;
  }

  #drpSubmission .p-dropdown-label {
    width: 127px !important;
  }

  .selectagent {
    width: 400px !important;
  }

  .wid-184 {
    width: 184px !important;
  }

  #divpaymentPlanStatus .form-group label {
    font-size: 11px !important;
  }
}

.home-custom-line-chart {
  width: 90%;
  /* width:100%; */
  height: 300px;
  max-height: 320px;
  display: inline-block;
  margin: auto;
  margin: auto auto auto 10%;
  /* overflow-y: hidden; */
}

.home-custom-bar-chart {
  width: 81%;
  /* width:100%; */
  height: 300px;
  max-height: 320px;
  display: inline-block;
  margin: auto;
  margin: auto auto auto 10%;
  /* overflow-y: hidden; */
}

.home-custom-pie-chart {
  width: 80%;
  /* width:100%; */
  height: 250px;
  max-height: 270px;
  display: inline-block;
  margin: auto;
  margin: auto auto auto 10%;
  /* overflow-y: hidden; */
}

.custom-no-data-component {
  width: 94%;
  height: 80%;
  background: #fff;
  position: absolute;
  top: 12%;
  font-size: 14px;
  z-index: 1;
}

.custom-no-data-component.bar-chart {
  top: 10%;
}

.drag-box {
  background: #fff;
  border: 1px solid #999;
  border-radius: 3px;
  width: 180px;
  height: 180px;
  margin: 10px;
  padding: 10px;
  float: left;
}

body .p-card.height-100 {
  height: calc(100% - 20px);
}

body .p-card.fix-height {
  min-height: 275px;
}

body .p-card .p-card {
  box-shadow: 0 5px 5px 5px rgba(80, 102, 224, 0.02);
}

body .layout-main .p-grid.last:last-child .p-card {
  margin-bottom: 0;
}

body .layout-main .p-grid:last-child .p-card.height-100 {
  height: 100%;
}

body .p-card .section-title i {
  padding-right: 6px;
}

body .app-tab .p-tabview.p-tabview-top,
body .app-tab .p-tabview-panels {
  padding: 0;
  float: left;
  width: 100%;
  border-radius: 0;
}

body .app-tab .p-tabview.p-tabview-top > .p-tabview-nav li {
  margin: 0;
}

body .app-tab .p-tabview.p-tabview-top > .p-tabview-nav li:last-child a,
body .app-tab .p-tabview.p-tabview-top > .p-tabview-nav li:last-child a:hover {
  border-width: 1px 1px 1px 1px !important;
}

body .app-tab .p-tabview.p-tabview-top .p-tabview-nav li a {
  border-width: 1px 0px 1px 1px;
  color: #33478a !important;
  font-weight: normal;
  background-color: transparent;
  margin: 0;
  border-radius: 0;
  border-bottom-color: transparent;
}

body .app-tab .p-tabview.p-tabview-top.custom-tab-parent .p-tabview-nav li a {
  padding: 0 !important;
}

body .app-tab .p-tabview.p-tabview-top .p-tabview-nav li a:hover,
body .app-tab .p-tabview.p-tabview-top .p-tabview-nav li a:active,
body .app-tab .p-tabview.p-tabview-top .p-tabview-nav li a:focus,
body .app-tab .p-tabview.p-tabview-top .p-tabview-nav li:hover a {
  text-decoration: none;
  background: transparent;
  color: #33478a;
  border-color: #c8c8c8;
  box-shadow: none;
  border-width: 1px 0px 1px 1px;
}

body
  .app-tab
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  a {
  background: transparent;
  border-color: #c8c8c8;
  border-width: 1px 0px 1px 1px;
  border-bottom-color: transparent;
  border-top-color: #33478a;
}

body .app-tab .p-tabview.p-tabview-top .p-tabview-nav li a .custom-tab-link {
  border: none !important;
  padding: 0.78em 1em !important;
}

body
  .app-tab
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li
  a:hover
  .custom-tab-link,
body
  .app-tab
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li
  a:active
  .custom-tab-link,
body
  .app-tab
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li
  a:focus
  .custom-tab-link,
body
  .app-tab
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li:hover
  a
  .custom-tab-link {
  border: none !important;
  padding: 0.78em 1em !important;
  color: #fff !important;
}

body
  .app-tab
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li
  a
  .custom-tab-link:hover,
body
  .app-tab
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li
  a
  .custom-tab-link:active,
body
  .app-tab
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li
  a
  .custom-tab-link:focus,
body
  .app-tab
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li:hover
  a
  .custom-tab-link {
  text-decoration: none;
  background: transparent;
  color: #33478a;
  border-color: #c8c8c8;
  box-shadow: none;
  border-width: 1px 0px 1px 1px;
}

body .app-tab .p-tabview li.p-tabview-selected .p-tabview-title,
body .app-tab .p-tabview li.p-tabview-selected .custom-tab-link {
  font-weight: bold !important;
}

body .app-tab.tab-opt2 .p-tabview-panels {
  border-color: transparent;
  border-top: 2px solid #33478a;
  margin: 0;
  border-width: 2px 0 0;
  /* background-color: #fcfcfd!important; */
  padding: 20px;
}

body .app-tab.tab-opt2 .p-tabview-panels.gray-bg {
  background-color: #edf0f5 !important;
}

body .app-tab.tab-opt2 .custom-policy-tab .p-tabview-panels {
  background-color: transparent !important;
}

body .p-card.gray-background {
  background-color: #edf0f5 !important;
}

body .app-tab.tab-opt2 .p-tabview-panels .p-card {
  box-shadow: 0 2px 2px 2px rgba(80, 102, 224, 0.02);
}

.no-padding {
  padding: 0px !important;
}

.margin-bottm-0 .form-group {
  margin-bottom: 0px !important;
}

body
  .app-tab.tab-opt2
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li.p-highlight
  a::after {
  content: none;
}

body .app-tab.tab-opt2 .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight a,
body .app-tab.tab-opt2 .p-tabview.p-tabview-top .p-tabview-nav li a:hover {
  background-color: #33478a !important;
  color: #fff !important;
}

body .app-tab.tab-opt2 .p-tabview.p-tabview-top .p-tabview-nav li a,
body .app-tab.tab-opt2 .p-tabview.p-tabview-top .p-tabview-nav li a:hover,
body
  .app-tab.tab-opt2
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  a {
  border: 0;
  padding: 0.7em 1.2em;
}

body .app-tab.tab-opt2 .p-tabview-panels .p-tabview-panels {
  border-color: transparent;
  padding: 0;
}

body .app-tab.tab-opt2 .app-tab.secondary-tab {
  border: 0;
  padding: 0;
}

body .app-tab.tab-opt2 .p-tabview.p-component.p-tabview-top .p-tabview-panels {
  padding: 0;
}

body
  .app-tab.tab-opt2
  .app-tab.secondary-tab
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li {
  margin-right: 2px;
}

body
  .app-tab.tab-opt2
  .app-tab.secondary-tab
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li.p-highlight
  a,
body
  .app-tab.tab-opt2
  .app-tab.secondary-tab
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li
  a:hover {
  background-color: #506ac3 !important;
  color: #fff !important;
  border-radius: 6px;
}

body
  .app-tab.tab-opt2
  .app-tab.secondary-tab
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li
  a,
body
  .app-tab.tab-opt2
  .app-tab.secondary-tab
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li
  a:hover,
body
  .app-tab.tab-opt2
  .app-tab.secondary-tab
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  a {
  padding: 0.5em 1.2em;
  border-radius: 6px;
  color: #5b6770;
}

body
  .app-tab.tab-opt2
  .app-tab.secondary-tab
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li.p-highlight {
  border-color: #33478a !important;
}

body
  .app-tab.tab-opt2
  .app-tab.secondary-tab
  .p-tabview.p-tabview-top
  .p-tabview-nav {
  margin-top: 0px;
  margin-bottom: 10px;
}

body .app-tab .form-group.enable-mobile .p-dropdown,
body .app-tab .form-group.enable-mobile .p-dropdown .p-inputtext,
body .app-tab .form-group.enable-mobile .p-dropdown .p-dropdown-trigger,
body
  .app-tab
  .form-group.enable-mobile
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight {
  background-color: #33478a;
  color: #fff !important;
  border: 0 !important;
  font-weight: bold;
  padding-left: 8px;
  border-radius: 0px;
}

body .app-tab .form-group.enable-mobile .p-dropdown .p-dropdown-trigger {
  right: 8px;
}

body .app-tab .app-tab.secondary-tab .form-group.enable-mobile .p-dropdown,
body
  .app-tab
  .app-tab.secondary-tab
  .form-group.enable-mobile
  .p-dropdown
  .p-inputtext,
body
  .app-tab
  .app-tab.secondary-tab
  .form-group.enable-mobile
  .p-dropdown
  .p-dropdown-trigger,
body
  .app-tab
  .app-tab.secondary-tab
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight {
  background-color: #506ac3 !important;
  color: #fff !important;
  border: 0 !important;
  font-weight: bold;
  border-radius: 4px;
}

body
  .app-tab
  .form-group.enable-mobile.unmatched
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight {
  background-color: #506ac3 !important;
}

body
  .app-tab
  .app-tab.secondary-tab
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight {
  border-radius: 0px;
}

body
  .app-tab
  .app-tab.secondary-tab
  .form-group.enable-mobile
  .p-dropdown
  .p-inputtext {
  padding: 0.4em 0.5em 0.5em;
}

@media (max-width: 767px) {
  body .app-tab .p-tabview.p-tabview-top {
    display: none;
  }

  body .app-tab.tab-opt2 .p-tabview-panels {
    border: 0;
  }
}

.enable-mobile {
  display: none;
}

@media (max-width: 767px) {
  .enable-mobile {
    display: block;
  }
}

.alert {
  position: relative;
  padding: 10px 35px 10px 10px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  border-radius: 5px;
}

.alert .p-button {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-17px);
}

.alert .p-button:enabled:hover,
.alert .p-button:enabled:active,
.alert .p-button:enabled:focus {
  background-color: transparent;
  border: none;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-success .p-button,
.alert-success .p-button:enabled:hover {
  color: #155724;
}

.alert-danger .p-button,
.alert-danger .p-button:enabled:hover {
  color: #721c24;
}

.alert-warning .p-button,
.alert-warning .p-button:enabled:hover {
  color: #856404;
}

.alert-info .p-button,
.alert-info .p-button:enabled:hover {
  color: #0c5460;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-10 {
  margin: 10px;
}

.section-title {
  color: #142933;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 15px;
  margin-top: 10px;
  letter-spacing: 0.2px;
  font-size: 16px;
}

.secondry-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}

.hdr-label {
  color: #5b6770;
  display: block;
  font-size: 13px;
  padding-bottom: 2px;
}

.value-label {
  color: #000;
  font-size: 13px;
}

.link {
  color: #506ac3;
  text-align: start !important;
  text-decoration: underline;
  cursor: pointer !important;
}

.link:hover {
  color: #334781;
}

.center-aligned {
  display: flex;
  justify-content: "center" !important;
}

.justify-center {
  display: flex;
  width: 100%;
  justify-content: center;
}

.width-auto {
  width: auto;
}

/* TODO : need to be validate below css  */
.card-miuicard-root {
  overflow: visible !important;
  z-index: 999999 !important;
}

.p-dialog .p-dialog-content {
  overflow: visible !important;
}

body   .workflow-sidebar{
  z-index: 9999 !important;
}
.workflow-sidebar {
  width: 40% !important;
}

.p-dialog.add-edit-doc-dailog .p-dialog-content {
  overflow-y: auto !important;
}

@media (max-width: 767px) {
  .minimizable-web-chat-email > .chat-box {
    width: 100% !important;
    min-width: 100% !important;
  }

  .p-dialog.add-edit-doc-dailog .p-dialog-content {
    overflow-y: auto !important;
  }

  .workflow-sidebar {
    width: 80% !important;
  }

  body .p-button.secondary-button.margin-b-10-mobile {
    margin-bottom: 10px;
  }
}

.display-flex-column {
  display: flex;
  flex-direction: column;
}

.display-flex-row {
  display: flex;
  flex-direction: row;
}

.custom-top-parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.font-size-12px {
  font-size: 12px;
}

.font-size-24px {
  font-size: 24px;
}

.align-items-end {
  align-items: flex-end !important;
}

.title {
  color: black;
  font-weight: 700;
}

/* 
.p-autocomplete .p-autocomplete-panel {
	min-width: 450px !important;
} */
.app-autocomplete .p-autocomplete .p-autocomplete-panel {
  min-width: 100% !important;
}

/* .p-autocomplete-panel .p-autocomplete-items li:nth-child(odd) {
  background-color: #f8f8f8 !important;
}

.p-autocomplete-panel .p-autocomplete-items li {
  background-color: #fff !important;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item {  
  border: .5px solid #d0d2d9 !important;
  font-size: 13px;
} */
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item:hover {
  color: #333333 !important;
  /* background-color: #eaeaea !important; */
  font-weight: 600 !important;
}

.p-autocomplete-panel .p-autocomplete-items li:nth-child(odd) {
  /* background-color: #f8f8f8 !important; */
  background-color: #eaeaea !important;
}

.p-autocomplete-panel .p-autocomplete-items li .header {
  /* background-color: #f8f8f8 !important; */
  background-color: #fff !important;
}

.p-autocomplete-panel .p-autocomplete-items li {
  background-color: #fff !important;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item {
  padding-bottom: 0 !important;
  border: 0.3px solid #eaeaea !important;
  font-size: 13px;
}

.p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item.p-highlight {
  color: #ffffff;
  background-color: #33478a !important;
}

.validation-error {
  color: #dc3545 !important;
}

.margin-10 {
  margin: 10px;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-left-5 {
  padding-left: 5px;
}

.margin-no-top-10 {
  margin-left: 10px !important;
  margin-bottom: 10px !important;
  margin-right: 10px !important;
}

.imas-auto-complete .p-autocomplete .p-autocomplete-panel {
  min-width: 100%;
}

.imas-secondary-button {
  background-color: #808080 !important;
  border: 1px solid #808080 !important;
}

.p-button .imas-split-button {
  color: #ffffff !important;
  background-color: #33478a !important;
  border: 1px solid #33478a !important;
}

.p-splitbutton-button {
  color: #ffffff !important;
  background-color: #33478a !important;
  border: 1px solid #33478a !important;
}

.p-fluid .p-splitbutton {
  width: auto;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #33478a !important;
  background-color: #33478a !important;
  color: #ffffff;
}

.custom-list {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

.custom-list li {
  display: inline;
}

.p-dialog .p-dialog-titlebar {
  background-color: #fff !important;
}

.display-align-right {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.outline-none {
  outline: none !important;
}

.imas-danger-button {
  background-color: #ff0000 !important;
  border: 1px solid #ff0000 !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #33478a !important;
  background-color: #33478a !important;
  color: #ffffff;
}

.custom-row {
  background-color: #808080 !important;
  color: #ffffff;
  text-decoration: line-through !important;
}

.contact-type-container {
  justify-content: flex-start;
  display: flex;
  align-items: center;
}

.default-contact-container {
  justify-content: space-around;
  /* display: flex; */
  align-items: center;
}

.global-topbar {
  background: #051d43 !important;
}

.global-sidebar {
  height: 100%;
  background: #051d43 !important;
  padding-bottom: 2rem;
}

.agent-value-coloum {
  font-weight: 600;
}

.agent-comment-coloum {
  font-weight: normal;
  color: red;
}

.margin-right {
  margin-left: 68px;
}

.marketers-container {
  display: flex;
  flex-direction: column;
}

.agent-details-name {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.agent-details-loc {
  font-size: 12px;
}

.agent-summary-notice-container {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.agent-summary-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quicklink {
  font-family: sans-serif;
  font-weight: 400;
  line-height: 2em;
  padding: 10px;
  text-decoration: none;
}

.clip-circle {
  border-radius: 10px;
  border-style: ridge;
  border-color: lightgray;
}

.list-quick {
  list-style: none;
}

.ul-quick {
  padding: 0;
}

.container-quick {
  margin: 40px;
  background-color: #ffea4c;
}

.is-selected {
  color: #33478a;
}

.panelHeader {
  font-weight: bold;
}

.agent-summary-btn-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}

.flex-column-center-start {
  display: flex;
  flex-direction: column;
}

.font-weight-800 {
  font-weight: 800;
}

.summary-button {
  margin-right: 10px;
  margin-left: 10px;
  padding: 5px;
  font-weight: 400;
  text-decoration: none;
  color: #909090;
}

.summary-button:hover {
  cursor: pointer;
  text-decoration: none;
  color: #33478a;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.active-summary-button {
  background-color: lightgray;
  text-decoration: none;
  color: #33478a;
  font-weight: 700;
  cursor: pointer;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.summary .hdr-label {
  display: inline;
  padding-right: 5px;
}

@media (max-width: 40em) {
  /* .p-splitbutton{width:45% !important;} */
  body .app-table.p-datatable .p-datatable-tbody > tr > td {
    padding: 0.4em 0.857em 0.4em;
  }

  body .app-table.p-datatable .p-datatable-tbody > tr > td:first-child {
    margin-top: 10px;
  }

  body .app-table.p-datatable .p-datatable-tbody > tr > td:last-child {
    margin-bottom: 10px;
  }

  body .app-table.p-datatable.p-datatable-responsive .p-paginator-bottom {
    border-top: none;
    text-align: center;
  }

  .p-datatable-responsive
    .p-datatable-tbody
    > tr.p-datatable-row
    > td
    .p-column-title {
    float: left;
  }

  .p-datatable-responsive
    .p-datatable-tbody
    > tr.p-datatable-row
    > td
    .p-column-title
    + .text-center {
    display: inline-block;
  }

  body .app-table .p-paginator .p-paginator-current {
    display: block;
    width: 100%;
  }

  body .app-table .p-paginator .p-dropdown {
    float: none;
  }

  .sm-order-1 {
    order: 1;
  }

  .sm-order-2 {
    order: 2;
  }

  .sm-order-3 {
    order: 3;
  }

  .sm-order-4 {
    order: 4;
  }

  .sm-order-5 {
    order: 5;
  }

  .sm-order-6 {
    order: 6;
  }

  .sm-order-7 {
    order: 7;
  }

  .sm-order-8 {
    order: 8;
  }

  .sm-order-9 {
    order: 9;
  }

  .p-datatable-responsive .p-datatable-tbody > tr.p-datatable-row > td {
    text-align: left;
    display: block;
    border: 0 none;
    width: 100%;
    float: left;
    clear: left;
  }

  .wid-184 {
    width: 184px !important;
  }

  #divpaymentPlanStatus .form-group label {
    font-size: 11px !important;
  }
}

@media (max-width: 850px) {
  .header-form-group {
    display: none;
  }
}

.spinner {
  position: relative;
}

.spinner .p-progress-spinner {
  width: 50px;
  height: 50px;
}

.box {
  vertical-align: bottom;
  border: 1.2px solid #5e5d5d;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50px;
}

.white-space .hdr-label {
  white-space: nowrap;
}

.light-green {
  background-color: #7fffc5 !important;
}

.light-yellow {
  background-color: #ff0 !important;
}

.light-white {
  background-color: #fff !important;
}

.light-orange {
  background-color: #ffe4c4 !important;
}

.light-blue {
  background-color: #91c8ff;
}

.blanchedalmond {
  background-color: blanchedalmond !important;
}

body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: #33478a !important;
}

.darkgray {
  color: darkgray;
}

.color-danger {
  color: #dc3545 !important;
}

.color-success {
  color: green;
}

.f-w-b {
  font-weight: bold;
}

.f-s-l {
  font-size: large;
}

.icon-fill-color {
  color: #334781;
}

.secondary-title {
  color: #142933;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.2px;
  font-size: 13px;
}

.profile-name {
  font-size: 18px;
  font-weight: 700;
}

.overflow-visible {
  overflow: visible !important;
  z-index: 999999 !important;
}

.admin-menu {
  padding: 0px 30px;
  list-style-type: disc;
  color: #506ac3;
}

.admin-menu li {
  line-height: 24px;
}

.admin-menu li a {
  cursor: pointer;
  font-size: 13px;
  color: #506ac3 !important;
}

.admin-menu li a:hover {
  text-decoration: none;
  columns: #33478a !important;
}

.admin-menu li span.custom-link {
  cursor: pointer;
  font-size: 13px;
  color: #506ac3 !important;
}

/* Documents related */
.dropzone.dz-clickable {
  cursor: pointer;
}

.dropzone {
  border: 1px dashed #082074;
  border-radius: 5px;
  background: white;
}

.dropzone .dz-message {
  font-weight: 400;
}

.dropzone .dz-message {
  text-align: center;
  margin: 30px 5px;
}

.dropzone .dz-message .note {
  display: block;
  margin-top: 0.4rem;
  font-size: 0.8em;
  font-weight: 200;
}

.app-listbox {
  height: 190px;
  overflow-y: scroll;
}

.app-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 40%;
  left: 50%;
  z-index: 999999;
  height: 55px;
  width: 55px;
}

/* Documents related  ends*/

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  width: 110px;
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 8px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 105%;
}

.tooltip .tooltiptext-import {
  min-width: 167px;
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 8px;
  position: absolute;
  z-index: 1;
  top: -29px;
  right: 50%;
}

.tooltip .tooltiptext-queue {
  min-width: 80px;
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 3px 11px;
  border-radius: 8px;
  position: absolute;
  z-index: 1;
  top: 16px;
  right: -162%;
}

.tooltip .custom-addr-tooltip + .tooltiptext {
  width: 65px;
}

.tooltip .tooltiptext.towardsright {
  left: 120%;
}

@media screen and (max-width: 767px) {
  .tooltip .tooltiptext.towardsright {
    left: -135%;
    top: 105%;
  }

  .tooltip .tooltiptext {
    width: 100px;
    padding: 2px 0;
  }
}

.tooltip .tooltiptext.towardsrightemail {
  left: 102%;
  width: 230px !important;
}

.breakword {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
}

.tooltip .tooltip-top {
  width: max-content;
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 8px;
  position: absolute;
  z-index: 1;
  top: -30px;
  right: 0;
  left: 0;
}

.tooltip:hover .tooltip-top {
  visibility: visible;
}

/* Tooltip level */
.tooltip .tooltip-level {
  width: 150px;
  visibility: hidden;
  background-color: #51629e;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 8px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip:hover .tooltip-level {
  visibility: visible;
}

.tooltip:hover .tooltiptext-import {
  visibility: visible;
}

.tooltip:hover .tooltiptext-queue {
  visibility: visible;
}

.tooltip .tooltip-level::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  /* border-color: black transparent transparent transparent; */
  border-color: transparent #51629e transparent transparent;
}

/*Tooltip ends */
.flex {
  display: flex;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.gray-background {
  background-color: #edf0f5 !important;
}

.p-0 {
  padding: 0px;
}

.grid-input .p-inputtext {
  width: 100%;
  text-align: center;
}

.row-value {
  /* color: #506ac3; */
  align-items: flex-start;
  padding-left: 5px !important;
  font-weight: 600 !important;
}

.row-right {
  /* color: #506ac3; */
  text-align: right !important;
}

.fixed-header-width {
  width: 65px;
}

.custom-doc-drpdwn,
#drpSubmission {
  min-width: 250px;
}

.custom-policy-labels .hdr-label {
  min-width: 110px;
  max-width: 110px;
}

.custom-policy-labels .custom-policy-link,
.custom-policy-add-edit .custom-policy-link {
  padding-left: 5px;
}

.custom-policy-add-edit .hdr-label {
  min-width: 115px;
  max-width: 110px;
}

.custom-policy-add-edit .custom-form.form-group label {
  min-width: 100px;
  max-width: 100px;
  text-align: right;
}

.custom-flex-leadsTab {
  flex-direction: row-reverse;
}

.workflow-tabs .p-tabview-panels {
  padding: 0 !important;
}

.custom-contract-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .custom-font {
    font-size: 14px;
    max-width: 120px;
  }

  .custom-margin-top {
    margin-top: 10px !important;
  }

  .custom-contract-wrap {
    flex-direction: column;
    align-items: flex-start;
  }

  .custom-contract.margin-10 {
    margin: 0 !important;
  }

  .custom-padding-0 {
    padding: 0 !important;
  }

  .p-datatable-auto-layout > .p-datatable-wrapper {
    overflow-x: auto !important;
  }

  .fc .fc-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start !important;
  }

  .fc .fc-button-group {
    flex-direction: row !important;
    display: block !important;
  }

  .fc .fc-button {
    padding: 0.2em 0.25em !important;
  }

  .fc .fc-toolbar-title {
    font-size: 1.05em !important;
  }

  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-left: 0 !important;
    margin-top: 5px !important;
  }

  .custom-doc-drpdwn {
    min-width: 150px;
  }

  .custom-policy-labels table {
    width: 100%;
  }

  .custom-policy-labels .hdr-label {
    min-width: 100px;
    max-width: 100px;
  }

  .custom-policy-add-edit .hdr-label {
    min-width: 80px;
    max-width: 80px;
  }

  .custom-policy-add-edit .custom-form.form-group label {
    min-width: 80px;
    max-width: 80px;
  }

  .custom-lead-labels .hdr-label {
    min-width: 90px;
    max-width: 90px;
  }

  .custom-flex-leadsTab {
    flex-direction: column-reverse;
  }

  /* .custom-policy-labels .row-right{
    text-align: left !important;
  } */
  .custom-style .hdr-label {
    min-width: 90px;
    max-width: 90px;
  }

  .custom-policy-labels .row-value {
    width: 100%;
  }
}

.margin-top-20 {
  margin-top: 24px !important;
}

.mr-r {
  margin-right: 2% !important;
}

.mr-t {
  margin-top: 3% !important;
}

.mr-t1 {
  margin-top: 5% !important;
}

.gray-color {
  color: #5b6770;
  font-size: 13px;
}

.row-fontcolor {
  color: maroon !important;
  cursor: pointer;
}

.font-bold {
  font-weight: 600 !important;
}

.list-box {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  margin: 0px;
}

.list-box li {
  background-color: #eaeaea;
  border-radius: 15px;
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.list-box li span {
  margin-right: 10px;
  max-width: 94%;
}

.list-box li:last-child {
  margin-right: 0px;
}

.list-box li a {
  cursor: pointer;
}

.list-box li a.doc-close {
  vertical-align: middle;
  background-color: #506ac3;
  color: #ffffff !important;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  line-height: 22px;
  cursor: pointer;
}

.mr-r {
  margin-right: 2% !important;
}

.mr-t {
  margin-top: 3% !important;
}

.mr-t1 {
  margin-top: 5% !important;
}

.gray-color {
  color: #5b6770;
  font-size: 13px;
}

.hide {
  display: none;
}

.slidebardivscroll {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 70%;
  margin-top: 4%;
}

.slidebarhistory-margin-bottom-0 {
  margin-bottom: 0px !important;
  margin-bottom: 0.3rem !important;
}

.p-datatable-auto-layout > .p-datatable-wrapper {
  overflow-x: visible !important;
}

.notes-contract {
  background-color: #c7f6ff !important;
}

body .app-table.p-datatable .p-datatable-tbody > tr.notes-contract:hover,
body .app-table.p-datatable .p-datatable-tbody > tr.notes-contract td:hover {
  background-color: #c7f6ff !important;
}

.deepskyblue {
  background-color: deepskyblue !important;
}

.mistyrose {
  background-color: #d6defb !important;
}

.isTeleSale {
  background-color: #ebf7bc !important;
}

body .app-table.p-datatable .p-datatable-tbody > tr.deepskyblue:hover {
  background-color: deepskyblue !important;
}

body .app-table.p-datatable .p-datatable-tbody > tr.mistyrose:hover {
  background-color: #d6defb !important;
}

body .app-table.p-datatable .p-datatable-tbody > tr.isTeleSale:hover {
  background-color: #ebf7bc !important;
}

body .app-table.p-datatable .p-datatable-tbody > tr.deepskyblue td:hover {
  background-color: deepskyblue !important;
}

body .app-table.p-datatable .p-datatable-tbody > tr.mistyrose td:hover {
  background-color: #d6defb !important;
}

body .app-table.p-datatable .p-datatable-tbody > tr.isTeleSale td:hover {
  background-color: #ebf7bc !important;
}

.app-tab.tab-opt2 .p-tabview-panels.gray-background .p-tabview-panels {
  background-color: transparent;
}

/* added cursor pointer on hover for renewal backout grid */
body .app-table#renewal-backout-table .p-datatable-tbody > tr td:hover {
  cursor: pointer;
}

body
  .app-table.email-table.p-datatable
  .p-datatable-tbody
  > tr.p-datatable-row:focus {
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  border: none !important;
}

.order-details td.hdr-label {
  float: right;
  white-space: nowrap;
}

.row-value.custom-url {
  max-width: 500px;
}

.row-value.custom-title {
  margin-left: -364px !important;
  max-width: 550px;
}

@media screen and (max-width: 1280px) and (min-width: 768px) {
  .row-value.custom-url {
    max-width: 350px;
  }
}

@media screen and (max-width: 767px) {
  .row-value.custom-url {
    max-width: 200px;
  }
}

.email_attachment {
  margin-top: 1.5rem;
  font-weight: bold;
}

.email_attachment_list {
  margin: 10px 0 0;
  padding: 0;
}

.email_attachment_list li {
  cursor: pointer;
  margin-bottom: 7px;
}

.cursor-none {
  cursor: default !important;
}

.new-line {
  white-space: pre-wrap !important;
}

.p-button.p-disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.p-datatable-resizable .p-datatable-tbody > tr > td {
  overflow: visible !important;
}

body .app-table.p-datatable .p-datatable-tbody > tr {
  cursor: default !important;
}

.margin-right-5 {
  margin-right: 5px !important;
}

.asterik-required:after {
  content: " *";
  color: #dc3545;
}

.mr-6 {
  margin-top: 6%;
}

.p-b0 {
  padding-bottom: 0;
}

.p-b10 {
  padding-bottom: 10px;
}

.mr-3 {
  margin-top: 3%;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer1 {
  cursor: pointer !important;
}

.mr-top {
  margin-top: 7%;
}

.red-c {
  color: red;
}

.a-link {
  color: #007ad9;
  text-decoration: underline;
}

.card-height {
  height: 204px;
}

.notice {
  font-size: 36px;
  font-weight: 700;
  color: #dc3545;
  padding-right: 0.25em;
  margin-right: 0.25em;
  border-right: 1px solid;
}

/* CSS Tree menu styles */
html {
  -webkit-print-color-adjust: exact;
}

ol.tree {
  padding: 0 0 0 30px;
  /* width: 300px; */
}

ol.tree .tooltip {
  font-size: 14px;
}

li {
  position: relative;
  /* margin-left: -15px; */
  list-style: none;
}

li.file {
  margin-left: -1px !important;
}

li .tooltip .tooltip-level {
  left: 105%;
  margin-left: 0;
  bottom: 0;
}

li.file .tooltip .tooltip-level {
  left: 105%;
  margin-left: 0;
  bottom: 0;
}

li.file a {
  display: inline-block;
  padding-left: 21px;
  /* background: url(../images/tree/user.png) 0 0 no-repeat; */
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAPCAYAAADtc08vAAAC1ElEQVQoz2XRzYscRRgG8Oetqu6enp6PnY/d7KA4s2TFkCyIBkRzUFZBMCLuQRTBg6DgPRBPIngSBA/ix5+gN4lEENGDFz2IwSAEk91kF81Ogrszu9090109VdVVngx0fP6AH8/7PrT5RUp3JzN3OP6b2NGef4LtLi58GGJ3byyIKCxVaR4/c0Z++dU3+PrdbwEAzjn8F/bUkLu1vkecyCllLRETRWGGg9XVN6Mo+iBf5Bcvfff90866YOuj87g/4smHGAJed3t3ehjHEzbY2DltVf2ttd7JV1Wol5TU5mianLfMvc8z/iMAWwGu7FuKF8zVmm0E3QFqzfkzRc5eXl5dXQ7rdSSDY28ST87Kudwqnf0VQFwBfriuXKvhCzDfNHv9Ws0/soeJinazHdeut2FII2pEPGpH3YIV/v9O0FpBgJU+aRJcpM6aq8fx8a0/96891uv3WKmN8z3vuNNp/5Y6lt4PsM1HQow6DKMuYw/3mbudvPMH4P2UZVmmpAIcKznxn8NaeHkwGhSvfbZVbbC5LpAUwEEW8n9SsjMlljeG555b4Mj3Ah/WWpo7WmNgz+dJlspU3q0AN6dExsJNc6edd9g8dzK/cbp3FtppzMsUM5PwlfqJjZlM39seb/cb3ehTAJN7wM4EtDBwhZF4cHArpsYEU1JY4l30+QoCP0AaJrBBueJP/Fc48d8BXLoHSO2cNCDjxs8Kb8fFhSGFnGJ/Cg4PC1ugKAsUKgcIQwJ7oQJEvnM+Lz2prr9RZLcpTRuw1qF0FkSA1sZpoyGlhNKLAOSeqKwQMkNtL+uw4ur+Iku3ldalNgamNDCmhNKa0mRGSZxQWRrBPLZeeWIuWu4v2Tro5Tc+bjwwvOIL7yXB+aOC8QHnokkgcTA7kPE0STorLdVaim5WgE9eJADA25+/Ho+Go8uyyH8RxNcF+KnA1k55EO1W0Lwzw/xaq9nKPS4qwL+8uGas1FnicQAAAABJRU5ErkJggg==")
    0 0 no-repeat;
  text-decoration: none;
  /*color: #fff;*/
}

/* li.file a[href*=".pdf"] {
  background: url(document.png) 0 0 no-repeat;
}

li.file a[href*=".html"] {
  background: url(document.png) 0 0 no-repeat;
}

li.file a[href$=".css"] {
  background: url(document.png) 0 0 no-repeat;
}

li.file a[href$=".js"] {
  background: url(document.png) 0 0 no-repeat;
} */

li input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  margin-left: 0;
  width: 1em;
  height: 1em;
  opacity: 0;
  cursor: pointer;
}

li input:checked + ol {
  /* margin: -0.938em 0 0 -44px; */
  margin: -1.45em 0 0 -44px;
  height: 1em;
  /* background: url(../images/tree/right-arrow-button.jpg) 40px 0 no-repeat; */
  background: url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD//gA7Q1JFQVRPUjogZ2QtanBlZyB2MS4wICh1c2luZyBJSkcgSlBFRyB2NjIpLCBxdWFsaXR5ID0gODUK/9sAQwAFAwQEBAMFBAQEBQUFBgcMCAcHBwcPCwsJDBEPEhIRDxERExYcFxMUGhURERghGBodHR8fHxMXIiQiHiQcHh8e/8AACwgAEQAPAQERAP/EABcAAAMBAAAAAAAAAAAAAAAAAAYHCAn/xAAsEAABAgUBBQgDAAAAAAAAAAABAgMEBQYHEQAIEiFRYRMVMTI3QUJxY3SB/9oACAEBAAA/AFJZTaUre3wZlswcNQyFGEiFinD2rKfxucSMcjkfWrXtFeKhrmQYVT00S3MAnL0tiiG4hr3Jxx3k9U5H1rPy0Vma5uZFp7hlimZcFYdmUUC3Dt88K+R6Jz/NWtZnZqoSgWUxcwYTUU7KN1UTGoAaRkYUG2vADqok6YNkvSCmv0UaLYfyK1//2Q==")
    40px 0 no-repeat;
  background-size: 7px;
  /* 15px */
}

li input:checked + ol > li {
  display: none;
  margin-left: -14px !important;
  padding-left: 1px;
}

.h-label {
  display: block;
  padding-left: 37px;
  /* background: url(../images/tree/users.png) 15px 1px no-repeat; */
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAPCAYAAADtc08vAAADSklEQVQoz23By28UdQAH8O/vMa+d2Zlud7uPlNLQllihKSk0CPaACYYEooH6aKJXe/GoBw7GePDiX4B/gAc9aWLiSY1XjY8CCQSwtEXs7tJ2HzOz3Xn9fjM//wE/HwIA618JWuRiLozl5W5vZB+1n2/Rwd79Bt1NP/7Cwu5emxNCrDzL5fmzZ+Ovv/ke393+AQDA2PkPwWcunlmo8U9ebrDNRORXXxz6y1nQ705o4bOVNcw4TvltBWwMg+GFrXsPVBaJF4vXTuePf9oGd87cNP3+4IY2q61fOlkqG6yEvU611vZ7+62l7RdFVvrgVHV+I7PERBYLOegHNwqqPmNj9jOAgusq4eMgmHzUcfVmxVR+SmGWPWpMtmpm+fhKEtGbU83mlFUqIWgNtZ7fuxAfx7dyVfwOwOdWehQNmffH3+3Rc8nsBdfRAaoPytXar6Y+KI6CzN4dbyuv5EESAduxme3ZkwlNdADgb72/gbu7fucgyDtCZPMclOhE7HPGt1Qhj4f+cOfR/sOVaq1KcyGVrmnDSsX7M1Q0BAC2sn57ae2089HyjP1aw+OGrVNotHDKWtE6COeeNSr3U0rVsmVYBiUsF5n4hYDccavuQWu1Dh5JtgnC3nipQVyDKRUkwOHYcg5C8voo4zNLs69mKQa6ZugoioIcK3KKgl6LgnEYh3GXnbj+6ZdJTurjlJBEEhKmhPTGUIp36erCYGqlOdecdk9yz/Rg6Sat2JN127RXgyhAnMYP+P4w89LcoJkEGcZEpRJIZIwTrR0Qp4c+yTDBJlFjdRi6gdAKUBh5Xe/p7zDC7vJoNOqMDW5Hghs6UySWgFRtxbVt5SeSZIiIr/fBoCEtEiR5giSLAIJZAnqdV2z6uWeoK45WnLN10tRZbsbZYzcZ/1sOQwdFoZCrAoQAQkglpEAcx8hEaoCoi/yVeefbUZz+6GqsaVFeL9HITsN7a+k4fDfzjHkhJddyCQKCTAgyCkeIojHyXHKq0QW+c5gUhmkFTDcDcOPJP7GLavTkN2d69i+da29yxs5xylqM8TIB4Yejw9jvB0Gl7mbuhP2U4H9s3nkPl65eZnESVQ1LXyiZpUWDWIt5Lr1Op9tpd7oPp+cbURxFT/8D0tebYr+rRQ8AAAAASUVORK5CYII=")
    15px 1px no-repeat;
  cursor: pointer;
}

.h-a-label {
  color: #142933 !important;
  font-size: 14px !important;
  font-weight: bold !important;
}

li input + ol {
  margin: -1.65em 0 0 -44px;
  padding: 1.563em 0 0 80px;
  height: auto;
  /* background: url(../images/tree/expand-button.jpg) 40px 3px no-repeat; */
  background: url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD//gA7Q1JFQVRPUjogZ2QtanBlZyB2MS4wICh1c2luZyBJSkcgSlBFRyB2NjIpLCBxdWFsaXR5ID0gODUK/9sAQwAFAwQEBAMFBAQEBQUFBgcMCAcHBwcPCwsJDBEPEhIRDxERExYcFxMUGhURERghGBodHR8fHxMXIiQiHiQcHh8e/8AACwgADwARAQERAP/EABcAAAMBAAAAAAAAAAAAAAAAAAUGBwn/xAAsEAABAgQDBgYDAAAAAAAAAAABAgMEBQcRAAhhEhMUF0NRFSEiI0FCVnGT/9oACAEBAAA/AJFnHpfy+qY5MJZD7uQzwqiYTZHpacv7jWlibjRQ7YVMu1OYiplToCQ7KxLmjxMydH0YSRcX7qNkj96Y0U5W08/EJV/EYHZhKdwtS6YTGQLCEx7aeJlzyum+gHZ8/gKF0nQ4VMnVMUU/pg1HRrbfjk8CYqLWCFbtvptAjsDc6qPbFtx//9k=")
    40px 3px no-repeat;
  background-size: 7px;
  /* 20px */
}

li input + ol > li {
  display: block;
  margin: 0 0 0.125em;
  /* 2px */
}

li input:checked + ol > li:last-child {
  margin: 0 0 0.063em;
  /* 1px */
}

/* Tooltip container */
a .tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip level */
a .tooltip .tooltip-level {
  width: 200px;
  visibility: hidden;
  background-color: #51629e;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 8px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
}

a .tooltip:hover .tooltip-level {
  visibility: visible;
}

a .tooltip .tooltip-level::after {
  content: " ";
  position: absolute;
  top: 100%;
  /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #51629e transparent transparent transparent;
}

/*Tooltip ends */

.callout {
  position: absolute;
  top: 30px;
  left: -12px;
  z-index: 20;
  border: 0;
}

.input-min-height {
  min-height: 36.6px;
}

.mr-l3 {
  margin-left: 3%;
}

.mr-l3 {
  margin-left: 3%;
}

.imas-agent-auto-complete .p-autocomplete-panel {
  overflow: inherit !important;
  padding-top: 30px !important;
}

.imas-agent-auto-complete .p-autocomplete-panel ul {
  overflow: overlay !important;
  max-height: 200px;
  position: static;
  border-radius: 0px;
  /* box-shadow: none!important; */
  margin-bottom: 0px;
}

.imas-agent-auto-complete .p-autocomplete-panel ul li:first-child {
  position: absolute !important;
  width: 100% !important;
  margin-top: -30px !important;
  background-color: #fff !important;
}

.imas-agent-auto-complete
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item:first-child:hover {
  color: #000 !important;
  background-color: #fff !important;
}

.imas-agent-auto-complete .p-autocomplete-header-Panel {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  display: block !important;
}

.default-contact {
  /* background-color: #D9D9D9 !important; */
  font-weight: bold;
}

.divider {
  background-color: #33478a !important;
  height: 3px;
  padding: 0em !important;
  margin-top: 3px;
  margin-bottom: 5px;
}

.margin-top-20 {
  margin-top: 24px !important;
}

.icon-fill-color-red {
  color: red;
}

.icon-fill-color-green {
  color: green;
}

.normal-link {
  color: #506ac3;
  text-decoration: underline !important;
  cursor: pointer !important;
}

.text-underline {
  text-decoration: underline !important;
}

.notif-dropbtn {
  background-color: #3498db;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.notif-dropbtn:hover,
.notif-dropbtn:focus {
  background-color: #2980b9;
}

.notif-dropdown {
  position: relative;
  display: inline-block;
}

.notif-dropdown-content {
  display: none;
  position: absolute;
  margin-top: 10px;
  background-color: #f1f1f1;
  min-width: 300px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
}

.custom-notify-box {
  /* padding: 10px 15px; */
  /* background-color: rgba(237,240,245,0.7); */
  background-color: #fff;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
}

.padding-right-5 {
  padding-right: 7px;
}

.custom-notify-box .display-flex-row {
  justify-content: space-between;
  /* padding: 10px 15px; */
  margin: 0;
  min-height: 35px;
  /* background-color: rgba(237,240,245,0.4); */
  /* border-radius: 4px; */
  background-color: #fff;
  border-bottom: 1px solid #c8c8c8;
}

.custom-notify-box .display-flex-row:hover {
  background-color: rgba(237, 240, 245, 0.55);
}

.custom-notify-box .fa-close {
  margin: 0 8px;
  cursor: pointer;
}

.notif-dropdown-content span {
  text-decoration: none;
  display: block;
  margin-left: 10px;
  font-size: 14px;
  /* font-weight: 600;
  color: maroon; */
  font-weight: 500;
  color: #000;
}

.notif-dropdown a:hover {
  background-color: #ddd;
}

.notif-show {
  display: block;
}

.icon-fill-color-yellow {
  color: goldenrod;
}

.align-items-center {
  align-items: center;
}

.p-overlay-badge {
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding-top: 8px;
}

.p-mr-5 {
  margin-right: 2rem !important;
}

.p-overlay-badge .p-badge {
  position: absolute;
  top: 10px;
  right: 5px;
  -webkit-transform: translate(1em, -1em);
  transform: translate(1em, -1em);
  margin: 0;
  background-color: red;
  color: white;
}

.p-badge {
  background: red;
  color: #ffffff;
  font-weight: 700;
  border-radius: 50%;
  min-width: 1.2rem;
  height: 1.2rem;
  line-height: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.text-normal {
  font-weight: normal !important;
}

.text-bold {
  font-weight: bold !important;
  text-decoration: underline !important;
  color: #000 !important;
}

.lightblue {
  background-color: #a1dcf2 !important;
}

.text-black {
  color: #000 !important;
}

.contact-underline {
  text-decoration: underline !important;
  color: #000 !important;
}

.permission-underline {
  text-decoration: underline !important;
  color: #000 !important;
  cursor: pointer;
}

.w-100 {
  width: 100px !important;
}

.custom-avatar-parent {
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.25rem;
  align-items: center;
  flex-shrink: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
}

.custom-avatar-child {
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
}

.carrier-name {
  color: #334781;
  font-size: 24px;
  font-weight: 700;
}

.sky-blue-color {
  background-color: rgb(170, 221, 255) !important;
}

.sec-loginname .p-button-text-only .p-c {
  width: 117px !important;
}

.main-header-content {
  display: flex;
  justify-content: space-between;
}

.main-content-left,
.main-content-right {
  display: flex;
}

.marketing-event {
  display: flex;
  min-height: 100%;
}

.marketing-event-sidebar {
  width: 200px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.marketing-event-sidebar-section {
  padding: 2em;
}

.marketing-event-sidebar button {
  margin-right: 0.5em;
}

.marketing-event-main {
  flex-grow: 1;
  /* padding: 3em; */
}

.fc {
  /* the calendar root */
  /* max-width: 1100px; */
  width: 100%;
  margin: 0 auto;
}

@media only screen and (max-width: 767px) {
  .fc .fc-button-group {
    flex-direction: column;
  }
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background-color: #fff !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  box-shadow: none !important;
}

.p-tree .p-tree-container {
  font-size: 11px;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
  width: 44px;
}

.p-tree-container .p-checkbox .p-checkbox-box {
  width: 15px;
  height: 15px;
}

.p-tree-container .p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-size: 12px;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  padding-top: 2px;
  margin: 0 0.29em 0 1px;
}

.p-treenode-label .p-button.secondary-button {
  border: none !important;
}

.tree-icons-custom {
  color: #33478a !important;
  margin-left: 5px;
}

.p-treenode .tree-input-custom {
  width: 100px;
  font-size: 11px;
  height: 19px;
  opacity: 1;
  left: 191px;
  top: 4px;
}

.mar-left {
  left: 108px !important;
}

.appsetting-tree {
  border: none !important;
  padding: 0 !important;
  min-width: 500px;
}

.appsetting-tree .p-tree-container {
  overflow: hidden;
  min-height: 270px !important;
}

.p-treenode .tree-input-custom1 {
  left: 191px !important;
}

.sec-title {
  color: #142933;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.2px;
  font-size: 16px;
}

.margin-left-4 {
  margin-left: 4.5%;
}

.margin-left-12 {
  margin-left: 12.7%;
}

.margin-left-11 {
  margin-left: 11.3%;
}

.wid-full {
  width: 100% !important;
}

.roles-tree .p-tree-container {
  height: 250px !important;
}

/* */
.agent-top-search {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex: 1 !important;
}

.custom-active-carriers p {
  color: #dc3545;
  margin-top: 0;
}

.custom-active-carriers ul {
  margin: 0;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #afb0b2;
  max-height: 100px;
  overflow-y: auto;
}

/* Admin Screens */
.pa-0 {
  padding: 0px !important;
}

.pa-1 {
  padding: 1px !important;
}

.pa-2 {
  padding: 2px !important;
}

.pa-3 {
  padding: 3px !important;
}

.pa-4 {
  padding: 4px !important;
}

.pa-5 {
  padding: 5px !important;
}

.pa-6 {
  padding: 6px !important;
}

.pa-7 {
  padding: 7px !important;
}

.pa-8 {
  padding: 8px !important;
}

.pa-10 {
  padding: 10px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-1 {
  padding-left: 1px !important;
}

.pl-2 {
  padding-left: 2px !important;
}

.pl-3 {
  padding-left: 3px !important;
}

.pl-4 {
  padding-left: 4px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-6 {
  padding-left: 6px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-1 {
  padding-top: 1px !important;
}

.pt-2 {
  padding-top: 2px !important;
}

.pt-3 {
  padding-top: 3px !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-6 {
  padding-top: 6px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 1px !important;
}

.mt-2 {
  margin-top: 2px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 1px !important;
}

.mb-2 {
  margin-bottom: 2px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mle-1 {
  margin-left: 1em !important;
}

.mle-2 {
  margin-left: 2em !important;
}

.mle-3 {
  margin-left: 3em !important;
}

.mre-1 {
  margin-right: 1em !important;
}

.mre-2 {
  margin-right: 2em !important;
}

.mre-3 {
  margin-right: 3em !important;
}

.p-grid-sep {
  border-top: 2px solid #33478a;
  margin: 5px 1px 15px 1px;
}

.sep-bot {
  border-bottom: 2px solid #33478a;
}

.sep-top {
  border-top: 2px solid #33478a;
}

.settings-box {
  /* min-height: 8em; */
  margin-bottom: 14px !important;
}

.settings-box .section-title .fa {
  width: 24px;
}

.settings-box .admin-menu {
  padding-left: 25px !important;
}

.t-table {
  display: table;
}

.t-cell {
  display: table-cell;
}

.y-cell {
  display: table-cell;
  position: absolute;
  top: 0;
  margin-bottom: 1000px;
}

.screen-container {
  padding: 8px;
}

.screen-title .back-icon {
  width: 3em;
}

.screen-title .back-icon .fa:hover {
  color: #506ac3;
}

.screen-title .fa-bx {
  font-size: 2.5em;
}

.screen-title .title-text {
  color: #334781;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.2px;
  font-size: 21px;
  vertical-align: middle;
  padding-bottom: 10px;
}

.screen-filters {
  margin-top: -1em;
}

.editor-host {
  border-top: 2px solid #33478a;
  padding: 1.2em;
}

.admin-dark-bg {
  /* background-color: #edf0f5; */
  background-color: transparent;
  padding: 1em 0;
}

.download-content-on-certificate {
  text-align: center;
  padding: 25px 0;
  font-size: 2rem;
  margin: 0;
  line-height: 1.5;
}

.download-content-on-certificate span {
  cursor: pointer;
  text-decoration: underline;
  color: #005ae0;
}

@media only screen and (max-width: 767px) {
  .download-content-on-certificate {
    font-size: 1.2rem;
  }

  .appsetting-tree {
    max-width: 300px;
    min-width: 300px;
  }

  .p-treenode-label {
    font-size: 13px;
  }
}

@media (max-width: 400px) {
  #addAgentButton {
    margin-right: 0 !important;
  }

  #mergeAgentButton {
    margin-top: 10px !important;
  }
}

.bool-text,
.pill {
  text-decoration: none;
  position: relative;
  display: inline-block;
  border-radius: 15px;
  display: inline-block;
  color: white;
  min-width: 4em;
  padding: 1px 5px 2px 5px;
  font-size: 0.8em;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
}

.bool-text.bool-yes,
.pill.pill-green {
  background-color: #7bdc52;
  border: 1px solid #6fc74a;
}

.bool-text.bool-no,
.pill.pill-red {
  background-color: #ff7f7f;
  border: 1px solid #ff5f5f;
}

.pill.pill-yellow {
  background-color: #f8aa00;
  border: 1px solid #f89500;
}

.dialog-host .p-checkbox-label,
.editor-host .p-checkbox-label,
.list-host .p-checkbox-label {
  margin: 0;
  padding-left: 0.5em;
}

.editor-host .editor-buttons-panel,
.editor-host .editor-panel-separator {
  margin-top: 1em;
  padding-top: 2em;
  border-top: 1px dashed lightgray;
}

.p-splitbutton span.p-button-icon-left {
  margin-top: -0.45em;
}

.small-link-button,
.small-grid-button {
  min-height: 28px !important;
  height: 28px !important;
}

.small-link-button .p-button-text,
.small-grid-button .p-button-text {
  padding: 0.25em 0.6em 0.3em 1.8em !important;
}

.small-link-button {
  border: 0 !important;
  background: transparent !important;
}

.w-120 {
  width: 100px !important;
}

.d-divider {
  margin-top: 1em;
  padding-top: 1em;
  border-top: 1px dashed lightgray;
}

.commission-dialog {
  height: 500px;
  overflow-y: auto;
}

.customViewer .columns {
  display: flex !important;
  width: 100%;
  background-color: rgb(50, 54, 57);
  color: #fff;
  display: block;
  height: auto;
}

.customViewer .columns.is-mobile {
  display: flex;
}

.customViewer .columns.is-gapless {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}

.customViewer .has-margin-bottom-15 {
  margin-bottom: 15px !important;
}

.customViewer .has-margin-top-15 {
  margin-top: 15px !important;
}

.customViewer .has-background-black {
  background-color: #0a0a0a !important;
}

.customViewer .has-text-white {
  color: #fff !important;
}

.customViewer .columns.is-gapless > .column {
  margin: 0;
  padding: 0 !important;
}

.customViewer .column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: center;
  padding-top: 15px;
}

.customCanvas {
  text-align: center;
  overflow: auto;
  background-color: rgb(82, 86, 89);
}

.margin-top-8 {
  margin-top: 8px !important;
}

.level1 {
  font-size: 20px;
  color: #000 !important;
  font-weight: 600;
}

.level1:hover {
  font-size: 20px;
  color: #000 !important;
  font-weight: 600;
}

.level2 {
  color: #464646 !important;
  font-size: 18px;
}

.level2:hover {
  color: #464646 !important;
  font-size: 18px;
}

.level0 {
  color: #506ac3 !important;
  font-size: 16px;
  font-weight: normal !important;
}

.level0:hover {
  color: #506ac3 !important;
  font-size: 16px;
  font-weight: normal !important;
}

.pi-minus {
  display: none !important;
}

body
  .p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content.p-treenode-selectable:not(.p-highlight):not(
    .p-disabled
  ):hover {
  background-color: #fff !important;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  color: #33478a !important;
}

body
  .p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content.p-highlight
  .p-tree-toggler,
body
  .p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content.p-highlight
  .p-treenode-icon {
  color: #33478a !important;
}

body .p-tabview.p-tabview-top .p-tabview-nav li a:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.p-callout-host {
  display: flex;
}

.p-callout {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.p-callout.right {
  margin-left: 10px;
  margin-right: 20px;
}

.p-callout.right::before {
  position: absolute;
  top: 50%;
  left: 4px;
  width: 0;
  height: 0;
  margin-top: -13px;
  border-top: 12px solid transparent;
  border-bottom: 11px solid transparent;
  border-right: 15px solid #dc3545;
  content: "";
}

.p-callout-text {
  -webkit-transform: translate(1em, -1em);
  transform: translate(1em, -1em);
  margin: 0;
  background-color: #dc3545;
  color: white;

  right: -10px;
  border-radius: 0px 15px 15px 0px;
  padding: 5px;
  display: inline-flex;
  position: relative;
  top: 6px;
  letter-spacing: 1.5px;
  font-size: 9px;
  padding-right: 1.1em;
}

.p-callout-text .fas {
  font-size: 1.5em;
}

@media only screen and (max-width: 767px) {
  .p-callout-text {
    top: 17px;
  }

  .profile-name > span {
    max-width: 140px;
  }

  .partnerlogo-sm {
    margin-top: 20px !important;
  }
}

.text-15 {
  font-size: 17px !important;
}

.margin-top-23 {
  margin-top: 23px !important;
}

.margin-top-2 {
  margin-top: 20px;
}

#txtDescription {
  height: 103px;
}

body .p-card .p-card {
  background-color: #ffffff;
  color: #333333;
  -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
}

.wid-150 {
  width: 150px !important;
}

.without-icon-btn .p-button-text {
  margin-right: 14px !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  color: #000 !important;
}

.product-col {
  text-decoration: underline !important;
  cursor: pointer;
}

/* BEGIN - Top Agent Search related */
.header-card-body-ov {
  position: relative;
  display: table;
  min-width: 0;
  background: #fff !important;
  background-clip: border-box;
  padding: 0 !important;
  align-items: center;
  border-radius: 5px;
}

.top-search-custom {
  border-right: none !important;
  margin-top: -2px;
  margin-left: 2px;
}

.header-card-body-ov label.p-dropdown-label {
  padding-top: 6px;
  border-radius: 5px;
}

.agent-top-search {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex: 1 !important;
}

.blue-c {
  color: #334781;
}

.ssn-error {
  width: 225px;
  font-size: 14px;
}

@media only screen and (max-width: 1301px) {
  .ssn-error {
    width: 170px;
    font-size: 10px;
  }
}

@media only screen and (max-width: 1155px) {
  .agent-top-search,
  .header-card-body-ov.top-search-custom {
    display: none;
  }

  .insuredId,
  .insuredState,
  .insuredZip,
  .agentNpn,
  .agentState,
  .agentZip,
  .agentId {
    text-align: left;
  }

  body .p-button {
    border-radius: 0 !important;
  }

  #followupDate {
    width: 82%;
  }

  .dropzone {
    border-radius: 0 !important;
  }

  .agentmap {
    text-align: left;
    overflow: visible;
  }

  .agentAddress {
    text-align: left;
    overflow: visible;
    width: 13%;
  }

  .agentStatusReason {
    text-align: left;
  }

  .contractdoNotCall {
    text-align: left;
    overflow: visible;
    width: 15%;
  }

  .column-center {
    text-align: left;
  }

  .column-right,
  .default-contact-container {
    text-align: left !important;
  }
}

#searchTypeTopHeaderDropdown {
  background-color: transparent !important;
  width: 153px !important;
  margin-right: 0px !important;
  border: none !important;
  border-radius: 5px 0px 0px 5px;
  display: table-cell;
  position: absolute;
  z-index: 10;
  top: 0px;
  height: 42px;
}

#searchTypemapDropdown {
  background-color: #edf0f5 !important;
  /* width: 155px !important; */
  margin-right: 0px !important;
  border: none !important;
  border-radius: 5px 0px 0px 5px;
}

#searchTypeTopHeaderDropdown.p-dropdown:not(.p-disabled).p-focus,
#searchTypeTopHeaderDropdown .p-inputwrapper-focus,
#searchTypemapDropdown.p-dropdown:not(.p-disabled).p-focus,
#searchTypemapDropdown .p-inputwrapper-focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: none !important;
  border: none !important;
}

#searchTypeTopHeaderDropdown .p-dropdown-trigger {
  height: 1.5em;
  margin-top: 6.2px;
}

#searchTypemapDropdown .p-dropdown-trigger {
  height: 1.5em;
  margin-top: 0.6em;
}

#searchTypeTopHeaderDropdown .p-dropdown-label,
#searchTypeTopHeaderDropdown .p-dropdown-trigger,
#searchTypemapDropdown .p-dropdown-label,
#searchTypemapDropdown .p-dropdown-trigger {
  background-color: #fff;
}

#agentTopHeaderAutoComplete {
  display: table-cell;
  border: 0.5px solid #dfdedd;
  border-radius: 3px;
}

#agentmapAutoComplete {
  width: 100%;
}

#agentTopHeaderAutoComplete .p-autocomplete-input::placeholder {
  color: #aaaaaa;
}

#agentTopHeaderAutoComplete .p-autocomplete-input {
  border: 0px;
  height: 39px;
  padding-left: 155px;
  min-width: 417px;
  font-family: "Lato";
  color: #434a51;
  font-weight: 300;
  font-size: 16px;
  padding-top: 8px;
}

#agentTopHeaderAutoComplete .p-autocomplete-loader {
  position: absolute;
  right: 3em;
  top: 50%;
  margin-top: -0.5em;
}

#TopHeaderSearchHeardCard.header-card-body-ov label.p-dropdown-label {
  padding-top: 11px;
  border-radius: 5px;
  background: transparent;
  font-family: "Lato";
  color: #434a51;
  font-weight: 300;
  font-size: 16px;
}

#agentTopHeaderAutoComplete
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item {
  background-color: white !important;
  border: 0 !important;
  border-bottom: 0.5px solid #dfdedd !important;
  padding-top: 0.6em !important;
  padding-bottom: 0.35em !important;
  font-family: "Lato";
}

#agentTopHeaderAutoComplete
  .p-autocomplete-panel
  .p-autocomplete-items
  li:nth-child(1) {
  height: 30px;
  padding-top: 0.4em !important;
}

#agentmapAutoComplete .p-autocomplete-input {
  border: 0px;
  height: 35px;
}

#agentTopHeaderAutoComplete:enabled:focus,
#agentmapAutoComplete:enabled:focus {
  box-shadow: none !important;
  border: none !important;
}

#searchTypeTopHeaderDropdown .p-dropdown-panel .p-dropdown-items-wrapper {
  width: 175px !important;
}

#searchTypemapDropdown .p-dropdown-panel .p-dropdown-items-wrapper {
  white-space: nowrap;
}

#agentTopHeaderAutoComplete .p-inputtext:enabled:focus,
#agentmapAutoComplete .p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: none !important;
  border: none !important;
  /* border-left: 1px solid #cccccc !important; */
}

/* responsive fix for global Agent Search field */

#agentTopHeaderAutoComplete > div.p-autocomplete-panel {
  max-height: 234px !important;
  overflow-y: auto !important;
}

#agentTopHeaderAutoComplete .p-autocomplete-panel {
  border-radius: 5px;
  /*border-top-left-radius: 0px;*/
  box-shadow: 0px 10px 10px 5px rgb(0 0 0 / 25%);
  left: -2px !important;
}

@media only screen and (min-width: 3840px) {
  #searchButtonTopHeader {
    right: 3px;
  }
}

/* responsive fix for global Agent Search field */

#agentmapAutoComplete .p-autocomplete-panel {
  min-width: 25em !important;
  border-radius: 5px;
  border-top-left-radius: 0px;
  margin-left: -1px;
  box-shadow: 0px 10px 10px 5px rgb(0 0 0 / 25%);
}

#agentmapAutoComplete
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item {
  background-color: white !important;
  border: 0 !important;
  border-bottom: 1px solid #efefef !important;
  padding-top: 0.6em !important;
  padding-bottom: 0.35em !important;
}

#agentTopHeaderAutoComplete .p-autocomplete-panel .p-autocomplete-items,
#agentTopHeaderAutoComplete
  .p-autocomplete-panel
  .p-autocomplete-items
  li:nth-child(even) {
  background-color: #fff !important;
}

#agentTopHeaderAutoComplete .p-autocomplete-panel .p-autocomplete-items,
#agentTopHeaderAutoComplete
  .p-autocomplete-panel
  .p-autocomplete-items
  li:nth-child(odd),
#agentmapAutoComplete .p-autocomplete-panel .p-autocomplete-items,
#agentmapAutoComplete
  .p-autocomplete-panel
  .p-autocomplete-items
  li:nth-child(odd) {
  background-color: white !important;
}

#agentTopHeaderAutoComplete .p-autocomplete-panel .p-top-search-item,
#agentmapAutoComplete .p-autocomplete-panel .p-top-search-item {
  display: table;
}

#agentTopHeaderAutoComplete .p-autocomplete-panel .p-top-search-item .p-cell,
#agentmapAutoComplete .p-autocomplete-panel .p-top-search-item .p-cell {
  display: table-cell;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 6px;
}

#agentTopHeaderAutoComplete
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item.p-highlight {
  background-color: #ededee !important;
  color: #0052ce !important;
  font-weight: 400 !important;
}

#agentTopHeaderAutoComplete
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item:hover {
  background-color: #ededee !important;
  color: #0052ce !important;
  font-weight: 400 !important;
}

#agentmapAutoComplete
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item.p-highlight,
#agentmapAutoComplete
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item:hover {
  background-color: #33478a !important;
  color: white !important;
}

#agentTopHeaderAutoComplete
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item:first-child {
  padding-top: 4px !important;
  min-height: 35px !important;
  background-color: white !important;
  color: #000000 !important;
  z-index: 9999;
}

#agentTopHeaderAutoComplete
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item:first-child:hover {
  padding-top: 4px !important;
  min-height: 35px !important;
  background-color: white !important;
  color: #000000 !important;
  z-index: 9999;
}

#agentTopHeaderAutoComplete .p-highlight .p-cell.p-cell-left,
#agentTopHeaderAutoComplete
  .p-autocomplete-panel
  .p-top-search-item
  .p-cell.p-cell-left:hover,
#agentmapAutoComplete .p-highlight .p-cell.p-cell-left,
#agentmapAutoComplete
  .p-autocomplete-panel
  .p-top-search-item
  .p-cell.p-cell-left:hover {
  color: white !important;
}

#agentTopHeaderAutoComplete
  .p-autocomplete-panel
  .p-top-search-item
  .p-cell.p-cell-left,
#agentmapAutoComplete
  .p-autocomplete-panel
  .p-top-search-item
  .p-cell.p-cell-left {
  width: 6em;
  color: #33478a;
}

#agentTopHeaderAutoComplete
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item:hover
  .p-cell.p-cell-left,
#agentmapAutoComplete
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item:hover
  .p-cell.p-cell-left {
  color: white !important;
}

#agentTopHeaderAutoComplete
  .p-autocomplete-panel
  .p-top-search-item
  .p-cell.p-cell-right,
#agentmapAutoComplete
  .p-autocomplete-panel
  .p-top-search-item
  .p-cell.p-cell-right {
  overflow: hidden;
}

#searchButtonTopHeader {
  width: 41px !important;
  height: 42.5px !important;
  border-radius: 4px 4px 4px 4px !important;
  display: table-cell;
  position: absolute;
  right: 1.5px;
  background: transparent;
  border: none;
}

#searchButtonMap {
  width: 35px !important;
  border-radius: 0px 4px 4px 0px !important;
  display: table-cell;
  min-height: 36px;
}

/* END - Top Agent Search related */

.text-15 {
  font-size: 17px !important;
}

.margin-top-23 {
  margin-top: 23px !important;
}

#txtDescription {
  height: 103px;
}

body .p-card .p-card {
  background-color: #ffffff;
  color: #333333;
  -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
}

.wid-150 {
  width: 150px !important;
}

.without-icon-btn .p-button-text {
  margin-right: 14px !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  color: #000 !important;
}

.auto-dialer-notes-section {
  overflow-y: overlay;
  min-height: 232px;
  max-height: 232px;
  padding-right: 12px;
  margin-bottom: 10px;
}

.auto-dialer-calldata-section {
  min-height: 75px;
  max-height: 75px;
}

.auto-dialer-notes-header {
  background-color: #506ac3;
  font-weight: bold;
  color: #fff;
  font-size: 13px;
  margin-bottom: 5px;
}

.pad-2em {
  padding: 0.2em;
}

.topSearch-pad-5em {
  padding: 0.5em;
}

.topSearch-pad-5-8em {
  padding-left: 12px;
}

.topSearch-pad-5-8em-1 {
  padding-left: 12px;
}

.topSearch-pad-5-8em-2 {
  padding-left: 7.5px;
}

.f-right {
  float: right;
}

.text-trans-capitalize {
  text-transform: capitalize;
}

.text-trans-lowercase {
  text-transform: lowercase;
}

.padding-right-10 {
  padding-right: 10px !important;
}

.padding-bottom-0 {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

.padding-left-4 {
  padding-left: 4px !important;
}

.auto-dialer-marketers-heirerchy-section {
  padding-top: 0em !important;
  margin-top: -0.1em !important;
  padding-left: 15px !important;
}

.padding-right-0 {
  padding-right: 0 !important;
}

.lisence-wid {
  min-width: 0 !important;
}

.pwd-custom {
  text-decoration: none !important;
  cursor: pointer;
}

.product-col {
  text-decoration: underline !important;
}

.county-tree .p-treenode .p-treenode-label {
  font-weight: bold;
}

.county-tree .p-treenode-leaf .p-treenode-label {
  font-weight: normal;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #efefef;
  color: black;
  padding: 0.5rem;
  text-align: center;
}

.footer-bottom {
  padding: 0.5rem;
  background-color: #efefef;
  text-align: center;
}

.county-tree .p-treenode-leaf .p-treenode-label {
  font-weight: normal;
}

.route-not-found {
  /* background: #334781; */
  color: #334781;
  text-align: center;
  margin: 30px;
  overflow: hidden;
}

.route-not-found .fa-5,
.route-not-found h2 {
  font-size: 12em;
  font-weight: 600;
  display: inline-block;
  margin: 0;
}

.view-not-found {
  color: #334781;
  text-align: center;
  font-size: 3em;
  font-weight: 600;
}

.p-dialog {
  transform: none !important;
}

.p-calendar.p-calendar-w-btn .p-inputtext {
  width: 100% !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.margin-left-8 {
  margin-left: 8px;
}

#PayoutLevelTable .selectedpayout {
  background-color: #bdc1c3 !important;
}

#PayoutLevelTable.p-datatable .p-datatable-tbody > tr:focus > td {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

#PayoutLevelTable.p-datatable .p-datatable-tbody > tr > td {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

body .app-table.p-datatable .p-datatable-thead > tr > th.insuredId,
.insuredId {
  text-align: center;
  width: 120px;
}

.insuredState {
  text-align: center;
  width: 100px;
}

.insuredZip {
  text-align: center;
  width: 100px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.zipCustom {
  width: 12.5%;
}

body .app-table.p-datatable .p-datatable-thead > tr > th.agentId,
.agentId {
  text-align: center;
  width: 100px;
}

body .app-table.p-datatable .p-datatable-thead > tr > th.agentNpn,
.agentNpn {
  text-align: center;
  width: 100px;
}

body .app-table.p-datatable .p-datatable-thead > tr > th.agentState,
.agentState {
  text-align: center;
  width: 80px;
}

body .app-table.p-datatable .p-datatable-thead > tr > th.agentZip,
.agentZip {
  text-align: center;
  width: 90px;
}

body .app-table.p-datatable .p-datatable-thead > tr > th.appCount,
.appCount {
  text-align: center;
  width: 100px;
}

body .app-table.p-datatable .p-datatable-thead > tr > th.annualizedPremium,
.annualizedPremium {
  text-align: center;
  width: 160px;
}

body .app-table.p-datatable .p-datatable-thead > tr > th.agentmap,
.agentmap {
  text-align: center;
  overflow: visible;
}

body .app-table.p-datatable .p-datatable-thead > tr > th.agentAddress,
.agentAddress {
  text-align: center;
  overflow: visible;
  width: 13%;
}

body .app-table.p-datatable .p-datatable-thead > tr > th.agentStatusReason,
.agentStatusReason {
  text-align: center;
}

body .app-table.p-datatable .p-datatable-thead > tr > th.contractdoNotCall,
.contractdoNotCall {
  text-align: center;
  overflow: visible;
  width: 15%;
}

.states-td {
  padding: 0.4em 0.857em 0.4em;
  font-size: 13px;
  color: #142933;
}

.circle-notes {
  vertical-align: bottom;
  border: 1.2px solid #5e5d5d;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50px;
}

.margin-left-63 {
  margin-left: 63px;
}

#LicensePersistencyColorTable .p-disabled,
#LicensePersistencyColorTable .p-component:disabled {
  opacity: 0.9 !important;
}

.view-center {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.payment-plan-gary {
  background-color: #bdc1c3 !important;
}

.margin-top-14 {
  margin-top: 7px;
}

.dividercp {
  padding-top: 10px;
  margin-top: 15px;
  border-top: 1px dashed lightgray;
}

.flat-rate {
  margin-top: 31px;
  margin-left: -9px;
}

#progressbarcontainer {
  min-width: 350px;
  margin-left: -175px;
  background-color: #edf0f5;
  text-align: center;
  border-radius: 2px;
  padding: 15px;
  position: fixed;
  z-index: 1;
  left: 75%;
  bottom: 40px;
}

#notificationCard {
  margin-bottom: 0px !important;
}

.p-progressbar-determinate .p-progressbar-label {
  text-align: center;
  height: 100%;
  width: 100%;
  position: absolute;
  font-weight: normal !important;
}

.scroll-tab {
  max-height: 500px;
  overflow-y: auto;
}

.commission-usage {
  width: 100%;
  border-top: none !important;
}

.commission-usage .p-tabview-panels {
  border-top: none !important;
}

.ml13 {
  margin-left: 13px;
}

.accountdebt {
  font-size: 13px !important;
  padding: 5px !important;
}

.partnerlogo {
  height: 33px;
  width: 26px;
}

.partnerlogo-sm {
  height: 26px;
  width: 48px;
  /* margin-top: 10px; */
}

.mar-t24 {
  margin-top: 24px;
}

#addCustomerButton .p-button-text.p-c {
  padding: 0px 9px 0px 22px;
}

#mergeCustomerButton .p-button-text.p-c {
  padding: 1px 5px 0 28px;
}

#addAgentButton .p-button-text.p-c {
  padding: 0.429em 0.8em 0.429em 2.25em;
}

#mergeAgentButton .p-button-text.p-c {
  padding: 0.429em 0.8em 0.429em 2.25em;
}

#taskDesc > p {
  margin-top: 1px;
}

#noteDesc > p {
  margin-top: 1px;
  margin-bottom: 2px;
}

.padding-t9 {
  padding-top: 9px;
}

.padding-t7 {
  padding-top: 7px;
}

.custom-submissiondropdown {
  margin-left: -42px;
}

#drpSubmission .p-dropdown-items-wrapper {
  min-width: 500px;
}

#drpSubmission .p-dropdown-label {
  max-width: 500px;
}

.policyDoc .p-dropdown-items-wrapper {
  min-width: 600px;
}

.map-copyright {
  display: none !important;
}

.partnerlogo-sm {
  height: 26px;
  width: 48px;
  margin-top: 5px;
}

.cursor-pointer.custom-underline {
  color: #005ae0;
}

.cursor-pointer.custom-underline:hover {
  color: #33478a;
  text-decoration: underline !important;
}

.add-doc-packet {
  overflow-y: auto;
}

.hidden-custom {
  visibility: hidden;
}

.into-custom {
  width: 50px;
  margin-top: 33px;
}

.size-err {
  font-weight: 400 !important;
}

.list-header-disabled {
  cursor: not-allowed !important;
  background-color: #fff !important;
}

.list-item-header {
  cursor: not-allowed;
  font-weight: bold;
  color: #000;
}

.size-err {
  font-weight: 400 !important;
}

.tooltip-revenue {
  position: relative;
  display: inline-block;
}

.tooltip-contract {
  position: relative;
  display: inline-block;
}

.tooltip-revenue .tooltiptext {
  visibility: hidden;
  width: 532px;
  background-color: #ebecf0;
  color: #000;
  text-align: left;
  border-radius: 6px;
  padding: 5px;
  font-size: 10px;
  border: 1px solid #ebecf0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  font-weight: bold;
  margin-top: -25px !important;
}

.tooltip-contract .tooltiptext::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 16.5%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ebecf0 transparent;
}

.tooltip-contract .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: #ebecf0;
  color: #000;
  text-align: left;
  padding: 5px;
  font-size: 10px;
  border: 1px solid #ebecf0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  font-weight: 400;
  margin-top: 18px !important;
  margin-left: -35px;
}

.tooltip-contract .tooltipw5 {
  width: auto;
  font-size: 13px;
  min-width: 180px;
}

.tooltip-contract:hover .tooltiptext {
  visibility: visible;
}

.tooltip-revenue .tooltipw5 {
  width: 440px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tooltip-revenue:hover .tooltiptext {
  visibility: visible;
}

#cancelButton {
  line-height: 40px;
  margin-right: 15px;
}

#add-note-button {
  color: #33478a !important;
  background-color: transparent !important;
  border: 1px solid #33478a !important;
  margin-left: 5px;
}

#add-note-button:hover {
  background-color: green;
  color: white;
}

#download-in-document-workflow {
  color: #33478a !important;
  background-color: transparent !important;
  border: 1px solid #33478a !important;
}

#edit-in-document-workflow {
  color: #33478a !important;
  background-color: transparent !important;
  border: 1px solid #33478a !important;
}

#unassign-in-document-workflow {
  color: #33478a !important;
  background-color: transparent !important;
  border: 1px solid #33478a !important;
  display: inline-block;
  justify-content: space-between;
  align-items: center;
}

#dataentry-in-document-workflow {
  color: #33478a !important;
  background-color: transparent !important;
  border: 1px solid #33478a !important;
  display: inline-block;
  justify-content: space-between;
  align-items: center;
}

.buttons-in-document-workflow {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: visible;
}

#file-name-idd {
  width: 60vw;
  cursor: pointer;
}

#first_row_fields {
  color: #5b6770;
  font-size: 13px;
}

@media only screen and (max-width: 800px) {
  .buttons-in-document-workflow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #edit-in-document-workflow {
    width: 100%;
  }

  #download-in-document-workflow {
    width: 100%;
  }

  #unassign-in-document-workflow {
    margin-top: 35px;
    margin-bottom: 25px;
    width: 100%;
  }

  #add-note-button {
    width: 100%;
    margin-left: -0.4%;
    margin-bottom: 25px;
  }

  .tooltiptext-i {
    width: 100%;

    padding: 5px 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    line-height: 15px;
    font-size: 11px;

    z-index: 1;
    top: -88px;
    margin-left: -260px;
  }
}

.tooltip-i {
  position: relative;
  display: inline-block;
  margin-top: -10px;
}

/* Tooltip text */
.tooltiptext-i {
  width: 430px;
  visibility: hidden;
  background-color: #e3e3e3;
  color: black;
  text-align: center;
  padding: 5px 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  line-height: 15px;
  font-size: 11px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  top: -28px;
  margin-left: -260px;
}

.tooltip-i:hover .tooltiptext-i {
  visibility: visible;
}

.tooltip-i:hover .tooltip-i-level {
  visibility: visible;
}

#paytoAgentDropdown input,
#policyDropdown input {
  min-height: 36.59px;
}

.update-link {
  position: absolute;
  right: 45%;
  font-weight: 500;
  margin-right: 23px;
  margin-top: 1px;
  left: auto;
  /* position: absolute;
  left: 4%;
  font-weight: 500; */
}

.remove-link {
  margin-left: 1px;
  font-weight: 500;
}

.bar-custom {
  padding: 0 5px;
  margin-left: 53px;
}

.tooltip-item-h {
  line-height: 22px;
}

#drpReportsTo .p-inputtext {
  padding: 7.5px;
}

#suplementary-info.p-accordion .p-accordion-content,
#suplementary-info.p-accordion .p-accordion-header a,
#suplementary-info.p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  a {
  background-color: #edf0f5 !important;
  border: none !important;
  color: #000 !important;
}

#suplementary-info.p-accordion .p-accordion-header a .p-accordion-toggle-icon {
  color: #848484 !important;
}

/*#suplementary-info.p-accordion .p-accordion-header:not(.p-disabled) a:focus{box-shadow: none !important;}*/
#suplementary-info.p-accordion
  .p-accordion-header
  a
  .p-accordion-toggle-icon.pi-caret-right:before,
#suplementary-info.p-accordion
  .p-accordion-header
  a
  .p-accordion-toggle-icon.pi-caret-down:before {
  display: none;
}

#suplementary-info.p-accordion .p-accordion-header a > span {
  margin-left: -16px;
}

#no-file-id {
  margin-top: -20px;
}

/* ========REPORT VIEWER EDP PRODUCTION========= */
.tooltip-revenue-edp {
  position: relative;
  display: inline-block;
}

.edpToolTip {
  position: absolute;
  top: -72px;
  left: 135px;
}

.edpToolTip_product {
  position: absolute;
  top: -72px;
  left: 53px !important;
}

.tooltiptext-edp {
  visibility: hidden;
  width: 100px;
  background-color: #ebecf0;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  font-size: 10px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  font-weight: bold;
  margin-top: -98px;
  margin-left: 140px;
}

.tooltiptext-edp_product {
  visibility: hidden;
  width: 277px;
  background-color: #ebecf0;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  font-size: 10px;
  position: absolute;
  z-index: 1;
  font-weight: bold;
  margin-top: -78px;
  margin-left: 71px;
}

.tooltip-revenue-edp:hover .tooltiptext-edp {
  visibility: visible;
}

.tooltip-revenue-edp:hover .tooltiptext-edp_product {
  visibility: visible;
}

.main-year-o-year {
  display: flex;
  flex-direction: column;
}

.sub-main-class-1 {
  padding: 0 !important;
  margin: 0 !important;
}

.sub-main-class-2 {
  padding: 0 !important;
  margin: 0 !important;
}

.year-O-year {
  width: 100%;
  height: 30px;
}

.right-div-prod {
  color: darkblue;
  margin-top: 1.5em;
  font-size: 10px;
  margin-top: -5px !important;
}

@media only screen and (max-width: 1135px) {
  .first-container {
    width: 98.3% !important;
    overflow: hidden !important;
  }

  .second-container {
    width: 98.3% !important;
    overflow: hidden !important;
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .third-container {
    width: 98.3% !important;
    overflow-y: hidden !important;
  }

  .fourth-container {
    width: 98.3% !important;
    overflow: hidden !important;
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .download-edp {
    width: 100vw;
    /* border: 2px solid red; */
    margin-bottom: 5px;
    right: 5px;
  }
}

/* ========REPORT VIEWER EDP PRODUCTION========= */
.text-center-profile {
  text-align: center !important;
  position: relative;
}

.p-input-overlay-visible {
  visibility: visible !important;
}

.approve-btn {
  color: #005ae0;
  cursor: pointer;
}

.approve-btn:hover {
  text-decoration: underline;
  color: #33478a;
}

#source,
#importdefaultValue {
  width: 100%;
}

#btnImportAdminDelete.secondary-button {
  min-height: 30px;
}

#btnColumnDD {
  min-height: 30px !important;
}

#btnColumnDD .p-button-text {
  padding: 0 0.7em 0 1.7em !important;
}

.margin-14 {
  margin-left: 14px !important;
}

.showAllCheckbox {
  margin-top: 5px;
  margin-right: 3px;
}

#workflowDropdown .p-menu.p-menu-overlay,
#searchWorkflowDropdown .p-menu.p-menu-overlay {
  margin-top: 1px;
  margin-left: 0px;
}

#workflowDropdown .p-menuitem-text,
#workflowDropdown .p-menuitem-icon,
#searchWorkflowDropdown .p-menuitem-text,
#searchWorkflowDropdown .p-menuitem-icon,
#tasksSplit .p-menuitem-text,
#tasksSplit .p-menuitem-icon {
  color: #33478a;
}

#tasksSplit .p-menu.p-menu-overlay,
#tasksSplit .p-menu.p-menu-overlay {
  margin-top: 1px;
  margin-left: 20px;
}

#workflowDropdown .p-menuitem-text,
#workflowDropdown .p-menuitem-icon,
#searchWorkflowDropdown .p-menuitem-text,
#searchWorkflowDropdown .p-menuitem-icon,
#tasksSplit .p-menuitem-text:hover,
#tasksSplit .p-menuitem-icon:hover {
  color: #33478a;
  text-decoration: underline;
}

/* code for email chatbox */
.minimizable-web-chat-email > button.maximize:hover:not(:active) {
  background-color: #069;
  border-color: #069;
}

.minimizable-web-chat-email {
  min-height: 610px;
  height: auto;
}

.minimizable-web-chat-email > .chat-box {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 4px;
  box-shadow: 4px 3px 2px 0 rgba(0, 0, 0, 0.2);
  border: 4px solid #fff;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  min-width: 400px;
  /* position: unset; */
  top: 20px;
  width: 50%;
  height: 100%;
}

.minimizable-web-chat-email > .chat-box > header > button:focus:not(:active),
.minimizable-web-chat-email > .chat-box > header > button:hover:not(:active) {
  color: rgba(255, 255, 255, 0.6);
}

.minimizable-web-chat-email > .chat-box > header > button:focus:active {
  color: rgba(255, 255, 255, 0.4);
}

.minimizable-web-chat-email > .chat-box > .react-web-chat {
  flex: 1;
  max-height: 500px;
  overflow-y: auto;
}

.minimizable-web-chat-email > .webchat--css-wwslq-13crlv1 {
  background-color: rgb(255, 255, 255);
  height: 100%;
  width: 100%;
  z-index: 0;
}

.minimizable-web-chat-email > .chat-box > header {
  display: flex;
  background: rgb(51, 71, 138);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: space-between;
}

.minimizable-web-chat-email header .white-col {
  width: 50%;
}

.white-col {
  color: #fff;
  font-weight: bold;
  padding: 10px;
}

.minimizable-web-chat-email .webchat__send-box__main {
  width: 100%;
  padding: 10px;
  padding-bottom: 15px;
}

.minimizable-web-chat-email > .webchat__send-box .webchat__send-box__button {
  background-color: #33478a !important;
  width: 28px !important;
  height: 28px !important;
  border-radius: 50% !important;
  margin: 11px 17px 0 0 !important;
  cursor: pointer;
  position: relative;
  display: flex;
}

.minimizable-web-chat-email #nameDropdown {
  width: 100%;
  padding-right: 2em;
}

.minimizable-web-chat-email #nameDropdown .p-dropdown {
  display: block;
}

.minimizable-web-chat-email #nameDropdown .p-dropdown-label {
  padding: 0;
}

.email-type {
  width: 85%;
  background-color: #edf0f5 !important;
  margin-right: 10px !important;
}

.webchat__icon-button-email {
  background: #33478a !important;
  width: 28px !important;
  height: 28px !important;
  border-radius: 50% !important;
  margin: 0px 17px 0 0 !important;
  cursor: pointer;
  position: fixed;
  margin-left: 12px;
  border: none;
  color: #fff;
}

.webchat__send-icon-email {
  margin-left: -5px;
}

#nameDropdown.p-dropdown,
#nameDropdow.p-dropdown .p-dropdown-trigger {
  background: transparent;
  border: none;
}

#nameDropdown .p-dropdown-label.p-placeholder,
#nameDropdown .p-dropdown-trigger {
  background: transparent;
  color: #fff;
}

#nameDropdown .p-focus {
  box-shadow: none;
  -webkit-box-shadow: none;
}

#nameDropdown .p-dropdown-label {
  background: transparent;
  color: #fff;
}

.webchat__bubble__content-email-grey {
  border-color: rgb(230, 230, 230);
  border-radius: 0 5px 5px 5px !important;
  margin-left: 15px !important;
  position: relative;
  overflow: initial !important;
  background-color: #edf0f5 !important;
  margin-right: 0px;
  transition-duration: 0s;
  transition-property: margin-left, margin-right;
  word-break: break-word;
  padding: 10px;
  margin-top: 10px;
}

.webchat__bubble__content-email-grey:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid #edf0f5;
  border-left: 10px solid transparent;
  top: 0;
  left: -8px;
}

.minimizable-web-chat-email .p-dataview-content {
  border: none !important;
  height: 500px;
  overflow-y: auto;
  vertical-align: bottom !important;
  display: table-cell;
  overflow-x: hidden;
  width: 542px;
  background: transparent;
}

.message-list {
  height: 500px;
  overflow-y: auto;
}

.minimizable-web-chat-email
  .p-dataview.p-dataview-list
  > .p-dataview-content
  > div.p-grid
  > div {
  width: 51%;
  font-size: 12px;
  margin-left: 15px;
}

.minimizable-web-chat-email .p-dropdown-trigger-icon {
  margin-left: -4px !important;
}

.webchat--css-lztgt-bsfxty-email {
  color: rgb(118, 118, 118);
  font-family: Calibri, "Helvetica Neue", Arial, "sans-serif";
  /* font-size: 80%; */
  padding-top: 0 !important;
  padding-left: 2px !important;
}

.webchat--css-lztgt-111jw2m-email {
  color: transparent;
  height: 1px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  white-space: nowrap;
  width: 1px;
}

.webchat__bubble__content-email-blue {
  background: rgb(51, 71, 138);
  border-color: rgb(230, 230, 230);
  border-width: 1px;
  color: rgb(255, 255, 255);
  min-height: 38px;
  position: relative;
  overflow: initial !important;
  margin-right: 0px;
  transition-duration: 0s;
  transition-property: margin-left, margin-right;
  word-break: break-word;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px 0 5px 5px !important;
  margin-right: 15px !important;
  margin-left: 49% !important;
}

.mar-40 {
  margin-left: 49% !important;
  text-align: right;
  margin-right: 15px;
}

.webchat__bubble__content-email-blue:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid #33478a;
  border-right: 10px solid transparent;
  top: 0;
  right: -8px;
}

.minimizable-web-chat-email .p-inputtextarea {
  font-size: 11px;
  border: none;
  height: 30px;
}

.minimizable-web-chat-email .editor-buttons-panel {
  padding-bottom: 5px;
  border-top: 1px solid lightgray;
  padding-top: 5px;
}

.mid-text {
  text-align: center;
  color: rgb(118, 118, 118);
  font-family: Calibri, "Helvetica Neue", Arial, "sans-serif";
  padding: 10px;
}

.minimizable-web-chat-email .p-inputtextarea:enabled:focus {
  box-shadow: none;
}

.minimizable-web-chat-email.p-dropdown:not(.p-disabled):focus {
  box-shadow: none !important;
}

.minimizable-web-chat-email .pi-chevron-down:before {
  content: "\e906" !important;
}

.minimizable-web-chat-email .p-inputwrapper-focus.p-focus {
  box-shadow: none !important;
}

/* sms chatbox css end */
.divAlerrt {
  margin-top: -40px !important;
}

.divAlerrt26 {
  margin-top: -45px !important;
}

.margin-m10 {
  margin-top: -10px;
}

.icons-custom {
  color: #ccc;
  font-size: 1.5em !important;
}

.fa-comment-sms:before,
.fa-sms:before {
  content: "\f7cd";
}

#workRadio,
#homeRadio,
#othersRadio {
  margin-left: 7px;
}

.tooltip-contact {
  position: relative;
  display: inline-block;
}

.tooltip-contact .tooltiptext::after {
  content: "";
  position: absolute;
  top: 32px;
  left: 16.5%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ebecf0 transparent transparent transparent;
}

.tooltip-contact .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: #ebecf0;
  color: #000;
  text-align: left;
  padding: 5px;
  font-size: 10px;
  border: 1px solid #ebecf0;
  position: absolute;
  z-index: 9;
  font-weight: 400;
  margin-top: -35px !important;
  margin-left: -37px;
}

.tooltip-contact .tooltipw5 {
  width: auto;
  font-size: 13px;
  min-width: 130px;
}

.tooltip-contact:hover .tooltiptext {
  visibility: visible;
}

.tooltipw6 {
  min-width: 101px;
  width: auto !important;
  font-size: 13px !important;
}

.tooltipw4 {
  min-width: 89px;
  width: auto !important;
  font-size: 13px !important;
}

.tooltipw7 {
  min-width: 154px;
  width: auto !important;
  font-size: 13px !important;
}

.tooltipw8 {
  min-width: 140px;
  width: auto !important;
  font-size: 13px !important;
}

.tooltipw9 {
  min-width: 108px;
  width: auto !important;
  font-size: 13px !important;
}

.wid100 {
  width: 100% !important;
  margin: 0px !important;
}

.load-more {
  color: #506ac3;
  font-weight: bold;
  cursor: pointer;
  font-size: 12px;
}

.p-menuitem-text {
  text-decoration-skip-ink: none;
}

.range #cancelButton {
  margin-right: 0px !important;
}

.stick-appsettings {
  float: right;
  position: fixed;
  right: 25px;
  top: 147px;
  padding-top: 0;
}

.p-tree-filter-container {
  width: 50% !important;
}

.color-chat {
  background: white !important;
  opacity: 1;
}

.color-read-message {
  color: #33478a !important;
  text-decoration: none !important;
  padding: 5px;
  display: flex !important;
  padding-left: 13px;
}

.color-unread-chat {
  background: #33478a;
  opacity: 1;
}

.color-unread-message {
  color: #33478a !important;
  text-decoration: none !important;
  padding: 5px;
  display: flex !important;
}

.sms-dot {
  font-size: 10px;
  padding-top: 3px;
}

.sms-align {
  float: right;
  color: #33478a !important;
  vertical-align: text-top !important;
}

#leftfilters .p-tree-filter-container,
.p-tree-container {
  width: auto !important;
}

/* Revenue dashboard tooltip */
.tooltip-cp {
  position: relative;
  display: inline-block;
}

.tooltip-cp .tooltiptext::after {
  content: "";
  position: absolute;
  top: 42px;
  left: 103.5%;
  margin-left: -6px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #000;
}

.tooltip-cp .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: #000;
  color: #fff;
  text-align: left;
  padding: 5px;
  font-size: 10px;
  border: 1px solid #000;
  position: absolute;
  z-index: 1;
  font-weight: 400;
  margin-top: -40px !important;
  margin-left: -229px;
  opacity: 0.7;
}

.tooltip-cp .tooltipw5 {
  width: 175px;
  font-size: 13px;
  min-width: 130px;
  margin-left: 0px;
  left: -185px;
}

.tooltip-cp:hover .tooltiptext {
  visibility: visible;
}

.tooltip-cp.tooltip-cp-payee .payee-header {
  font-size: 14px;
}

/* Revenue dashboard tooltip */
.p-tree-filter-container,
.p-tree-container {
  width: 50% !important;
}

.p-rolepermission .p-tree-filter-container,
.p-tree-container {
  width: 72% !important;
}

.contactWarning .alert-warning,
.addressWarning .alert-warning,
.custom-report-warning .alert-warning,
.team-list .alert-warning {
  margin-top: 0px !important;
}

.team-list {
  padding-left: 15px;
  padding-right: 2px;
}

#hasMultipleHeader {
  margin-left: 10px;
}

/* Audit report start */
.step {
  position: relative;
  min-height: 1em;
  color: gray;
}

.step + .step {
  margin-top: 1.5em;
}

.step > div:first-child {
  position: static;
  height: 0;
}

.step > div:not(:first-child) {
  margin-left: 1.5em;
  padding-left: 1em;
}

.step.step-Active {
  color: #4285f4;
}

.step.step-Active .circle {
  background-color: #4285f4;
}

.step.step-Create .circle {
  background-color: #298d36;
}

.step.step-Update .circle {
  background-color: #4285f4;
}

.step.step-Delete .circle {
  background-color: #ff6a00;
}

/* Circle */
.circle {
  background: #4285f4;
  position: relative;
  width: 2em;
  height: 2em;
  line-height: 2em;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.15);
}

/* Vertical Line */
.circle:after {
  content: " ";
  position: absolute;
  display: block;
  top: 1px;
  right: 50%;
  bottom: 1px;
  left: 50%;
  height: 100%;
  width: 1px;
  transform: scale(1, 2);
  transform-origin: 50% -100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 0;
}

.rectangle {
  background: #4285f4;
  position: relative;
  width: 7em !important;
  height: 1.5em;
  line-height: 1.5em;
  border-radius: 10%;
  color: #fff;
  text-align: center;
  box-shadow: 0 0 0 3px #fff;
  margin-bottom: 2rem;
  margin-top: 27px;
}

.rectangle:after {
  content: " ";
  position: absolute;
  display: block;
  top: 1px;
  right: 50%;
  bottom: 1px;
  left: 15%;
  height: 100%;
  width: 1px;
  transform: scale(1, 2);
  transform-origin: 50% -100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 0;
}

.step:last-child .circle:after {
  display: none;
}

/* Stepper Titles */
.title-step {
  line-height: 1.5em;
  font-weight: normal;
  float: left;
}

.caption {
  font-size: 0.8em;
  margin-bottom: 4px;
  clear: both;
}

.detail-link {
  background-color: rgba(0, 0, 0, 0.25);
  color: white;
  width: 100px !important;
  margin: 0px;
  padding: 3px;
  line-height: 1px;
}

#auditReport .p-dataview-content {
  border: none !important;
}

#auditReportTable.p-accordion .p-accordion-header a,
#auditReportTable.p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  a {
  background-color: #edf0f5 !important;
  border: none !important;
  color: #000 !important;
  box-shadow: none !important;
}

#auditReportTable.p-accordion .p-accordion-content,
#auditReportTable .p-accordion-header .p-highlight {
  background-color: none !important;
  border: none !important;
  color: #000 !important;
  float: left !important;
}

#auditReportTable.p-accordion .p-accordion-header a .p-accordion-toggle-icon {
  color: #848484 !important;
}

#auditReportTable.p-accordion
  .p-accordion-header
  a
  .p-accordion-toggle-icon.pi-caret-right:before,
#auditReportTable.p-accordion
  .p-accordion-header
  a
  .p-accordion-toggle-icon.pi-caret-down:before {
  display: none;
}

#auditReportTable.p-accordion .p-accordion-header a > span {
  margin-left: -7px;
  margin-top: -67px;
}

#auditReportTable .p-accordion-header {
  width: 10px;
  height: 10px;
  float: left;
}

#auditReportTable.p-accordion .p-accordion-header a {
  width: 10px;
  height: 10px;
}

#userReport .p-autocomplete-input {
  height: 37px;
}

#auditReportTable
  .p-toggleable-content
  .p-toggleable-content-collapsed
  .p-toggleable-content-exit-done {
  float: left;
}

#auditReportTable .p-toggleable-content-enter-done,
#auditReportTable .p-toggleable-content-enter-active,
#auditReportTable .p-toggleable-content-exit-active {
  width: 100% !important;
  float: left !important;
}

/* Audit report end*/
.table-scroll {
  height: 400px;
  overflow: auto;
}

/* css for self service reports */
#tab-parent-custom .p-tabview-nav {
  background-color: #fff;
  border-radius: 3px;
  border-bottom: 3px solid #f5f6fa;
  margin-top: -2px;
}

#tab-parent-custom .p-tabview-panels,
.custom-tabview .app-tab.tab-opt2 .p-tabview-panels {
  border: none;
}

#tab-parent-custom .p-tabview-nav li.p-highlight a,
#tab-parent-custom .p-tabview-nav li.a {
  color: #006fac !important;
  background-color: #fff !important;
  /* border-bottom: 1.5px solid #006FAC !important; */
  font-weight: 700 !important;
}

#tab-parent-custom .p-tabview-nav li a,
#tab-parent-custom .p-tabview-nav li a:hover {
  color: #006fac !important;
  background-color: #fff !important;
  font-weight: 400 !important;
}

#tab-parent-custom .p-tabview-nav li.p-highlight a:hover,
.title-color {
  color: #006fac !important;
  background-color: transparent !important;
  font-weight: 700 !important;
}

.title-report {
  font-weight: 500;
  font-size: 32px;
  line-height: 38.4px;
  display: flex;
  align-items: center;
  color: #00296b;
  margin-left: 10px;
  width: 351px;
  display: flex;
  align-items: center;
  color: #00296b;
  margin-left: 10px;
}

.title-icon {
  color: #00296b;
  margin-left: 10px;
}

.fa-screwdriver-wrench:before,
.fa-tools:before {
  content: "\f7d9";
}

.fa-file-lock:before,
.fa-tools:before {
  content: "\e3a6";
}

.custom-tabview {
  background-color: #fff;
  border-radius: 3px;
}

.header-title-1 {
  color: #536c8f;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21.6px;
  display: flex;
  align-items: center;
}

/* #createCutomReport{margin-right: -83px;} */
.report-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 25px;
  gap: 25px;
  width: 100%;
  height: 300px;
  background: #ffffff;
  border-top: 3px solid #f5f6fa;
}

.report-no-content {
  padding-top: 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #434a51;
}

.custom-team-logo {
  background-color: #e6f1f7;
  border-radius: 100px;
  width: 150px;
  height: 150px;
}

.custom-logo {
  background-color: #e6eefb;
  border-radius: 100px;
  width: 174px;
  min-height: 174px;
  margin-bottom: 5px;
}

.wrench-color {
  fill: #4f9cc6;
  color: #4f9cc6;
  width: 93px;
  height: 83px;
  padding-top: 23px;
  padding-left: 32px;
}

.wrench-team-color {
  fill: #4f9cc6;
  color: #4f9cc6;
  width: 116px;
  height: 137px;
  padding-top: 23px;
  padding-left: 32px;
}

.report-new {
  width: 240px;
  height: 30px;
  /* font-family: 'Lato'; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #434a51;
  margin-bottom: 6px;
}

.report-btn {
  background: #006fac !important;
  border-radius: 3px !important;
  cursor: pointer;
  font-size: 16px !important;
  color: #fff;
  line-height: 19.2px;
  font-weight: 400;
}

.report-btn-disabled {
  background: #dfdedd !important;
  border-radius: 3px !important;
  pointer-events: none;
  border: none !important;
  font-size: 16px !important;
  color: #fff;
  line-height: 19.2px;
  font-weight: 400;
}

#createCustomReportNew {
  width: 200px;
  background: #0052ce;
}

.createCustomReportEn {
  background: #0052ce;
}

.createCutomReportDs {
  background: #dfdedd !important;
  border: none !important;
}

.standardRepo {
  height: 14px;
  width: 15px;
  fill: #006fac;
  margin-right: 5px;
}

.queueRepo {
  height: 22px;
  width: 20px;
  fill: black;
  margin-right: 5px;
  float: left;
}

.reportqueuewarning {
  height: 10px;
  font-weight: bold;
  fill: black;
  font-size: 11px;
  margin-top: -5px !important;
}

.wid30 {
  width: 30% !important;
}

.margin-tree {
  margin-top: -18px;
}

.no-border {
  border-radius: 0px !important;
  box-shadow: none !important;
}

.margin-top6 {
  margin-top: -6px;
}

.margin-left65 {
  margin-left: -65px;
}

.marginL16 {
  margin-left: -16px;
}

.selfReport-pad-right {
  padding-right: 0 !important;
}

.selfReport-split .p-button-text {
  display: none !important;
}

.selfReport-split .p-button {
  border-top: none !important;
  border-left: none !important;
  border-bottom: none !important;
}

.selfReport-split .p-button-icon-only {
  border: 1.5px solid #006fac !important;
  height: 35px;
  border-radius: 3px !important;
}

.selfReport-split
  .p-splitbutton.p-buttonset
  .p-button.p-splitbutton-menubutton {
  border-left: 1.5px solid #006fac !important;
}

#customReportTable .p-paginator-next,
#customReportTable .p-paginator-last {
  color: #848484 !important;
}

#customReportTable .app-table.p-datatable .p-datatable-thead > tr th:hover,
#customReportTable .p-datatable-thead > tr > th {
  background: #d3e7ea !important;
  border: none !important;
  color: #434a51;
  border-right: 0.5px solid #434a51 !important;
  border-bottom: 1px solid #dfdedd !important;
}

.wid99 {
  width: 99%;
}

.margin-left-6 {
  margin-left: 6px;
}

.disabledColumn {
  opacity: 0.5;
  pointer-events: none;
}

.customEnabled {
  border-radius: 3px;
}

.customDisabled {
  border-radius: 3px;
}

.marginLeft31 {
  margin-left: 31px;
}

.margin-top-15 {
  margin-top: 15px;
}

.wid98 {
  width: 98%;
}

.standard-report-label {
  /* font-family: 'Lato'; */
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
  color: #536c8f;
}

#reportFor .p-placeholder {
  color: #aaaaaa !important;
}

#reportFor .p-placeholder {
  color: #aaaaaa !important;
}

#custom-grid-split-menu {
  left: 81.3% !important;
}

.p-input-icon-left-cr {
  position: relative;
  display: inline-block;
  width: 100%;
  border: 1px solid #a6a6a6;
  border-radius: 3px;
}

.cr-search-icon {
  border-right: none;
  padding: .5rem;
  color: #a6a6a6 !important;
  margin-left: 7px;
}

.ac-cr-search-icon {
  padding-top: 9px;
}

.user-audit-report-title {
  font-weight: 500 !important;
  font-size: 32px !important;
  line-height: 38px !important;
  align-items: center !important;
  color: #00296b !important;
  padding-bottom: 0px !important;
}

.teams-report-title {
  font-weight: 500 !important;
  font-size: 32px !important;
  line-height: 38px !important;
  align-items: center !important;
  color: #00296b !important;
  padding-bottom: 0px !important;
}

.alert-title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  align-items: center;
  color: #434a51;
  float: left;
}

.alert-content {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  align-items: center;
  text-align: center;
  color: #434a51;
}

.content-padding50 {
  padding: 50px 0px;
}

#tab-parent-custom .tab-left-border:before {
  content: "";
  position: absolute;
  left: 0%;
  top: 10%;
  height: 28px;
  width: 50%;
  border-left: 1.5px solid #d8dbe0;
}

#tab-parent-custom .p-tabview-nav li.p-highlight:before {
  content: "";
  position: absolute;
  left: 11px;
  bottom: 0;
  height: 15px;
  width: 80%;
  border-bottom: 1.5px solid #006fac;
}

body #customReportTable .p-datatable-tbody > tr:focus > td {
  box-shadow: none !important;
}

body #customReportTable .p-datatable-tbody > tr:focus {
  transition: none !important;
  box-shadow: none !important;
}

body #customReportTable .p-datatable-tbody > tr > td {
  transition: none !important;
  box-shadow: none !important;
}

#tab-parent-custom_header_1 {
  border: none !important;
}

#tab-parent-custom_header_2 {
  border: none !important;
}

#tab-parent-custom_header_0 {
  border: none !important;
}

.report-queue-alert {
  padding: 11px 7px 11px 4px !important;
}

.cr-justify-center {
  justify-content: space-between;
}

.cr-justify-left {
  justify-content: left;
}

.mobile-custom-table,
.mobile-custom-table .p-inputtext,
.mobile-custom-table .p-dropdown-trigger,
.mobile-custom-table .p-dropdown-item.p-highlight {
  background-color: #006fac !important;
}

.cr-ml-8 {
  margin-left: -8px;
}

.cr-eye,
.cr-funnel,
.cr-ellipse {
  display: inline-block;
  width: 14px;
}

.cr-ellipse {
  padding-left: 5px;
  display: inline-block;
}

.custom-report-add-logo {
  width: 128px;
  height: 120px;
}

.arrow-up-left {
  height: 103px;
}

.report-new-empty {
  width: 270px;
}

#filterData .p-dataview-content {
  border: none !important;
  margin-top: 20px;
  min-height: 160px;
  overflow-y: auto;
  max-height: 300px;
}

.width-auto {
  width: auto;
}

.rt-report-edit {
  display: inline-flex;
}

.fill-p {
  fill: #434a51;
  height: 12px;
  padding-top: 3px;
}

.disp-flex {
  display: flex;
}

body .cr-table .p-datatable-tbody > tr > td:first-child {
  border-left: none !important;
}

body .userTeamTable .p-datatable-tbody > tr > td:first-child {
  border-left: none !important;
}

.TeamListViewTable #customReportTable .p-datatable-tbody > tr > td:last-child {
  border-right: none !important;
  border-left: none !important;
  padding-bottom: 25px !important;
}

#customReportTable .app-table.p-ndatatable .p-datatable-thead > tr th:hover {
  border-right: none !important;
}

.Toastify__progress-bar {
  display: none !important;
}

.Toastify__toast-icon {
  margin-right: 30px !important;
}

.Toastify__toast {
  cursor: default !important;
  background: linear-gradient(
    to right,
    #6cf8cf 18%,
    white 0%,
    white 150%,
    white 30%
  ) !important;
}

.Toastify__toast-container {
  margin-top: 60px !important;
}

body #customReportTable .p-datatable-tbody > tr > td .custom-report-row {
  padding: 10px !important;
  background: white !important;
  border: none !important;
  border: 0.1px solid #dfdedd;
  /* font-family: 'Lato'; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  align-items: center;
  color: #434a51;
}

body #customReportTable .p-datatable-thead > tr > th {
  position: sticky;
  top: 0;
  position: -webkit-sticky;
  z-index: 2;
}

/* css for self service reports */

#customReportTable td.custom-report-row {
  background-color: white !important;
}

#customReportTable tr:hover td {
  background-color: #ededee !important;
}

.custom-report-menuspan > span > span {
  content: url("../src/fonts/ellipsisSolid.svg") !important;
  padding-bottom: 3px;
}

.custom-report-menu-active > span > span {
  content: url("../src/fonts/ellipsis.svg") !important;
  padding-bottom: 3px;
}

.PeopleGroup {
  content: url("../src/fonts/people-group.svg") !important;
  height: 17px !important;
  width: auto;
  margin-left: -3px;
}

#reportName::placeholder {
  font-style: italic;
}

.report-desc-limit {
  font-family: "Lato";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  color: #aaaaaa;
  margin-top: -6px;
}

#customReportTable .p-datatable .p-datatable-tbody > tr > td {
  background: inherit;
  border: 1px solid #c8c8c8;
  /* padding: .429em .857em; */
}

#customReportTable .app-table.p-datatable .p-datatable-tbody > tr > td {
  /* padding: .4em .857em; */
  font-size: 13px;
  color: #142933;
  border: 0.5px solid #d0d2d9;
}

.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 94vh;
}

.flex-item1 {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  width: 300px;
}

.flex-item2 {
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  margin-right: 0px;
  padding-left: 0px;
}

.mr-3 {
  margin-right: 3px;
}

.margin-right-14 {
  margin-right: 14px !important;
}

body .app-table.p-datatable .p-datatable-thead > tr th:hover,
#customReportTable .p-datatable-thead > tr > th .margin-right-14 {
  margin-right: 14px !important;
}

/* css for self service reports */

#btnAddcreareTeam.p-button.primary-button,
#btnAddcreareTeam .p-button.primary-button:enabled:hover,
#btnAddcreareTeam .p-button.primary-button:enabled:focus {
  background: #006fac;
  border: 1px solid #006fac;
}

#btnAddcreareTeam {
  border-radius: 3px !important;
}

#saveButtonTeam {
  border-radius: 3px !important;
  min-height: 32px;
}

.ml-3 {
  margin-left: 3px;
}

.team-th-bc {
  background-color: #d3e7ea !important;
  border-right: 0.5px solid black !important;
}

#team-list-autocomplete
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item:hover {
  font-weight: normal !important;
}

#team-list-autocomplete {
  border: none;
  width: 86%;
  vertical-align: top;
  margin-left: -2px;
}

#team-list-autocomplete .p-autocomplete-input,
#team-list-autocomplete .p-inputtext:enabled:focus {
  border: none !important;
  box-shadow: none;
  margin: 0px;
  padding: 0px;
}

#team-list-autocomplete
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item,
#team-list-autocomplete
  .autocomplete-panel
  .p-autocomplete-items
  li:nth-child(odd) {
  border: 0 none !important;
  background-color: #fff !important;
}

#team-list-autocomplete
  .p-autocomplete-panel
  .p-autocomplete-items
  li:first-child {
  border-bottom: 1px solid #a6a6a6 !important;
  pointer-events: none;
}

#team-list-autocomplete .p-autocomplete-panel .p-autocomplete-items li:hover {
  background-color: #ededee !important;
  color: #006fac !important;
}

#team-list-autocomplete .p-autocomplete-panel {
  left: -42px !important;
  width: 116.7%;
  margin-top: 2px;
}

#team-list-autocomplete .p-autocomplete-panel .p-autocomplete-items li .header {
  background-color: #fff !important;
  pointer-events: none;
}

#team-list-autocomplete
  .p-autocomplete-panel
  .p-autocomplete-items
  li
  .header:hover {
  color: #000;
}

#team-list-autocomplete .p-autocomplete-panel::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: white;
}

#team-list-autocomplete .p-autocomplete-panel::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: lightgray;
}

#team-list-autocomplete .p-autocomplete-panel::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
}

.custom-datatable .p-datatable-scrollable-body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: white;
}

.custom-datatable .p-datatable-scrollable-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: lightgray;
}

.custom-datatable .p-datatable-scrollable-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
}

.custom-datatable .p-datatable-scrollable-header-box {
  margin-right: 0px !important;
}

.team-alert-warning {
  color: #856404;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.team-alert-warning .team-alert-message {
  color: #856404;
  background-color: #fff;
  width: 94%;
  padding: 13px;
  color: #000;
  font-size: 12px;
}

.queueRepo-warning {
  height: 60px;
  width: 71px;
  fill: black;
  margin-right: 5px;
  float: left;
  background-color: #ffd390;
  padding: 10px;
}

.team-alert-warning .p-team-close-button {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 36px;
  top: 205px !important;
  transform: translateY(-17px);
  color: #000;
  width: 18px !important;
  font-size: 10px;
}

.team-alert-warning button:focus,
button:focus-visible {
  outline: none !important;
}

.team-alert-warning .p-button:enabled:hover {
  background: none !important;
  color: #000;
  border: none !important;
}

.form-group .p-inputtext.highlight-red-border {
  border: 1px solid red !important;
}

#customReportTree ul.p-treenode-children {
  margin-top: -5px !important;
}

/**/
.custom-datatable .p-paginator-next,
#customReportTable .p-paginator-last {
  color: #848484 !important;
}

.custom-datatable .p-datatable-thead > tr > th {
  background: #d3e7ea !important;
  border: none !important;
  color: #434a51;
  border-right: 0.5px solid #434a51 !important;
  border-bottom: 1px solid #dfdedd !important;
}

.custom-datatable .p-datatable-thead > tr > th:last-child,
.custom-datatable .p-datatable-tbody > tr > td:last-child {
  border-left: none !important;
  border-right: none !important;
}

.custom-datatable
  .p-datatable-thead
  > tr
  > th:not(:first-child):not(:last-child),
.custom-datatable
  .p-datatable-tbody
  > tr
  > td:not(:first-child):not(:last-child) {
  border-right: none !important;
}

.custom-datatable .p-datatable-tbody .close-icon {
  font-size: 12px;
}

.custom-datatable .p-sortable-column-icon,
.custom-datatable
  .p-sortable-column:not(.p-highlight):hover
  .p-sortable-column-icon {
  color: #434a51;
}

.custom-datatable .pi-sort-alt:before {
  color: #434a51;
}

body .custom-datatable .p-datatable-tbody > tr > td .custom-report-row {
  padding: 10px !important;
  background: #ffffff !important;
  border: none !important;
  border: 0.1px solid #dfdedd;
  /* font-family: 'Lato'; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  align-items: center;
  color: #434a51;
  vertical-align: top !important;
}

.custom-datatable .pi-sort-amount-up-alt:before {
  content: "\e91c";
  color: #006fac;
}

.custom-datatable .pi-sort-amount-down:before {
  content: "\e919";
  color: #006fac;
}

.custom-datatable .p-paginator-current {
  /* font-family: 'Lato'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  letter-spacing: 0.06em;
  color: #434a51;
}

.custom-datatable
  .p-paginator
  .p-paginator-pages
  .p-paginator-page.p-highlight {
  background: #d3e7ea;
  border-width: 0.5px 0.5px 0.5px 0px;
  border-style: solid;
  border-color: #dfdedd;
  color: #434a51;
}

.custom-datatable .p-paginator-right-content {
  /* font-family: 'Lato'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  letter-spacing: 0.06em;
  color: #434a51;
}

.custom-datatable .p-paginator .p-paginator-pages .p-paginator-page.p-highlight,
.custom-datatable
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight {
  background-color: #d3e7ea !important;
  color: #434a51;
}

.custom-datatable .p-paginator .p-paginator-right-content {
  margin-top: 9px;
}

body .custom-datatable .p-datatable-tbody > tr:focus > td {
  box-shadow: none !important;
}

body .custom-datatable .p-datatable-tbody > tr:focus {
  transition: none !important;
  box-shadow: none !important;
}

body .custom-datatable .p-datatable-tbody > tr > td {
  transition: none !important;
  box-shadow: none !important;
}

.custom-datatable .app-table.p-datatable .p-datatable-thead > tr th:hover,
.custom-datatable .p-datatable-thead > tr > th {
  background: #d3e7ea !important;
  color: #434a51 !important;
}

.custom-datatable .p-datatable-thead > tr > th {
  border: none !important;
  border-right: 0.5px solid #434a51 !important;
  border-bottom: 1px solid #dfdedd !important;
}

body .custom-datatable .p-datatable-tbody > tr > td:first-child {
  border-left: none !important;
}

body .custom-datatable .app-table.p-datatable .p-datatable-tbody > tr:hover,
.custom-datatable .app-table.p-datatable .p-datatable-tbody > tr td:hover {
  background-color: transparent !important;
}

.custom-datatable .p-datatable-scrollable-body {
  overflow: overlay !important;
}

@media screen and (min-width: 1330px) {
  .margin-right-14 {
    margin-right: 21px !important;
  }

  .team-no-content {
    margin-left: 0 !important;
  }

  .cr-no-report {
    margin-left: -20px !important;
  }

  .cr-new-report {
    margin-left: -9px !important;
  }

  .report-new {
    width: 240px;
    height: 30px;
    /* font-family: 'Lato'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #434a51;
    margin-left: -120px;
  }

  .teams-new {
    margin-left: 0px !important;
  }

  .custom-report-add-logo {
    width: 128px;
    height: 120px;
    margin-left: -120px;
  }
}

/* media query end */
#TeamsCustomReport {
  border: none;
  width: 84%;
  padding-bottom: 7px;
  padding-left: 0;
  vertical-align: bottom;
}

.custom-table-menu-list-last {
  position: absolute !important;
  top: -55px;
}

.userTeamTable .p-menu .p-menuitem-link:hover,
.userTeamTable .p-menubar .p-menuitem-link:hover,
.userTeamTable .p-tieredmenu .p-menuitem-link:hover,
.userTeamTable .p-contextmenu .p-menuitem-link:hover,
.userTeamTable .p-megamenu .p-menuitem-link:hover,
.userTeamTable .p-slidemenu .p-menuitem-link:hover {
  background-color: #eaeaea;
  margin: 5px;
  padding: 0.374em 0.501em;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word !important;
  white-space: pre-wrap !important;
  -moz-white-space: pre-wrap !important;
}

.team-name-search.p-input-icon-left-cr:has(.p-inputtext:enabled:focus) {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
  -moz-box-shadow: 0 0 0 0.2em #8dcdff;
  box-shadow: 0 0 0 0.2em #8dcdff;
  border-color: #007ad9;
}

.team-name-search .p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0px;
}

.team-user-add-button
  .p-button.primary-button.report-btn-disabled:enabled:focus {
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

body .p-dialog .p-dialog-content {
  border-radius: 0px 0px 3px 3px;
}

.team-user-temp {
  margin-top: 2px;
  padding: 0px;
  padding-left: 7px;
}

.policy-error .color-danger {
  margin-top: 4px;
}

@font-face {
  font-family: "Lato";
  src: url("../src/fonts/Lato-Regular.woff") format("woff");
  font-weight: 100;
}

body .agent-contract-task-create-modal td,
body .agent-contract-task-create-modal td a,
body .agent-contract-task-create-modal td span a,
body .agent-contract-task-create-modal td span,
body .agent-contract-task-create-modal .p-column-title,
body
  .agent-contract-task-create-modal
  .document-attach-autosuggestion-header
  .p-col-align-center
  span,
body
  .agent-contract-task-create-modal
  .document-attach-autosuggestion-header
  .p-col-align-center,
.layout-wrapper .layout-sidebar ul li span {
  font-family: "Lato";
}

.margin-right-0 {
  margin-right: 0px;
}

.cs-dialog .alert-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  color: #333333;
}

.cs-dialog .p-dialog-content .p-grid .alert-content {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  color: #333333;
  text-align: left;
  padding-left: 1%;
}

.cs-dialog {
  width: 49vw;
}

.cs-dialog .content-padding50 {
  padding: 20px 0 50px 0;
}

.cs-dialog .ssr-action-btns {
  margin: 0px;
  float: right;
}

.ssr-action-btns {
  margin-bottom: 30px;
}

.cs-cancel-btn {
  width: 89px !important;
  height: 33px !important;
  background: #ffffff;
  border: 0.5px solid #33478a;
  border-radius: 3px;
}

.cs-ok-btn {
  width: 89px !important;
  height: 33px !important;
  background: #33478a;
  border-radius: 3px;
}

.agentContractRadio > label.p-checkbox-label {
  margin-right: 10px;
}

.production-breakdown-table {
  margin: 30px 0px 0px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}

#showActiveContract .p-checkbox-box {
  height: 11px;
  width: 11px;
  border-radius: 2px;
}

#showActiveContract .p-checkbox-icon {
  font-size: 6px;
  padding-top: 2.5px;
  font-weight: bold;
}

#showActiveContract {
  width: 9px;
  height: 25px;
  padding-top: 4px;
}

.cs-terminate {
  width: 47vw;
}

.agent-policy-btns {
  padding-left: 0px;
  padding-right: 5px;
}

.pp-showall {
  margin-bottom: 15px !important;
}

#showAllCb .p-checkbox-box {
  height: 16px;
  width: 16px;
}

#si.p-component:disabled {
  opacity: 0.5 !important;
  background: none !important;
}

body
  .contractedAgentProductColor
  .app-table.p-datatable
  .p-datatable-tbody
  > tr.lightblue:hover,
body
  .contractedAgentProductColor
  .app-table.p-datatable
  .p-datatable-tbody
  > tr.lightblue
  td:hover,
body
  .contractedAgentProductColor
  .app-table.p-datatable
  .p-datatable-tbody
  > tr.lightblue.p-highlight {
  background-color: #a1dcf2 !important;
}

.PrincipalAgentDeleteIcon {
  content: url("../src/fonts/trash-can.svg") !important;
  height: 14px !important;
  width: 14px !important;
  font-weight: 900;
  text-align: center;
  left: 0.6em !important;
}

body .principalagent-delete .p-button.secondary-button {
  height: 32px;
  width: auto;
  min-width: 85px;
}

.agent-profile-endDate {
  width: "auto";
  background-color: "#334781";
}

#si {
  min-height: 36.6px !important;
}

.agent-notes-carrier .p-dropdown-panel {
  max-width: 20%;
}

.mw190 {
  min-width: 20.8%;
}

.mw198 {
  min-width: 10%;
}

@media screen and (min-width: 1270px) and (max-width: 1422px) {
  .agent-address-modal {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .mw190 {
    min-width: 20% !important;
  }

  .mw198 {
    min-width: 20% !important;
  }
}

.agent-note-clone:before {
  content: url("../src/fonts/agentnoteclone.svg") !important;
  margin-left: -1px;
}

.agent-note-ping:before {
  content: url("../src/fonts/agentnoteping.svg") !important;
  margin-left: -1px;
}

.agent-note-ping {
  color: #33478a;
  font-weight: 300;
  font-size: 14px;
  width: 14px;
  height: 30px;
}

.agent-note-ping-view:before {
  content: url("../src/fonts/agentnotepinunpin.svg") !important;
  margin-left: -1px;
}

.agent-note-ping-view {
  color: #33478a;
  width: 16px;
  height: 16px;
  float: right;
}

.agent-note-ping-views {
  padding-bottom: 20px !important;
}

.agent-note-ping-view-user {
  padding-top: 20px !important;
}

.agent-note-pane {
  margin-left: 7px;
  margin-right: 7px;
  margin-bottom: 10px;
}

.agent-note-add-save {
  margin-top: 10px;
  margin-bottom: 10px;
}

body #chkPinNote .p-checkbox-box .p-checkbox-icon {
  font-size: 9px !important;
  color: #ffffff !important;
  padding-top: 4px;
  font-weight: 900;
}

body #chkPinNote .p-checkbox-box {
  width: 18px !important;
  height: 18px !important;
  background-color: #ffffff;
  font-size: 16px !important;
  font-weight: 900;
}

body #chkPinNote .p-checkbox-box.p-highlight {
  background-color: #0052ce !important;
}

.agent-note-pinnote {
  color: #33478a;
  font-weight: 400;
  font-size: 14px;
}

.agent-note-pin-dialog {
  font-size: 24px !important;
  color: #434a51 !important;
  font-weight: 400;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
}

.agent-note-pin-dialog-buttons {
  text-align: center;
  margin-bottom: 40px;
  font-family: Lato !important;
}

.agent-note-pin-dialog-buttons #agentNotePinCancel {
  width: 137px;
  border-radius: 3px !important;
  margin-right: 25px !important;
  text-align: center !important;
  border-color: #0052ce !important;
  background-color: #ffffff !important;
  height: 32px;
}

.agent-note-pin-dialog-buttons #agentNotePinCancel .p-button-text {
  padding: 7px 5px 5px 9px !important;
  color: #0052ce !important;
  font-weight: 700;
  font-size: 16px;
}

.agent-note-pin-dialog-buttons #agentNotePinCancel span {
  font-family: Lato !important;
}

.agent-note-pin-dialog-buttons #agentNotePinReplace:hover {
  background-color: #2175f4 !important;
  border-color: #2175f4 !important;
  border-radius: 3px !important;
}

.agent-note-pin-dialog-buttons #agentNotePinReplace {
  width: 137px;
  height: 32px;
  background-color: #0052ce !important;
  border-color: #0052ce !important;
  border-radius: 3px !important;
}

.agent-note-pin-dialog-buttons #agentNotePinReplace:hover {
  background-color: #2175f4 !important;
  border-color: #2175f4 !important;
  border-radius: 3px !important;
}

.agent-note-pin-dialog-buttons #agentNotePinReplace .p-button-text {
  padding: 5px 5px 5px 9px !important;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  font-family: Lato !important;
}

.agent-note-pin-dialog-buttons #agentNotePinReplace span {
  font-family: Lato !important;
}

.agent-note-pin-dialog-buttons .agent-note-pin-dialog-buttons-pin {
  left: 0%;
  right: 0%;
  top: -0.5px;
  bottom: 0%;
  border-color: #ff4444 !important;
  background: #ff4444 !important;
  border-radius: 3px !important;
  border: 1px solid #ff4444;
  width: 137px;
  height: 32px;
  border-radius: 3px;
}

.agent-note-pin-dialog-buttons #agentNotePinReplace .p-button-text {
  padding: 5px 5px 5px 9px !important;
}

.agent-note-pin-confirmAlert-Custom
  #agent-note-pin-unpin-dialog
  .pi-times:before {
  color: #0052ce;
  font-weight: 300;
  font-size: 20px;
}

.agent-note-pin-confirmAlert-Custom #agent-note-pin-unpin-dialog_header {
  font-size: 24px;
  font-weight: 400;
  color: #002d72;
  font-family: Lato !important;
}

.openSansFam {
  font-family: "Open Sans" !important;
}

.no-documents-message {
  border: 1px dashed #142933;
  text-align: center;
  border-radius: 3px;
  color: #333333;
  font-size: 10px;
  min-height: 84px;
  padding-bottom: 36px;
  padding-top: 36px;
  margin-top: -13px;
  margin-bottom: 43px;
}

.document-attachments-title {
  font-weight: bold;
}

.document-attachments ul {
  margin: 0px;
  padding: 0px;
}

.document-attachments ul li {
  margin: 0px;
  padding: 0px 0px 10px 20px;
}

.document-attachments-task ul li {
  padding-left: 0px;
}

.document-attachments-task ul li a span {
  color: #506ac3;
}

.agent-doc-upload-display {
  padding-top: 10px;
  padding-bottom: 15px;
  padding-left: 30px !important;
}

.agent-doc-upload-msg-display {
  font-size: 13px !important;
  font-family: "Open Sans" !important;
  width: 625px !important;
  background-color: #fff3cd !important;
  height: 40px !important;
  padding-top: 10px !important;
  padding-left: 10px !important;
  border-radius: 3px !important;
  color: #856404 !important;
}

body .agent-state-license-doc-table .p-datatable-thead > tr > th {
  max-height: 33px !important;
  min-height: 33px !important;
}

.agent-state-license-doc-table {
  margin-top: -13px;
}

.document-attach-text .p-inputtext {
  font-size: 13px !important;
}

.document-attach-autosuggestion-header {
  font-weight: 600;
  color: #142933;
}

.document-attachment-list-table
  .app-table.p-datatable
  .p-datatable-tbody
  > tr
  > td {
  color: #333333;
}

.document-attachment-list-table a,
.document-attachments ul li a {
  color: #506ac3;
}

.document-attachment-list-table .p-button.secondary-button.document-delete-btn {
  color: #33478a;
}

.agent-state-license-auto #txtDocumentAutoComplete {
  color: #142933;
  font-weight: 400;
  font-size: 13px;
}

.agent-state-license-auto #txtDocumentAutoComplete::placeholder {
  color: #142933;
  font-weight: 400;
  font-size: 13px;
  opacity: 0.5px;
}

.agent-state-license-auto #txtDocumentAutoComplete {
  color: #142933;
  font-weight: 400;
  font-size: 13px;
}

.agent-state-license-auto
  .document-attach-autosuggestion-header
  .p-col-align-center {
  font-size: 11px !important;
  font-weight: 600 !important;
  color: #142933 !important;
  padding-top: 7px;
}

.agent-state-license-doc-table .agent-state-license-doc-link {
  color: #506ac3;
}

.agent-state-license-doc-table .agent-state-license-doc-link:hover {
  color: #506ac3 !important;
}

.agent-state-license-doc-table .fa-trash:before {
  margin-left: 5px !important;
}

body
  .agent-state-license-auto
  #txtDocumentAutoComplete
  .p-input-overlay-visible
  .p-autocomplete-list
  .p-autocomplete-list-item {
  border-bottom: 0px solid lightgray !important;
}

.agent-state-license-auto {
  position: relative;
}

.agent-doc-SearchIcon {
  position: absolute;
  margin-left: -31px !important;
  font-size: 14px !important;
  margin-top: -4px !important;
  vertical-align: bottom;
  color: #142933 !important;
  font-weight: 300 !important;
  right: 4px;
  padding: 13px 9px 4px 0px;
  opacity: 0.6;
}

.agent-doc-submit-to-wrapper {
  display: flex;
}

.agent-doc-submit-to-grid {
  margin-bottom: -11px;
}

.agent-doc-submit-to {
  padding-top: 0px;
}

.agent-doc-radio-btns {
  margin-top: 5px;
}

.agent-state-license-auto #txtDocumentAutoComplete .p-autocomplete-loader {
  display: none !important;
}

.agent-manage-buttons {
  margin-top: 40px;
  margin-bottom: 6px;
  margin-left: 22%;
  display: flex !important;
}

#agent-manage-back-btn {
  background-color: #fff !important;
  color: #0052ce !important;
  border: 1px solid #0052ce;
  font-weight: 700 !important;
  border-radius: 3px !important;
  margin-right: 40px !important;
}

.agent-manage-alert-modal .p-dialog-content {
  padding-top: 33px !important;
  border-top: 0.5px solid #dfdedd !important;
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.agent-manage-alert-modal #txtMessage .p-editor-toolbar {
  border: 0.5px solid #dfdedd !important;
  border-bottom: 0px solid #dfdedd !important;
  background-color: transparent !important;
  border-radius: 4px 4px 0px 0px !important;
  padding-top: 3px;
  padding-bottom: 0px;
}

.agent-manage-alert-modal #txtMessage .p-editor-content {
  border: 0.5px solid #dfdedd !important;
  border-top: 0px solid #dfdedd !important;
  border-radius: 4px !important;
  border-radius: 0px 0px 4px 4px !important;
  max-height: 145px !important;
}

.agent-manage-alert-modal #txtMessage .ql-editor.ql-blank::before {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #aaaaaa !important;
  font-style: normal !important;
  margin-left: -4px;
  font-family: Lato !important;
}

.agent-manage-buttons button {
  width: 135px !important;
  height: 31px !important;
}

.agent-manage-buttons button .p-button-text {
  font-size: 16px !important;
  font-weight: 700 !important;
}

.agent-manage-alert-modal #agent-manage-alert-dialog-id_header {
  font-size: 24px !important;
  color: #002d72 !important;
  font-family: Lato !important;
  font-weight: 400 !important;
}

.agent-manage-alert-modal .p-dialog-titlebar-close-icon {
  display: none !important;
}

.manage-alert-icon {
  content: url("../src/fonts/manage-alert-icon.svg") !important;
}

.agent-manage-save-btn-disable {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

.agent-manage-save-btn-disable .p-button-text {
  font-weight: 700 !important;
  font-size: 16px !important;
}

.agent-manage-alert-modal
  .agent-manage-alert
  #txtMessage
  .p-editor-content
  .ql-editor {
  max-height: 180px;
}

.agent-manage-alert-modal
  #txtMessage
  .p-editor-content
  .ql-editor::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
}

.agent-manage-alert-modal
  #txtMessage
  .p-editor-content
  .ql-editor::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: white;
}

.agent-manage-alert-modal
  #txtMessage
  .p-editor-content
  .ql-editor::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: lightgray;
}

.agentAlertTooltip {
  position: relative;
  display: inline-block;
}

.agentAlertInfoTooltip:hover .agentAlerttooltiptextSave {
  visibility: visible;
}

.agentAlertInfoTooltip .agentAlerttooltiptextSave {
  visibility: hidden;
  width: 300px;
  background-color: #434a51;
  z-index: 99899 !important;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 97% !important;
  margin-left: -17.9em !important;
  text-align: start;
  padding-left: 10px;
  font-size: 12px;
  padding: 12px;
  font-weight: 400;
}

.agentAlertInfoTooltip .agentAlerttooltiptextSave::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 48% !important;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #434a51 transparent;
}

.agent-alert-text-error .agentAlertCounter {
  color: #ff4444 !important;
  font-weight: 400;
}

.agentAlertCounter {
  color: #000000 !important;
  font-size: 12px;
  position: absolute;
  font-weight: 300;
  font-family: Lato !important;
  right: 3.5%;
  margin-top: -16px;
  margin-right: 30px;
}

.agent-manage-alert-modal .p-dialog-titlebar-icons {
  display: none !important;
}

.agent-manage-alert-modal .ql-toolbar.ql-snow .ql-picker-label {
  color: #434a51 !important;
  font-size: 12px !important;
  font-weight: 300 !important;
}

.agent-clear-alert {
  color: #0052ce;
  font-size: 14px;
  font-weight: 400;
  float: right;
  margin-top: -20px;
  cursor: pointer;
}

.agent-clear-alert-hidden {
  display: none;
}

#CMToaster .Toastify__close-button > svg {
  content: url("./fonts/ssrClose.svg");
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4%;
}

#CMToaster {
  cursor: default !important;
  background: linear-gradient(
    to right,
    #d8f5d9 62px,
    white 0%,
    white 150%,
    white 30%
  ) !important;
  max-width: 370px;
  margin-left: -58px;
}

#CMToaster .Toastify__close-button--light {
  color: #0052ce;
  font-weight: 400;
  opacity: 1;
}

#CMToaster .Toastify__toast-body.ssrToasterContainerBody > div:last-child {
  color: #434a51 !important;
  font-weight: 400 !important;
  font-size: 12px;
}

#CMToaster .Toastify__toast-body > div:last-child {
  max-width: 76% !important;
  margin-left: 11px;
}

#CMToaster .Toastify__toast-body > div:first-child {
  margin-left: 6px;
  margin-top: -6px;
}

.ssrToasterContainer #CMToaster .Toastify__close-button > svg {
  content: url("./fonts/ssrClose.svg");
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4%;
}

.ssrToasterContainer #CMToaster {
  margin-top: 12px;
  cursor: default !important;
  background: linear-gradient(
    to right,
    #d8f5d9 18%,
    white 0%,
    white 150%,
    white 30%
  ) !important;
}

.ssrToasterContainer .Toastify__close-button--light {
  color: #434a51;
  opacity: 1;
}

#CMToaster .Toastify__toast-body.ssrToasterContainerBody > div:last-child {
  color: #434a51 !important;
  font-weight: 400 !important;
  font-size: 12px;
}

.agent-note-task
  .ql-snow
  .ql-picker:not(.ql-color-picker):not(.ql-icon-picker)
  svg,
.agent-manage-alert
  .ql-snow
  .ql-picker:not(.ql-color-picker):not(.ql-icon-picker)
  svg {
  left: auto !important;
  right: auto !important;
}

.agent-manage-alert .ql-toolbar.ql-snow .ql-picker.ql-font {
  width: auto !important;
  margin-right: 10px;
}

.agent-manage-alert .ql-toolbar.ql-snow .ql-picker.ql-header {
  width: auto !important;
  margin-right: 10px;
}

.agent-manage-alert button.ql-italic {
  margin-left: -13px !important;
  margin-right: -10px !important;
}

.agent-manage-alert button.ql-underline {
  margin-right: -16px !important;
}

.agent-manage-alert button {
  font-size: 12px !important;
  font-weight: 300 !important;
  color: #434a51 !important;
}

.agent-manage-alert button svg {
  height: 12px !important;
  font-weight: 300 !important;
  color: #434a51 !important;
}

.agent-manage-alert-view div {
  font-size: 16px !important;
}

.agent-manage-alert-view {
  border: 0.5px solid #dfdedd;
  border-radius: 4px;
  height: 244px;
  overflow: auto;
  padding: 12px;
  padding-top: 0px !important;
}

.agent-manage-alert-view-modal .p-dialog-content {
  padding-top: 20px !important;
  border-top: 0px solid #dfdedd !important;
  padding-left: 48px !important;
  padding-right: 48px !important;
  padding-bottom: 24px !important;
}

.agent-manage-alert-view-modal #agent-manage-alert-dialog-id_header {
  font-size: 24px !important;
  color: #434a51 !important;
}

.agent-manage-alert-header {
  display: flex;
  margin-bottom: 22px;
  margin-top: -22px;
}

.manage-alert-agent-name {
  max-width: 526px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 10px !important;
}

.agent-manage-alert-header .manage-alert-icon {
  font-size: 32px;
  height: 32px;
}

.agent-manage-alert-header span {
  font-size: 32px;
  color: #002d72;
  font-weight: 400;
}

.agent-manage-alert-createdby {
  margin-top: 15px !important;
  font-size: 13px;
  font-weight: 400;
  color: #434a51;
}

#agent-manage-edit-btn {
  margin-left: 17%;
}

.agent-alert-text-error .agent-manage-alert {
  border: 1px solid #ff4444;
  border-radius: 4px;
}

.agent-clear-alert-view {
  margin-top: 10px;
}

.agent-manage-alert-view-modal .p-dialog-titlebar {
  padding-bottom: 0px !important;
}

.agent-manage-alert-modal
  .agent-alert-text-error
  #txtMessage
  .p-editor-toolbar {
  border: 0px solid lightgray !important;
}

.agent-manage-alert-modal
  .agent-alert-text-error
  #txtMessage
  .p-editor-content {
  border: 0px solid lightgray !important;
}

.agent-manage-alert-view-modal .pi-times:before {
  content: url("./fonts/managealertclose.svg");
}

.agent-manage-alert-view-modal .pi-times:before {
  content: url("./fonts/managealertclose.svg");
}

.p-button.calendar-footer-button {
  background-color: #fff;
  border: none;
  color: #33478a;
  width: auto !important;
  min-height: 36.6px;
  min-width: 30px;
  margin-bottom: 4px;
  margin-left: 4px;
}

.p-button.calendar-footer-button.active {
  background-color: #506ac3;
  border: none;
  color: #fff;
  width: auto !important;
  min-height: 36.6px;
  min-width: 30px;
  font-weight: 700;
  border-radius: 10px !important;
}

.p-button.calendar-footer-button:enabled:focus {
  box-shadow: none;
  border-radius: 10px !important;
  background-color: #506ac3 !important;
}

.range-datepicker {
  width: 378px;
  /* z-index: 1008; */
  position: relative;
}

.range-datepicker .p-datepicker {
  min-width: 27em !important;
}

.range-datepicker .p-button.calendar-footer-button:enabled:active {
  background-color: #506ac3;
  color: #ffffff;
  border: none;
  border-radius: 10px !important;
}

.range-datepicker .p-button.calendar-footer-button:enabled:hover {
  background-color: #506ac3;
  color: #fff;
  border: none;
  border-radius: 10px !important;
}

.range-datepicker table tr td:has(span.p-highlight) {
  background-color: #edf0f5 !important;
  color: #333 !important;
  padding: 0px;
  margin: 0px;
}

.range-datepicker table tr td > span.p-highlight {
  background-color: #edf0f5 !important;
  color: #333 !important;
}

.range-datepicker table tr > td.selected-date:has(span.p-highlight) {
  background-color: #506ac3 !important;
  color: #fff !important;
  border-radius: 10px !important;
}

.range-datepicker table tr > td.selected-date > span.p-highlight {
  background-color: #506ac3 !important;
  color: #fff !important;
  border-radius: 10px !important;
}

.range-datepicker table td {
  padding: 6px !important;
}

.range-datepicker table td > span {
  padding: 0px !important;
  border-radius: 0px;
}

.range-datepicker table tr {
  border-bottom: 10px solid #fff;
}

.calendar-footer-submit {
  border-top: 1px solid #d8dae2;
  border-bottom: 1px solid #d8dae2;
  padding-top: 5px;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.agent-manage-alert-view::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
}

.agent-manage-alert-view::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: white;
}

.agent-manage-alert-view::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: lightgray;
}

.agent-manage-alert-view img {
  width: 510px;
}

.agent-manage-alert-createdby-create {
  margin-top: 5px !important;
  font-size: 13px;
  font-weight: 400;
  color: #434a51;
  margin-bottom: -20px;
}

.agent-manage-save-btn {
  background-color: #0052ce !important;
}

.CMMDownload {
  content: url("../src/fonts/downloadCm.svg") !important;
  height: 14px !important;
  width: 14px;
  fill: #33478a !important;
  color: #33478a !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.linkColor-blue {
  color: #33478a !important;
}

.tooltip-replace-contract {
  font-size: 12px;
  margin-left: 0;
  left: 44px;
  top: -16px !important;
  background-color: #434a51 !important;
  border-radius: 5px !important;
  width: 235px !important;
  padding: 15px 0 !important;
}

.tooltip .tooltip-replace-contract:after {
  content: "";
  position: absolute;
  top: 15px;
  left: -7%;
  margin-left: -3px;
  border: 10px solid transparent;
  border-bottom-color: #434a51;
  rotate: 270deg;
}

.replaced-contract-view {
  background: #d4edda;
  border-radius: 3px;
  color: #155724;
  padding: 10px;
  width: 140px;
  font-weight: bold;
  font-size: 12px;
}

.agent-task-attachment-add:before {
  content: url("../src/fonts/attachmentsagenttask.svg") !important;
  padding-right: 5px !important;
}

.agent-task-attachment-view:before {
  content: url("../src/fonts/attachmentsagenttaskadd.svg") !important;
  padding-right: 5px !important;
}

.agent-task-attachment-add-attach,
.agent-task-attachment-add-attach .p-autocomplete .p-autocomplete-input {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 13px !important;
  color: #142933;
  height: 38px;
}

.agent-task-attachment-add-attach .pi {
  padding-top: 16px;
}

.custom-dialog-model {
  width: 644px;
}

.custom-dialog-model .p-dialog-content p {
  font-size: 24px;
  color: #434a51;
  text-align: center;
  display: flex;
  align-items: center;
  line-height: 29px;
  font-weight: 400;
  padding: 0px 20px;
}

.custom-dialog-model .p-dialog-title {
  font-size: 24px;
  color: #002d72;
  font-weight: 400 !important;
}

.custom-dialog-model .p-dialog-titlebar-icon {
  font-size: 24px !important;
  color: #0052ce !important;
  font-weight: 300 !important;
  cursor: pointer;
}

.custom-dialog-model .p-dialog-titlebar-icons {
  margin-top: 5px;
}

.custom-dialog-model .p-button.secondary-button {
  border: 1px solid #0052ce;
  color: #0052ce;
  font-size: 16px;
  font-weight: 700;
  min-width: 50px;
  cursor: pointer;
}

.custom-dialog-model .p-button.primary-button {
  background: #0052ce;
  border: 1px solid #0052ce;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  min-width: 50px;
  cursor: pointer;
}

.custom-dialog-model p-dialog-titlebar {
  align-items: center !important;
}

.mt-20 {
  margin-top: 20px;
}

.custom-dialog-model .pi {
  font-size: 24px !important;
  align-items: center;
}

.content-section {
  padding: 1.2em 0 1.2em 0;
}

.content-section-body {
  padding: 0em 1.2em 1.2em 1.2em !important;
}

.LatoFont {
  font-family: Lato !important;
}

.revenueNotetooltip {
  position: relative;
  display: inline-block;
}

.revenueNotetooltip .revenueNotetooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #434a51;
  color: #fff !important;
  z-index: 1;
  top: -20px;
  right: 150%;
  text-align: left;
  border-radius: 6px;
  padding: 20px;
  position: absolute;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.revenueNotetooltip .revenueNotetooltiptext:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -13px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent #434a51;
}

.revenueNotetooltip:hover .revenueNotetooltiptext {
  visibility: visible;
}

.document-attach-icon {
  padding-left: 18px;
  color: #142933;
}

.document-attach-icon::before {
  content: url("../src/fonts/dark-attach-icon.svg") !important;
  font-size: 16px;
  position: absolute;
  height: 14px;
  width: 14px;
  margin-left: -18px;
  margin-top: -2px;
  color: #142933;
}

.document-list-attach-icon::before {
  content: url("../src/fonts/blue-attach-icon.svg") !important;
  font-size: 16px;
  position: absolute;
  height: 14px;
  width: 14px;
  margin-left: -18px;
  color: #506ac3;
}

.contract-exchange-icon {
  color: #33478a;
}

.task-document-add-edit {
  display: inline !important;
}

.task-add-edit-tootltip {
  width: 300px !important;
}

.document-delete-btn-add-task .fa-trash:before {
  color: #33478a;
  font-weight: 900;
}

.document-delete-btn-add-task .p-button-text-icon-left .p-button-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #33478a;
}

.custom-dropdown-pannel .p-dropdown .p-dropdown-panel {
  max-width: 100%;
}

.document-delete-btn-add-task-doctype {
  color: #333333;
}

#searchButtonTopHeader .fa-search::before {
  content: url("./fonts/SSRSearch.svg");
}

#searchTypeTopHeaderDropdown
  .p-dropdown-trigger
  .p-dropdown-trigger-icon::before {
  content: url("./fonts/header-search-down.svg");
}

.impersonatingUserDropdown-label {
  color: #aaaaaa;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5.5px;
  margin-right: 10px;
}

#impersonatingUserDropdown {
  border: none;
  width: fit-content;
  max-width: 165px;
  min-width: 165px;
  width: 165px;
}

#impersonatingUserDropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
  margin-top: -11.5px;
}

#impersonatingUserDropdown
  .p-dropdown-trigger
  .p-dropdown-trigger-icon::before {
  content: url("./fonts/SSR-down-arrow.svg");
}

#impersonatingUserDropdown
  .p-dropdown-trigger[aria-expanded="true"]
  .p-dropdown-trigger-icon::before,
.main-header-content
  .Logged-User
  .p-splitbutton
  .p-splitbutton-menubutton[aria-expanded="true"]
  .pi-caret-down::before {
  content: url("./fonts/header-up-arrow.svg");
}

#impersonatingUserDropdown.header-form-group .p-dropdown .p-dropdown-label {
  color: #434a51;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  font-family: "Lato";
}

.main-header-content
  .Logged-User
  .p-splitbutton
  .p-splitbutton-menubutton
  .pi-caret-down::before {
  content: url("./fonts/SSR-down-arrow.svg");
}

.main-header-content
  .bell-icon
  .notif-dropdown
  .p-overlay-badge
  .pi-bell::before {
  content: url("./fonts/bell.svg");
}

.header-notes-icon-wrap {
  padding-left: 16.5px;
  width: 52px;
  min-width: 52px;
  border-right: 1px solid #aaaaaa;
  border-left: 1px solid #aaaaaa;
  height: 24px;
  margin-top: 21px;
  margin-right: 10px;
}

.Logged-User .p-menuitem:first-child .p-menuitem-icon::before {
  content: url("./images/agentDashboard.svg");
}

.Logged-User .p-menuitem:last-child .p-menuitem-icon::before {
  content: url("./fonts/header-logout.svg");
}

#impersonatingUserDropdown .p-dropdown-panel .p-dropdown-items-wrapper {
  overflow-x: hidden;
}

#impersonatingUserDropdown .p-dropdown-panel {
  margin-left: -40px;
  max-width: 200px;
}

#agentTopHeaderAutoComplete
  .p-autocomplete-panel
  .p-autocomplete-items::-webkit-scrollbar,
#searchTypeTopHeaderDropdown
  .p-dropdown-panel
  .p-dropdown-items-wrapper::-webkit-scrollbar,
#impersonatingUserDropdown
  .p-dropdown-panel
  .p-dropdown-items-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: white;
}

#agentTopHeaderAutoComplete
  .p-autocomplete-panel
  .p-autocomplete-items::-webkit-scrollbar-thumb,
#searchTypeTopHeaderDropdown
  .p-dropdown-panel
  .p-dropdown-items-wrapper::-webkit-scrollbar-thumb,
#impersonatingUserDropdown
  .p-dropdown-panel
  .p-dropdown-items-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: lightgray;
}

#agentTopHeaderAutoComplete
  .p-autocomplete-panel
  .p-autocomplete-items::-webkit-scrollbar-track,
#searchTypeTopHeaderDropdown
  .p-dropdown-panel
  .p-dropdown-items-wrapper::-webkit-scrollbar-track,
#impersonatingUserDropdown
  .p-dropdown-panel
  .p-dropdown-items-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
}

#agentTopHeaderAutoComplete .p-autocomplete-panel ul.p-autocomplete-items li {
  word-break: break-word;
}

body
  #searchTypeTopHeaderDropdown
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:hover,
body
  #impersonatingUserDropdown
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:hover {
  background-color: #ededee !important;
}

body
  #searchTypeTopHeaderDropdown
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight,
body
  #impersonatingUserDropdown
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight {
  background-color: #ededee !important;
  color: #0052ce;
  margin: 4px 7px 4px 12px;
  font-size: 16px;
  font-weight: 400;
  font-family: "Lato";
}

body
  #searchTypeTopHeaderDropdown
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item,
body
  #impersonatingUserDropdown
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item {
  color: #434a51;
  font-size: 16px;
  font-weight: 400;
  margin: 4px 6px 4px 6px;
  font-family: "Lato";
}

#impersonatingUserDropdown
  .p-dropdown-filter-container
  .p-dropdown-filter-icon::before {
  content: url("./fonts/SSRSearch.svg");
}

.agent-note-create-task::before {
  content: url("./fonts/agent-create-task.svg");
}

.agent-note-task-create-modal-wrapper #agent-note-task-create .p-dialog-title {
  font-size: 24px;
  color: #002d72;
  font-weight: 400;
}

.agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content {
  padding: 16px 13px 19.5px 13px !important;
  max-height: 586px;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .form-group
  .p-inputtext {
  border: 1px solid #dfdedd;
  border-radius: 4px;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .ql-toolbar.ql-snow
  .ql-picker-options
  .ql-picker-item {
  font-family: "Lato";
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .ql-toolbar.ql-snow
  .ql-picker-label {
  color: #434a51 !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  font-family: "Lato";
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .agent-manage-buttons {
  margin-top: 22px;
  float: right;
  margin-right: 9px;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .agent-manage-buttons
  #agent-manage-save-btn {
  max-height: 32px;
  width: 82px !important;
  font-weight: 500;
  background-color: #0052ce;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .agent-manage-buttons
  #agent-manage-back-btn {
  max-height: 32px;
  width: 97px !important;
  margin-right: 24px !important;
  font-weight: 500;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .agent-manage-buttons
  #agent-manage-back-btn
  .p-button-text {
  font-weight: 500 !important;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .agent-manage-buttons
  #agent-manage-save-btn
  .p-button-text {
  padding-top: 6px;
  font-family: "Lato";
  font-weight: 500 !important;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-calendar.p-calendar-w-btn
  .p-inputtext,
body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .form-group
  .p-inputtext,
body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .form-group
  label {
  font-size: 16px;
  font-weight: 400;
  color: #434a51 !important;
  font-family: "Lato";
  line-height: 19px;
}

.agent-note-task-descr-label {
  margin-bottom: 6px;
  font-size: 16px;
  color: #434a51;
  font-family: "Lato";
}

.agent-note-task {
  margin-top: -6px;
  margin-left: 8px;
  margin-right: 8px;
}

#note-task-create-user,
#note-task-create-priority,
#agentTaskaskTitle {
  border: 1px solid #dfdedd !important;
  border-radius: 4px !important;
}

#agentTaskaskTitle {
  padding-top: 11px;
  padding-bottom: 9px;
}

#note-task-create-priority label.p-dropdown-label.p-inputtext,
body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  #startDate
  input.p-inputtext.p-component.p-inputtext.p-component,
#note-task-create-user label.p-dropdown-label.p-inputtext {
  padding-top: 11px;
  padding-bottom: 8px;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-calendar
  .p-inputtext {
  border: 1px solid #dfdedd !important;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-calendar
  .p-datepicker-trigger {
  border: 1px solid #dfdedd !important;
  border-left: none !important;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-calendar
  .pi-calendar::before {
  content: url("./fonts/agent-task-date.svg");
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-calendar
  .p-datepicker-trigger.p-button:enabled:hover {
  background: transparent;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-calendar
  .p-datepicker-buttonbar {
  display: none;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-datepicker-prev-icon {
  margin-left: 12.9em;
  color: #0052ce;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-datepicker
  .p-datepicker-header
  .p-datepicker-prev:focus {
  box-shadow: none;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .pi-chevron-right {
  color: #0052ce;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .pi-chevron-down::before {
  content: url("./fonts/SSR-down-arrow.svg");
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-dropdown
  .p-dropdown-trigger
  .p-dropdown-trigger-icon {
  top: 44%;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .ql-container.ql-snow
  * {
  font-size: 16px;
  color: #434a51;
  font-family: "Lato";
  max-height: 110px !important;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-datepicker-year {
  height: 23px;
  border: 1px solid #dfdedd;
  border-radius: 2px;
  color: #142933;
  font-size: 12px;
  font-weight: 700;
  font-family: "Lato";
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-datepicker-month {
  margin-left: -9.1em;
  margin-right: 10.4px;
  height: 23px;
  border: 1px solid #dfdedd;
  border-radius: 2px;
  color: #142933;
  font-size: 12px;
  font-weight: 700;
  font-family: "Lato";
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-datepicker
  .p-disabled {
  color: #aaaaaa;
  font-size: 12px;
  font-weight: 400;
  font-family: "Lato";
  padding-top: 7.5px;
  height: 30px;
  width: 30px;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-datepicker:not(.p-disabled)
  table
  td
  span:not(.p-highlight):not(.p-disabled) {
  color: #333333;
  font-size: 12px;
  font-weight: 400;
  font-family: "Lato";
  padding-top: 7.5px;
  height: 30px;
  width: 30px;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-datepicker:not(.p-disabled)
  table
  td
  span:not(.p-highlight):not(.p-disabled):hover {
  border-radius: 50%;
  color: #333333;
  background-color: #f1faff;
  font-size: 12px;
  font-weight: 400;
  font-family: "Lato";
  padding-top: 7.5px;
  height: 30px;
  width: 30px;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-datepicker
  table
  td.p-datepicker-today
  > span.p-highlight {
  border-radius: 50%;
  color: #0052ce;
  background-color: #f1faff;
  font-size: 12px;
  font-weight: 700;
  font-family: "Lato";
  padding-top: 7.5px;
  height: 30px;
  width: 30px;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-datepicker-calendar
  thead
  tr
  th
  span {
  color: #0a0a0a;
  font-weight: 700;
  font-family: "Lato";
  font-size: 12px;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-datepicker-calendar
  thead
  tr
  th:first-child
  span {
  color: #ff3b30;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-datepicker {
  border: 1px solid #dfdedd;
  text-decoration: none;
  border-radius: 2px;
  margin-top: 12px;
  padding-left: 8px;
  padding-right: 6px;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-datepicker:before {
  content: "";
  position: absolute;
  left: 70px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: #dfdedd;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-datepicker:after {
  content: "";
  position: absolute;
  left: 71px;
  bottom: 100%;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: white;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .pi-chevron-left::before {
  content: url("./fonts/PrevMonth.svg");
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .pi-chevron-right::before {
  content: url("./fonts/NextMonth.svg");
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-datepicker-next {
  margin-right: -4px;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-datepicker-calendar
  tbody
  tr
  td {
  padding-left: 3px;
  padding-bottom: 2px;
  padding-top: 2px;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-datepicker-calendar
  thead
  tr
  th {
  padding-left: 0px;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-datepicker-calendar {
  margin-top: 2px;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .pi-times::before {
  content: url("./fonts/ssr-modal-close.svg");
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-content
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight {
  background-color: #0052ce !important;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-titlebar-close-icon,
body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dialog-titlebar-icons {
  display: block !important;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-dropdown-panel.p-hidden.p-input-overlay.p-input-overlay-visible {
  max-width: 188px;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .ql-list:first-child
  svg {
  display: none;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .ql-list:first-child::before {
  content: url("./fonts/agent-ol.svg");
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .ql-list:last-child
  svg {
  display: none;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .ql-list:last-child::before {
  content: url("./fonts/agent-ul.svg");
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .ql-align:first-child
  svg {
  display: none;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .ql-align:first-child::before {
  content: url("./fonts/agent-align-left.svg");
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .ql-align:nth-child(2)
  svg {
  display: none;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .ql-align:nth-child(2)::before {
  content: url("./fonts/agent-align-center.svg");
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .ql-align:nth-child(3)
  svg {
  display: none;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .ql-align:nth-child(3)::before {
  content: url("./fonts/agent-align-right.svg");
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .ql-align:nth-child(4)
  svg {
  display: none;
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .ql-align:nth-child(4)::before {
  content: url("./fonts/agent-align-justify.svg");
}

body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-editor-content,
body
  .agent-note-task-create-modal-wrapper
  #agent-note-task-create
  .p-editor-content {
  max-height: 120px !important;
}

.agentTaskaskTitle-error-wrap #agentTaskaskTitle {
  border: 1px solid #ff4444 !important;
}

#agent-note-task-title-error {
  color: #ff4444;
  font-size: 12px;
  font-family: "Lato";
  margin-top: 5px;
}

button#agent-manage-save-btn.p-disabled {
  background-color: #a0c4f8 !important;
  border-color: #a0c4f8 !important;
}

.notif-dropdown i.pi.pi-bell {
  margin-top: 5px;
}

#impersonatingUserDropdown .p-dropdown-filter-container .p-inputtext {
  border: 1px solid #dfdedd !important;
}

#searchButtonTopHeader .fa-search {
  top: 48%;
}

#agentTopHeaderAutoComplete
  .p-autocomplete-panel
  .p-autocomplete-list-item
  .p-grid {
  padding-top: 12px;
}

#agentTopHeaderAutoComplete
  .p-autocomplete-panel
  .p-autocomplete-list-item
  .p-grid
  .p-col-3:first-child {
  padding-top: 9px;
}

.odd-UC,
.odd-UC td {
  background-color: deepskyblue;
}

#divAlert .p-button-icon-only .fa-times {
  top: 56%;
}

#standard-report-product
  .p-dropdown-panel.p-hidden.p-input-overlay.p-input-overlay-visible {
  left: 0px !important;
  max-width: 367px !important;
}

.report-upline-error-wrap #upline .p-inputtext {
  border: 0.5px solid #ff4444 !important;
}

#report-upline-error-div {
  font-size: 12px;
  color: #ff4444;
  font-weight: 400;
}

.circle-info {
  content: url("../src/fonts/Circle-info.svg");
}

.task-document-search {
  padding-top: 12px;
  margin-left: -6px;
}

.tooltip .tooltip-level.task-document-tooltip {
  width: 220px;
  left: 94%;
  bottom: 25%;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  background-color: #434a51;
  font-family: "Lato" !important;
  height: 26px;
  padding-top: 7px;
  padding-bottom: 7px;
  position: relative;
  vertical-align: text-top;
}

.tooltip .tooltip-level.task-document-tooltip::after {
  border-color: transparent #434a51 transparent transparent;
  top: 52% !important;
}

.title-text-sec {
  font-family: sans-serif;
  font-size: 30px;
  color: rgb(51, 71, 129);
  margin-left: 15px;
}

.back-icon-wflow {
  font-size: 28px;
  margin-left: 13px;
  margin-right: -6px;
}

body
  .layout-wrapper.layout-static-sidebar-inactive
  .layout-sidebar
  ul
  li.active-menuitem
  a
  span {
  font-weight: 500 !important;
  color: #051d43;
}

.normal-menuitem .menu-home::before {
  content: url("./fonts/menu-home.svg");
}

.normal-menuitem .menu-agents::before {
  content: url("./fonts/menu-agents.svg");
}

.normal-menuitem .menu-insured::before {
  content: url("./fonts/menu-insured.svg");
}

.normal-menuitem .menu-tasks::before {
  content: url("./fonts/menu-tasks.svg");
}

.normal-menuitem .menu-documents::before {
  content: url("./fonts/menu-documents.svg");
}

.normal-menuitem .menu-dialer::before {
  content: url("./fonts/menu-dialer.svg");
}

.normal-menuitem .menu-workflow::before {
  content: url("./fonts/menu-workflow.svg");
}

.normal-menuitem .menu-goals::before {
  content: url("./fonts/menu-goals.svg");
  color: white;
}

.normal-menuitem .menu-leads::before {
  content: url("./fonts/menu-leads.svg");
}

.normal-menuitem .menu-supplies::before {
  content: url("./fonts/menu-supplies.svg");
}

.normal-menuitem .menu-reports::before {
  content: url("./fonts/menu-reports.svg");
}

.normal-menuitem .carrier-management-icon::before {
  content: url("./fonts/carriers_white.svg");
}

.normal-menuitem .menu-administration::before {
  content: url("./fonts/menu-administration.svg");
}

.normal-menuitem .menu-bob::before {
  content: url("./fonts/bobMenu.svg");
}

.normal-menuitem .menu-upload::before {
  content: url("./fonts/upload.svg");
  position: relative;
  right: 4px;
}

.normal-menuitem .menu-rts-review::before {
  content: url("./fonts/menu-rts-review.svg");
}

.normal-menuitem .menu-contract-management::before {
  content: url("./css/fontawesome-pro-6.6.0-web/svgs/sharp-light/file-contract.svg");
  filter: invert(99%) sepia(14%) saturate(670%) hue-rotate(180deg) brightness(99%) contrast(94%);
  display: inline-block;
  height:16px;
  width:16px;
  margin-bottom: 8px;
  margin-left: 2px;
}

.normal-menuitem .menu-leads,
.active-menuitem .menu-leads,
.normal-menuitem .menu-tasks,
.active-menuitem .menu-tasks {
  margin-left: 3px;
  margin-right: 3px;
}

.normal-menuitem .menu-dialer,
.active-menuitem .menu-dialer,
.normal-menuitem .menu-documents,
.active-menuitem .menu-documents {
  margin-left: 3px;
}

.active-menuitem .menu-home::before {
  content: url("./fonts/menu-active-home.svg");
}

.active-menuitem .menu-agents::before {
  content: url("./fonts/menu-active-agents.svg");
}

.active-menuitem .menu-insured::before {
  content: url("./fonts/menu-active-insured.svg");
}

.active-menuitem .menu-tasks::before {
  content: url("./fonts/menu-active-tasks.svg");
}

.active-menuitem .menu-documents::before {
  content: url("./fonts/menu-active-documents.svg");
}

.active-menuitem .menu-dialer::before {
  content: url("./fonts/menu-active-dialer.svg");
}

.active-menuitem .menu-workflow::before {
  content: url("./fonts/menu-active-workflow.svg");
}

.active-menuitem .menu-goals::before {
  content: url("./fonts/menu-active-goals.svg");
}

.active-menuitem .menu-leads::before {
  content: url("./fonts/menu-active-leads.svg");
}

.active-menuitem .menu-supplies::before {
  content: url("./fonts/menu-active-supplies.svg");
}

.active-menuitem .menu-reports::before {
  content: url("./fonts/menu-active-report.svg");
}

.active-menuitem .carrier-management-icon::before {
  content: url("./fonts/carriers-menu-active.svg");
}

.active-menuitem .menu-administration::before {
  content: url("./fonts/menu-active-administration.svg");
}

.active-menuitem .menu-rts-review::before {
  content: url("./fonts/menu-rts-review-active.svg");
}

.active-menuitem .menu-contract-management::before {
  content: url("./css/fontawesome-pro-6.6.0-web/svgs/sharp-light/file-contract.svg");
  filter: invert(9%) sepia(75%) saturate(1631%) hue-rotate(211deg) brightness(93%) contrast(98%);
  display: inline-block;
  height:16px;
  width:16px;
  margin-bottom: 8px;
  margin-left: 2px;
}

.active-menuitem .menu-bob::before {
  content: url("./fonts/bob-active.svg");
}

.active-menuitem .menu-upload::before {
  content: url("./fonts/upload-a.svg");
  position: relative;
  right: 4px;
}

.padding-left-100 {
  padding-left: 100px;
}

/* 110% */
@media only screen and (min-width: 1163px) and (max-width: 1200px) {
  .top-header-form-group {
    width: 455px;
  }

  .top-header-search-font {
    font-size: 12px !important;
  }
}

/* 125% */
@media only screen and (min-width: 1024px) and (max-width: 1163px) {
  .top-header-form-group {
    width: 425px;
  }
}

/* 100% */
@media screen and (min-width: 1270px) and (max-width: 1422px) {
  .top-header-search-font {
    font-size: 12px !important;
  }

  .contract-auto-td {
    padding-left: 13px;
  }

  .contract-auto-td-number {
    padding-left: 15px;
  }

  .contract-auto-td-type {
    padding-left: 17px;
  }

  .contract-auto-td-active {
    padding-left: 22px;
  }

  .top-header-form-group {
    width: 455px;
  }

  #agentTopHeaderAutoComplete .p-autocomplete-input {
    padding-right: 50px;
  }

  #agentTopHeaderAutoComplete > div.p-autocomplete-panel {
    min-width: 468px;
  }
}

/* 90% */
@media screen and (min-width: 1422px) and (max-width: 1600px) {
  .top-header-search-font {
    font-size: 14px !important;
  }

  .contract-auto-td {
    padding-left: 12px;
  }

  .top-header-form-group {
    width: 520px;
  }

  #agentTopHeaderAutoComplete > div.p-autocomplete-panel {
    min-width: 501px;
  }

  #agentTopHeaderAutoComplete .p-autocomplete-input {
    padding-right: 79px;
  }
}

/* 80% */
@media screen and (min-width: 1600px) and (max-width: 1706px) {
  .top-header-search-font {
    font-size: 14px !important;
  }

  .contract-auto-td {
    padding-left: 12px;
  }

  .contract-auto-td-number {
    padding-left: 16px;
  }

  .contract-auto-td-type {
    padding-left: 20px;
  }

  .contract-auto-td-active {
    padding-left: 25px;
  }

  .top-header-form-group {
    width: 520px;
  }

  #agentTopHeaderAutoComplete > div.p-autocomplete-panel {
    min-width: 501px;
  }

  #agentTopHeaderAutoComplete .p-autocomplete-input {
    padding-right: 73px;
  }
}

/* 75% */
@media screen and (min-width: 1706px) and (max-width: 1920px) {
  .top-header-search-font {
    font-size: 14px !important;
  }

  .contract-auto-td {
    padding-left: 13px;
  }

  .contract-auto-td-number {
    padding-left: 16px;
  }

  .contract-auto-td-type {
    padding-left: 20px;
  }

  .contract-auto-td-active {
    padding-left: 27px;
  }

  .top-header-form-group {
    width: 520px;
  }

  #agentTopHeaderAutoComplete > div.p-autocomplete-panel {
    min-width: 501px;
  }

  #agentTopHeaderAutoComplete .p-autocomplete-input {
    padding-right: 82px;
  }
}

/* 67% */
@media screen and (min-width: 1920px) and (max-width: 2560px) {
  .top-header-search-font {
    font-size: 14px !important;
  }

  .contract-auto-td {
    padding-left: 14px;
  }

  .contract-auto-td-number {
    padding-left: 18px;
  }

  .contract-auto-td-type {
    padding-left: 23px;
  }

  .contract-auto-td-active {
    padding-left: 29px;
  }

  .top-header-form-group {
    width: 520px;
  }

  #agentTopHeaderAutoComplete > div.p-autocomplete-panel {
    min-width: 501px;
  }

  #agentTopHeaderAutoComplete .p-autocomplete-input {
    padding-right: 82px;
  }
}

/* 50% */
@media screen and (min-width: 2560px) {
  .top-header-search-font {
    font-size: 14px !important;
  }

  .contract-auto-td {
    padding-left: 16px;
  }

  .contract-auto-td-number {
    padding-left: 20px;
  }

  .contract-auto-td-type {
    padding-left: 27px;
  }

  .contract-auto-td-active {
    padding-left: 37px;
  }

  .top-header-form-group {
    width: 520px;
  }

  #agentTopHeaderAutoComplete > div.p-autocomplete-panel {
    min-width: 501px;
  }

  #agentTopHeaderAutoComplete .p-autocomplete-input {
    padding-right: 82px;
  }
}

/* 33% */
@media screen and (min-width: 3840px) {
  .top-header-search-font {
    font-size: 14px !important;
  }

  .contract-auto-td {
    padding-left: 20px;
  }

  .contract-auto-td-number {
    padding-left: 28px;
  }

  .contract-auto-td-type {
    padding-left: 37px;
  }

  .contract-auto-td-active {
    padding-left: 50px;
  }

  .top-header-form-group {
    width: 520px;
  }

  #agentTopHeaderAutoComplete > div.p-autocomplete-panel {
    min-width: 501px;
  }

  #agentTopHeaderAutoComplete .p-autocomplete-input {
    padding-right: 82px;
  }
}

/* 25% */
@media screen and (min-width: 5110px) {
  .top-header-search-font {
    font-size: 14px !important;
  }

  .contract-auto-td {
    padding-left: 23px;
  }

  .contract-auto-td-number {
    padding-left: 36px;
  }

  .contract-auto-td-type {
    padding-left: 47px;
  }

  .contract-auto-td-active {
    padding-left: 65px;
  }

  .top-header-form-group {
    width: 520px;
  }

  #agentTopHeaderAutoComplete > div.p-autocomplete-panel {
    min-width: 501px;
  }

  #agentTopHeaderAutoComplete .p-autocomplete-input {
    padding-right: 85px;
  }
}

.top-header-search-font {
  font-weight: 700;
  color: #000000;
}

.top-header-li-search-no-record {
  padding-top: 7px !important;
  margin-left: -3px;
}

.top-header-li-search-font {
  font-size: 14px !important;
}

.w-break-bw,
.wflow-edit-info .row-value,
.products-list-td tbody td,
.custom-td-w-break-bw {
  word-break: break-word;
}

.font-size-23px {
  font-size: 23px;
}

.Font12px {
  font-size: 11px;
}

.padding-left-15 {
  padding-left: 15px;
}

.padding-left-20 {
  padding-left: 20px;
}

.padding-left-25 {
  padding-left: 25px;
}

.tooltip-upload-file {
  vertical-align: middle;
  padding-left: 10px;
}

.tooltip-upload-file .tooltip-upload-file-text {
  font-size: 13px;
  margin-left: 0;
  left: -422px;
  top: 27px;
  background-color: #434a51;
  border-radius: 5px;
  width: 750px;
}

.tooltip-upload-file .tooltip-upload-file-text:after {
  content: "";
  position: absolute;
  top: -22px;
  left: 57.8%;
  margin-left: -6px;
  border: 12px solid transparent;
  border-bottom-color: #434a51;
}

.margin-top-25 {
  margin-top: 25px;
}

.documentManagementViewTitle .screen-title .title-text {
  padding-bottom: 0px !important;
}

.margin-top-24-5 {
  margin-top: 24.5px;
}

/**Add Policy CSS**/
.agent-autocomplete-col {
  width: auto;
  padding-right: 0 !important;
}

.agent-autocomplete label {
  color: #5b6770;
  font-size: 13px;
  display: inline-block;
  text-align: right;
  min-width: 115px;
  max-width: 110px;
  vertical-align: top;
  margin-top: 10px;
}

.agent-autocomplete .p-autocomplete-input {
  min-width: 265px;
  height: 38px;
  line-height: 25px;
  margin-left: 6px;
  display: inline-block;
}

.padding-right-2 {
  padding-right: 2px;
}

.padding-left-0 {
  padding-left: 0;
}

.margin-left-n2 {
  margin-left: -2px;
}

.margin-left-n5 {
  margin-left: -5px;
}

.margin-top-7 {
  margin-top: 7px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-left-9 {
  margin-left: 9px;
}

.margin-left-4 {
  margin-left: 4px;
}

/**Add Policy CSS**/
.pdf-viewer-container {
  width: 100%;
  height: calc(100% - 60px);
}

/**Add Policy CSS**/

.add-payment-plan-modal .p-dialog-titlebar .p-dialog-title,
.custom-attributes-modal .p-dialog-titlebar .p-dialog-title,
.insured-notes-wrap .p-dialog-titlebar .p-dialog-title,
body .contract-delete-modal .p-dialog-titlebar .p-dialog-title,
.contract-wrapper-save .p-dialog .p-dialog-titlebar .p-dialog-title {
  color: #002d72;
  font-size: 24px;
  font-weight: 500 !important;
}

.add-payment-plan-modal .p-dialog-titlebar,
.custom-attributes-modal .p-dialog-titlebar,
.insured-notes-wrap .p-dialog-titlebar,
body .contract-delete-modal .p-dialog-titlebar,
.contract-wrapper-save .p-dialog .p-dialog-titlebar {
  border-radius: 8px 8px 0px 0px !important;
}

.add-payment-plan-modal .p-dialog-content,
.custom-attributes-modal .p-dialog-content,
.insured-notes-wrap .p-dialog-content,
body .contract-delete-modal .p-dialog-content,
.contract-wrapper-save .p-dialog-content {
  border-radius: 0px 0px 8px 8px;
}

body .contract-delete-modal .p-dialog-content {
  padding: 60px 20px 20px 30px !important;
}

body .contract-delete-modal .contract-delete-content {
  color: #434a51;
  font-size: 24px;
  font-weight: 400;
}

.contract-delete-footer {
  padding-top: 30px;
}

.contract-delete-footer #contract-delete-btn {
  min-height: 32px;
  max-height: 32px;
  background-color: #ff4444;
  border-color: #ff4444;
}

.contract-delete-footer #cancelButton {
  max-height: 32px;
  min-height: 32px;
  border-color: #0052ce;
  margin-right: 20px !important;
}

.contract-delete-footer #cancelButton:hover .p-button-text,
.contract-delete-footer #cancelButton:hover {
  border-color: #2175f4;
}

.contract-delete-footer #contract-delete-btn:hover {
  border-color: #ff5f5f;
}

.contract-delete-footer #cancelButton .p-button-text {
  color: #0052ce;
  font-size: 16px;
  font-weight: 500;
}

.contract-delete-footer #contract-delete-btn .p-button-text {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}

.contract-delete-footer .p-button-text {
  font-family: "Lato";
}

.contract-delete-modal .pi-times:before {
  content: url("./fonts/ssr-modal-close.svg");
}

.max-width-inh {
  max-width: inherit !important;
}

/**workflow search css start**/
.w-flow-search-wrap {
  position: relative;
}

.w-flow-search-wrap .p-inputtext {
  padding-right: 40px;
}

.w-flow-search-wrap .c-search-icon {
  position: absolute;
  right: 14px;
  bottom: 11px;
  color: #0052ce;
}

.w-flow-search-wrap .pi-search:before {
  font-size: 14px;
}

/**workflow search css end**/

/**Secondary new button**/
.download-icon-n {
  content: url("../src/fonts/download-n.svg");
}

.p-button.secondary-button.new-sec-btn {
  min-height: 35px;
}

.p-button.secondary-button.new-sec-btn .p-button-icon-left {
  padding-left: 15px;
}

.p-button.secondary-button.new-sec-btn .p-button-text {
  padding: 0.429em 1.4em 0.429em 3.2em;
}

/**Map Search CSS start**/
.map-search-wrap #agentmapAutoComplete .p-autocomplete-input {
  border: 1px solid #afb0b2 !important;
  height: unset;
  padding-right: 30px;
}

.map-search-wrap #agentmapAutoComplete .p-inputtext:enabled:focus,
.map-search-wrap #searchTypemapDropdown.p-inputwrapper-focus.p-focus {
  border: 1px solid #afb0b2 !important;
  box-shadow: 0 0 0 0.2em #8dcdff !important;
}

.map-search-wrap #agentmapAutoComplete .p-autocomplete-panel {
  margin-left: 0;
  width: 100%;
}

.map-search-wrap #searchTypemapDropdown {
  background-color: transparent !important;
  border: 1px solid #afb0b2 !important;
  border-radius: 3px !important;
  width: 100%;
}

/**Map Search CSS end**/

.p-button.secondary-button.bg-transparent {
  background-color: transparent;
}

/* New modal design, Please add the modal class name here or use existing classname to apply this style */
.cusAttrubute-delete-modal,
.contract-wrapper-save #cm-dialog-notes-delete,
.w9-use-address-modal {
  box-shadow: none !important;
}

.agent-production-reconciled-details .p-dialog-titlebar,
.w9-use-address-modal .p-dialog-titlebar {
  height: 55px !important;
  border-radius: 8px 8px 0 0 !important;
}

.agent-production-reconciled-details .p-dialog-content,
.w9-use-address-modal .p-dialog-content {
  border-radius: 0 0 8px 8px !important;
}

.agent-production-reconciled-details .p-dialog-title,
.w9-use-address-modal .p-dialog-title {
  font-size: 24px;
  color: #002d72;
  font-weight: 500 !important;
}

/**Bulk Delete CSS Start**/

.bulk-delete-table .p-datatable-thead > tr > th:first-child {
  border-right: 0.5px solid #506ac3 !important;
  padding-right: 5px !important;
  padding-left: 8px !important;
}

.bulk-delete-table .p-datatable-tbody > tr > td:first-child {
  border-right: 0.5px solid transparent !important;
  padding-right: 5px !important;
  padding-left: 8px !important;
}

.bulk-delete-table .p-datatable-thead > tr > th:nth-child(2),
.bulk-delete-table .p-datatable-tbody > tr > td:nth-child(2) {
  padding-left: 1px !important;
}

.bulk-delete-table .p-checkbox .p-checkbox-box {
  width: 13px;
  height: 13px;
  border: 1px solid #506ac3;
}

.bulk-delete-table .p-checkbox .p-checkbox-box .p-checkbox-icon {
  top: 3px !important;
  font-size: 7px !important;
}

body
  .bulk-delete-table
  .app-table.p-datatable
  .p-datatable-tbody
  > tr.p-highlight {
  background-color: #fff !important;
}

body .bulk-delete-table .p-checkbox {
  height: 16px;
}

body .bulk-delete-table.p-datatable .p-datatable-thead > tr > th,
body .bulk-delete-table.p-datatable .p-datatable-tbody > tr > td {
  border: 0.5px solid #afb0b2;
}

body .bulk-delete-table.p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background-color: #f5f6fa;
}

body .bulk-delete-table.p-datatable .p-datatable-tbody > tr.p-highlight {
  background-color: #fff !important;
}

body .bulk-delete-btn .p-button.p-button-text-icon-left .p-button-text {
  padding: 6px 10px 6px 30px;
}

.bulk-delete-btn .p-button.p-component {
  min-height: 28px !important;
}

.bulk-delete-btn .p-button.secondary-button.new-sec-btn .p-button-icon-left {
  padding-left: 5px;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.bulk-delete-btn .plus-icon-n {
  height: 0.9em;
}

.delete-icon-n {
  content: url("../src/fonts/trash-alt-new.svg");
}

body .bulk-delete-btn .p-component.primary-button .p-button-text {
  padding: 6px 18px 6px 42px;
}

.plus-icon-n {
  content: url("../src/fonts/plus.svg");
  margin-left: 7px;
}

.modal-bulk-delete .p-dialog-titlebar {
  border-radius: 3px 3px 0px 0px !important;
  border: transparent !important;
}

.modal-bulk-delete .p-dialog-titlebar .p-dialog-title {
  color: #002d72;
  font-size: 24px;
  font-weight: 400 !important;
}

.p-dialog.modal-bulk-delete .p-dialog-titlebar-icon span {
  color: #0052ce;
  font-size: 20px;
}

.modal-bulk-delete .p-dialog-content {
  border-radius: 0px 0px 3px 3px !important;
  border: transparent !important;
  border-top: 1px solid #aaaaaa !important;
}

.modal-bulk-delete .bulk-delete-content {
  min-height: 180px;
  font-size: 24px;
  color: #434a51;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-bulk-delete .bulk-delete-footer .p-button.p-component {
  min-width: 135px !important;
  min-height: 32px !important;
  border: 1px solid #0052ce !important;
  font-size: 16px;
  font-weight: 700;
  font-family: Lato !important;
  letter-spacing: 0.8px;
}

.modal-bulk-delete .bulk-delete-footer .p-button.secondary-button,
.modal-bulk-delete .bulk-delete-footer .p-button.secondary-button:enabled:hover,
.modal-bulk-delete
  .bulk-delete-footer
  .p-button.secondary-button:enabled:focus {
  color: #0052ce !important;
}

.modal-bulk-delete .bulk-delete-footer .p-button.primary-button,
.modal-bulk-delete .bulk-delete-footer .p-button.primary-button:enabled:hover,
.modal-bulk-delete .bulk-delete-footer .p-button.primary-button:enabled:focus {
  background-color: #0052ce;
  color: #fff;
}

/**Bulk Delete CSS End**/

#agGroup .p-multiselect-panel {
  width: 100%;
  overflow-x: auto;
}

.p-relative {
  position: relative;
}

/*************Contract Create Task CSS*************/
.agent-contract-create-task::before {
  content: url("./fonts/createTask.svg");
  display: inline-block;
  margin-top: 1px;
}

.agent-contract-task-create-modal .p-dialog .p-dialog-titlebar {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 1em 1.3em 1em 1.3em;
}

.agent-contract-task-create-modal .p-dialog .p-dialog-content {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.agent-contract-task-create-modal
  .p-dialog
  .p-dialog-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
}

.agent-contract-task-create-modal
  .p-dialog
  .p-dialog-content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: white;
}

.agent-contract-task-create-modal
  .p-dialog
  .p-dialog-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: lightgray;
}

.agent-contract-task-create-modal .p-dialog {
  box-shadow: 4px 4px 6px 0 rgba(0, 0, 0, 0.25);
}

.agent-contract-task-create-modal .p-dialog-content .form-group label {
  line-height: 19.2px;
}

.agent-contract-task-create-modal .p-dialog-content .form-group .p-inputtext {
  padding-left: 15px;
  padding-right: 30px;
}

.agent-contract-task-create-modal
  .p-dialog-content
  .form-group
  .p-inputtext::placeholder {
  color: #aaaaaa;
  font-size: 16px;
}

.agent-contract-task-create-modal
  .p-dropdown-filter-container
  .p-dropdown-filter-icon {
  color: #0052ce;
}

.agent-contract-task-create-modal .agent-task-watchers-add,
.agent-contract-task-create-modal .contract-task-attachment-add-attach {
  font-size: 16px;
  color: #142933;
  font-weight: 400;
}

.agent-contract-task-create-modal .agent-task-watchers-SearchIcon,
.agent-contract-task-create-modal .agent-attachments-SearchIcon {
  position: absolute;
  margin-left: -31px !important;
  font-size: 18px !important;
  margin-top: -1px !important;
  vertical-align: bottom;
  color: #0052ce !important;
  font-weight: 300 !important;
  right: 4px;
  padding: 13px 9px 4px 0px;
}

.circle-info-new {
  content: url("../src/fonts/info-circle.svg");
}

.margin-top-n25 {
  margin-top: -25px;
}

.agent-contract-task-create-modal .agent-manage-buttons {
  margin-top: 0 !important;
}

.agent-contract-task-create-modal
  .agent-task-attachment-add-attach
  .p-autocomplete
  .p-autocomplete-input {
  font-size: 16px !important;
  color: #434a51;
}

.agent-contract-task-create-modal
  .p-dropdown-panel
  .p-dropdown-filter-container
  .p-dropdown-filter-icon {
  color: #0052ce !important;
  font-size: 14px;
}

.agent-contract-task-create-modal
  .p-dropdown-panel
  .p-dropdown-filter-container
  .p-dropdown-filter
  .p-dropdown-panel
  .p-dropdown-items {
  padding: 0.3em;
  border: 1px solid #a6a6a6 !important;
  border-radius: 3px !important;
  color: #434a51 !important;
  font-size: 14px !important;
}

.agent-contract-task-create-modal
  .p-dialog-content
  .p-dropdown-panel
  .p-dropdown-filter-container
  .p-inputtext {
  padding: 0.3em;
  padding-right: 25px !important;
}

.agent-contract-task-create-modal
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item {
  font-size: 16px;
  color: #434a51;
  font-weight: 300 !important;
  font-family: Lato !important;
  border-radius: 3px !important;
  padding: 0.429em 0.857em !important;
  margin: 3px;
}

.agent-contract-task-create-modal
  .p-dropdown-panel
  .p-dropdown-filter-container
  .p-inputtext:enabled:hover {
  border-color: #212121 !important;
}

.agent-contract-task-create-modal
  .p-dropdown-panel
  .p-dropdown-filter-container
  .p-inputtext:enabled:focus {
  box-shadow: none !important;
  border-color: #007ad9 !important;
}

.agent-contract-task-create-modal
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background-color: #ededee !important;
  color: #434a51 !important;
}

body
  .agent-contract-task-create-modal
  #agent-note-task-create
  .p-dialog-content
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight {
  color: #0052ce !important;
  background-color: #ededee !important;
}

.agent-contract-task-create-modal .required {
  color: #ff4444 !important;
  font-size: 16px !important;
}

.agent-add-edit-doc-container .p-dialog-content .pi-chevron-down::before,
body
  .agent-contract-task-create-modal
  #agent-note-task-create
  .p-dialog-content
  .pi-chevron-down::before {
  content: url("./fonts/chevron-down-new.svg");
}

.agent-contract-task-create-modal
  .p-calendar.p-calendar-w-btn
  .p-datepicker-trigger.p-button {
  width: 2.8em;
}

.agent-contract-task-create-modal .p-dropdown .p-dropdown-trigger {
  width: 2.5em;
}

.agent-contract-task-create-modal .ctask-SearchIcon {
  top: 37.5px;
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #0052ce;
}

.agent-contract-task-create-modal .agent-manage-buttons #agent-manage-save-btn {
  border: 1px solid #0052ce;
  border-radius: 3px !important;
}

body
  .agent-contract-task-create-modal
  #agent-note-task-create
  .p-dialog-content
  .p-datepicker-month,
body
  .agent-contract-task-create-modal
  #agent-note-task-create
  .p-dialog-content
  .p-datepicker-year {
  font-weight: 500;
  font-size: 14px;
}

.agent-contract-task-create-modal
  .document-attachment-list-table
  .no-documents-message {
  display: none;
}

body
  .agent-contract-task-create-modal
  #agent-note-task-create
  .p-dialog-content
  .agent-manage-buttons {
  margin-top: -25px !important;
  margin-bottom: 2px !important;
}

body .agent-contract-task-create-modal .agent-state-license-doc-table {
  margin-bottom: 22px !important;
}

.insured-dropdown-opt .p-autocomplete-panel .p-autocomplete-items {
  width: 430px;
}

.input-agent-pr .p-autocomplete-panel .p-autocomplete-items {
  width: 330px;
}

.agent-contract-task-create-modal .form-group .p-dropdown,
.agent-contract-task-create-modal .form-group .p-inputtext {
  border: 1px solid #dfdedd !important;
}

.agent-contract-task-create-modal
  .p-autocomplete-panel
  .p-autocomplete-items
  li
  .header {
  margin-top: -6px;
}

.agent-contract-task-create-modal
  .p-autocomplete-panel
  .p-autocomplete-items
  li
  .header
  div {
  padding-top: 6px;
}

.agent-contract-task-create-modal .CMNotesCounter {
  margin-top: 4px;
}

.agent-contract-task-create-modal .p-dropdown-items-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: white;
}

.agent-contract-task-create-modal
  .p-dropdown-items-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: lightgray;
}

.agent-contract-task-create-modal
  .p-dropdown-items-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
}

.agent-contract-task-create-modal .p-autocomplete .p-autocomplete-input,
.agent-contract-task-create-modal .form-group .p-dropdown,
.agent-contract-task-create-modal
  .agent-task-attachment-add-attach
  .p-autocomplete
  .p-autocomplete-input,
.agent-contract-task-create-modal
  #divstartDate
  .p-calendar
  .p-inputtext.p-component.p-inputtext,
.agent-contract-task-create-modal .form-group #agentTaskaskTitle.p-inputtext {
  height: 40px;
}

.agent-contract-task-create-modal .form-group #carrierdropdown label {
  line-height: 22px !important;
}

.agent-contract-task-create-modal
  #divstartDate
  input.p-inputtext.p-component.p-inputtext.p-component {
  line-height: 42px !important;
}

.agent-contract-task-create-modal
  #divnote-task-create-priority
  .p-dropdown-label.p-inputtext {
  line-height: 16px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.pl-4 {
  padding-left: 4px;
}

/**Contract Task Watcher Tooltip Start**/
.contract-task-watchers-field .p-autocomplete .p-autocomplete-input {
  font-size: 16px !important;
  color: #434a51;
  height: 38px;
}

.contract-task-watchers-field .pi {
  padding-top: 16px;
}

.c-task-document-search {
  padding-top: 10px;
  margin-left: -6px;
}

.tooltip .tooltip-level.c-task-watchers-tooltip,
.tooltip .tooltip-level.c-task-document-tooltip {
  position: absolute;
  width: 280px;
  height: 48px;
  left: 125px;
  top: -5px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  background-color: #434a51;
  font-family: "Lato" !important;
  padding: 11px 0 0 20px;
  vertical-align: text-top;
  text-align: left;
}

.tooltip .tooltip-level.c-task-document-tooltip {
  width: 235px;
  height: 35px;
  top: 0;
  left: -153px;
}

.tooltip .tooltip-level.c-task-watchers-tooltip::after,
.tooltip .tooltip-level.c-task-document-tooltip::after {
  border-color: transparent #434a51 transparent transparent;
  top: 45% !important;
  right: -19px;
  transform: rotate(180deg);
  margin-top: -7px;
  border-width: 10px;
}

/**Contract Task Watcher Tooltip End**/

/*agent contract add task css */

.agent-contract-task-save-tooltip {
  position: relative;
  display: inline-block;
}

.agent-contract-task-save-tooltip:hover .agent-contract-task-save-tooltip-text {
  visibility: visible;
}

.agent-contract-task-save-tooltip .agent-contract-task-save-tooltip-text {
  visibility: hidden;
  width: 300px;
  background-color: #434a51;
  z-index: 99899 !important;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  left: 0 !important;
  top: -190% !important;
  margin-left: -17.9em !important;
  text-align: start;
  padding-left: 10px;
  font-size: 12px;
  padding: 12px;
  font-weight: 400;
}

.agent-contract-task-save-tooltip
  .agent-contract-task-save-tooltip-text::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 84% !important;
  bottom: -9px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #434a51 transparent;
  transform: rotate(180deg);
}

/*  agent contract add task css */

.rcd-wrap {
  display: flex;
}

.rcd-label {
  color: #002d72;
  font-weight: 400;
  margin-bottom: 5px;
}

.rcd-value {
  color: #434a51;
  font-size: 16px;
  font-weight: 400;
}

.Reconciled-policy-td {
  color: #0052ce;
  cursor: pointer;
}

.Reconciled-policy-td:hover {
  color: #2175f4;
  text-decoration: underline;
  cursor: pointer;
}

/**DOB Task CSS Start**/
.task-insured-header .ins-id {
  width: 21.7%;
}

.task-insured-header .ins-name {
  width: 29%;
}

.task-insured-header .ins-dob {
  width: 23%;
  margin-left: -2px;
}

.task-insured-header .ins-cs {
  width: 26%;
}

.task-insured-content .ins-c-id {
  width: 21.7%;
}

.task-insured-content .ins-c-name {
  width: 29%;
}

.task-insured-content .ins-c-dob {
  width: 23%;
}

.task-insured-content .ins-c-cs {
  width: 26%;
}

.task-insured-header {
  font-family: "Lato" !important;
  color: #000000;
  font-weight: 700;
}

body
  .task-ins-wrap
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item {
  font-family: "Lato" !important;
  color: #434a51 !important;
  font-weight: 400;
}

.task-ins-wrap
  .imas-agent-auto-complete
  .p-autocomplete-panel
  ul
  li:first-child {
  margin-top: -29px !important;
}

.task-ins-wrap
  .imas-agent-auto-complete
  .p-autocomplete-panel
  ul::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: white;
}

.task-ins-wrap
  .imas-agent-auto-complete
  .p-autocomplete-panel
  ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: lightgray;
}

.task-ins-wrap
  .imas-agent-auto-complete
  .p-autocomplete-panel
  ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
}

/**DOB Task CSS end**/

/**Agent Insured CSS Start**/
.ag-insured-header .ag-ins-id {
  width: 21.7%;
}

.ag-insured-header .ag-ins-name {
  width: 29%;
}

.ag-insured-header .ag-ins-dob {
  width: 23%;
  margin-left: -2px;
}

.ag-insured-header .ag-ins-cs {
  width: 26%;
}

.ag-insured-content .ag-ins-c-id {
  width: 21.7%;
}

.ag-insured-content .ag-ins-c-name {
  width: 29%;
}

.ag-insured-content .ag-ins-c-dob {
  width: 23%;
}

.ag-insured-content .ag-ins-c-cs {
  width: 26%;
}

.ag-insured-header {
  font-family: "Lato" !important;
  color: #000000;
  font-weight: 700;
  font-size: 14px;
}

.ag-insured-content {
  font-family: "Lato" !important;
  color: #434a51;
  font-weight: 400;
  font-size: 14px;
}

.ag-ins-wrap .imas-agent-auto-complete .p-autocomplete-panel ul li:first-child {
  margin-top: -29px !important;
}

.ag-ins-wrap
  .imas-agent-auto-complete
  .p-autocomplete-panel
  ul::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: white;
}

.ag-ins-wrap
  .imas-agent-auto-complete
  .p-autocomplete-panel
  ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: lightgray;
}

.ag-ins-wrap
  .imas-agent-auto-complete
  .p-autocomplete-panel
  ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
}

.ag-ins-wrap .imas-agent-auto-complete .p-autocomplete-panel ul {
  max-height: 165px;
}

.ag-ins-wrap .p-autocomplete-panel .p-autocomplete-items li:nth-child(odd) {
  background-color: transparent !important;
}

.ag-ins-wrap
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item:hover
  .ag-insured-content {
  background-color: #ededee !important;
  color: #0052ce !important;
}

.ag-ins-wrap
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item {
  border: 0.3px solid #fff !important;
}

.agent-contract-task-create-modal
  .ag-main-wrap
  .p-autocomplete-panel
  .p-autocomplete-items
  li
  .header {
  font-family: "Lato" !important;
  color: #000000;
  font-weight: 700;
  font-size: 14px;
}

.agent-contract-task-create-modal
  .ag-main-wrap
  .p-autocomplete-panel
  .p-autocomplete-items
  .ag-main-content {
  font-family: "Lato" !important;
  color: #434a51;
  font-weight: 400;
  font-size: 14px;
}

.ag-main-wrap
  .imas-agent-auto-complete
  .p-autocomplete-panel
  ul::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: white;
}

.ag-main-wrap
  .imas-agent-auto-complete
  .p-autocomplete-panel
  ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: lightgray;
}

.ag-main-wrap
  .imas-agent-auto-complete
  .p-autocomplete-panel
  ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
}

.ag-main-wrap .p-autocomplete-panel .p-autocomplete-items li:nth-child(odd) {
  background-color: transparent !important;
}

.ag-main-wrap
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item:hover
  .ag-main-content {
  background-color: #ededee !important;
  color: #0052ce !important;
}

.ag-main-wrap
  .p-autocomplete-panel
  .p-autocomplete-items
  .p-autocomplete-list-item {
  border: 0.3px solid #fff !important;
}

.ag-main-wrap .imas-agent-auto-complete .p-autocomplete-panel ul {
  max-height: 165px;
}

/**Agent Insured CSS Start**/

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.refresh-btn-pl .p-button-text {
  padding-left: 2.65em !important;
}

.refresh-icon {
  content: url("../src/fonts/arrows-rotate.svg");
  padding-left: 6px;
  top: 53% !important;
}

body .insured-notes-wrap {
  font-family: "Lato";
}

#divstatusDropdown {
  width: 100%;
}

#workflowDropdown .p-menu.p-menu-overlay {
  width: 130px;
  margin-left: -37px;
}

@media (min-width: 990px) and (max-width: 1265px) {
  .policies-total {
    width: 34%;
  }
}

.circle-info-new-gray {
  content: url("../src/fonts/circle-info-gray.svg");
}

body
  .agent-contract-notification-modal-wrapper
  #agent-contract-notification
  .p-dialog-title {
  font-size: 24px;
  color: #002d72;
  font-weight: 400;
}

body
  .agent-contract-notification-manage-alert-modal
  .p-dialog-titlebar-close-icon,
body
  .agent-contract-notification-modal-wrapper
  #agent-contract-notification
  .p-dialog-titlebar-icons {
  display: none !important;
}

body
  .agent-contract-notification-modal-wrapper
  #agent-contract-notification
  .p-dialog-content
  .agent-manage-buttons {
  margin-top: 22px;
  float: right;
  margin-right: 9px;
}

body
  .agent-contract-notification-modal-wrapper
  #agent-contract-notification
  .p-dialog-content
  .agent-manage-buttons
  #agent-manage-save-btn {
  max-height: 32px;
  width: 82px !important;
  font-weight: 500;
  background-color: #0052ce;
}

body
  .agent-contract-notification-modal-wrapper
  #agent-contract-notification
  .p-dialog-content
  .agent-manage-buttons
  #agent-manage-back-btn {
  max-height: 32px;
  width: 82px !important;
  margin-right: 24px !important;
  font-weight: 500;
}

body
  .agent-contract-notification-modal-wrapper
  #agent-contract-notification
  .p-dialog-content
  .agent-manage-buttons
  #agent-manage-back-btn
  .p-button-text {
  font-weight: 500 !important;
}

body
  .agent-contract-notification-modal-wrapper
  #agent-contract-notification
  .p-dialog-content
  .agent-manage-buttons
  #agent-manage-save-btn
  .p-button-text {
  padding-top: 6px;
  font-family: "Lato";
  font-weight: 500 !important;
}

/* // Custome Attribute related CSS */
.CustomAttributeLine {
  border: 2px solid #33478a;
  border-radius: 2px;
}

/* .custom-attribute-label {
  font-size: 13px;
} */

.custom-attribute-add-btn-wrap {
  display: flex;
  justify-content: space-between;
}

.custom-attribute-radio-label {
  font-size: 13px !important;
  color: #142933 !important;
  margin-bottom: 6px !important;
  display: inline-block !important;
}

.imas-tooltip {
  background-color: #434a51;
}

/* New checkbox design  */
body .add-payment-plan-modal .p-checkbox .p-checkbox-box.p-highlight,
body .checkbox-new-design .p-checkbox .p-checkbox-box.p-highlight {
  border-color: #0052ce !important;
  background-color: #0052ce !important;
  color: #ffffff !important;
}

body .add-payment-plan-modal .p-checkbox .p-checkbox-box .p-checkbox-icon,
body .checkbox-new-design .p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-size: 10px;
  top: 3px;
  font-weight: 700;
}

body .add-payment-plan-modal .p-checkbox-label,
body .checkbox-new-design .p-checkbox-label {
  font-size: 16px;
  font-weight: 400;
  color: #434a51;
  vertical-align: middle;
  margin-bottom: 1px;
}

body .checkbox-new-design-checked .p-checkbox-label {
  font-size: 16px;
  font-weight: 700;
  color: #0052ce;
}

/* New checkbox design end  */

/* modal footer btns new design  */

.modal-footer-new-design {
  justify-content: end;
  bottom: 0;
  right: -8px;
  position: relative;
}

.optional-label {
  opacity: 0.7;
  margin-left: 3px;
}

/**View Tasks Inactive Users CSS Start**/
.show-inactive-users label {
  color: #0052ce !important;
  margin-left: 2px !important;
  padding-left: 0 !important;
  font-size: 16px !important;
  font-weight: 400;
  vertical-align: top;
  font-family: Lato !important;
}

.show-inactive-users #showInactiveUsers .p-checkbox-box {
  width: 15px;
  height: 15px;
  border-radius: 3px;
  border: 1.5px solid #0052ce;
  background: #fff;
}

.show-inactive-users #showInactiveUsers.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #0052ce !important;
  background-color: #0052ce !important;
}

.show-inactive-users .p-checkbox .p-checkbox-box:not(.p-disabled).p-focus {
  box-shadow: none !important;
}

.show-inactive-users #showInactiveUsers .p-checkbox-icon.p-c.pi.pi-check {
  padding-top: 2.8px !important;
  font-size: 8.5px;
}

.show-inactive-users #showInactiveUsers .p-checkbox-icon {
  padding-top: 0 !important;
  font-weight: bold;
  font-size: 6px;
}

.show-inactive-users .p-checkbox .p-checkbox-box .p-checkbox-icon {
  background-color: #0052ce !important;
  border-color: #0052ce !important;
}

.show-inactive-users .p-checkbox-label {
  vertical-align: baseline;
}

.created-inactive-wrap
  .p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container {
  width: 78%;
}

/**View Tasks Inactive Users CSS End**/

.form-group.none-p-error .p-inputtext.p-error {
  border-color: #afb0b2 !important;
}

.layout-main-menu .__react_component_tooltip.allow_click {
  pointer-events: none;
}

.Toastify__toast-container--top-right {
  right: 1.9em;
}
.screen-title .title-text-sec {
  padding-bottom: 0 !important;
}

#carriersByUserDropdown.p-multiselect .p-multiselect-label.p-placeholder {
  color: #333333;
}

.overflow-initial {
  overflow: initial !important;
}

.overflow-x-initial {
  overflow-x: initial !important;
}

.overflow-y-initial {
  overflow-y: initial !important;
}

/***Admin Product Search Wrap**/
.admin-product-search-container {
  padding-bottom: 15px;
  margin-top: -20px;
}

.admin-product-search-wrapper {
  border: 1px solid #afb0b2 !important;
  height: 36px;
  border: none;
  display: flex;
}

body
  .admin-product-search-wrapper
  #divddlRecordType
  #ddlRecordType
  span.p-dropdown-trigger-icon.pi.pi-chevron-down.p-clickable {
  margin-top: -9px !important;
}

.admin-product-search-wrapper #ddlRecordType {
  border: none;
  width: 140px;
  border-radius: 4px 0px 0px 4px;
}

.admin-product-search-wrapper
  #ddlRecordType.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
}

.admin-product-search-wrapper #ddlRecordType .p-inputtext {
  padding-left: 8px;
  height: 33px;
  padding-top: 10px;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #333333;
}

.admin-product-search-wrapper #ddlRecordType .pi-chevron-down:before {
  content: "\f0d7";
  font-family: "FontAwesome";
  color: #434a51;
}

.admin-product-search-wrapper #txtSearchFor {
  font-size: 14px;
  width: 56%;
}

.admin-product-search-wrapper #txtSearchFor::placeholder {
  color: #aaaaaa !important;
  padding-right: 9%;
}

.admin-product-searchInputValue.p-inputtext,
.admin-product-searchInputValue.p-inputtext:enabled:focus {
  padding: 0;
  margin-bottom: 1px;
}

.admin-product-searchInputValue.p-inputtext:enabled:focus,
.admin-product-searchInputValue.p-inputtext {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
  border: none !important;
}

.admin-product-search-wrapper input:-webkit-autofill,
.admin-product-search-wrapper input:-webkit-autofill:hover,
.admin-product-search-wrapper input:-webkit-autofill:focus,
.admin-product-search-wrapper input:-webkit-autofill:active {
  transition: background-color 5000s;
}

.admin-product-search-wrapper
  input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

.admin-product-search-wrapper .CMSearchIcon::before {
  font-size: 14px !important;
}

.admin-product-search-wrapper .CMSearchIcon {
  margin-top: 7px;
  margin-right: 9px;
  vertical-align: top;
  position: absolute;
  right: 4px;
  padding: 3px 0px 4px 0px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #334781;
}

.admin-product-search-right .p-dropdown {
  border: 1px solid #afb0b2 !important;
  min-height: 26px !important;
  line-height: 22px;
  width: 100%;
}
.admin-product-search-right .p-dropdown .p-dropdown-filter-container {
  width: 100%;
}

.admin-product-search-right .p-multiselect {
  border: 1px solid #afb0b2 !important;
  min-height: 26px !important;
  line-height: 19px;
  width: 100%;
}
.admin-product-search-right .p-multiselect .p-multiselect-label.p-placeholder {
  color: #333333;
}
.admin-product-search-right .p-multiselect-panel {
  width: 80%;
}
.admin-product-search-right
  .p-multiselect
  .p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container {
  width: 80%;
}

body .admin-product-search-wrapper-wrap.search-refresh-wrap {
  margin-left: 8px;
  margin-top: 42px;
  position: relative !important;
  cursor: pointer;
}

body
  .admin-product-search-wrapper-wrap.search-refresh-wrap
  .search-refresh-icon {
  content: url("../src/fonts/CMRefresh.svg") !important;
  height: 15px !important;
  width: 15px;
  fill: #0052ce;
}

body
  .admin-product-search-wrapper-wrap.search-refresh-wrap
  .search-refresh-text {
  padding: 4px;
  vertical-align: top;
  font-size: 16px;
  font-weight: 500;
  color: #0052ce;
}
.p-input-icon-left-cm.admin-product-search-wrapper {
  margin-top: 6.5px;
}

.label-prd-show {
  font-size: 13px;
  color: #142933;
}

.admin-product-search-right label {
  font-size: 13px;
  color: #142933;
  padding-bottom: 6px;
  display: inline-block;
}

/***Admin Product Search end**/
/* .payout-level-search-wrapper{ height: 80px;} */
.payout-level-search-wrapper .search-value {
  width: 300px;
}

.payout-search-main {
  width: 516px;
  height: 40px;
  padding-top: 22px;
  left: 10px;
}
#payoutlevel-search-filter {
  width: 120px;
}
#payoutlevel-search-value {
  width: 320px;
  color: rgb(20, 41, 51);
}
#payoutlevel-search-filter,
#payoutlevel-search-value {
  height: 34px;
  background-color: transparent;
}
#payoutlevel-search-value,
#payoutlevel-search-filter {
  border: none;
}

body .payoutlevel-search-icon-right {
  height: 36px;
  border: 1px solid #afb0b2;
  border-radius: 4px;
}
.payoutlevel-search-icon {
  margin: auto;
  padding-right: 10px;
  margin-top: 10px;
}
/* body .p-dropdown:not(.p-disabled).p-focus */
body #payoutlevel-search-filter.p-focus:focus,
body #payoutlevel-search-value:focus {
  box-shadow: none !important;
}
#payoutlevel-search-filter .p-input-overlay-visible {
  top: 36px;
}
.payoutlevel-search-container .connect-search-refresh {
  margin-left: -16px;
}

.SSR-container-main #alertcard {
  padding: 50px 20px 6px 20px;
}

.wrt-number-wrap #writingNumber {
  width: 265px;
}

.taskCheckboxDisabled .p-checkbox .p-checkbox-box {
  pointer-events: none;
  border: 1px solid #ddd;
}
.taskCheckboxDisabled .p-checkbox .p-checkbox-box:hover {
  pointer-events: none;
  border: 1px solid #ddd;
}
.agent-contact-chk-bx {
  margin-top: 5px;
}
.agent-commission-note {
  font-size: 13px;
  opacity: 0.7;
  margin-left: 8px;
  margin-bottom: 8px;
  display: block;
}

/*** Warning and success Toaster Message***/

.CMToaster .Toastify__toast--success {
  background: linear-gradient(
    to right,
    #d8f5d9 62px,
    white 0%,
    white 150%,
    white 30%
  ) !important;
  min-width: 300px;
  right: -3px;
  border: 1px solid #dfdedd;
}

.CMToaster .Toastify__toast-icon {
  margin-right: 36px !important;
  margin-left: 4px;
}

.CMToaster .Toastify__toast-body > div:last-child {
  max-width: 245px !important;
  color: #434a51;
  font-size: 12px;
  font-family: Lato !important;
}

.CMToaster .Toastify__close-button--light {
  opacity: 1;
}

.CMToaster .Toastify__close-button > svg {
  content: url("./fonts/ssrClose.svg");
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4%;
  fill: #0052ce;
  font-weight: 400;
  opacity: 1;
}

.Toastify__toast.validation-toaster {
  background: #fff !important;
  padding: 0;
  width: 372px;
  color: #434a51;
  font-size: 12px;
  font-family: Lato !important;
  right: 57px;
  border: 1px solid #dfdedd;
  border-top-width: 1.5px;
}

.validation-toaster .Toastify__toast-body {
  padding: 0;
}

.validation-toaster .Toastify__toast-body > div:last-child {
  max-width: 245px !important;
}

.validation-toaster .Toastify__toast-icon {
  background-image: url("./fonts/warning.svg");
  width: 62px;
  height: 62px;
  margin-right: 12px !important;
  margin-left: 0;
}

.validation-toaster .Toastify__toast-icon img {
  display: none;
}

.validation-toaster .fa.fa-close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
}

.validation-toaster .fa-close:before {
  content: url("./fonts/validation-msg-close.svg") !important;
  cursor: pointer;
}

.validation-toaster.website .Toastify__toast-icon {
  background-image: url("./fonts/error.svg") !important;
}

/*** Warning Toaster Message End***/

.agent-contact-text-bold {
  font-weight: bold !important;
  color: #000 !important;
}

body .agent-summary-contact-add #alertcard {
  margin-top: 50px !important;
}
.screen-main-title {
  font-size: 32px;
  font-weight: 400;
  color: #052a63;
  margin: 18px 0 6px -11px;
  font-family: Lato !important;
}

/**View Inactive Carrier CSS Start**/
.inactive-carriers-selection label {
  color: #0052ce !important;
  margin-left: 2px !important;
  padding-left: 0 !important;
  font-size: 16px !important;
  font-weight: 400;
  vertical-align: top;
  font-family: Lato !important;
}

.inactive-carriers-selection #showInactiveCarriers .p-checkbox-box {
  width: 15px;
  height: 15px;
  border-radius: 3px;
  border: 1.5px solid #0052ce;
  background: #fff;
}

.inactive-carriers-selection
  #showInactiveCarriers.p-checkbox
  .p-checkbox-box.p-highlight {
  border-color: #0052ce !important;
  background-color: #0052ce !important;
}

.inactive-carriers-selection
  .p-checkbox
  .p-checkbox-box:not(.p-disabled).p-focus {
  box-shadow: none !important;
}

.inactive-carriers-selection
  #showInactiveCarriers
  .p-checkbox-icon.p-c.pi.pi-check {
  padding-top: 2.8px !important;
  font-size: 8.5px;
}

.inactive-carriers-selection #showInactiveCarriers .p-checkbox-icon {
  padding-top: 0 !important;
  font-weight: bold;
  font-size: 6px;
}

.inactive-carriers-selection .p-checkbox .p-checkbox-box .p-checkbox-icon {
  background-color: #0052ce !important;
  border-color: #0052ce !important;
}

.inactive-carriers-selection .p-checkbox-label {
  vertical-align: baseline;
}

/**View Inactive Carrier CSS End**/

.bob-container iframe {
  height: 100vh !important;
  width: 100%;
}

.debt-container {
  margin: -20px;
}

.debt-container iframe {
  width: 100%;
}

.bookOfBusiness-wrapper-main .p-card-body,
.goals-wrapper-main .p-card-body,
.universalUpload-wrapper-main .p-card-body,
.layout-main:has(.bookOfBusiness-wrapper-main),
.layout-main:has(.goals-wrapper-main),
.layout-main:has(.universalUpload-wrapper-main) {
  padding: 0;
}

#root:has(.bookOfBusiness-wrapper-main),
#root:has(.goals-wrapper-main),
#root:has(.universalUpload-wrapper-main) 
{
  overflow: hidden !important;
}

.layout-main:has(.goals-wrapper-main) {
  padding-top: 70px !important;
}

.goals-wrapper-main {
  border-radius: unset !important;
  box-shadow: unset !important;
}

body .p-card.goals-wrapper-main {
  background: transparent !important;
}

.p-dialog-mask {
  overflow-y: auto;
  z-index: 9999 !important;
}

.insured-date-selected .search-form-group {
  display: flex;
}
.insured-date-selected #divsearchInputValue {
  width: 80%;
}

body .insured-dob-wrap .p-datepicker .p-datepicker-buttonbar {
  min-height: 40px;
}
body .insured-dob-wrap .p-datepicker .p-datepicker-buttonbar .p-button {
  left: 11px;
  right: auto;
}

/* New Spacers */
.ml-1 {
  margin-left: .25rem !important;
}

.ml-2 {
  margin-left: .5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.add-role-wrap #add-role-btn{
  position: relative;
  margin-right: -29px;
}



body .Toastify__toast-container {
  z-index: 99999 !important;
}
 
.agent-merge-radio-grid div{
  font-weight: 600;
  background-color: #506ac3;
  color: white; 
  padding-left: 10px;
  line-height: 36px;
  border-width: 0.1px;
  border-color: #d0d2d9;
}
.agent-merge-radio-td {
  border-width: 0.1px;
  border-style: solid;
  border-color: #dfdedd;
  padding-left: 10px;
  line-height: 36px;
}
.agent-edit-save-cancel-btns-disabled #saveButton,
.create-update-agent-btn-disabled #saveButton {
  pointer-events: none;
  opacity: 0.5;
}

.break-word{
  word-break: break-word;
}