.custom-dialog-container {
  position: fixed;
  z-index: calc(var(--header-z-index) + 1);
  background-color: rgba(0, 0, 0, 0.4);
  padding: 5px;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  inset: 0; 

  .custom-dialog {
    background-color: #fff;
    border-radius: 3px;
    margin: 2rem auto;
    overflow: visible;
    width: 36vw; 

    .content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .title {
        font-size: 16px;
        font-weight: 400;
        color: #0052ce;
        margin-left: 15px;
      }

      .close {
        color: #0052ce;
        float: right;
        margin-right: 14px;
        margin-left: 14px;
        font-size: 31px;
        font-weight: 100;

        &:hover,
        &:focus,
        &:focus-visible {
          color: #000;
          text-decoration: none;
          cursor: pointer;
        }
      }

      .custom-focus {
        &:focus,
        &:focus-visible {
          outline: 0 none;
          outline-offset: 0;
          box-shadow: 0 0 0 0.1em #8dcdff;
          border-color: #007ad9;
        }
      }
    }
  }
}
