.email_body_modal_container {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
  max-width: 750px;
  height: 450px;
  overflow: auto;
  margin-top: 20px;
}

.email_body_modal_content {
  margin-bottom: 30px;
  margin-top: 10px;
  width: 100%;
}

.email_body_modal_container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: white;
  margin-left: 20px;
}

.email_body_modal_container::-webkit-scrollbar {
  width: 6px;
  height: 16px;
  background-color: white;
  margin-left: 20px;
}

.email_body_modal_container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: lightgray;
  margin-left: 20px;
}




body .template-test-modal ol {
  list-style: none;
  counter-reset: item;
  margin-left: -10px;
}

body .template-test-modal li {
  counter-increment: item;
  margin-bottom: 5px;
}

body .template-test-modal li:before {
  margin-right: 0px;
  margin-left: -30px;
  content: counter(item) ".";
  background: transparent;
  border-radius: 100%;
  color: #434a51;
  width: 1.2em;
  text-align: center;
  display: inline-block;
}

body .custom-dialog-container .template-test-modal {
  border-radius: 8px;
  color: #434a51;
  font-family: "Lato";
}

body .custom-dialog-container .template-test-modal {
  width: 700px;
}

body .custom-dialog-container .template-test-modal .content .title {
  font-size: 24px;
  color: #002d72;
  font-weight: 400;
  width: 610px;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

body .custom-dialog-container .template-test-modal .content {
  min-height: 56px;
  border-bottom: 0.5px solid #dfdedd;
}

body .custom-dialog-container .template-test-modal .email-template-place-holder,
body .custom-dialog-container .template-test-modal .email-logs-content {
  font-size: 16px;
}

body .custom-dialog-container .template-test-modal .email-logs-content>div p:last-child span.email-template-place-holder,
body .custom-dialog-container .template-test-modal .email-logs-content>div p:last-child {
  font-size: 12px;
}

body .custom-dialog-container .template-test-modal .email-logs-content>div>p:first-child>img:first-child {
  /* margin-top: 210px; */
}