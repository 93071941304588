.email_search_cotainer {
  display: flex;
  justify-content: start;
}

.email_search_buttons {
  margin-top: 25px;
}

@media (max-width: 767px) {
  .email_search_cotainer {
    display: flex;
    flex-direction: column;
  }
  .email_search_buttons {
    margin-top: 0;
    margin-bottom: 6px;
    text-align: right;
  }
}
