.email_view_column {
  width: 50px;
  text-align: center;
}

.email_view_created_date {
  width: 15%;
}

@media (max-width: 640px) {
  .email_view_column {
    text-align: left;
  }
}
