@font-face {
    font-family: 'Lato';
    src: url('./fonts/Lato-Regular.woff') format('woff');
    font-weight: 100;
}

body .connect-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item,
body .connect-new-modal .p-inputtext,
body .connect-new-modal textarea,
body .connect-new-modal label {
    font-family: 'Lato';
    font-size: 16px;
    color: #434a51 !important;
}

body .connect-dropdown .p-dropdown-panel .p-dropdown-items-wrapper::-webkit-scrollbar,
.connect-scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: white;
}

body .connect-dropdown .p-dropdown-panel .p-dropdown-items-wrapper::-webkit-scrollbar-thumb,
.connect-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: lightgray;
}

body .connect-dropdown .p-dropdown-panel .p-dropdown-items-wrapper::-webkit-scrollbar-track,
.connect-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: white;
}



body .connect-new-modal.p-dialog .p-dialog-titlebar .p-dialog-title {
    color: #002d72;
    font-size: 24px;
    font-weight: 500 !important;
}

body .connect-new-modal.p-dialog .p-dialog-titlebar {
    border-radius: 8px 8px 0px 0px !important;
}

body .connect-new-modal.p-dialog-content {
    border-radius: 0px 0px 8px 8px;
}

body .connect-new-modal.p-dialog {
    box-shadow: none;
}

body .connect-new-modal .p-inputtextarea,
body .connect-new-modal textarea,
body .connect-new-modal .p-dropdown {
    border: 0.5px solid #dfdedd !important;
}

body .connect-new-modal .p-button.primary-button {
    background-color: #0052ce;
    border: 1px solid #0052ce;
}

body .connect-new-modal   .pi-chevron-down:before {
    content: url("./fonts/cm-chevron-down.svg");
}


body .connect-new-modal .dropzone {
    border: 2px dashed #0052ce;
    min-height: 170px;
    max-height: 220px;
    margin-top: -5px;
    border-radius: 10px;
    outline: none;
}
body .connect-new-modal .dropzone .dz-message{
    margin-top: 20px;
}
body .connect-new-modal .dropzone.dropzone-reject {
    border: 2px dashed #f44;
}
body .connect-new-modal  .dropzone .dz-message .note{
    font-size: 20px;
    font-weight: 300;
    color: #0052ce;
    margin-top: 13px;
}
body .connect-new-modal .dropzone .dz-message .note.validation-error.size-err {
    margin-top: 5px;
}
body .connect-new-modal  .dropzone.dropzone-reject .dz-message .note{
    font-size: 20px;
    font-weight: 300;
    color: #f44;
}

body .connect-dropdown .p-dropdown-filter-container {
    width: 100%;
}

body .connect-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
body .connect-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group.p-highlight {
    background-color: #EDEDEE !important;
    color: #0052ce !important;
}

body .connect-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    margin: 5px;
    border-radius: 4px;
}

body .connect-new-modal .connect-upload-button .pi-upload {
    position: relative;
    margin-bottom: 3px;
}

body .connect-new-modal .connect-upload-button .p-button-text {
    color: #434a51;
}

body .connect-new-modal .connect-upload-button .p-button.primary-button {
    background-color: #fff;
    border-color: #fff;
}

.connect-upload-button .pi-upload::before {
    content: url('./fonts/cloud-arrow-up.svg');
}

.connect-upload-btn-container {
    display: grid;
}

.connect-upload-btn-text {
    font-size: 18px;
    color: #000;
    font-family: 'Lato';
    font-weight: 300;
}
.connect-new-ckbox-label{
    font-size: 16px;
    color: #434a51;
}
.connect-new-ckbox-label-selected{
    color: #0052ce;
    font-weight: 700;
}
.connect-dropdown{
    height: 40px;
}
.connect-dropdown label{
    padding: 11px !important;
}

.connect-dropdown .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon{
    top: 46%;
}

.connect-optional-label {
    font-size: 16px;
    color: #434a51;
}
.connect-optional-label span{
    font-size: 16px;
    color: #434a51;
    opacity: 0.6;
}

.connect-upload-btn-container .c-info-icon{
    margin-top:4px;
}
.connect-agent-add-doc-footer #cust-Attri-Save-Btn{
    min-height: 31.5px;
}

.connect-agent-add-doc-footer #cust-Attri-Save-Btn .p-button-text{
    padding: 0.449em 1em 0.429em !important;
    margin-top: 1px;
}

.connect-disabled-footer{
    pointer-events: none;
}




.connect-search-main {
    width: 650px;
    padding: 23px;
}

.connect-search-icon-right {
    border: 0.5px solid #dfdedd;
    border-radius: 4px;
    min-height: 40px;
}

#connect-search-value,
#connect-search-filter {
    border: none;
}

#connect-search-value {
    width: 332px;
}

#connect-search-filter {
    width: 124px;
}


#connect-search-value:focus,
#connect-search-filter:focus {
    box-shadow: none;
}

.connect-search-refresh {
    margin-left: 14px;
}

.connect-search-refresh-text {
    color: #0052ce;
    font-size: 14px;
    font-weight: 400;
}

#connect-search-value::placeholder {
    color: #AAAAAA;
}


.connect-show-more{
    display: flex;
    margin: auto;
    width: fit-content;
    cursor: pointer;
    height: fit-content;
  }
  
  .connect-show-more-icon{
    margin-right: 15px;
    margin-top: 8px;
  }
  
  .connect-show-more-icon::before{
    content: url("./fonts/SSR-down-arrow.svg");
  }
  
  .connect-show-more-text{
    font-size: 24px;
    color: #0052ce; 
    text-align: center;
  }
  